import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import Admin from 'layouts/Admin/Admin';

export const PublicRoute = ({ component: Component, ...rest }) => {
  let location = useLocation();
  const accessToken = useSelector(state => {
    return get(state, 'auth.tokens.user.token', null) ||
      typeof window !== 'undefined'
      ? localStorage.getItem('token')
      : null;
  });

  return (
    <Route {...rest}>
      {!accessToken ? (
        // not logged in so show the login page
        <Component />
      ) : (
        // authorised so go to default Page
        <Redirect
          exact
          to={{
            pathname: '/investments',
            state: { from: (location.state || {}).from },
          }}
        />
      )}
    </Route>
  );
};

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const accessToken = useSelector(state => {
    return (
      get(state, 'auth.tokens.user.token', null) ||
      localStorage.getItem('token')
    );
  });
  return (
    <Route {...rest}>
      {!accessToken ? (
        // not logged in so redirect to login page with the return url
        <Redirect
          exact
          to={{
            pathname: '/auth/login',
            state: { from: location.pathname },
          }}
        />
      ) : (
        <Admin>
          <Component />
        </Admin>
      )}
    </Route>
  );
};
