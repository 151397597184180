import * as actions from '../actions/deprecated-documents';

const initialState = {
  deprecatedDocuments: {
    isInProgress: false,
    isError: false,
    message: '',
    data: {},
  },
  details: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
    data: {},
  },
  download: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
  },
  updateDocument: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
  },
};

const deprecatedDocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_DEPRECATED_DOCUMENTS_INPROGRESS:
      return {
        ...state,
        deprecatedDocuments: {
          ...state.deprecatedDocuments,
          isInProgress: true,
        },
      };

    case actions.FETCH_DEPRECATED_DOCUMENTS_SUCCESS:
      return {
        ...state,
        deprecatedDocuments: {
          ...state.deprecatedDocuments,
          isInProgress: false,
          data: action.data,
        },
      };
    case actions.FETCH_DEPRECATED_DOCUMENTS_FAILURE:
      return {
        ...state,
        deprecatedDocuments: {
          ...state.deprecatedDocuments,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case actions.FETCH_DEPRECATED_DOCUMENT_DETAILS_INPROGRESS:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: true,
        },
      };
    case actions.FETCH_DEPRECATED_DOCUMENT_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: false,
          status: 1,
          data: action.data,
          isError: false,
          error: '',
        },
      };
    case actions.FETCH_DEPRECATED_DOCUMENT_DETAILS_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };
    case actions.DOWNLOAD_DEPRECATED_DOCUMENT_PREIVEW_INPROGRESS:
      return {
        ...state,
        download: {
          ...state.download,
          isInProgress: true,
        },
      };
    case actions.DOWNLOAD_DEPRECATED_DOCUMENT_PREIVEW_SUCCESS:
      return {
        ...state,
        download: {
          ...state.download,
          isInProgress: false,
          status: 1,
          isError: false,
          error: '',
        },
      };
    case actions.DOWNLOAD_DEPRECATED_DOCUMENT_PREIVEW_FAILURE:
      return {
        ...state,
        download: {
          ...state.download,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };
    case actions.UPDATE_DEPRECATED_DOCUMENT_DETAILS_INPROGRESS:
      return {
        ...state,
        updateDocument: {
          ...state.updateDocument,
          isInProgress: true,
        },
      };
    case actions.UPDATE_DEPRECATED_DOCUMENT_DETAILS_SUCCESS:
      return {
        ...state,
        updateDocument: {
          ...state.updateDocument,
          isInProgress: false,
          status: 1,
          isError: false,
          error: '',
        },
      };
    case actions.UPDATE_DEPRECATED_DOCUMENT_DETAILS_FAILURE:
      return {
        ...state,
        updateDocument: {
          ...state.updateDocument,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default deprecatedDocumentsReducer;
