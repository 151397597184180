import Request from './request';

export const getDocuments = async (
  page,
  sort,
  query,
  limit,
  startDate,
  endDate,
  type
) =>
  await Request.call({
    url: '/documents',
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
      limit,
      startDate,
      endDate,
      type,
    },
  });

export const fetchDocumentsData = async (id, type) =>
  await Request.call({
    url: `/documents/${id}`,
    method: 'GET',
    params: {
      type,
    },
  });

export const downloadDocument = async (id, type) =>
  await Request.call({
    url: `/documents/${id}/preview`,
    method: 'GET',
    params: {
      type,
    },
  });

export const verifyDocument = async (id, data) =>
  await Request.call({
    url: `/documents/${id}/verify`,
    method: 'POST',
    data,
  });

export const addDocumentActivity = async (id, data, type) =>
  await Request.call({
    url: `/documents/${id}/activity`,
    method: 'POST',
    data: {
      ...(type ? { type } : null),
      ...data,
    },
  });

export const fetchAllDocumentData = async () =>
  await Request.call({
    url: `/documents/all`,
    method: 'GET',
  });

export const getDocumentsByUserId = async (userId, type) =>
  await Request.call({
    url: `/documents/user/${userId}`,
    method: 'GET',
    params: {
      type,
    },
  });

export const reSendEmailDocumentsUpload = async (id, message, type) =>
  await Request.call({
    url: `/documents/${id}/email`,
    method: 'POST',
    ...(message ? { data: { message, type } } : { type }),
  });

export const bulkReSendEmailDocumentsUpload = async (documentIds, message) => {
  return await Request.call({
    url: `/documents/sendBulkEmail`,
    method: 'POST',
    data: { documentIds, ...(message ? { message } : {}) },
  });
};
