import Request from './request';

export const fetchAllUsers = async (page, sort, query, limit) =>
  await Request.call({
    url: '/users',
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
      limit,
    },
  });

export const fetchUserDetail = async id =>
  await Request.call({
    url: `/users/${id}`,
    method: 'GET',
  });

export const addUser = async data =>
  await Request.call({
    url: '/users',
    method: 'POST',
    data: {
      name: data.name,
      email: data.email,
      role: data.roles,
    },
  });

export const updateUser = async (id, data) =>
  await Request.call({
    url: `/users/${id}`,
    method: 'PATCH',
    data,
  });

export const getRolesDropdown = async () =>
  await Request.call({
    url: `/roles/dropdown`,
    method: 'GET',
  });

export const resetUserPassword = async (
  id,
  password,
  new_password,
  confirm_password
) =>
  await Request.call({
    url: `/users/${id}/reset-password`,
    method: 'PUT',
    data: {
      password,
      new_password,
      confirm_password,
    },
  });
