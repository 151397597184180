import React from 'react';
import styles from './dropdown.module.scss';
import cs from 'classnames';

const Dropdown = ({ className, overlayClassname, content, onClickOverlay }) => {
  return (
    <>
      <div className={cs(styles.dropdown, className)}>{content}</div>
      <div
        className={cs(styles.overlay, overlayClassname)}
        onClick={onClickOverlay}
      />
    </>
  );
};

export default Dropdown;
