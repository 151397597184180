import Request from './request';

export const fetchPermissions = async (page, sort, query, limit) =>
  await Request.call({
    url: '/permissions',
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
      limit,
    },
  });

export const fetchPermissionID = async id =>
  await Request.call({
    url: `/permissions/${id}`,
    method: 'GET',
  });

export const deletePermission = async id =>
  await Request.call({
    url: `/permissions/${id}`,
    method: 'DELETE',
  });

export const addPermission = async data =>
  await Request.call({
    url: '/permissions',
    method: 'POST',
    data,
  });

export const updatePermission = async (id, data) =>
  await Request.call({
    url: `/permissions/${id}`,
    method: 'PUT',
    data,
  });

export const availablePermissions = async () =>
  await Request.call({
    url: `/permissions/all`,
    method: 'GET',
  });
