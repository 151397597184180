import * as actions from '../actions/leads';

const initialState = {
  leads: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
    data: {},
  },
  details: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
    data: {},
  },
  rejectLead: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
  },
  addActivity: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
  },
  exportToCsv: {
    isInProgress: true,
    isError: false,
    error: '',
    data: {},
  },
};

const leadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_LEADS_INPROGRESS:
      return {
        ...state,
        leads: {
          ...state.leads,
          isInProgress: true,
        },
      };

    case actions.FETCH_LEADS_SUCCESS:
      return {
        ...state,
        leads: {
          ...state.leads,
          isInProgress: false,
          status: 1,
          data: action.data,
          isError: false,
          error: '',
        },
      };
    case actions.FETCH_LEADS_FAILURE:
      return {
        ...state,
        leads: {
          ...state.leads,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };

    case actions.FETCH_LEADS_DETAIL_INPROGRESS:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: true,
        },
      };

    case actions.FETCH_LEADS_DETAIL_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: false,
          status: 1,
          data: action.data,
          isError: false,
          error: '',
        },
      };

    case actions.FETCH_LEADS_DETAIL_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };
    case actions.REJECT_LEAD_INPROGRESS:
      return {
        ...state,
        rejectLead: {
          ...state.rejectLead,
          isInProgress: true,
        },
      };
    case actions.REJECT_LEAD_SUCCESS:
      return {
        ...state,
        rejectLead: {
          ...state.rejectLead,
          isInProgress: false,
          status: 1,
          isError: false,
          error: '',
        },
      };
    case actions.REJECT_LEAD_FAILURE:
      return {
        ...state,
        rejectLead: {
          ...state.rejectLead,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };

    case actions.ADD_LEAD_ACTIVITY_INPROGRESS:
      return {
        ...state,
        addActivity: {
          ...state.addActivity,
          isInProgress: true,
        },
      };
    case actions.ADD_LEAD_ACTIVITY_SUCCESS:
      return {
        ...state,
        addActivity: {
          ...state.addActivity,
          isInProgress: false,
        },
        details: {
          ...state.details,
          data: action.data,
          isError: false,
          error: '',
        },
      };
    case actions.ADD_LEAD_ACTIVITY_FAILED:
      return {
        ...state,
        addActivity: {
          ...state.addActivity,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };

    case actions.EXPORT_LEAD_CSV_INPROGRESS:
      return {
        ...state,
        exportToCsv: {
          ...state.exportToCsv,
          isInProgress: true,
        },
      };
    case actions.EXPORT_LEAD_CSV_SUCCESS:
      return {
        ...state,
        exportToCsv: {
          ...state.exportToCsv,
          isInProgress: false,
        },
      };
    case actions.EXPORT_LEAD_CSV_FAILED:
      return {
        ...state,
        exportToCsv: {
          ...state.exportToCsv,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };
    default:
      return state;
  }
};

export default leadsReducer;
