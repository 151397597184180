import React, { useState } from 'react';
import cs from 'classnames';
import Input from 'components/Input';
import Button from 'components/Button';
import Modal from 'components/Modal';
import {
  createNewPermission,
  fetchPermissionDetail,
  updatePermissionById,
} from 'store/actions/permissions';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Plus } from 'react-feather';
import { fetchAvailablePermissions } from 'store/actions/roles';

const PermissionForm = ({ onModalClose, data, viewMode }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const {
    formState: { errors },
    register,
    watch,
    setValue,
    setError,
    handleSubmit,
  } = useForm({
    defaultValues: {
      page_name: data?.page_name || '',
      newPermission: '',
      permissions: data.permissions || [],
    },
    resolver: yupResolver(
      Yup.object({
        page_name: Yup.string().required('Permission is Required'),
        permissions: Yup.array().min(1, 'At least 1 Permission is Required'),
      })
    ),
  });

  const createPermission = async values => {
    // eslint-disable-next-line no-unused-vars
    const { newPermission, ...rest } = values;
    if (loading) {
      return;
    }
    setLoading(true);
    if (!viewMode) {
      await dispatch(createNewPermission(rest));
    } else {
      await dispatch(updatePermissionById(data?._id.toString(), rest));
      await dispatch(fetchPermissionDetail(data?._id.toString()));
    }
    setLoading(false);
    onModalClose(true);
    dispatch(fetchAvailablePermissions());
  };

  const permissionsToAdd = watch('permissions');
  const newPermission = watch('newPermission');

  const onAddNewPermission = () => {
    setError('permissions', {});
    setValue('permissions', [...permissionsToAdd, newPermission]);
    setValue('newPermission', '');
  };

  return (
    <Modal
      isOpen
      hasCloseIcon
      handleCloseClick={onModalClose}
      containerClassname="max-w-screen-sm"
      onClick={onModalClose}
      title={!viewMode ? 'Add a Permission' : 'Edit Permission'}
    >
      <div
        className={cs('flex w-full flex-col mt-4', {
          'items-center justify-center': loading,
          'items-start': !loading,
        })}
      >
        <form className="w-full">
          <Input
            id="page_name"
            name="page_name"
            wrapperClassName="w-full"
            placeholder="Page Name"
            error={errors.page_name?.message}
            register={register}
          />
          <div className="flex mt-4">
            <Input
              id="newPermission"
              name="newPermission"
              wrapperClassName="w-full mr-3"
              placeholder="Create New Permission"
              error={errors.permissions?.message}
              register={register}
            />
            <Button
              type="button"
              onClick={onAddNewPermission}
              label={<Plus />}
            />
          </div>

          {permissionsToAdd && permissionsToAdd.length ? (
            <div className="flex flex-col mt-4">
              <span className="font-bold">
                Following Permissions will be added
              </span>
              <ul className="flex flex-col w-full list-disc pl-4">
                {permissionsToAdd.map((p, index) => (
                  <li className="py-2" key={index}>
                    {p}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          <div className="w-full my-4 flex justify-between">
            <Button
              onClick={onModalClose}
              variant="transparent"
              className="mr-3"
              label="Cancel"
            />
            <Button
              isLoading={loading}
              onClick={handleSubmit(createPermission)}
              label={!viewMode ? 'Create' : 'Save'}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default PermissionForm;
