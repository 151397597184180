import Request from './request';

export const fetchReservationsList = async (page, sort, query, limit) =>
  await Request.call({
    url: '/reservations',
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
      limit,
    },
  });

export const fetchReservationDetailList = async id =>
  await Request.call({
    url: `/reservations/${id}`,
    method: 'GET',
  });

export const fetchAllReservationData = async () =>
  await Request.call({
    url: `/reservations/all`,
    method: 'GET',
  });

export const addReservationActivity = async (id, data) =>
  await Request.call({
    url: `/reservations/${id}/activity`,
    method: 'POST',
    data,
  });
