import React from 'react';
import cs from 'classnames';
import Input from 'components/Input';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { useForm } from 'react-hook-form';

const DocumentsUploadEmailMessage = ({
  onModalClose,
  onDocumentsUploadEmailMessageClick,
}) => {
  const { register, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      message: null,
    },
  });

  const sendEmail = async values => {
    onDocumentsUploadEmailMessageClick(values.message);
    onModalClose(true);
  };

  return (
    <Modal isOpen containerClassname="sm:max-w-sm" onClick={onModalClose}>
      <div className={cs('flex w-full flex-col ')}>
        <form className="w-full">
          <Input
            id="message"
            name="message"
            wrapperClassName="w-full my-4"
            placeholder="Message"
            label="Email Message"
            register={register}
          />

          <div className="w-full my-4 flex justify-between">
            <Button
              onClick={onModalClose}
              variant="transparent"
              label="Cancel"
            />
            <Button
              type="submit"
              onClick={handleSubmit(sendEmail)}
              label="Send"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default DocumentsUploadEmailMessage;
