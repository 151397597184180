import * as actions from '../actions/documents';

const initialState = {
  documentState: {
    isInProgress: false,
    isError: false,
    message: '',
    data: {},
  },
  documentDetailsState: {
    isInProgress: false,
    isError: false,
    message: '',
  },
  details: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
    data: {},
  },
  download: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
  },
  update: {
    isInProgress: false,
    isError: false,
    status: 0,
    data: null,
    error: '',
  },
  addActivity: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
  },
  reSendDocumentUploadEmail: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
  },
  bulkReSendDocumentUploadEmail: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
  },
};

const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_DOCUMENTS_INPROGRESS:
      return {
        ...state,
        documentState: {
          ...state.documentState,
          isInProgress: true,
        },
      };

    case actions.FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documentState: {
          ...state.documentState,
          isInProgress: false,
          data: action.data,
        },
      };
    case actions.FETCH_DOCUMENTS_FAILURE:
      return {
        ...state,
        documentState: {
          ...state.documentState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case actions.FETCH_DOCUMENT_DETAILS_INPROGRESS:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: true,
        },
      };
    case actions.FETCH_DOCUMENT_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: false,
          status: 1,
          data: action.data,
          isError: false,
          error: '',
        },
      };
    case actions.FETCH_DOCUMENT_DETAILS_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };
    case actions.DOWNLOAD_DOCUMENT_PREIVEW_INPROGRESS:
      return {
        ...state,
        download: {
          ...state.download,
          isInProgress: true,
        },
      };
    case actions.DOWNLOAD_DOCUMENT_PREIVEW_SUCCESS:
      return {
        ...state,
        download: {
          ...state.download,
          isInProgress: false,
          status: 1,
          isError: false,
          error: '',
        },
      };
    case actions.DOWNLOAD_DOCUMENT_PREIVEW_FAILURE:
      return {
        ...state,
        download: {
          ...state.download,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };
    case actions.VERIFY_DOCUMENT_INPROGRESS:
      return {
        ...state,
        update: {
          ...state.update,
          isInProgress: true,
        },
      };
    case actions.VERIFY_DOCUMENT_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          isInProgress: false,
          status: 1,
          isError: false,
          data: action.data.data,
          error: '',
        },
      };

    case actions.VERIFY_DOCUMENT_FAILURE:
      return {
        ...state,
        update: {
          ...state.update,
          isInProgress: false,
          isError: true,
          data: null,
          error: action.error,
        },
      };
    case actions.ADD_DOCUMENT_ACTIVITY_INPROGRESS:
      return {
        ...state,
        addActivity: {
          ...state.addActivity,
          isInProgress: true,
        },
      };
    case actions.ADD_DOCUMENT_ACTIVITY_SUCCESS:
      return {
        ...state,
        addActivity: {
          ...state.addActivity,
          isInProgress: false,
        },
        details: {
          ...state.details,
          data: action.data,
          isError: false,
          error: '',
        },
      };
    case actions.ADD_DOCUMENT_ACTIVITY_FAILED:
      return {
        ...state,
        addActivity: {
          ...state.addActivity,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };
    case actions.FETCH_DOCUMENTS_BY_USER_INPROGRESS:
      return {
        ...state,
        documentDetailsState: {
          ...state.documentDetailsState,
          isInProgress: true,
        },
      };
    case actions.FETCH_DOCUMENTS_BY_USER_SUCCESS:
      return {
        ...state,
        documentDetailsState: {
          ...state.documentDetailsState,
          isInProgress: false,
        },
      };
    case actions.FETCH_DOCUMENTS_BY_USER_FAILURE:
      return {
        ...state,
        documentDetailsState: {
          ...state.documentDetailsState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case actions.RESEND_DOCUMENTS_UPLOAD_EMAIL_INPROGRESS:
      return {
        ...state,
        reSendDocumentUploadEmail: {
          ...state.update,
          isInProgress: true,
        },
      };
    case actions.RESEND_DOCUMENTS_UPLOAD_EMAIL_SUCCESS:
      return {
        ...state,
        reSendDocumentUploadEmail: {
          ...state.update,
          isInProgress: false,
          status: 1,
          isError: false,
          error: '',
        },
      };
    case actions.RESEND_DOCUMENTS_UPLOAD_EMAIL_FAILURE:
      return {
        ...state,
        reSendDocumentUploadEmail: {
          ...state.update,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };
    case actions.BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_INPROGRESS:
      return {
        ...state,
        bulkReSendDocumentUploadEmail: {
          ...state.update,
          isInProgress: true,
        },
      };
    case actions.BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_SUCCESS:
      return {
        ...state,
        bulkReSendDocumentUploadEmail: {
          ...state.update,
          isInProgress: false,
          status: 1,
          isError: false,
          error: '',
        },
      };
    case actions.BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_FAILURE:
      return {
        ...state,
        bulkReSendDocumentUploadEmail: {
          ...state.update,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default documentsReducer;
