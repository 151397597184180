import * as actions from '../actions/stats';

const initialState = {
  inProgress: false,
  stats: {},
};

const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_HEADERSTATS_INPROGRESS:
      return {
        ...state,
        inProgress: true,
      };

    case actions.FETCH_HEADER_STATS:
      return {
        ...state,
        stats: action.data,
        inProgress: false,
      };

    case actions.FETCH_HEADERSTATS_FAILED:
      return {
        ...state,
        stats: {},
        inProgress: false,
      };
    default:
      return state;
  }
};

export default statsReducer;
