import React from 'react';
import styles from './details.module.scss';

const Details = ({ data, title }) => {
  return (
    <div className={styles.root}>
      <h2>{title}</h2>
      <p>{data}</p>
    </div>
  );
};

export default Details;
