import React, { useEffect, useState } from 'react';
import { ChevronLeft } from 'react-feather';
import { useParams, useHistory } from 'react-router-dom';
import {
  deletePermissionById,
  fetchPermissionDetail,
} from 'store/actions/permissions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PermissionsForm from 'views/Permissions/Permission.form';
import { AlertPopupHandler } from 'components/AlertPopup';
import Details from 'components/Details';
import DropdownMenu from 'components/DropdownMenu';
import LoadingIndicator from 'components/LoadingIndicator';
import Card from 'components/Card';
import get from 'lodash/get';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';

const PermissionDetails = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  let history = useHistory();

  const [modal, setModal] = useState(false);

  const isLoading = useSelector(
    ({ permissions }) => permissions.permissionsDetail.isInProgress
  );

  const permissionData = useSelector(
    ({ permissions }) => permissions.permissionsDetail.data
  );

  useEffect(() => {
    dispatch(fetchPermissionDetail(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onModalClose = () => {
    setModal(false);
  };

  const confirmDelete = async data => {
    await dispatch(deletePermissionById(data?._id.toString()));
    history.push('/permissions');
  };

  const handlePermissionDelete = () => {
    AlertPopupHandler.open({
      type: 'error',
      text: `You are about to delete ${permissionData.label} permission. Do you want to continue?`,
      onConfirm: confirmDelete,
      data: permissionData,
    });
  };

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'permissions'
  );

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <div className="flex items-center justify-between">
              <div className="flex justify-between w-full">
                <Card.Header
                  title={
                    <>
                      <ChevronLeft
                        onClick={() => history.push(`/permissions`)}
                        className="cursor-pointer mr-2"
                      />
                      Permission Details
                    </>
                  }
                />
                <DropdownMenu
                  options={[
                    {
                      label: 'Edit Permission',
                      onClick: () => setModal(true),
                    },
                    {
                      label: 'Delete Permission',
                      onClick: handlePermissionDelete,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3  gap-6 mt-4">
              <Details
                title="Permission"
                data={permissionData?.page_name || ''}
              />
              <Details
                title="Created"
                data={
                  permissionData?.createdAt
                    ? moment(permissionData.createdAt).format('LL')
                    : '-'
                }
              />
              <Details
                title="Last Updated"
                data={
                  permissionData?.updatedAt
                    ? moment(permissionData.updatedAt).format('LL')
                    : ''
                }
              />
            </div>
            <div className="flex w-full mt-4">
              <Details
                title="Allowed Permissions"
                data={
                  <div className="flex flex-col w-full">
                    <ul className="list-disc px-4">
                      {permissionData.permissions
                        ? permissionData.permissions.map((p, index) => (
                            <li className="py-2 capitalize" key={index}>
                              {p}
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                }
              />
            </div>
          </>
        )}
        {modal ? (
          <PermissionsForm
            data={permissionData}
            viewMode="edit"
            onModalClose={onModalClose}
          />
        ) : null}
      </Card>
    </CanAccessComponent>
  );
};

export default PermissionDetails;
