import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import {
  downloadDocumentById,
  fetchDocumentsByUserId,
  reSendDocumentUpload,
  verifyDocumentById,
} from '../../store/actions/documents';
import { byteToReadbleSize } from 'helpers/byteToReadableSize';
import LoadSpinner from 'components/LoadSpinner';
import { CheckCircle, Clock } from 'react-feather';
import moment from 'moment';
import DropdownMenu from 'components/DropdownMenu';
import LoadingIndicator from 'components/LoadingIndicator';
import Table from 'components/Table';
import Checkbox from 'components/Checkbox';
import DocumentsUploadEmailMessage from 'views/Documents/DocumentsUploadEmailMessage.form';

const DocumentsSubRow = ({ row, handleChange, selectedIds, type }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [modal, setModal] = useState(false);
  const history = useHistory();

  const details = useSelector(({ documents }) =>
    get(documents, 'update.data', {})
  );

  useEffect(() => {
    const fetchData = async () => {
      const resp = await dispatch(
        fetchDocumentsByUserId(row?.original?._id, type)
      );
      if (resp.status) {
        setData(resp.data || []);
      }
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData(
      data.map(data => (data._id === details?._id ? { ...details } : data))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  const verifyDocument = async document => {
    await dispatch(
      verifyDocumentById(document._id.toString(), {
        is_verified: !document.is_verified,
        type,
      })
    );
  };

  const onModalClose = () => {
    setModal(false);
  };

  const onReSendDocumentUploadClick = async message => {
    const response = await dispatch(
      reSendDocumentUpload(selectedId, message, type)
    );
    if (response && response.status) {
      setSelectedId('');
    }
  };

  const useColumns = useMemo(
    () => [
      {
        Header: () => 'Selection',
        accessor: 'selection',
        Cell: ({ row }) => (
          <Checkbox
            className="flex-row-reverse"
            checked={selectedIds.some(
              e =>
                e.userId === row.original?.user?._id &&
                e.offering === row.original?.offering?.slug &&
                e.documentId === row.original?._id
            )}
            onChange={event => handleChange(event, row)}
          />
        ),
      },
      {
        Header: () => 'File Name',
        accessor: 'name',
        Cell: ({ value, row }) => {
          return value ? (
            <div
              className="flex items-start cursor-pointer"
              onClick={async () => {
                const resp = await dispatch(
                  downloadDocumentById(row.original._id, type)
                );
                if (resp.status) {
                  window.open(resp.data.url, '_blank');
                }
              }}
            >
              {value}
            </div>
          ) : null;
        },
      },
      {
        Header: 'Size',
        accessor: 'size',
        Cell: ({ value }) =>
          value ? <div>{byteToReadbleSize(Number(value))}</div> : 'N/A',
      },
      {
        Header: 'Offering Slug',
        accessor: 'offering.slug',
        Cell: ({ value }) => (value ? value : 'N/A'),
      },
      {
        Header: 'Type',
        accessor: 'verification_for',
        Cell: ({ value }) =>
          value ? (
            <div className="uppercase">{value.replace('_', ' ')}</div>
          ) : (
            'N/A'
          ),
      },
      {
        Header: 'Document Verified?',
        accessor: 'is_verified',
        Cell: ({ value, row }) => {
          const [loading, setLoading] = useState(false);
          return (
            <div
              className="flex justify-center cursor-pointer"
              onClick={async () => {
                setLoading(true);
                await verifyDocument(row.original);
                setLoading(false);
              }}
            >
              {value ? (
                <>
                  {loading ? (
                    <LoadSpinner color="#d3d3d3" height={35} width={35} />
                  ) : (
                    <>
                      <CheckCircle className="mr-2 text-green-500	" /> Approved
                    </>
                  )}
                </>
              ) : (
                <>
                  {loading ? (
                    <LoadSpinner color="#d3d3d3" height={35} width={35} />
                  ) : (
                    <>
                      <Clock className="mr-2 text-blue-500	" /> Pending
                    </>
                  )}
                </>
              )}
            </div>
          );
        },
      },
      {
        Header: () => (
          <div className="flex justify-end w-full">Uploaded On</div>
        ),
        accessor: 'createdAt',
        Cell: ({ value, row }) => (
          <div className="flex justify-end">
            <span className="flex items-center mr-4">
              {value ? moment(value).format('MMM Do, YYYY') : null}
            </span>
            <DropdownMenu
              options={[
                {
                  label: 'View Document',
                  onClick: () =>
                    history.push(
                      `/documents/${row.original._id}${type ? '?type=aml' : ''}`
                    ),
                },
                {
                  label: 'Re-send Verification Upload Email',
                  onClick: () => {
                    setSelectedId(row.original._id);
                    setModal(true);
                  },
                },
              ]}
            />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIds]
  );

  return (
    <div className="px-6 py-3">
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Table
          columns={useColumns}
          data={data || []}
          total={10}
          showSearch={false}
          withPagination={true}
          withPerPage={false}
          subRowComponentDocument={true}
        />
      )}
      {modal ? (
        <DocumentsUploadEmailMessage
          onModalClose={onModalClose}
          onDocumentsUploadEmailMessageClick={onReSendDocumentUploadClick}
        />
      ) : null}
    </div>
  );
};

export default DocumentsSubRow;
