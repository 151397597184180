import { useParams, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  // addProspectActivityById,
  fetchProspectDetail,
  rejectProspectById,
  sendEmailToProspect,
} from 'store/actions/prospects';
import get from 'lodash/get';
import Details from 'components/Details';
import moment from 'moment';
import { CheckCircle, ChevronLeft } from 'react-feather';
import LoadingIndicator from 'components/LoadingIndicator';
import Card from 'components/Card';
import DropdownMenu from 'components/DropdownMenu';
// import Timeline from 'components/Timeline';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';
import LoadSpinner from 'components/LoadSpinner';

const ProspectDetails = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  let history = useHistory();
  const [emailSent, setEmailSent] = useState(false);

  const prospect = useSelector(({ prospects }) =>
    get(prospects, 'details.data.data', {})
  );
  const isLoading = useSelector(({ prospects }) =>
    get(prospects, 'details.isInProgress', true)
  );

  const isEmailLoading = useSelector(({ prospects }) =>
    get(prospects, 'sendEmail.isInProgress', false)
  );

  useEffect(() => {
    dispatch(fetchProspectDetail(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const rejectProspect = async () => {
    await dispatch(rejectProspectById(id));
    dispatch(fetchProspectDetail(id));
  };

  /*const onActivityCreate = async value => {
    await dispatch(addProspectActivityById(id, value));
  };*/

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'prospects'
  );

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <div className="flex items-center justify-between">
              <div className="flex justify-between w-full">
                <Card.Header
                  title={
                    <>
                      <ChevronLeft
                        onClick={() => history.push(`/prospects`)}
                        className="cursor-pointer mr-2"
                      />
                      Prospect Details
                    </>
                  }
                />
                {!prospect.is_rejected ? (
                  <DropdownMenu
                    options={[
                      {
                        label: 'Reject Prospect',
                        onClick: rejectProspect,
                      },
                    ]}
                  />
                ) : null}
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3  gap-6 mt-4">
              <Details title="Name" data={prospect?.person?.name || '-'} />
              <Details title="Email" data={prospect?.person?.email || '-'} />
              <Details title="Phone" data={prospect?.person?.phone || '-'} />
              <Details title="Type" data={prospect?.type || '-'} />
              <Details
                title="Offering"
                data={prospect?.offering?.name || '-'}
              />
              <Details
                title="Send Email to invest"
                data={
                  <span
                    onClick={async () => {
                      await dispatch(sendEmailToProspect(id));
                      setEmailSent(true);
                    }}
                  >
                    {emailSent ? (
                      <span className="flex items-center">
                        <CheckCircle
                          size={24}
                          className="text-green-500 mr-2"
                        />{' '}
                        Email Sent
                      </span>
                    ) : isEmailLoading ? (
                      <span className="flex items-center">
                        <LoadSpinner
                          color="#ffa400"
                          width={20}
                          height={20}
                          className="mr-2"
                        />{' '}
                        Please wait
                      </span>
                    ) : (
                      'Click here'
                    )}
                  </span>
                }
              />
              <Details
                title="Received On"
                data={
                  prospect?.createdAt
                    ? moment(prospect.createdAt).format('MMM Do, YYYY')
                    : '-'
                }
              />
            </div>
            {/*<Timeline
              allowToCreateActivity
              title="Prospect Activity"
              className="mt-6"
              activities={prospect.activity || []}
              onActivityCreate={onActivityCreate}
            />*/}
          </>
        )}
      </Card>
    </CanAccessComponent>
  );
};

export default ProspectDetails;
