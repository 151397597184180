import React from 'react';

import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import Card from 'components/Card';
import permission_image from './permission.png';

const PermissionError = () => {
  let history = useHistory();

  return (
    <div>
      <Card>
        <div className="flex flex-col items-center justify-center">
          <h2 className="pb-14 text-black font-semibold text-xl">
            Sorry! You don't have permissions to access this page
          </h2>
          <Button
            label={'Go to My Profile'}
            onClick={() => history.push('/profile')}
          />
          <div className="flex flex-col items-center justify-center w-full h-full">
            <img src={permission_image} width={500} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PermissionError;
