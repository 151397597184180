import Table from 'components/Table';
import { get } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersData } from 'store/actions/users';
import { Link, useHistory } from 'react-router-dom';
import DropdownMenu from 'components/DropdownMenu';
import Card from 'components/Card';
import UsersForm from 'views/Users/Users.form';
import Button from 'components/Button';
import cs from 'classnames';
import { useMedia } from 'react-use';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';
const Users = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMaxWidth640px = useMedia('(max-width: 640px)');
  const [modal, setModal] = useState(false);
  const [data, setData] = useState({});
  const [viewMode, setViewMode] = useState(false);

  const users = useSelector(({ users }) => get(users, 'users.data.data', []));

  const usersMetadata = useSelector(({ users }) =>
    get(users, 'users.data.meta', {})
  );

  const handleTableChange = async ({ pageIndex, sortBy, pageSize, query }) => {
    let sort = '';
    if (sortBy.length) {
      sort = sortBy[0].desc ? `-${sortBy[0].id}` : sortBy[0].id;
    }
    await dispatch(getUsersData(pageIndex + 1, sort, query, pageSize));
  };

  const columns = useMemo(
    () => [
      {
        Header: () => 'Name',
        accessor: 'name',
        Cell: ({ value, row }) =>
          value ? (
            <div className="flex items-start">
              <Link to={`/users/${row.original._id}`}>{value}</Link>
            </div>
          ) : null,
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        Cell: ({ value }) =>
          value ? moment(value).format('MMM Do, YYYY') : null,
      },
      {
        Header: () => (
          <div className="flex justify-end w-full">Last Updated</div>
        ),
        accessor: 'updatedAt',
        Cell: ({ value, row }) => (
          <div className="flex justify-end">
            <span className="flex items-center mr-4">
              {value ? moment(value).format('MMM Do, YYYY') : null}
            </span>
            <DropdownMenu
              options={[
                {
                  label: 'View User',
                  onClick: () => history.push(`/users/${row.original._id}`),
                },
                {
                  label: 'Edit User',
                  onClick: () => {
                    setViewMode('edit');
                    setData({ ...row.original });
                    setModal(true);
                  },
                },
                {
                  label: 'Delete User',
                  onClick: () => {},
                },
              ]}
            />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'users'
  );

  const onModalClose = () => {
    setModal(false);
    setViewMode(null);
    setData({});
  };

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        <div
          className={cs('flex justify-between sm:items-center mb-2', {
            'flex-col': isMaxWidth640px,
          })}
        >
          <Card.Header
            title={`Users ${
              usersMetadata && usersMetadata.totalResults
                ? `(${usersMetadata.totalResults})`
                : ''
            }`}
          />
          <Button
            className="mt-2 sm:mt-0"
            onClick={() => setModal(!modal)}
            label="Add User"
          />
        </div>
        <Card.Content className="h-full flex">
          <Table
            columns={columns}
            data={users.length ? users : []}
            total={usersMetadata.totalPages}
            fetchData={handleTableChange}
          />
          {modal ? (
            <UsersForm
              data={data}
              viewMode={viewMode}
              onModalClose={onModalClose}
            />
          ) : null}
        </Card.Content>
      </Card>
    </CanAccessComponent>
  );
};

export default Users;
