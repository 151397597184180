import React, { useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  downloadDocumentForApplication,
  fetchApplicationDetail,
  rejectApplicationById,
  addApplicationActivityById,
} from 'store/actions/applications';
import styles from './applicationsDetail.module.scss';
import cs from 'classnames';
import get from 'lodash/get';
import Details from 'components/Details';
import moment from 'moment';
import Table from 'components/Table';
import { ChevronLeft } from 'react-feather';
import { byteToReadbleSize } from 'helpers/byteToReadableSize';
import Button from 'components/Button';
import Card from 'components/Card';
import LoadingIndicator from 'components/LoadingIndicator';
import DropdownMenu from 'components/DropdownMenu';
import Timeline from 'components/Timeline';
import { AlertPopupHandler } from 'components/AlertPopup';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';

const ApplicationDetails = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  let history = useHistory();

  const application = useSelector(({ applications }) =>
    get(applications, 'details.data.data', {})
  );
  const isLoading = useSelector(({ applications }) =>
    get(applications, 'details.isInProgress', true)
  );

  useEffect(() => {
    dispatch(fetchApplicationDetail(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleDownload = data => async () => {
    const resp = await dispatch(downloadDocumentForApplication(id, data._id));
    if (resp.status) {
      window.open(resp.data.url, '_blank');
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ value }) => (value ? value : 'N/A'),
      },
      {
        Header: 'Size',
        accessor: 'size',
        Cell: ({ value }) =>
          value ? <div>{byteToReadbleSize(Number(value))}</div> : 'N/A',
      },
      {
        Header: 'Download',
        accessor: 'url',
        Cell: ({ cell, value }) =>
          value ? (
            <div className="flex w-full justify-center">
              <Button
                onClick={handleDownload(cell.row.original)}
                label="Click Here to Download"
              />
            </div>
          ) : (
            'N/A'
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleRejectApplication = () => {
    AlertPopupHandler.open({
      type: 'error',
      text: `You are about to reject application from ${application.first_name} ${application.last_name}. Do you want to continue?`,
      onConfirm: rejectApplication,
      data: application,
    });
  };

  const rejectApplication = async () => {
    await dispatch(rejectApplicationById(id));
    dispatch(fetchApplicationDetail(id));
  };

  const onActivityCreate = async value => {
    await dispatch(addApplicationActivityById(id, value));
  };

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'job applications'
  );

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <div className="flex items-center justify-between">
              <Card.Header
                title={
                  <>
                    <ChevronLeft
                      onClick={() => history.push(`/applications`)}
                      className="cursor-pointer mr-2"
                    />
                    Application Details
                  </>
                }
              />
              {!application.is_rejected ? (
                <DropdownMenu
                  options={[
                    {
                      label: 'Reject Application',
                      onClick: handleRejectApplication,
                    },
                  ]}
                />
              ) : null}
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3  gap-6 mt-4">
              <Details
                title="First Name"
                data={application?.first_name || '-'}
              />
              <Details title="Last Name" data={application?.last_name || '-'} />
              <Details title="Email" data={application?.email || '-'} />
              <Details title="Phone" data={application?.phone || '-'} />
              <Details title="About me" data={application?.about_me || '-'} />
              <Details
                title="Is Rejected?"
                data={application?.is_rejected ? 'Yes' : 'No' || '-'}
              />
              <Details
                title="Description"
                data={application?.description || '-'}
              />
              <Details
                title="Applied On"
                data={
                  application?.createdAt
                    ? moment(application.createdAt).format('MMM Do, YYYY')
                    : '-'
                }
              />
            </div>
            {application?.files?.length ? (
              <div className="mt-6">
                <h2 className="text-black font-medium mb-2">Attachments</h2>
                <div className={cs('w-full', styles.attachmentsTable)}>
                  <Table
                    showSearch={false}
                    withPerPage={false}
                    withPagination={false}
                    columns={columns}
                    data={application.files}
                    total={application.files ? application.files.length : 0}
                    fetchData={() => {}}
                  />
                </div>
              </div>
            ) : null}
            <Timeline
              allowToCreateActivity
              title="Application Activity"
              className="mt-6"
              activities={application.activity || []}
              onActivityCreate={onActivityCreate}
            />
          </>
        )}
      </Card>
    </CanAccessComponent>
  );
};
export default ApplicationDetails;
