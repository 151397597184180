import React from 'react';

import Modal from 'components/Modal';

import styles from './IssueRefundModal.module.scss';
import Button from 'components/Button';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Input from 'components/Input';
import { showSnackBar } from 'store/actions/snackbar';
import { accountUpdate } from 'store/api/investments';
const AccountDisapproveModal = ({
  onModalClose,
  modal,
  chosenInvestor,
  reject,
  approve,
  reSendAcc,
}) => {
  const [loadingAccount, setLoadingAccount] = React.useState(false);

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    defaultValues: {
      message: '',
      by: '',
    },
    resolver: yupResolver(
      Yup.object({
        message: Yup.string(),
        by: Yup.string(),
      })
    ),
  });

  const onSubmit = async value => {
    if (value.message.length === 0 && reject === true) {
      return showSnackBar('You must add a message to your rejection', 'error');
    }

    if (reject === true && value.by.length === 0) {
      return showSnackBar('You must add your name', 'error');
    }

    try {
      setLoadingAccount(true);
      const data = await accountUpdate({
        accountId: chosenInvestor.accountId,
        reject,
        approve,
        reSendAcc,
        ...value,
      });
      if (data.err) {
        showSnackBar(JSON.stringify(data), 'error');
      }
      //success
      showSnackBar('', 'success');
      setLoadingAccount(false);
      onModalClose();
    } catch (err) {
      setLoadingAccount(false);
    }
  };

  return (
    <Modal isOpen={modal} onClick={onModalClose} width="500px">
      <div className={styles.container}>
        <div className={styles.container__tradeId}>
          {reSendAcc ? (
            'Re-send Accreditation Email'
          ) : approve ? (
            'Approve'
          ) : reject ? (
            <>
              Reject/Refund <br /> <br />
            </>
          ) : (
            'Disapprove'
          )}{' '}
          {reSendAcc ? null : chosenInvestor?.accountRegistration}
          <br />
          <br />
          {reSendAcc ? null : 'Accreditation'}
        </div>

        {reSendAcc ? (
          <>
            {' '}
            <div className="mt-3 mb-2 text-xl">
              {chosenInvestor?.accountRegistration}
            </div>
          </>
        ) : null}

        <form onSubmit={handleSubmit(onSubmit)}>
          {!approve && (
            <Input
              id="message"
              type="text"
              name="message"
              error={errors.message?.message}
              register={register}
              label={
                reject
                  ? 'Reject Reason (Email Sent To The Investor)'
                  : 'Message'
              }
            />
          )}

          {reject && (
            <Input
              id="by"
              type="text"
              name="by"
              error={errors.by?.by}
              register={register}
              label={'Your Name'}
            />
          )}

          <div className="w-full my-4 flex justify-between mr-3">
            <Button
              onClick={onModalClose}
              variant="transparent"
              label="Cancel"
            />
            <Button
              type="submit"
              isDisabled={loadingAccount}
              isLoading={loadingAccount}
              // isDisabled={isAllPartiesApproved && isAiVerified ? false : true}
              // onClick={markTradeAsSettled}
              label="Confirm"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AccountDisapproveModal;
