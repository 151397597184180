import React, { useRef, useEffect, useState } from 'react';
import styles from './EditorFeaturedLinkButton.module.scss';
import Input from 'components/Input';
import Button from 'components/Button';
import featured_link from './featured_link.png';
const useOnOutsideTouch = (ref, setDropDown) => {
  useEffect(() => {
    /**
     * if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setDropDown(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

const EditorFeaturedLinkButton = ({ register }) => {
  const [showDropDown, setDropDown] = useState(false);

  const wrapperRef = useRef(null);
  useOnOutsideTouch(wrapperRef, setDropDown);

  return (
    <div ref={wrapperRef} className={styles.featured_link}>
      <div
        className={styles.featured_link__container}
        onClick={() => setDropDown(!showDropDown)}
      >
        <img src={featured_link} style={{ height: 20, width: 30 }} />
      </div>
      {showDropDown ? (
        <div className={styles.featured_link__dropdown}>
          <div className={styles.featured_link__dropdown__wrapper}>
            <h2>Featured Image URL</h2>
            <Input
              wrapperClassName={styles.input_wrapper}
              placeholder="insert a image url"
              name="featured_link"
              register={register}
            />
            <Button
              type="submit"
              label={'submit'}
              className={styles.button}
              onClick={() => setDropDown(false)}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default EditorFeaturedLinkButton;
