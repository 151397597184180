import * as actions from '../actions/profile';

const initialState = {
  investments: {
    data: [],
    isLoading: true,
    isError: false,
    error: '',
  },
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCHING_INVESTMENTS_INPROGRESS:
      return {
        ...state,
        investments: {
          ...state.investments,
          isLoading: true,
        },
      };

    case actions.FETCH_USERS_INVESTMENTS:
      return {
        ...state,
        investments: {
          ...state.investments,
          data: action.data,
          isLoading: false,
          isError: false,
          error: false,
        },
      };

    case actions.FETCH_USERS_INVESTMENTS_FAILED:
      return {
        ...state,
        investments: {
          data: [],
          isLoading: false,
          isError: true,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default profileReducer;
