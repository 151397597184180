import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import Input from 'components/Input';
import Button from 'components/Button';
import Modal from 'components/Modal';
import {
  createNewUser,
  fetchRolesDropdown,
  getUserDetail,
  updateUserDetail,
} from 'store/actions/users';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { get } from 'lodash';
import Select from 'components/Select';

const UsersForm = ({ onModalClose, data, viewMode }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const {
    formState: { errors },
    register,
    setValue,
    handleSubmit,
    control,
    watch,
  } = useForm({
    defaultValues: {
      name: data?.name || '',
      email: data?.email || '',
      roles: data?.roles
        ? data.roles.map(d => ({
            label: d.label,
            value: d._id,
          }))
        : [],
    },
    resolver: yupResolver(
      Yup.object({
        name: Yup.string().required('Name is Required'),
        roles: Yup.array()
          .required('Role is Required')
          .min(1, 'You need to select at least 1 Role'),
      })
    ),
  });

  useEffect(() => {
    dispatch(fetchRolesDropdown());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const roles = useSelector(({ users }) =>
    get(users, 'roleOptions.data.data', [])
  );

  const createEditUser = async values => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (!viewMode) {
      await dispatch(
        createNewUser({ ...values, roles: values.roles.map(v => v.value) })
      );
    } else {
      await dispatch(
        updateUserDetail(data?._id.toString(), {
          name: values.name,
          roles: values.roles.map(v => v.value),
        })
      );
      await dispatch(getUserDetail(data?._id.toString()));
    }
    setLoading(false);
    onModalClose(true);
  };

  return (
    <Modal
      isOpen
      hasCloseIcon
      handleCloseClick={onModalClose}
      containerClassname="max-w-screen-sm"
      onClick={onModalClose}
      title={!viewMode ? 'Add a User' : 'Edit User'}
    >
      <div
        className={cs('flex w-full flex-col mt-4', {
          'items-center justify-center': loading,
          'items-start': !loading,
        })}
      >
        <form className="w-full">
          <Input
            id="name"
            name="name"
            label="Name"
            wrapperClassName="w-full"
            placeholder="User name"
            error={errors.name?.message}
            register={register}
            onChange={e => {
              setValue('name', e.target.value);
            }}
          />
          <Input
            id="email"
            name="email"
            label="Email"
            wrapperClassName="w-full my-4"
            placeholder="User email"
            error={errors.email?.message}
            register={register}
            isDisabled={viewMode}
            onChange={e => {
              setValue('email', e.target.value);
            }}
          />
          <Select
            isMulti
            wrapperClassName="w-full my-4"
            label="Role"
            id="roles"
            name="roles"
            control={control}
            error={errors?.roles ? errors.roles.message : null}
            options={roles.map(role => ({
              label: role.label,
              value: role._id,
            }))}
            onChange={value => {
              setValue('roles', value);
            }}
            value={watch('roles')}
          />
          <div className="w-full my-4 flex justify-between">
            <Button
              onClick={onModalClose}
              variant="transparent"
              className="mr-3"
              label="Cancel"
            />
            <Button
              isLoading={loading}
              onClick={handleSubmit(createEditUser)}
              label={!viewMode ? 'Create' : 'Save'}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UsersForm;
