import { fetchHeaderStats } from '../api/stats';
import { get } from 'lodash';

export const FETCH_HEADER_STATS = 'FETCH_HEADER_STATS';
export const FETCH_HEADERSTATS_INPROGRESS = 'FETCH_HEADERSTATS_INPROGRESS';
export const FETCH_HEADERSTATS_FAILED = 'FETCH_HEADERSTATS_FAILED';

export const fetchHeaderStatistics = () => async dispatch => {
  dispatch({
    type: FETCH_HEADERSTATS_INPROGRESS,
  });

  const headerStatsData = await fetchHeaderStats();
  const status = get(headerStatsData, 'status');

  try {
    if (status) {
      dispatch({
        type: FETCH_HEADER_STATS,
        data: headerStatsData,
      });
    } else {
      dispatch({
        type: FETCH_HEADERSTATS_FAILED,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_HEADERSTATS_FAILED,
    });
  }
};
