import Request from './request';

export const login = async data =>
  Request.call({
    url: '/auth/login',
    method: 'POST',
    data,
  });

export const logout = async () =>
  Request.call(
    {
      url: '/logout',
      method: 'POST',
    },
    true
  );

export const register = async user =>
  Request.call({
    url: '/auth/register',
    method: 'POST',
    data: user,
  });

export const getProfile = async () =>
  Request.call({
    url: '/users/profile',
    method: 'GET',
  });

export const forgotPassword = async email =>
  Request.call({
    url: '/auth/forgot-password',
    method: 'POST',
    data: {
      email: email,
    },
  });

export const resetPassword = async (token, password, confirm_password) =>
  await Request.call({
    url: '/auth/reset-password',
    method: 'POST',
    data: {
      token,
      password,
      confirm_password,
    },
  });

export const resendEmail = async email =>
  await Request.call({
    url: '/auth/resend-verification-email',
    method: 'POST',
    data: {
      email,
    },
  });

export const verifyEmail = async token =>
  await Request.call({
    url: '/auth/verify-email',
    method: 'POST',
    data: {
      token,
    },
  });
