import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDocumentsDetail,
  downloadDocumentById,
  addDocumentActivityById,
} from 'store/actions/documents';
import get from 'lodash/get';
import Details from 'components/Details';
import moment from 'moment';
import { CheckCircle, ChevronLeft, Clock, X, Check } from 'react-feather';
import { byteToReadbleSize } from 'helpers/byteToReadableSize';
import Button from 'components/Button';
import Card from 'components/Card';
import LoadingIndicator from 'components/LoadingIndicator';
import Timeline from 'components/Timeline';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';
import useQuery from 'helpers/useQuery';
const DocumentDetails = () => {
  const query = useQuery();
  let { id } = useParams();
  const dispatch = useDispatch();
  let history = useHistory();
  const type = query.get('type') === 'aml' ? 'aml' : undefined;
  const document = useSelector(({ documents }) =>
    get(documents, 'details.data.data', {})
  );
  const isLoading = useSelector(({ documents }) =>
    get(documents, 'details.isInProgress', true)
  );

  useEffect(() => {
    dispatch(fetchDocumentsDetail(id, type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleDownload = data => async () => {
    const resp = await dispatch(downloadDocumentById(data._id, type));
    if (resp.status) {
      window.open(resp.data.url, '_blank');
    }
  };

  const onActivityCreate = async value => {
    await dispatch(addDocumentActivityById(id, value, type));
  };

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'documents'
  );

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <div className="flex items-center justify-between">
              <Card.Header
                title={
                  <>
                    <ChevronLeft
                      onClick={() => history.push(`/documents`)}
                      className="cursor-pointer mr-2"
                    />
                    Document Details
                  </>
                }
              />
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3  gap-6 mt-4">
              <Details title="File Name" data={document?.name || '-'} />
              <Details title="Uploaded By" data={document?.user?.name || '-'} />
              <Details title="Email" data={document?.user?.email || '-'} />
              <Details
                title="File Size"
                data={byteToReadbleSize(document?.size) || '-'}
              />
              <Details
                title="Type"
                data={
                  <span className="uppercase">
                    {document?.verification_for?.replace('_', ' ') || ''}
                  </span>
                }
              />
              <Details
                title="Is Verified?"
                data={
                  document?.is_verified ? (
                    <div className="flex">
                      <CheckCircle className="mr-2 text-green-500	" /> Approved
                    </div>
                  ) : (
                    <div className="flex">
                      <Clock className="mr-2 text-blue-500	" /> Pending
                    </div>
                  )
                }
              />
              <Details
                title="Is Deleted?"
                data={
                  document?.is_deleted ? (
                    <div className="flex">
                      <Check className="mr-2 text-green-500	" /> Yes
                    </div>
                  ) : (
                    <div className="flex">
                      <X className="mr-2 text-blue-500	" /> No
                    </div>
                  )
                }
              />
              <Details
                title="Uploaded On"
                data={
                  document?.createdAt
                    ? moment(document.createdAt).format('MMM Do, YYYY')
                    : '-'
                }
              />
              <Details
                title="File URL"
                data={
                  <Button onClick={handleDownload(document)} label="Download" />
                }
              />
            </div>
            <Timeline
              allowToCreateActivity
              title="Document Activity"
              className="mt-6"
              activities={document.activity || []}
              onActivityCreate={onActivityCreate}
            />
          </>
        )}
      </Card>
    </CanAccessComponent>
  );
};
export default DocumentDetails;
