import { get } from 'lodash';
import {
  addRole,
  availablePermissions,
  deleteRole,
  fetchRoleID,
  fetchRoles,
  updateRole,
} from '../api/roles';
import { showSnackBar } from './snackbar';

export const FETCH_ALL_ROLES_DATA = 'FETCH_ALL_ROLES_DATA';
export const FETCH_ROLES_INPROGRESS = 'FETCH_ROLES_INPROGRESS';
export const FETCH_ALL_ROLES_DATA_FAILED = 'FETCH_ALL_ROLES_DATA_FAILED';

export const FETCH_ROLE_DETAIL = 'FETCH_AFETCH_ROLE_DETAILLL_ROLES_DATA';
export const FETCH_ROLE_DETAIL_INPROGRESS = 'FETCH_ROLE_DETAIL_INPROGRESS';
export const FETCH_ROLE_DETAIL_FAILED = 'FETCH_ROLE_DETAIL_FAILED';

export const EDIT_ROLE_DETAIL = 'EDIT_ROLE_DETAIL';
export const EDIT_ROLE_DETAIL_FAILED = 'EDIT_ROLE_DETAIL_FAILED';

export const ADD_ROLE_INPROGRESS = 'ADD_ROLE_INPROGRESS';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const ADD_ROLE_FAILED = 'ADD_ROLE_FAILED';

export const UPDATE_ROLE_INPROGRESS = 'UPDATE_ROLE_INPROGRESS';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILED = 'UPDATE_ROLE_FAILED';

export const DELETE_ROLE_INPROGRESS = 'DELETE_ROLE_INPROGRESS';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILED = 'DELETE_ROLE_FAILED';

export const EXPORT_ROLE_CSV_INPROGRESS = 'EXPORT_ROLE_CSV_INPROGRESS';
export const EXPORT_ROLE_CSV_SUCCESS = 'EXPORT_ROLE_CSV_SUCCESS';
export const EXPORT_ROLE_CSV_FAILED = 'EXPORT_ROLE_CSV_FAILED';

export const FETCH_AVAILABLE_PERMISSIONS_INPROGRESS =
  'FETCH_AVAILABLE_PERMISSIONS_INPROGRESS';
export const FETCH_AVAILABLE_PERMISSIONS_SUCCESS =
  'FETCH_AVAILABLE_PERMISSIONS_SUCCESS';
export const FETCH_AVAILABLE_PERMISSIONS_FAILED =
  'FETCH_AVAILABLE_PERMISSIONS_FAILED';

export const fetchAllRoles = (page, sort, q, limit) => async dispatch => {
  dispatch({
    type: FETCH_ROLES_INPROGRESS,
  });

  try {
    const resp = await fetchRoles(page, sort, q, limit);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    const data = get(resp, 'data', []);

    if (status) {
      dispatch({
        type: FETCH_ALL_ROLES_DATA,
        data,
      });
    } else {
      dispatch({
        type: FETCH_ALL_ROLES_DATA_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: FETCH_ALL_ROLES_DATA_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const fetchRoleDetail = id => async dispatch => {
  dispatch({
    type: FETCH_ROLE_DETAIL_INPROGRESS,
  });

  try {
    const data = await fetchRoleID(id);
    const status = get(data, 'status');
    const message = get(data, 'message');

    if (status) {
      dispatch({
        type: FETCH_ROLE_DETAIL,
        data,
      });
    } else {
      dispatch({
        type: FETCH_ROLE_DETAIL_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: FETCH_ROLE_DETAIL_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const createNewRole = role => async dispatch => {
  dispatch({
    type: ADD_ROLE_INPROGRESS,
  });
  try {
    const data = await addRole(role);
    const status = get(data, 'status');
    const message = get(data, 'message');

    if (status) {
      dispatch({
        type: ADD_ROLE_SUCCESS,
        data,
      });
      dispatch(fetchAllRoles());
      showSnackBar('You have successfully updated the role', 'success');

      return data;
    } else {
      dispatch({
        type: ADD_ROLE_FAILED,
        message,
      });
      showSnackBar(message, 'error');

      return data;
    }
  } catch (error) {
    dispatch({
      type: ADD_ROLE_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');

    return { status: 0 };
  }
};

export const updateRoleById = (id, role) => async dispatch => {
  dispatch({
    type: UPDATE_ROLE_INPROGRESS,
  });
  try {
    const data = await updateRole(id, role);
    const status = get(data, 'status');
    const message = get(data, 'message');

    if (status) {
      dispatch({
        type: UPDATE_ROLE_SUCCESS,
        data,
      });

      dispatch(fetchAllRoles());
      showSnackBar('You have successfully updated the role', 'success');
    } else {
      dispatch({
        type: UPDATE_ROLE_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: UPDATE_ROLE_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const deleteRoleById = (id, role) => async dispatch => {
  dispatch({
    type: DELETE_ROLE_INPROGRESS,
  });
  try {
    const data = await deleteRole(id, role);
    const status = get(data, 'status');
    const message = get(data, 'message');

    if (status) {
      dispatch({
        type: DELETE_ROLE_SUCCESS,
        data,
      });

      showSnackBar(message, 'success');
    } else {
      dispatch({
        type: DELETE_ROLE_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: DELETE_ROLE_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const exportRolesToCSV = () => async dispatch => {
  dispatch({
    type: EXPORT_ROLE_CSV_INPROGRESS,
  });
  try {
    const resp = await fetchRoles();
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: EXPORT_ROLE_CSV_SUCCESS,
      });
    } else {
      dispatch({
        type: EXPORT_ROLE_CSV_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
    return resp;
  } catch (error) {
    dispatch({
      type: EXPORT_ROLE_CSV_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const fetchAvailablePermissions = () => async dispatch => {
  dispatch({
    type: FETCH_AVAILABLE_PERMISSIONS_INPROGRESS,
  });
  try {
    const resp = await availablePermissions();
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    const data = get(resp, 'data');

    if (status) {
      dispatch({
        type: FETCH_AVAILABLE_PERMISSIONS_SUCCESS,
        data,
      });
    } else {
      dispatch({
        type: FETCH_AVAILABLE_PERMISSIONS_FAILED,
        message,
      });
      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: FETCH_AVAILABLE_PERMISSIONS_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};
