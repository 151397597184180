import React, { useState } from 'react';
import cs from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { MoreVertical } from 'react-feather';
import styles from './dropdownMenu.module.scss';

const DropdownMenu = ({
  options = [],
  isOneRow = false,
  domElement = null,
}) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className={cs('relative inline-block text-left', styles.root)}>
      <button
        type="button"
        className={cs(
          styles.toggle,
          'inline-flex justify-center rounded-md border border-opacity-0 hover:border-opacity-100 hover:border-gray-300 shadow-sm px-1 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none '
        )}
        id="menu-button"
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        {domElement ? domElement : <MoreVertical size={18} />}
      </button>
      {isOpen ? (
        <OutsideClickHandler
          onOutsideClick={e => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(false);
          }}
        >
          <menu
            style={isOneRow ? { top: '-45px' } : null}
            className={cs(
              'origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
              styles.options
            )}
          >
            {options.map((opt, index) => {
              return opt ? (
                <div
                  className={cs('py-1 rounded-md', styles.option)}
                  key={index}
                >
                  <span
                    className={`text-gray-700 block px-4 py-2 ${
                      opt.isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
                    }`}
                    onClick={() => {
                      if (!opt.isDisabled) {
                        setOpen(false);
                        opt.onClick();
                      }
                    }}
                  >
                    {opt.label}
                  </span>
                </div>
              ) : null;
            })}
          </menu>
        </OutsideClickHandler>
      ) : null}
    </div>
  );
};

export default DropdownMenu;
