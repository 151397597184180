import { getNoPaymentMethod } from 'store/api/nopaymentMethod';
import { showSnackBar } from './snackbar';

export const GET_NO_PAYMENTS_INVESTORS_SUCCESS =
  'GET_NO_PAYMENTS_INVESTORS_SUCCESS';
export const GET_NO_PAYMENTS_INVESTORS_INPROGRESS =
  'GET_NO_PAYMENTS_INVESTORS_INPROGRESS';
export const GET_NO_PAYMENTS_INVESTORS_FAILURE =
  'GET_NO_PAYMENTS_INVESTORS_FAILURE';

export const getNoPaymentInvestors = (
  page,
  per_page,
  sort,
  query
) => async dispatch => {
  dispatch({
    type: GET_NO_PAYMENTS_INVESTORS_INPROGRESS,
  });

  try {
    const resp = await getNoPaymentMethod(page, per_page, sort, query);

    if (resp.is_error)
      return dispatch({
        type: GET_NO_PAYMENTS_INVESTORS_SUCCESS,
        data: {
          data: resp.data,
          meta: resp.meta,
        },
      });

    if (typeof resp.message === 'string') {
      showSnackBar(resp.message, 'error');
    } else {
      showSnackBar(JSON.stringify(resp.message), 'error');
    }

    return dispatch({
      type: GET_NO_PAYMENTS_INVESTORS_FAILURE,
      data: {
        message: resp?.message,
      },
    });
  } catch (err) {
    return dispatch({
      type: GET_NO_PAYMENTS_INVESTORS_FAILURE,
      data: {
        data: [],
        message: err.response,
      },
    });
  }
};
