import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import Auth from 'layouts/Auth.js';
import { useDispatch } from 'react-redux';
import Input from 'components/Input';
import Button from 'components/Button';
import { registerNewUser } from 'store/actions/auth';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Eye, EyeOff } from 'react-feather';

const Register = () => {
  // Show eye password to text
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      Yup.object({
        name: Yup.string().required(),
        email: Yup.string()
          .email('Please enter valid Email')
          .required('Email is Required'),
        password: Yup.string()
          .required()
          .max(200, 'Password must be less than 200 characters')
          .required('Password is required'),
        confirm_password: Yup.string()
          .oneOf(
            [Yup.ref('password')],
            'The passwords you entered don’t match. '
          )
          .required('The passwords you entered don’t match. '),
      })
    ),
  });

  const onSubmit = async values => {
    setLoading(true);
    await dispatch(registerNewUser(values));
    setLoading(false);
  };

  const togglePassword = () => setPasswordShow(!passwordShow);
  const toggleConfirmPassword = () =>
    setConfirmPasswordShow(!confirmPasswordShow);

  return (
    <Auth>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
              <div className="flex-auto px-4 lg:px-10 py-10">
                <div className="w-full flex items-center justify-center mb-6">
                  <h1 className="font-medium text-3xl">Register</h1>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Input
                    wrapperClassName="mt-2"
                    id="name"
                    type="text"
                    name="name"
                    label="Name"
                    error={errors.name?.message}
                    register={register}
                  />
                  <Input
                    wrapperClassName="mt-4"
                    id="email"
                    type="email"
                    name="email"
                    label="Email"
                    error={errors.email?.message}
                    register={register}
                  />
                  <Input
                    wrapperClassName="mt-4"
                    id="password"
                    name="password"
                    label="Password"
                    type={`${passwordShow ? 'text' : 'password'}`}
                    rightIcon={
                      passwordShow ? (
                        <EyeOff onClick={togglePassword} />
                      ) : (
                        <Eye onClick={togglePassword} />
                      )
                    }
                    error={errors.password?.message}
                    register={register}
                  />
                  <Input
                    wrapperClassName="mt-4"
                    id="confirm_password"
                    name="confirm_password"
                    label="Confirm Password"
                    type={`${confirmPasswordShow ? 'text' : 'password'}`}
                    rightIcon={
                      confirmPasswordShow ? (
                        <EyeOff onClick={toggleConfirmPassword} />
                      ) : (
                        <Eye onClick={toggleConfirmPassword} />
                      )
                    }
                    error={errors.confirm_password?.message}
                    register={register}
                  />
                  <div className="text-center mt-6">
                    <Button
                      className="full-width"
                      type="submit"
                      label="Register"
                      isLoading={loading}
                    />
                  </div>

                  <div className="w-full flex items-center justify-center mt-4">
                    <p className="text-blueGray-500">
                      Already have an account?&nbsp;
                    </p>
                    <Link to="/auth/login">
                      <p className="text-blueGray-700 font-bold cursor-pointer">
                        Log In
                      </p>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Auth>
  );
};

export default Register;
