import React, { useEffect, useState } from 'react';
import styles from './Admin.module.scss';

// components
import Sidebar from 'components/Sidebar/Sidebar';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import ResponsiveNavbar from 'components/ResponsiveNavbar';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { doLogout, getUserProfile } from 'store/actions/auth';
import { useHistory } from 'react-router-dom';
import PageChange from 'components/PageChange/PageChange.js';
import { fetchAvailablePermissions } from 'store/actions/roles';

const Admin = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchAvailablePermissions());
      setLoading(false);
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const token = useSelector(({ auth }) =>
    get(auth, 'user.tokens.access.token')
  );

  useEffect(() => {
    const expiresAt = localStorage.getItem('expiresAt');

    if (token && expiresAt) {
      const currentTime = new Date().getTime();
      if (expiresAt && new Date(expiresAt).getTime() - currentTime <= 0) {
        dispatch(doLogout());
        history.push('/auth/login');
      } else {
        dispatch(getUserProfile());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      {!isLoading ? (
        <div className={styles.Admin}>
          <ResponsiveNavbar />
          <AdminNavbar />

          <div className={styles.AdminView}>
            <Sidebar />
            <div className={styles.children}>{children}</div>
          </div>
        </div>
      ) : (
        <PageChange />
      )}
    </>
  );
};

export default Admin;
