import Request from './request';

export const fetchProspectsList = async (page, sort, query, limit) =>
  await Request.call({
    url: '/prospect',
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
      limit,
    },
  });

export const sendBulkEmailToProspect = async data =>
  await Request.call({
    url: '/prospect/email-prospects',
    method: 'POST',
    data,
  });

export const postCreateUrl = async data =>
  await Request.call({
    url: '/prospect/create-url-prospects',
    method: 'POST',
    data,
  });

export const fetchProspectData = async id =>
  await Request.call({
    url: `/prospect/${id}`,
    method: 'GET',
  });

export const rejectProspect = async id =>
  await Request.call({
    url: `/prospect/${id}`,
    method: 'DELETE',
  });

export const fetchAllProspectsData = async () =>
  await Request.call({
    url: `/prospect/all`,
    method: 'GET',
  });

export const sendEmail = async prospectId =>
  await Request.call({
    url: `/prospect/${prospectId}/email`,
    method: 'POST',
  });

export const addProspectActivity = async (id, data) =>
  await Request.call({
    url: `/prospect/${id}/activity`,
    method: 'POST',
    data,
  });
