import React, { useEffect, useMemo, useState } from 'react';
import Table from 'components/Table';
import { get } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvestors } from 'store/actions/investors';
import { Link, useHistory } from 'react-router-dom';
import Select from 'components/Select';
import NumberFormat from 'react-number-format';
import DropdownMenu from 'components/DropdownMenu';
import Card from 'components/Card';
import cs from 'classnames';
import { useMedia } from 'react-use';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';

export const renderInvestorType = investorType => {
  switch (investorType) {
    case 'individual':
      return 'Individual';

    case 'company':
      return 'Company';

    case 'ira':
      return 'IRA';

    case 'joint':
      return 'Joint';

    case 'trust':
      return 'Trust';

    default:
      return 'Individual';
  }
};

const Investors = () => {
  const [investorType, setInvestorType] = useState('individual');
  const dispatch = useDispatch();
  let history = useHistory();
  const isMaxWidth640px = useMedia('(max-width: 640px)');

  const investorsData = useSelector(({ investors }) =>
    get(investors, `investors.data.data.${investorType}`, [])
  );

  const investorsMetadata = useSelector(({ investors }) =>
    get(investors, 'investors.data.meta', {})
  );

  useEffect(() => {
    dispatch(fetchInvestors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const investorsOptions = [
    { label: 'Individual', value: 'individual' },
    { label: 'Company', value: 'company' },
    { label: 'IRA', value: 'ira' },
    { label: 'Joint', value: 'joint' },
    { label: 'Trust', value: 'trust' },
  ];
  const columns = useMemo(
    () => [
      {
        Header: () => 'Name',
        accessor: 'name',
        Cell: ({ value, row }) =>
          value ? (
            <div className="flex items-start">
              <Link to={`/investors/${row.original.id}`}>{value}</Link>
            </div>
          ) : null,
      },
      {
        Header: 'Net Worth',
        accessor: `${investorType}.net_worth`,
        Cell: ({ value }) =>
          value ? (
            <>
              $
              <NumberFormat
                value={value}
                displayType="text"
                thousandSeparator={true}
              />
            </>
          ) : null,
      },
      {
        Header: 'Accredited',
        accessor: `${investorType}.accredited`,
        Cell: ({ value }) =>
          value ? <p>Accredited</p> : <p>Non Accredited</p>,
      },
      {
        Header: 'Created At',
        accessor: `${investorType}.createdAt`,
        Cell: ({ value }) =>
          value ? moment(value).format('MMM Do, YYYY') : null,
      },
      {
        Header: () => (
          <div className="flex justify-end w-full">Last Updated</div>
        ),
        accessor: `${investorType}.updatedAt`,
        Cell: ({ value, row }) => (
          <div className="flex justify-end">
            <span className="flex items-center mr-4">
              {value ? moment(value).format('MMM Do, YYYY') : null}
            </span>
            <DropdownMenu
              options={[
                {
                  label: 'View Investor',
                  onClick: () => history.push(`/investors/${row.original.id}`),
                },
                {
                  label: 'Delete Investor',
                  onClick: () => {},
                },
              ]}
            />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [investorType]
  );

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'investors'
  );

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        <div
          className={cs('flex justify-between sm:items-center mb-2', {
            'flex-col': isMaxWidth640px,
          })}
        >
          <Card.Header
            title={`${renderInvestorType(investorType)} Investors ${
              investorsData.length ? `(${investorsData.length})` : ''
            }`}
          />
          <Select
            name="investors"
            options={investorsOptions}
            wrapperClassName="w-40 mt-2 sm:mt-0 "
            onChange={value => setInvestorType(value.value)}
            value={
              investorType
                ? investorsOptions.find(type => type.value === investorType)
                : null
            }
          />
        </div>
        <Card.Content className="h-full flex">
          <Table
            withPagination={false}
            withPerPage={false}
            columns={columns}
            data={investorsData}
            total={investorsMetadata.totalPages}
            fetchData={() => {}}
          />
        </Card.Content>
      </Card>
    </CanAccessComponent>
  );
};

export default Investors;
