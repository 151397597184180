import React, { useEffect, useState } from 'react';
import Details from 'components/Details';
import Toggle from 'components/Toggle';
import { get } from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetail, updateUserDetail } from 'store/actions/users';
import { fetchAllRoles } from 'store/actions/roles';
import moment from 'moment';
import { ChevronLeft } from 'react-feather';
import LoadingIndicator from 'components/LoadingIndicator';
import Card from 'components/Card';
import DropdownMenu from 'components/DropdownMenu';
import UsersForm from 'views/Users/Users.form';
import LoadSpinner from 'components/LoadSpinner';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';

const UserDetails = () => {
  let history = useHistory();

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState({
    is_active: false,
    is_accredited: false,
  });

  const data = useSelector(({ users }) =>
    get(users, 'userDetail.data.data', [])
  );

  const isLoading = useSelector(({ users }) =>
    get(users, 'userDetail.isInProgress', [])
  );

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getUserDetail(id));
    dispatch(fetchAllRoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onModalClose = () => {
    setModal(false);
  };

  const updateUserDetails = async (key, value) => {
    setLoading({
      ...loading,
      [key]: true,
    });
    await dispatch(
      updateUserDetail(data?._id.toString(), {
        name: data.name,
        role: data.role._id,
        [key]: value,
      })
    );
    setLoading({
      ...loading,
      [key]: false,
    });
    await dispatch(getUserDetail(data?._id.toString()));
  };

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'users'
  );

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <div className="flex items-center justify-between">
              <div className="flex justify-between w-full">
                <Card.Header
                  title={
                    <>
                      <ChevronLeft
                        onClick={() => history.push(`/users`)}
                        className="cursor-pointer mr-2"
                      />
                      User Details
                    </>
                  }
                />
                <DropdownMenu
                  options={[
                    {
                      label: 'Edit User',
                      onClick: () => setModal(true),
                    },
                    {
                      label: 'Delete User',
                      onClick: () => {},
                    },
                  ]}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3  gap-6 mt-4">
              <Details title="Name" data={data.name} />
              <Details title="Email" data={data.email} />
              <Details title="Role" data={data.role && data.role.label} />
              <Details title="Total Investments" data={data.total_investment} />
              <Details
                title="Created"
                data={moment(data.createdAt).format('MMM Do, YYYY')}
              />
              <Details
                title="Last Updated"
                data={moment(data.updatedAt).format('MMM Do, YYYY')}
              />
              <Details
                title="Is Active?"
                data={
                  <div className="flex">
                    <Toggle
                      id="is_active"
                      name={`is_active`}
                      className="flex-col-reverse"
                      wrapperClassName="mb-3"
                      labelClassName="mb-2"
                      checked={data.is_active}
                      onChange={event => {
                        updateUserDetails('is_active', event.target.checked);
                      }}
                    />
                    {loading.is_active ? (
                      <LoadSpinner color="#d3d3d3" height={25} width={25} />
                    ) : null}
                  </div>
                }
              />
              <Details
                title="Is Accredited?"
                data={
                  <div className="flex">
                    <Toggle
                      id="is_accredited"
                      name={`is_accredited`}
                      className="flex-col-reverse"
                      wrapperClassName="mb-3"
                      labelClassName="mb-2"
                      checked={data.is_accredited}
                      onChange={event => {
                        updateUserDetails(
                          'is_accredited',
                          event.target.checked
                        );
                      }}
                    />
                    {loading.is_accredited ? (
                      <LoadSpinner color="#d3d3d3" height={25} width={25} />
                    ) : null}
                  </div>
                }
              />
            </div>
          </>
        )}
        {modal ? (
          <UsersForm data={data} viewMode="edit" onModalClose={onModalClose} />
        ) : null}
      </Card>
    </CanAccessComponent>
  );
};

export default UserDetails;
