import React, { useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import Button from 'components/Button';
import { showSnackBar } from 'store/actions/snackbar';

const CSVDownload = ({ onDataFetch, filename, csvHeaders }) => {
  const [data, setData] = useState([]);
  const csvLink = useRef();
  const [loading, setLoading] = useState(false);

  const onCSVClick = async () => {
    setLoading(true);
    const csvData = await onDataFetch();
    setData(csvData);
    if (csvLink && csvLink.current) {
      csvLink.current.link.click();
    }
    showSnackBar('Your CSV has been exported successfully!', 'success');
    setLoading(false);
  };

  return (
    <>
      <Button
        isLoading={loading}
        loadingText="Please wait"
        className="mt-2 sm:mt-0 mr-2"
        onClick={onCSVClick}
        label="Export CSV"
      />
      <CSVLink
        ref={csvLink}
        className="hidden"
        filename={filename}
        data={data}
        headers={csvHeaders}
      />
    </>
  );
};

export default CSVDownload;
