import React, { useEffect } from 'react';
import Navbar from 'components/Navbars/AuthNavbar.js';
import IsNotAuthorized from '../helpers/isNotAuthorized';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { doLogout, getUserProfile } from 'store/actions/auth';
import PageChange from 'components/PageChange/PageChange';

const Auth = ({ children }) => {
  const dispatch = useDispatch();

  const token =
    useSelector(({ auth }) => get(auth, 'user.tokens.access.token')) ||
    typeof window !== 'undefined'
      ? localStorage.getItem('token')
      : null;

  const user = useSelector(({ auth }) => get(auth, 'user.user'));

  useEffect(() => {
    const expiresAt = localStorage.getItem('expiresAt');

    if (token && expiresAt) {
      const currentTime = new Date().getTime();
      if (expiresAt && new Date(expiresAt).getTime() - currentTime <= 0) {
        dispatch(doLogout());
      } else {
        dispatch(getUserProfile());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      {!user ? (
        <div className="min-h-screen flex flex-col">
          <Navbar />
          <section className="relative flex items-center justify-center w-full h-full flex-1">
            <div
              className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
              style={{
                background: '#E5E5E5',
              }}
            />
            {children}
          </section>
        </div>
      ) : (
        <PageChange />
      )}
    </>
  );
};

export default IsNotAuthorized(Auth);
