import Request from './request';

export const fetchRoles = async (page, sort, query, limit) => {
  let params = {};

  if (query) {
    params = {
      page,
      sort,
      q: query,
      limit,
    };
  } else {
    params = {
      page,
      sort,
      limit,
    };
  }

  return await Request.call({
    url: '/roles',
    method: 'GET',
    params: params,
  });
};

export const fetchRoleID = async id =>
  await Request.call({
    url: `/roles/${id}`,
    method: 'GET',
  });

export const deleteRole = async id =>
  await Request.call({
    url: `/roles/${id}`,
    method: 'DELETE',
  });

export const addRole = async data =>
  await Request.call({
    url: '/roles',
    method: 'POST',
    data,
  });

export const updateRole = async (id, data) =>
  await Request.call({
    url: `/roles/${id}`,
    method: 'PUT',
    data,
  });

export const availablePermissions = async () =>
  await Request.call({
    url: `/permissions/all`,
    method: 'GET',
  });
