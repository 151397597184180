import React from 'react';
import { Link } from 'react-router-dom';
import Logo from 'components/Logo/Logo';

export default function Navbar() {
  return (
    <>
      <nav className=" z-50 w-full flex flex-wrap items-center justify-center px-2 py-7 navbar-expand-lg bg-white">
        <div className="w-full relative flex justify-center lg:w-auto lg:static lg:block lg:justify-start">
          <Link to="/auth/login">
            <div className="w-full flex justify-center items-center">
              <Logo />
            </div>
          </Link>
        </div>
      </nav>
    </>
  );
}
