import { showSnackBar } from 'store/actions/snackbar';
export const dispatchError = (dispatch, type, error) => {
  // Request made and server responded
  if (error.response) {
    dispatch({ type: type, payload: error.response });
    showSnackBar(
      error.response.data.message +
        ' ' +
        error.response.config.url +
        ' ' +
        error.response.status,
      'error'
    );
  }
  // The request was made but no response was received
  else if (error.request) {
    dispatch({ type: type, payload: error.message });
    showSnackBar(error.message + ' Check Url', 'error');
  }

  // Something happened in setting up the request that triggered an Error
  else {
    dispatch({ type: type, payload: error.message });
    showSnackBar(error.message + ' Check Url', 'error');
  }
};
