import { get } from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvestorDetail } from 'store/actions/investors';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { ChevronLeft } from 'react-feather';
import Details from 'components/Details';
import renderEntityType from 'helpers/renderEntityType';
import Card from 'components/Card';
import LoadingIndicator from 'components/LoadingIndicator';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';

const InvestorDetails = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  let history = useHistory();

  const data = useSelector(({ investors }) =>
    get(investors, `investorDetail.data.data`, [])
  );

  const isLoading = useSelector(({ investors }) =>
    get(investors, 'investorDetail.isInProgress', {})
  );

  useEffect(() => {
    dispatch(fetchInvestorDetail(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'investors'
  );

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <div className="flex items-center justify-between">
              <Card.Header
                title={
                  <>
                    <ChevronLeft
                      onClick={() => history.push(`/leads`)}
                      className="cursor-pointer mr-2"
                    />
                    Investor Details
                  </>
                }
              />
            </div>
            <Card.Content>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
                <Details title="Investor Name" data={data.name} />
                <Details title="Type" data={renderEntityType(data.type)} />
                <Details
                  title="Type"
                  data={
                    data.accreditation_type === 'non_accredited'
                      ? 'Non Accredited'
                      : 'Accredited'
                  }
                />
                <Details
                  title="Accredited"
                  data={data.accredited ? 'Accredited' : 'Not Accredited'}
                />
                <Details
                  title="Annual Income"
                  data={
                    <NumberFormat
                      value={parseInt(data.annual_income)}
                      displayType="text"
                      thousandSeparator={true}
                      prefix={`$`}
                    />
                  }
                />
                <Details
                  title="Net Worth"
                  data={
                    <NumberFormat
                      value={parseInt(data.net_worth)}
                      displayType="text"
                      thousandSeparator={true}
                      prefix={`$`}
                    />
                  }
                />
                <Details
                  title="US Citizen or Resident"
                  data={
                    data.us_citizen_or_resident
                      ? 'US Citizen or Resident'
                      : 'Not US Citizen or Resident'
                  }
                />
                <Details
                  title="Exempt From Withholding"
                  data={
                    data.exempt_from_withholding
                      ? 'Exempt From Withholding'
                      : 'Not Exempt From Withholding'
                  }
                />
                <Details
                  title="Created"
                  data={moment(data.createdAt).format('MMM Do, YYYY')}
                />
                <Details
                  title="Last Updated"
                  data={moment(data.updatedAt).format('MMM Do, YYYY')}
                />
              </div>
            </Card.Content>
          </>
        )}
      </Card>
    </CanAccessComponent>
  );
};

export default InvestorDetails;
