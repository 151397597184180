import React from 'react';
import { toast } from 'react-toastify';
import { AlertTriangle, CheckCircle, Bell } from 'react-feather';

export const showSnackBar = (message, type) => {
  const renderToaster = () => {
    switch (type) {
      case 'error':
        return toast.error(
          <div className="flex items-center">
            <AlertTriangle className="icon" />
            <p>Error: {message}</p>
          </div>
        );

      case 'info':
        return toast.info(
          <div className="flex items-center">
            <Bell className="icon" />
            <p>Info: {message}</p>
          </div>
        );

      case 'success':
        return toast.success(
          <div className="flex items-center">
            <CheckCircle className="icon" />
            <p>Success: {message}</p>
          </div>
        );

      default:
        return toast(
          <div className="flex items-center">
            <AlertTriangle className="icon" />
            <p>Notification: {message}</p>
          </div>
        );
    }
  };

  return renderToaster();
};
