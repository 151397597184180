import React from 'react';

import Modal from 'components/Modal';

import styles from './IssueRefundModal.module.scss';
import Button from 'components/Button';

import { showSnackBar } from 'store/actions/snackbar';
import { updateParty } from 'store/api/investments';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Input from 'components/Input';
const PartyUpdateModal = ({
  onModalClose,
  modal,
  chosenParty,
  partyStatus,
  reSendKycAml,
  chosenInvestor,
}) => {
  const [loadingParty, setLoadingParty] = React.useState(false);

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(
      Yup.object({
        message: Yup.string(),
      })
    ),
  });

  const onSubmit = async value => {
    try {
      setLoadingParty(true);

      const data = await updateParty({
        ...value,
        reSendKycAml: reSendKycAml,

        ...(reSendKycAml
          ? {
              // for resend-kycaml
              investors: chosenParty.investors,
            }
          : {
              // for updating a party
              partyId: chosenParty.partyId,
              type: chosenParty.type,
              partyStatus,
            }),
      });
      if (data.err) {
        showSnackBar(JSON.stringify(data), 'error');
      }
      //success
      showSnackBar('', 'success');
      setLoadingParty(false);
      onModalClose();
    } catch (err) {
      setLoadingParty(false);
    }
  };

  return (
    <Modal isOpen={modal} onClick={onModalClose} width="500px">
      <div className={styles.container}>
        <div className={styles.container__tradeId}>
          {reSendKycAml ? (
            'Re-send KYC/AML Email'
          ) : partyStatus ? (
            <span
              style={{
                color: 'green',
                textTransform: 'uppercase',
                fontWeight: '600',
              }}
            >
              Manually Approve
            </span>
          ) : (
            <span
              style={{
                color: 'red',
                textTransform: 'uppercase',
                fontWeight: '600',
              }}
            >
              Disapprove
            </span>
          )}
          {reSendKycAml ? null : (
            <>
              <br />
              Name:{' '}
              {chosenParty?.firstName
                ? chosenParty?.firstName
                : chosenParty?.entityName}
              <br />
              PartyId: {chosenParty?.partyId}
            </>
          )}
        </div>
        {reSendKycAml ? (
          <div className="mt-3 mb-2 text-xl">
            {' '}
            {chosenInvestor?.accountRegistration}
          </div>
        ) : null}

        <form onSubmit={handleSubmit(onSubmit)}>
          {!partyStatus && (
            <Input
              id="message"
              type="text"
              name="message"
              error={errors.message?.message}
              register={register}
              label={'Message'}
            />
          )}

          <div className="w-full my-4 flex justify-between mr-3">
            <Button
              onClick={onModalClose}
              variant="transparent"
              label="Cancel"
            />
            <Button
              type="submit"
              isDisabled={loadingParty}
              isLoading={loadingParty}
              // isDisabled={isAllPartiesApproved && isAiVerified ? false : true}
              // onClick={markTradeAsSettled}
              label="Confirm"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default PartyUpdateModal;
