import React from 'react';
import styles from './myaccount.module.scss';
import moment from 'moment';
import NumberFormat from 'react-number-format';

export const renderPaymentMethod = paymentMethod => {
  switch (paymentMethod) {
    case 'ach':
      return 'ACH';

    case 'credit_card':
      return 'Credit Card';

    case 'ethereum':
      return 'Ethereum';

    case 'check':
      return 'Check';

    case 'bitcoin':
      return 'Bitcoin';

    case 'wire':
      return 'Wire';

    default:
      paymentMethod;
  }
};

const Investments = ({ data }) => {
  return (
    <tr className={styles.investment}>
      <td>
        $
        <NumberFormat
          thousandSeparator={true}
          value={data.amount}
          displayType={'text'}
        />
      </td>
      <td>{renderPaymentMethod(data.payment_method)}</td>
      <td>{moment(data.createdAt).format('MMMM Do YYYY')}</td>
    </tr>
  );
};

export default Investments;
