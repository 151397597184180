import React from 'react';

const Connector = () => {
  return (
    <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
      <div className="h-full w-6 flex items-center justify-center">
        <div className="h-full w-1 bg-brandblue pointer-events-none" />
      </div>
      <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-brandorange drop-shadow-md flex items-center justify-center">
        <div className="w-3 h-3 bg-brandgrey relative rounded-full" />
      </div>
    </div>
  );
};

export default Connector;
