import React, { useMemo } from 'react';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';

import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import Card from 'components/Card';
import { getNoPaymentInvestors } from 'store/actions/NoPaymentMethod';
import moment from 'moment';
import Table from 'components/Table';

const NoPaymentMethod = () => {
  const dispatch = useDispatch();

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const noPaymentMethodData = useSelector(({ noPaymentMethod }) =>
    get(noPaymentMethod, 'noPaymentMethodInvestors.data.data', [])
  );

  const noPaymentMethodMetaData = useSelector(({ noPaymentMethod }) =>
    get(noPaymentMethod, 'noPaymentMethodInvestors.data.meta', [])
  );

  const noPaymentMethodLoading = useSelector(({ noPaymentMethod }) =>
    get(noPaymentMethod, 'noPaymentMethodInvestors.isInProgress', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'Abandoned Investments'
  );

  const columns = useMemo(
    () => [
      {
        Header: () => 'Name',
        accessor: 'name',
        Cell: ({ value }) => {
          return value ? (
            <div className="flex justify-center">{value}</div>
          ) : null;
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ value }) => (value ? <div>{value}</div> : null),
      },

      {
        Header: 'Phone',
        accessor: 'phone',
        Cell: ({ value }) => (value ? <div>{value}</div> : null),
      },

      {
        Header: () => <div className="flex justify-end w-full">Created At</div>,
        accessor: 'createdAt',
        Cell: ({ value }) => {
          return (
            <div className="flex justify-end">
              <span className="flex items-center mr-4">
                {value ? moment(value).format('MMM Do, YYYY') : null}
              </span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, dispatch]
  );

  const onClickNoPaymentMethod = ({
    pageIndex: page,
    pageSize: per_page,
    sortBy,
  }) => {
    let isSort = 1;

    if (sortBy.length > 0) {
      if (sortBy[0].desc === false) {
        isSort = -1;
      } else {
        isSort = 1;
      }
    }

    dispatch(
      getNoPaymentInvestors(page, per_page, isSort, {
        email: 'easyrun32@gmail.com',
      })
    );
  };

  return (
    <CanAccessComponent
      allowedPermissions={permissionsForPage}
      redirectUrl="/permission-error"
    >
      <Card>
        <div className="flex justify-between w-full">
          <Card.Header title={'Abandoned Investments'} />
        </div>

        <Card.Content>
          <Table
            showSearch={false}
            loader={noPaymentMethodLoading}
            columns={columns}
            data={noPaymentMethodData}
            total={noPaymentMethodMetaData.totalPages}
            fetchData={onClickNoPaymentMethod}
            withShowPerPage={false}
          />
        </Card.Content>
      </Card>
    </CanAccessComponent>
  );
};

export default NoPaymentMethod;
