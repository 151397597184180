import * as actions from '../actions/prospects';

const initialState = {
  prospects: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
    data: {},
  },
  details: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
    data: {},
  },
  rejectProspect: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
  },
  addActivity: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
  },
  exportToCsv: {
    isInProgress: true,
    isError: false,
    error: '',
    data: {},
  },
  sendEmail: {
    isInProgress: false,
    isError: false,
    error: '',
  },
};

const prospectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_PROSPECTS_INPROGRESS:
      return {
        ...state,
        prospects: {
          ...state.prospects,
          isInProgress: true,
        },
      };

    case actions.FETCH_PROSPECTS_SUCCESS:
      return {
        ...state,
        prospects: {
          ...state.prospects,
          isInProgress: false,
          status: 1,
          data: action.data,
          isError: false,
          error: '',
        },
      };
    case actions.FETCH_PROSPECTS_FAILURE:
      return {
        ...state,
        prospects: {
          ...state.prospects,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };

    case actions.FETCH_PROSPECTS_DETAIL_INPROGRESS:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: true,
        },
      };

    case actions.FETCH_PROSPECTS_DETAIL_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: false,
          status: 1,
          data: action.data,
          isError: false,
          error: '',
        },
      };

    case actions.FETCH_PROSPECTS_DETAIL_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };
    case actions.REJECT_PROSPECT_INPROGRESS:
      return {
        ...state,
        rejectProspect: {
          ...state.rejectProspect,
          isInProgress: true,
        },
      };
    case actions.REJECT_PROSPECT_SUCCESS:
      return {
        ...state,
        rejectProspect: {
          ...state.rejectProspect,
          isInProgress: false,
          status: 1,
          isError: false,
          error: '',
        },
      };
    case actions.REJECT_PROSPECT_FAILURE:
      return {
        ...state,
        rejectProspect: {
          ...state.rejectProspect,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };

    case actions.ADD_PROSPECT_ACTIVITY_INPROGRESS:
      return {
        ...state,
        addActivity: {
          ...state.addActivity,
          isInProgress: true,
        },
      };
    case actions.ADD_PROSPECT_ACTIVITY_SUCCESS:
      return {
        ...state,
        addActivity: {
          ...state.addActivity,
          isInProgress: false,
        },
        details: {
          ...state.details,
          data: action.data,
          isError: false,
          error: '',
        },
      };
    case actions.ADD_PROSPECT_ACTIVITY_FAILED:
      return {
        ...state,
        addActivity: {
          ...state.addActivity,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };

    case actions.EXPORT_PROSPECT_CSV_INPROGRESS:
      return {
        ...state,
        exportToCsv: {
          ...state.exportToCsv,
          isInProgress: true,
        },
      };
    case actions.EXPORT_PROSPECT_CSV_SUCCESS:
      return {
        ...state,
        exportToCsv: {
          ...state.exportToCsv,
          isInProgress: false,
        },
      };
    case actions.EXPORT_PROSPECT_CSV_FAILED:
      return {
        ...state,
        exportToCsv: {
          ...state.exportToCsv,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };
    case actions.SEND_PROSPECT_EMAIL_INPROGRESS:
      return {
        ...state,
        sendEmail: {
          ...state.sendEmail,
          isInProgress: true,
        },
      };
    case actions.SEND_PROSPECT_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmail: {
          ...state.sendEmail,
          isInProgress: false,
        },
      };
    case actions.SEND_PROSPECT_EMAIL_FAILED:
      return {
        ...state,
        sendEmail: {
          ...state.sendEmail,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };
    default:
      return state;
  }
};

export default prospectsReducer;
