import { get } from 'lodash';
import {
  fetchOfferingData,
  fetchOfferingsList,
  addOffering,
  updateOffering,
  deleteOffering,
} from '../api/offerings';
import { showSnackBar } from './snackbar';

export const FETCH_OFFERINGS_INPROGRESS = 'FETCH_OFFERINGS_INPROGRESS';
export const FETCH_OFFERINGS_SUCCESS = 'FETCH_OFFERINGS_SUCCESS';
export const FETCH_OFFERINGS_FAILURE = 'FETCH_OFFERINGS_FAILURE';

export const FETCH_OFFERINGS_DETAIL_INPROGRESS =
  'FETCH_OFFERINGS_DETAIL_INPROGRESS';
export const FETCH_OFFERINGS_DETAIL_SUCCESS = 'FETCH_OFFERINGS_DETAIL_SUCCESS';
export const FETCH_OFFERINGS_DETAIL_FAILURE = 'FETCH_OFFERINGS_DETAIL_FAILURE';

export const ADD_OFFERINGS = 'ADD_OFFERINGS';
export const ADD_OFFERINGS_FAILED = 'ADD_OFFERINGS_FAILED';

export const UPDATE_OFFERINGS_INPROGRESS = 'UPDATE_OFFERINGS_INPROGRESS';
export const UPDATE_OFFERINGS_SUCCESS = 'UPDATE_OFFERINGS_SUCCESS';
export const UPDATE_OFFERINGS_FAILED = 'UPDATE_OFFERINGS_FAILED';

export const DELETE_OFFERINGS_INPROGRESS = 'DELETE_OFFERINGS_INPROGRESS';
export const DELETE_OFFERINGS_SUCCESS = 'DELETE_OFFERINGS_SUCCESS';
export const DELETE_OFFERINGS_FAILED = 'DELETE_OFFERINGS_FAILED';

export const fetchOfferings = (page, sort, q, limit) => async dispatch => {
  dispatch({
    type: FETCH_OFFERINGS_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await fetchOfferingsList(page, sort, q, limit);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    const data = get(resp, 'data', []);

    if (status) {
      dispatch({
        type: FETCH_OFFERINGS_SUCCESS,
        data: data || [],
      });
    } else {
      dispatch({
        type: FETCH_OFFERINGS_FAILURE,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_OFFERINGS_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const fetchOfferingDetail = id => async dispatch => {
  dispatch({
    type: FETCH_OFFERINGS_DETAIL_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await fetchOfferingData(id);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: FETCH_OFFERINGS_DETAIL_SUCCESS,
        data: resp || {},
      });
    } else {
      dispatch({
        type: FETCH_OFFERINGS_DETAIL_FAILURE,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_OFFERINGS_DETAIL_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const addOfferings = offering => async dispatch => {
  try {
    const data = await addOffering(offering);
    const status = get(data, 'status');
    const message = get(data, 'message');

    if (status) {
      dispatch({
        type: ADD_OFFERINGS,
        data,
      });

      showSnackBar('You have successfully updated the Offering', 'success');
    } else {
      dispatch({
        type: ADD_OFFERINGS_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: ADD_OFFERINGS_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const updateOfferingById = (id, offering) => async dispatch => {
  dispatch({
    type: UPDATE_OFFERINGS_INPROGRESS,
  });
  try {
    const data = await updateOffering(id, offering);
    const status = get(data, 'status');
    const message = get(data, 'message');

    if (status) {
      dispatch({
        type: UPDATE_OFFERINGS_SUCCESS,
        data,
      });

      showSnackBar('You have successfully updated the offering', 'success');
    } else {
      dispatch({
        type: UPDATE_OFFERINGS_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: UPDATE_OFFERINGS_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const deleteOfferingById = (id, offering) => async dispatch => {
  dispatch({
    type: DELETE_OFFERINGS_INPROGRESS,
  });
  try {
    const data = await deleteOffering(id, offering);
    const status = get(data, 'status');
    const message = get(data, 'message');

    if (status) {
      dispatch({
        type: DELETE_OFFERINGS_SUCCESS,
        data,
      });

      showSnackBar(message, 'success');
    } else {
      dispatch({
        type: DELETE_OFFERINGS_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: DELETE_OFFERINGS_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};
