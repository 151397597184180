import React, { useState } from 'react';
import Input from 'components/Input';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Button from 'components/Button/Button';
import { resetPasswordUser } from 'store/actions/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

const ResetPassword = () => {
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  let { token } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      Yup.object({
        newPassword: Yup.string()
          .required()
          .max(200, 'Password must be less than 200 characters')
          .required('Password is required'),
        confirmNewPassword: Yup.string()
          .oneOf(
            [Yup.ref('newPassword')],
            'The passwords you entered don’t match. '
          )
          .required('The passwords you entered don’t match. '),
      })
    ),
  });

  const onSubmit = async ({ newPassword, confirmNewPassword }) => {
    await resetPasswordUser(token, newPassword, confirmNewPassword);

    setValue('newPassword', '');
    setValue('confirmNewPassword', '');
  };

  return (
    <div className="min-h-screen" style={{ background: '#E5E5E5' }}>
      <div className="container py-40 mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="px-4 lg:px-10 py-10"
              >
                <div className="w-full flex justify-center items-center mb-6">
                  <h1 className="font-medium text-3xl">Reset Password</h1>
                </div>

                <Input
                  id="newPassword"
                  type={`${password ? 'text' : 'password'}`}
                  name="newPassword"
                  label="New Password"
                  eye={true}
                  show={password}
                  toggleShow={() => setPassword(!password)}
                  error={errors.newPassword?.message}
                  register={register}
                />
                <Input
                  id="confirmNewPassword"
                  type={`${confirmPassword ? 'text' : 'password'}`}
                  name="confirmNewPassword"
                  label="Re-enter Your New Password"
                  eye={true}
                  show={confirmPassword}
                  toggleShow={() => setConfirmPassword(!confirmPassword)}
                  error={errors.confirmNewPassword?.message}
                  register={register}
                  wrapperClassName={`mt-2`}
                />

                <div className="text-center mt-6 py-1 w-full">
                  <Button
                    className="bg-yellow-500 text-white text-blueGray-700 w-full full-width"
                    type="submit"
                    label="Submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
