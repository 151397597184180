// Importing dependencies
import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { createFilter } from 'redux-persist-transform-filter';
// redux-thunk (middleware)
import { persistReducer, persistStore } from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const cacheNews = createFilter('news', [
  'headers',
  'selectednews',
  'newsState.data',
  'newsState.isInProgress',
  'details.data',
  'details.isInProgress',
  'isAuthloading',
  'auth',
]);

const persistConfig = {
  key: 'root',
  storage,
  transforms: [cacheNews],
  blacklist: [],
};

const emptyMiddleWare = () => next => action => {
  const result = next(action);
  return result;
};

let loggerMiddleWare = emptyMiddleWare;

if (process.env.NODE_ENV === 'development') {
  loggerMiddleWare = createLogger({
    collapsed: true,
  });
}

const composeEnhaner =
  (process.env.NODE_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  composeEnhaner(applyMiddleware(thunk, loggerMiddleWare))
);
const persistor = persistStore(store);

export { store, persistor };
