import axios from 'axios';
import Request from './request';
import qs from 'qs';

export const authNews = async () =>
  await axios
    .post(`${process.env.REACT_APP_API_WORDPRESS}/wp-json/jwt-auth/v1/token`, {
      username: process.env.REACT_APP_API_WORDPRESS_USERNAME,
      password: process.env.REACT_APP_API_WORDPRESS_PASSWORD,
    })
    .then(res => localStorage.setItem('wordpress_jwt', res.data.token));

export const getNews = async (per_page, page, status = 'publish') =>
  await axios.get(
    `${process.env.REACT_APP_API_WORDPRESS}/wp-json/wp/v2/posts?&status=${status}&per_page=${per_page}&page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('wordpress_jwt')}`,
      },
    }
  );

export const getOneNews = async id =>
  await axios.get(
    `${process.env.REACT_APP_API_WORDPRESS}/wp-json/wp/v2/posts/${id}`
  );

export const updateNews = async (id, title, HTML, status, featured_link) => {
  return await Request.call({
    url: '/wordpress/news',
    method: 'PUT',
    data: {
      id: id,
      title: title,
      content: qs.stringify({ data: HTML }),
      status: status,
      featured_link: featured_link,
    },
    headers: {
      [`X-Authorization-Wordpress`]: localStorage.getItem('wordpress_jwt'),
    },
  });
};

export const postOneNews = async (title, HTML, featured_link) =>
  await Request.call({
    url: '/wordpress/news',
    method: 'POST',
    data: {
      title: title,
      content: qs.stringify({ data: HTML }),
      status: 'publish',
      featured_link: featured_link,
    },
    headers: {
      [`X-Authorization-Wordpress`]: localStorage.getItem('wordpress_jwt'),
    },
  });
export const deleteOneNews = async id =>
  await Request.call({
    url: '/wordpress/news',
    method: 'DELETE',
    data: {
      id: id,
    },
    headers: {
      [`X-Authorization-Wordpress`]: localStorage.getItem('wordpress_jwt'),
    },
  });
