import React from 'react';
import Logo from './Logo';
import styles from './responsivenavbar.module.scss';
import { useHistory } from 'react-router-dom';
import {
  Users,
  Settings,
  Home,
  DollarSign,
  List,
  Shield,
  Briefcase,
  Printer,
  Package,
  ShoppingCart,
  File,
} from 'react-feather';
import cs from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { toggleNavBar } from 'store/actions/nav';
import { HasInvestments } from 'helpers/hasInvestments';
import { get } from 'lodash';

const ResponsiveNavbar = () => {
  const history = useHistory();
  const role = useSelector(({ auth }) => get(auth, 'user.user.role.value', ''));

  const sideBarButtons = [
    {
      name: 'Account Overview',
      icon: <Home className={styles.icon} />,
      path: '/my-account',
      visible: HasInvestments(),
    },
    {
      name: 'My Investments',
      icon: <DollarSign className={styles.icon} />,
      path: '/my-investments',
      visible: HasInvestments(),
    },
    {
      name: 'Investments',
      icon: <List className={styles.icon} />,
      path: '/investments',
      visible: role === 'admin',
    },
    {
      name: 'Roles',
      icon: <Shield className={styles.icon} />,
      path: '/roles',
      visible: role === 'admin',
    },
    {
      name: 'Job Applications',
      icon: <Briefcase className={styles.icon} />,
      path: '/applications',
      visible: role === 'admin',
    },
    {
      name: 'Leads',
      icon: <Printer className={styles.icon} />,
      path: '/leads',
      visible: role === 'admin',
    },
    {
      name: 'Offerings',
      icon: <Package className={styles.icon} />,
      path: '/offerings',
      visible: role === 'admin',
    },
    {
      name: 'Investors',
      path: '/investors',
      icon: <Users className={styles.icon} />,
      visible: role === 'admin',
    },
    {
      name: 'Documents',
      path: '/documents',
      icon: <File className={styles.icon} />,
      visible: role === 'admin',
    },
    {
      name: 'Deprecated Documents',
      path: '/deprecated-documents',
      icon: <File className={styles.icon} />,
      visible: role === 'admin',
    },
    {
      name: 'Reservations',
      path: '/reservations',
      icon: <ShoppingCart className={styles.icon} />,
      visible: role === 'admin',
    },
    {
      name: 'Users',
      path: '/users',
      icon: <Users className={styles.icon} />,
      visible: role === 'admin',
    },
    {
      name: 'Settings',
      icon: <Settings />,
      path: '/profile',
      visible: true,
    },
  ];

  const dispatch = useDispatch();
  const nav = useSelector(state => state.nav.navBoolean);

  const onRouteClick = path => () => {
    history.push(path);
    dispatch(toggleNavBar());
  };

  return (
    <>
      <div className={cs(styles.responsiveNavbar, { [styles.active]: nav })}>
        <Logo className={styles.logo} />

        {sideBarButtons.map(({ name, icon, path, visible }, index) =>
          visible ? (
            <div
              key={index}
              className={cs(styles.button, {
                [styles.active]: location.pathname.startsWith(path),
              })}
              onClick={onRouteClick(path)}
            >
              {icon} <p>{name}</p>
            </div>
          ) : null
        )}
      </div>
      <div
        className={cs(styles.overlay, { [styles.active]: nav })}
        onClick={() => dispatch(toggleNavBar())}
      />
    </>
  );
};

export default ResponsiveNavbar;
