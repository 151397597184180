import * as actions from '../actions/permissions';

const initialState = {
  permissions: {
    isInProgress: true,
    isError: false,
    error: '',
    data: [],
  },
  permissionsDetail: {
    isInProgress: true,
    isError: false,
    error: '',
    data: {},
  },
  createPermission: {
    isInProgress: true,
    isError: false,
    error: '',
  },
  updatePermission: {
    isInProgress: true,
    isError: false,
    error: '',
  },
  deletePermission: {
    isInProgress: true,
    isError: false,
    error: '',
  },
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_PERMISSIONS_INPROGRESS:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          isInProgress: true,
        },
      };

    case actions.FETCH_ALL_PERMISSIONS_DATA:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          data: action.data,
          isInProgress: false,
          isError: false,
          error: '',
        },
      };

    case actions.FETCH_ALL_PERMISSIONS_DATA_FAILED:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          data: {},
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };

    case actions.FETCH_PERMISSION_DETAIL_INPROGRESS:
      return {
        ...state,
        permissionsDetail: {
          ...state.permissionsDetail,
          isInProgress: true,
        },
      };

    case actions.FETCH_PERMISSION_DETAIL_FAILED:
      return {
        ...state,
        permissionsDetail: {
          ...state.permissionsDetail,
          data: {},
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };

    case actions.FETCH_PERMISSION_DETAIL:
      return {
        ...state,
        permissionsDetail: {
          ...state.permissionsDetail,
          data: action.data.data,
          isInProgress: false,
          isError: false,
          error: '',
        },
      };

    case actions.ADD_PERMISSION_INPROGRESS:
      return {
        ...state,
        createPermission: {
          ...state.createPermission,
          isInProgress: true,
        },
      };
    case actions.ADD_PERMISSION_SUCCESS:
      return {
        ...state,
        createPermission: {
          ...state.createPermission,
          isInProgress: false,
        },
      };
    case actions.ADD_PERMISSION_FAILED:
      return {
        ...state,
        createPermission: {
          ...state.createPermission,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };

    case actions.UPDATE_PERMISSION_INPROGRESS:
      return {
        ...state,
        updatePermission: {
          ...state.updatePermission,
          isInProgress: true,
        },
      };
    case actions.UPDATE_PERMISSION_SUCCESS:
      return {
        ...state,
        updatePermission: {
          ...state.updatePermission,
          isInProgress: false,
        },
      };
    case actions.UPDATE_PERMISSION_FAILED:
      return {
        ...state,
        updatePermission: {
          ...state.updatePermission,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };
    case actions.DELETE_PERMISSION_INPROGRESS:
      return {
        ...state,
        deletePermission: {
          ...state.deletePermission,
          isInProgress: true,
        },
      };
    case actions.DELETE_PERMISSION_SUCCESS:
      return {
        ...state,
        deletePermission: {
          ...state.deletePermission,
          isInProgress: false,
        },
      };
    case actions.DELETE_PERMISSION_FAILED:
      return {
        ...state,
        deletePermission: {
          ...state.deletePermission,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };

    default:
      return state;
  }
};

export default permissionsReducer;
