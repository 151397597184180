import React, { useMemo, useState } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'components/Table';
import { Link, useHistory } from 'react-router-dom';
import {
  fetchProspects,
  rejectProspectById,
  exportProspectToCSV,
} from 'store/actions/prospects';
import DropdownMenu from 'components/DropdownMenu';
import Card from 'components/Card';
import cs from 'classnames';
import CSVDownload from 'components/CSVDownload';
import { useMedia } from 'react-use';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';
import NumberFormat from 'react-number-format';
import Button from 'components/Button';
import { showSnackBar } from 'store/actions/snackbar';
import { sendBulkEmailToProspect, postCreateUrl } from 'store/api/prospects';

import { Clipboard } from 'react-feather';

const Prospects = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMaxWidth640px = useMedia('(max-width: 640px)');
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [createUrlLoading, setCreateUrlLoading] = useState(false);
  const [offering] = useState('reg_a');
  const prospectsData = useSelector(({ prospects }) =>
    get(prospects, 'prospects.data.data', [])
  );

  const prospectsMetadata = useSelector(({ prospects }) =>
    get(prospects, 'prospects.data.meta', {})
  );

  const handleTableChange = async ({ pageIndex, sortBy, pageSize, query }) => {
    let sort = '';
    if (sortBy.length) {
      sort = sortBy[0].desc ? `-${sortBy[0].id}` : sortBy[0].id;
    }
    await dispatch(fetchProspects(pageIndex + 1, sort, query, pageSize));
  };

  const rejectProspect = prospectId => async () => {
    await dispatch(rejectProspectById(prospectId));
  };

  const generateCSVClick = async () => {
    const response = await dispatch(exportProspectToCSV());
    if (response.status) {
      return response?.data.map(res => {
        return {
          ...res,
          type: res.type || '-',
          ...(res.offering
            ? {
                offering: { ...res.offering, name: res?.offering?.name || '-' },
              }
            : { offering: { name: '-' } }),
          ...(res.investment
            ? {
                investment: {
                  ...res.investment,
                  amount: `$${res.investment.amount || 0}`,
                  equity_share_count: res.investment.equity_share_count || 0,
                },
              }
            : { investment: { amount: '$0', equity_share_count: 0 } }),
          ...(res.type !== 'person'
            ? {
                organization: {
                  name: res[res.type]?.name,
                  email: res[res.type]?.email,
                  contact_name: res[res.type]?.contact_name || '-',
                  phone: res[res.type]?.phone || '-',
                  date_of_birth:
                    moment(res[res.type]?.date_of_birth).format('MM-DD-YYYY') ||
                    '-',
                  street_address_1: res[res.type]?.street_address_1 || '-',
                  street_address_2: res[res.type]?.street_address_2 || '-',
                  city: res[res.type]?.city || '-',
                  region: res[res.type]?.region || '-',
                  country: res[res.type]?.country || '-',
                  postal_code: res[res.type]?.postal_code || '',
                },
              }
            : {}),
          createdAt: moment(res.createdAt).format('MMM Do, YYYY') || '-',
        };
      });
    }
    return [];
  };

  const columns = useMemo(
    () => [
      {
        Header: () => 'Invested?',
        accessor: 'email_notify',
        Cell: ({ row }) => {
          return (
            <div>
              {row?.original?.url && row?.original?.url?.length > 0
                ? row?.original?.url[0]
                  ? !row?.original?.url[0].email_notify
                    ? 'Yes'
                    : 'No'
                  : 'No'
                : 'No'}
            </div>
          );
        },
      },
      {
        Header: () => 'Name',
        accessor: 'name',
        Cell: ({ row }) => {
          return (
            <div className="flex items-start">
              <Link to={`/prospects/${row.original._id}`}>
                {row?.original?.person?.firstName +
                  ' ' +
                  row?.original?.person?.lastName}
              </Link>
            </div>
          );
        },
      },
      {
        Header: 'Email',
        accessor: 'person.email',
      },

      {
        Header: () => {
          return 'URL';
        },
        id: 'url',
        Cell: ({ row }) => {
          return row?.original?.url && row?.original?.url?.length > 0 ? (
            <span
              className="cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(row?.original?.url[0].url);
                showSnackBar('Copied url to clipboard', 'info');
              }}
            >
              <Clipboard />
            </span>
          ) : null;
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ value }) => <span className="uppercase">{value || '-'}</span>,
      },
      {
        Header: 'Offering',
        accessor: 'offering.name',
        Cell: ({ row }) => (
          <Link to={`/offerings/${row.original?.offering?._id}`}>
            reg_a_ttw
          </Link>
        ),
      },
      {
        Header: 'Amount',
        accessor: 'investment.amount',
        Cell: ({ value }) => (
          <NumberFormat
            thousandSeparator={true}
            value={value || 0}
            displayType="text"
            prefix="$"
          />
        ),
      },
      {
        Header: 'Equity Share Units',
        accessor: 'investment.share',
        Cell: ({ value }) => (
          <NumberFormat
            thousandSeparator={true}
            value={value || 0}
            displayType={'text'}
          />
        ),
      },
      {
        Header: () => (
          <div className="flex justify-end w-full">Received On</div>
        ),
        accessor: 'createdAt',
        Cell: ({ value, row }) => (
          <div className="flex justify-end">
            <span className="flex items-center mr-4">
              {value ? moment(value).format('MMM Do, YYYY') : null}
            </span>
            <DropdownMenu
              options={[
                {
                  label: 'View Prospect',
                  onClick: () => history.push(`/prospects/${row.original._id}`),
                },
                ...(!row.original.is_rejected
                  ? [
                      {
                        label: 'Reject Prospect',
                        onClick: rejectProspect(row.original._id),
                      },
                    ]
                  : []),
              ]}
            />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'prospect'
  );
  const sendProspectEmails = async () => {
    try {
      setSendEmailLoading(true);
      const data = await sendBulkEmailToProspect({ data: offering });
      if (data.err) {
        showSnackBar(data.message, 'error');
        setSendEmailLoading(false);
        return;
      }
      showSnackBar('Sent Emails', 'success');
      setSendEmailLoading(false);
    } catch (err) {
      showSnackBar('Something went wrong', 'error');
      setSendEmailLoading(false);
    }
  };

  const createUrls = async () => {
    try {
      setCreateUrlLoading(true);
      const data = await postCreateUrl();

      if (data.err) {
        setCreateUrlLoading(false);
        return showSnackBar(data.message, 'error');
      }
      setCreateUrlLoading(false);
    } catch (err) {
      showSnackBar('Something went wrong', 'error');
      setCreateUrlLoading(false);
    }
  };

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        <div
          className={cs('flex justify-between sm:items-center mb-2', {
            'flex-col': isMaxWidth640px,
          })}
        >
          <Card.Header
            title={`Prospects ${
              prospectsMetadata && prospectsMetadata.totalResults
                ? `(${prospectsMetadata.totalResults})`
                : ''
            }`}
          />
          <div className="flex">
            <Button
              isLoading={sendEmailLoading}
              loadingText="Please wait"
              className="mt-2 sm:mt-0 mr-2"
              label={`Send ${offering} emails`}
              onClick={sendProspectEmails}
            />
            <Button
              isLoading={createUrlLoading}
              loadingText="Please wait"
              className="mt-2 sm:mt-0 mr-2"
              label={`Create Url`}
              onClick={createUrls}
            />

            <CSVDownload
              filename="Prospect.csv"
              onDataFetch={generateCSVClick}
              csvHeaders={[
                { label: 'First Name', key: 'person.name' },
                { label: 'Email', key: 'person.email' },
                { label: 'Phone', key: 'person.phone' },
                { label: 'Type', key: 'type' },
                { label: 'Offering', key: 'offering.name' },
                { label: 'Amount', key: 'investment.amount' },
                {
                  label: 'Equity Share Units',
                  key: 'investment.equity_share_count',
                },
                {
                  label: 'Organization Full Legal Name',
                  key: 'organization.name',
                },
                {
                  label: 'Organization Email',
                  key: 'organization.email',
                },
                {
                  label: 'Organization Contact Name',
                  key: 'organization.contact_name',
                },
                {
                  label: 'Organization Phone',
                  key: 'organization.phone',
                },
                {
                  label: 'Date Of Birth',
                  key: 'organization.date_of_birth',
                },
                {
                  label: 'Organization Street Address',
                  key: 'organization.street_address_1',
                },
                {
                  label: 'Organization Address',
                  key: 'organization.street_address_2',
                },
                {
                  label: 'Organization City',
                  key: 'organization.city',
                },
                {
                  label: 'Organization Region',
                  key: 'organization.region',
                },
                {
                  label: 'Organization Country',
                  key: 'organization.country',
                },
                {
                  label: 'Organization Postal Code',
                  key: 'organization.postal_code',
                },
                { label: 'Received On', key: 'createdAt' },
              ]}
            />
          </div>
        </div>
        <Card.Content className="h-full flex">
          <Table
            columns={columns}
            forceRefetch={createUrlLoading}
            data={prospectsData}
            total={prospectsMetadata.totalPages}
            fetchData={handleTableChange}
          />
        </Card.Content>
      </Card>
    </CanAccessComponent>
  );
};

export default Prospects;
