import React from 'react';

import Modal from 'components/Modal';

import styles from './InvestmentLinkModal.module.scss';
import cs from 'classnames';

import { Clipboard } from 'react-feather';
import { getLink } from 'store/api/investments';
import { showSnackBar } from 'store/actions/snackbar';
const InvestmentLinkModal = ({
  onModalClose,
  modal,
  chosenInvestor,
  getAiLink,
  getAmlLink,
  getPaymentLink,
}) => {
  const [getLinkLoader, setLinkLoader] = React.useState(false);
  const onClickClipboard = async () => {
    try {
      setLinkLoader(true);

      const linkResponse = await getLink({
        nanoId: chosenInvestor.nanoId,
        getAiLink,
        getAmlLink,
        getPaymentLink,
      });

      if (linkResponse.err) {
        showSnackBar('Could not get url ', 'error');
        setLinkLoader(false);
        return;
      }

      navigator.clipboard.writeText(linkResponse.data.link);

      showSnackBar('Copied url to clipboard', 'info');
      setLinkLoader(false);
    } catch (err) {
      showSnackBar('Something Wetn Wrong', 'error');
      setLinkLoader(false);
    }
  };
  return (
    <Modal isOpen={modal} onClick={onModalClose} width="500px">
      <div className={styles.container}>
        <div className={cs('flex w-full flex-col ')}>
          <div className="w-full">
            <div className="text-center text-lg">
              <div
                className={styles.container__tradeId}
                style={{ fontSize: '24px' }}
              >
                {getAiLink && 'Accredited Link'}

                {getAmlLink && 'AML Link'}

                {getPaymentLink && 'Payment Link'}
              </div>

              <div className="mt-3 mb-2 text-xl">
                {' '}
                {chosenInvestor?.accountRegistration}
              </div>
              <button
                className={styles.clipboard}
                onClick={() => onClickClipboard()}
                disabled={getLinkLoader}
              >
                <Clipboard />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InvestmentLinkModal;
