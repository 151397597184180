import React from 'react';
import LoaderSpinner from 'components/LoadSpinner';

const LoadingIndicator = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <LoaderSpinner color="#FEA400" className="mx-auto mb-5" />
      <h2 className="pb-14 text-black font-semibold text-xl">
        Please wait while we fetch data..
      </h2>
    </div>
  );
};

export default LoadingIndicator;
