import React from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';
import LoadSpinner from '../LoadSpinner';

const Button = ({
  label,
  id,
  variant,
  isDisabled,
  isLoading,
  className,
  leftIcon,
  rightIcon,
  loadingText,
  ...rest
}) => {
  const getClassNames = () => {
    return cs(styles.root, className, {
      [styles.primary]: variant === 'primary',
      [styles.secondary]: variant === 'secondary',
      [styles.transparent]: variant === 'transparent',
    });
  };

  return (
    <button
      className={getClassNames()}
      id={id}
      disabled={isDisabled || isLoading}
      {...rest}
    >
      {leftIcon ? leftIcon : null}
      {isLoading ? (
        <>
          {loadingText ? loadingText : 'Loading...'}
          <LoadSpinner className={styles.loading} />
        </>
      ) : (
        label
      )}
      {rightIcon ? rightIcon : null}
    </button>
  );
};

Button.propTypes = {
  /** Button's label */
  label: PropTypes.node,
  /** Button's Left Icon */
  leftIcon: PropTypes.node,
  /** Button's Right Icon */
  rightIcon: PropTypes.node,
  /** Pass the unique ID */
  id: PropTypes.string.isRequired,
  /** The type of the button  */
  variant: PropTypes.oneOf(['primary', 'secondary', 'transparent']),
  /** if isDisabled styling changes and onClick disabled */
  isDisabled: PropTypes.bool,
  /** if isLoading styling changes and click event loading */
  isLoading: PropTypes.bool,
  /** Text when loading is true */
  loadingText: PropTypes.string,
};

Button.defaultProps = {
  label: '',
  id: '',
  leftIcon: null,
  rightIcon: null,
  variant: 'primary',
  className: '',
  isLoading: false,
  isDisabled: false,
  loadingText: 'Loading',
};

export default Button;
