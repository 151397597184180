import { combineReducers } from 'redux';
import authReducer from './auth';
import investorsReducer from './investors';
import usersReducer from './users';
import statsReducer from './stats';
import rolesReducer from './roles';
import navReducer from './nav';
import documentsReducer from './documents';
import investmentsReducer from './investments';
import profileReducer from './profile';
import reservationReducer from './reservations';
import applicationsReducer from './applications';
import leadsReducer from './leads';
import offeringsReducer from './offerings';
import prospectsReducer from './prospects';
import deprecatedDocumentsReducer from './deprecated-documents';
import newsReducer from './news';
import permissionsReducer from './permissions';
import noPaymentMethodReducer from './NoPaymentMethod';

const reducers = {
  auth: authReducer,
  investors: investorsReducer,
  users: usersReducer,
  stats: statsReducer,
  roles: rolesReducer,
  nav: navReducer,
  noPaymentMethod: noPaymentMethodReducer,
  profile: profileReducer,
  investments: investmentsReducer,
  documents: documentsReducer,
  reservations: reservationReducer,
  applications: applicationsReducer,
  leads: leadsReducer,
  offerings: offeringsReducer,
  deprecatedDocuments: deprecatedDocumentsReducer,
  news: newsReducer,
  permissions: permissionsReducer,
  prospects: prospectsReducer,
};

const combinedReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default rootReducer;
