import React from 'react';
import RSelect, { components } from 'react-select';
import cs from 'classnames';
import { Check } from 'react-feather';
import styles from './Select.module.scss';
import PropTypes from 'prop-types';

const { Option } = components;
const Select = ({
  id = '',
  label = '',
  wrapperClassName = 'flex-col',
  labelClassName = '',
  className = '',
  options = [],
  isDisabled = false,
  error = null,
  ...rest
}) => {
  const errorId = 'error-' + id;
  const labelTestId = id + '-label';

  const IconOption = props => (
    <Option
      {...props}
      className={cs({
        [styles.select_hasValue]: props.hasValue && !props.isSelected,
      })}
    >
      {props.isSelected ? <Check color="#6F7782" /> : null}
      {props.data.icon ? props.data.icon : null}
      {props.data.label}
    </Option>
  );

  return (
    <div
      className={cs(styles.root, wrapperClassName, {
        [styles.select_error]: error,
      })}
    >
      {label ? (
        <label
          className={cs(styles.label, labelClassName)}
          htmlFor={id}
          data-testid={labelTestId}
        >
          {label}
        </label>
      ) : null}
      <RSelect
        open
        classNamePrefix="rselect"
        className={cs(styles.container, className)}
        getOptionLabel={opt => opt.label}
        id={id}
        name={name}
        options={options}
        onChange={onchange}
        isDisabled={isDisabled}
        isOptionDisabled={option => option.isdisabled}
        components={{ Option: IconOption }}
        {...rest}
      />
      {/* If there is an error and we have an error message to display */}
      {error && (
        <span role="alert" id={errorId} className={styles.errorText}>
          {error}
        </span>
      )}
    </div>
  );
};

Select.propTypes = {
  /** Pass the unique ID */
  id: PropTypes.string.isRequired,
  /** Select tag label display above the input tag. */
  label: PropTypes.string,
  /** Select Component Wrapper Class Names  */
  wrapperClassName: PropTypes.string,
  /** Select Component Label Class Name  */
  labelClassName: PropTypes.string,
  /** Select Component Class Name  */
  className: PropTypes.string,
  /** Select disabled or not */
  isDisabled: PropTypes.bool,
  /** Select use to set validation or not and it default is False*/
  required: PropTypes.bool,
  /** Options use to show in select component*/
  options: PropTypes.array,
};

Select.defaultProps = {
  id: '',
  label: '',
  wrapperClassName: 'flex-col',
  labelClassName: '',
  className: '',
  type: 'text',
  error: '',
  options: [],
  required: false,
};

export default Select;
