import React, { useState } from 'react';
import moment from 'moment';
import cs from 'classnames';
import Card from 'components/Card';
import {
  CheckCircle,
  XCircle,
  UploadCloud,
  Clock,
  Trash2,
} from 'react-feather';
import Connector from 'components/Timeline/Connector';
import Button from 'components/Button';
import ActivityForm from 'components/Timeline/Activity.form';

const Timeline = ({
  className,
  title,
  activities = [],
  allowToCreateActivity = false,
  onActivityCreate,
}) => {
  const [modal, setModal] = useState(false);
  const renderIcon = icon => {
    switch (icon) {
      case 'upload': {
        return <UploadCloud className="text-green-500 mr-2 md:mr-4" />;
      }
      case 'cross': {
        return <XCircle className="text-red-500 mr-2 md:mr-4" />;
      }
      case 'check': {
        return <CheckCircle className="text-green-500 mr-2 md:mr-4" />;
      }
      case 'update': {
        return <Clock className="text-blue-500 mr-2 md:mr-4" />;
      }
      case 'delete': {
        return <Trash2 className="text-red-500 mr-2 md:mr-4" />;
      }
      default: {
        return <Clock className="text-blue-500 mr-2 md:mr-4" />;
      }
    }
  };

  const onModalClose = () => {
    setModal(false);
  };

  const renderContent = activity => {
    return (
      <div className="flex items-center">
        {renderIcon(activity.icon)}
        <div className="flex-col flex">
          <div className="text-brandgrey uppercase font-bold mb-1">
            {moment(activity.createdAt).format('MMM DD, YYYY')}
          </div>
          <h3 className="font-semibold text-lg mb-1">{activity.title}</h3>
          <span className="leading-tight">{activity.description}</span>
          {activity.user ? (
            <div className="flex flex-col items-end mt-2">
              <span className="text-brandorange uppercase text-sm font-bold">
                Updated By:
              </span>
              <span className="text-brandgrey text-sm font-semibold text-right">
                {activity.user?.name}{' '}
                {activity.user?.email ? `(${activity.user?.email})` : ''}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const reverseActivities = [...activities].reverse();

  return (
    <div className={className}>
      <Card.Header className="mb-2" color="text-brandgrey" title={title} />
      <Card fullHeight={false} background="bg-gray-400">
        <Card.Content>
          <div className="flex w-full justify-end -mt-2">
            {allowToCreateActivity ? (
              <Button label="Add Activity" onClick={() => setModal(!modal)} />
            ) : null}
          </div>
          <div className="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-blue-50">
            {reverseActivities.map((activity, index) => (
              <div
                key={index}
                className={cs('flex md:contents', {
                  'flex-row-reverse': index % 2 === 0,
                })}
              >
                {index % 2 !== 0 ? (
                  <Connector />
                ) : (
                  <div className="bg-brandblue col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto drop-shadow-2xl">
                    {renderContent(activity)}
                  </div>
                )}
                {index % 2 === 0 ? (
                  <Connector />
                ) : (
                  <div className="bg-brandblue col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto drop-shadow-xl">
                    {renderContent(activity)}
                  </div>
                )}
              </div>
            ))}
          </div>
        </Card.Content>
      </Card>
      {modal ? (
        <ActivityForm
          onModalClose={onModalClose}
          onActivityCreate={onActivityCreate}
        />
      ) : null}
    </div>
  );
};

export default Timeline;
