import { useParams, useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addLeadActivityById,
  fetchLeadDetail,
  rejectLeadById,
} from 'store/actions/leads';
import get from 'lodash/get';
import Details from 'components/Details';
import moment from 'moment';
import { ChevronLeft } from 'react-feather';
import LoadingIndicator from 'components/LoadingIndicator';
import Card from 'components/Card';
import DropdownMenu from 'components/DropdownMenu';
import Timeline from 'components/Timeline';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';

const LeadDetails = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  let history = useHistory();

  const lead = useSelector(({ leads }) => get(leads, 'details.data.data', {}));
  const isLoading = useSelector(({ leads }) =>
    get(leads, 'details.isInProgress', true)
  );

  useEffect(() => {
    dispatch(fetchLeadDetail(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const rejectLead = async () => {
    await dispatch(rejectLeadById(id));
    dispatch(fetchLeadDetail(id));
  };

  const onActivityCreate = async value => {
    await dispatch(addLeadActivityById(id, value));
  };

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'leads'
  );

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <div className="flex items-center justify-between">
              <div className="flex justify-between w-full">
                <Card.Header
                  title={
                    <>
                      <ChevronLeft
                        onClick={() => history.push(`/leads`)}
                        className="cursor-pointer mr-2"
                      />
                      Lead Details
                    </>
                  }
                />
                {!lead.is_rejected ? (
                  <DropdownMenu
                    options={[
                      {
                        label: 'Reject Lead',
                        onClick: rejectLead,
                      },
                    ]}
                  />
                ) : null}
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3  gap-6 mt-4">
              <Details title="First Name" data={lead?.first_name || '-'} />
              <Details title="Last Name" data={lead?.last_name || '-'} />
              <Details title="Email" data={lead?.email || '-'} />
              <Details title="Phone" data={lead?.phone || '-'} />
              <Details title="Company" data={lead?.company || '-'} />
              <Details title="Details" data={lead?.details || '-'} />
              <Details title="Message" data={lead?.message || '-'} />
              <Details
                title="Rejected?"
                data={lead?.is_rejected ? 'Yes' : 'No' || '-'}
              />
              <Details title="Lead Type" data={lead?.type || '-'} />
              <Details
                title="Has Resources?"
                data={lead?.has_resources ? 'Yes' : 'No' || '-'}
              />
              <Details
                title="Received On"
                data={
                  lead?.createdAt
                    ? moment(lead.createdAt).format('MMM Do, YYYY')
                    : '-'
                }
              />
            </div>
            <Timeline
              allowToCreateActivity
              title="Lead Activity"
              className="mt-6"
              activities={lead.activity || []}
              onActivityCreate={onActivityCreate}
            />
          </>
        )}
      </Card>
    </CanAccessComponent>
  );
};

export default LeadDetails;
