import * as actions from '../actions/roles';

const initialState = {
  roles: {
    isInProgress: true,
    isError: false,
    error: '',
    data: [],
  },
  rolesDetail: {
    isInProgress: true,
    isError: false,
    error: '',
    data: {},
  },
  createRole: {
    isInProgress: true,
    isError: false,
    error: '',
  },
  updateRole: {
    isInProgress: true,
    isError: false,
    error: '',
  },
  deleteRole: {
    isInProgress: true,
    isError: false,
    error: '',
  },
  exportToCsv: {
    isInProgress: true,
    isError: false,
    error: '',
    data: {},
  },
  availablePermissions: {
    isInProgress: false,
    isError: false,
    error: '',
    data: [],
  },
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_ROLES_INPROGRESS:
      return {
        ...state,
        roles: {
          ...state.roles,
          isInProgress: true,
        },
      };

    case actions.FETCH_ALL_ROLES_DATA:
      return {
        ...state,
        roles: {
          ...state.roles,
          data: action.data,
          isInProgress: false,
          isError: false,
          error: '',
        },
      };

    case actions.FETCH_ALL_ROLES_DATA_FAILED:
      return {
        ...state,
        roles: {
          ...state.roles,
          data: {},
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };

    case actions.FETCH_ROLE_DETAIL_INPROGRESS:
      return {
        ...state,
        rolesDetail: {
          ...state.rolesDetail,
          isInProgress: true,
        },
      };

    case actions.FETCH_ROLE_DETAIL_FAILED:
      return {
        ...state,
        rolesDetail: {
          ...state.rolesDetail,
          data: {},
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };

    case actions.FETCH_ROLE_DETAIL:
      return {
        ...state,
        rolesDetail: {
          ...state.rolesDetail,
          data: action.data.data,
          isInProgress: false,
          isError: false,
          error: '',
        },
      };

    case actions.EDIT_ROLE_DETAIL:
      return {
        ...state,
        rolesDetail: action.data,
      };

    case actions.EDIT_ROLE_DETAIL_FAILED:
      return {
        ...state,
        rolesDetail: {
          ...state.rolesDetail,
          data: {},
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };

    case actions.ADD_ROLE_INPROGRESS:
      return {
        ...state,
        createRole: {
          ...state.createRole,
          isInProgress: true,
        },
      };
    case actions.ADD_ROLE_SUCCESS:
      return {
        ...state,
        createRole: {
          ...state.createRole,
          isInProgress: false,
        },
      };
    case actions.ADD_ROLE_FAILED:
      return {
        ...state,
        createRole: {
          ...state.createRole,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };

    case actions.UPDATE_ROLE_INPROGRESS:
      return {
        ...state,
        updateRole: {
          ...state.updateRole,
          isInProgress: true,
        },
      };
    case actions.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        updateRole: {
          ...state.updateRole,
          isInProgress: false,
        },
      };
    case actions.UPDATE_ROLE_FAILED:
      return {
        ...state,
        updateRole: {
          ...state.updateRole,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };
    case actions.DELETE_ROLE_INPROGRESS:
      return {
        ...state,
        deleteRole: {
          ...state.deleteRole,
          isInProgress: true,
        },
      };
    case actions.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        deleteRole: {
          ...state.deleteRole,
          isInProgress: false,
        },
      };
    case actions.DELETE_ROLE_FAILED:
      return {
        ...state,
        deleteRole: {
          ...state.deleteRole,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };
    case actions.EXPORT_ROLE_CSV_INPROGRESS:
      return {
        ...state,
        exportToCsv: {
          ...state.exportToCsv,
          isInProgress: true,
        },
      };
    case actions.EXPORT_ROLE_CSV_SUCCESS:
      return {
        ...state,
        exportToCsv: {
          ...state.exportToCsv,
          isInProgress: false,
        },
      };
    case actions.EXPORT_ROLE_CSV_FAILED:
      return {
        ...state,
        exportToCsv: {
          ...state.exportToCsv,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };
    case actions.FETCH_AVAILABLE_PERMISSIONS_INPROGRESS:
      return {
        ...state,
        availablePermissions: {
          ...state.availablePermissions,
          isInProgress: true,
        },
      };
    case actions.FETCH_AVAILABLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        availablePermissions: {
          ...state.availablePermissions,
          isInProgress: false,
          data: action.data,
        },
      };
    case actions.FETCH_AVAILABLE_PERMISSIONS_FAILED:
      return {
        ...state,
        availablePermissions: {
          ...state.availablePermissions,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };

    default:
      return state;
  }
};

export default rolesReducer;
