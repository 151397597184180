import React from 'react';

const Boxabl = ({ ...rest }) => {
  return (
    <svg
      width="94"
      height="14"
      viewBox="0 0 94 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer"
      {...rest}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M8.80295 6.71458C9.39467 6.00426 9.75183 5.08428 9.75183 4.07935C9.75183 1.82911 7.95535 0 5.74129 0C5.72707 0 5.71285 0 5.69864 0H0V13.9931H6.28325C6.29391 13.9931 6.3028 13.9931 6.31346 13.9931C8.52752 13.9931 10.3258 12.164 10.3258 9.91372C10.3258 8.61779 9.73051 7.46285 8.80295 6.71458ZM2.2887 2.33519H5.71819H5.72174C6.67418 2.33519 7.44715 3.11961 7.44715 4.08478C7.44715 5.04994 6.67418 5.83436 5.72174 5.83436H5.71819H2.2887V2.33519ZM6.30991 11.6705H6.30635L2.2958 11.6687V8.17136L6.30635 8.17317H6.30991C7.26234 8.17317 8.03531 8.95759 8.03531 9.92275C8.03531 10.8861 7.26057 11.6705 6.30991 11.6705Z"
          fill="white"
        />
        <path
          d="M77.6011 6.72157C78.1928 6.01126 78.55 5.09128 78.55 4.08635C78.55 1.8343 76.7517 0.00518799 74.5377 0.00518799C74.5235 0.00518799 74.5092 0.00518799 74.495 0.00518799H68.7964V13.9983H75.0797C75.0903 13.9983 75.0992 13.9983 75.1098 13.9983C77.3239 13.9983 79.1222 12.1691 79.1222 9.91891C79.124 8.62479 78.5287 7.46985 77.6011 6.72157ZM71.0868 2.34219H74.5163H74.5199C75.4723 2.34219 76.2453 3.12661 76.2453 4.09177C76.2453 5.05694 75.4723 5.84136 74.5199 5.84136H74.5163H71.0868V2.34219ZM75.1081 11.6775H75.1045L71.094 11.6757V8.17655L75.1045 8.17836H75.1081C76.0605 8.17836 76.8335 8.96278 76.8335 9.92794C76.8335 10.8931 76.0587 11.6775 75.1081 11.6775Z"
          fill="white"
        />
        <path
          d="M21.7906 0.0144653C17.9986 0.0144653 14.9192 3.14673 14.9192 7.00377C14.9192 10.8608 17.9986 13.9931 21.7906 13.9931C25.5826 13.9931 28.662 10.8608 28.662 7.00377C28.662 3.14492 25.5826 0.0144653 21.7906 0.0144653ZM21.7906 11.6615C19.262 11.6615 17.2097 9.57393 17.2097 7.00197C17.2097 4.43 19.262 2.34243 21.7906 2.34243C24.3192 2.34243 26.3716 4.43 26.3716 7.00197C26.3716 9.57393 24.3192 11.6615 21.7906 11.6615Z"
          fill="white"
        />
        <path
          d="M38.6929 4.91438L41.9482 0.00180742L44.7114 0.00361485L40.0753 7.00015L44.7114 13.9949H41.9447L38.6929 9.08592L35.4393 13.9949H32.6726L37.3086 7.00015L32.6744 0.00180742L35.4375 0L38.6929 4.91438Z"
          fill="white"
        />
        <path
          d="M49.2941 13.9967V7.00198L56.1726 0.00183105L63.0511 7.00198V13.9967H60.7606V7.96353L56.1726 3.30038L51.5863 7.96353V13.9967H49.2941Z"
          fill="white"
        />
        <path
          d="M57.3187 7.9635V10.2951H59.611V7.9635H57.3187Z"
          fill="#FFA400"
        />
        <path
          d="M84.2606 0.0144653V14.0021H94.0036V11.6597H86.5529V0.0144653H84.2606Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="94" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Boxabl;
