import { get } from 'lodash';
import {
  downloadApplicationDocs,
  fetchApplicationData,
  fetchApplicationsList,
  rejectApplication,
  addApplicationActivity,
  fetchAllApplicationData,
} from '../api/applications';
import { showSnackBar } from './snackbar';

export const FETCH_APPLICATIONS_INPROGRESS = 'FETCH_APPLICATIONS_INPROGRESS';
export const FETCH_APPLICATIONS_SUCCESS = 'FETCH_APPLICATIONS_SUCCESS';
export const FETCH_APPLICATIONS_FAILURE = 'FETCH_APPLICATIONS_FAILURE';

export const FETCH_APPLICATIONS_DETAIL_INPROGRESS =
  'FETCH_APPLICATIONS_DETAIL_INPROGRESS';
export const FETCH_APPLICATIONS_DETAIL_SUCCESS =
  'FETCH_APPLICATIONS_DETAIL_SUCCESS';
export const FETCH_APPLICATIONS_DETAIL_FAILURE =
  'FETCH_APPLICATIONS_DETAIL_FAILURE';

export const REJECT_APPLICATION_INPROGRESS = 'REJECT_APPLICATION_INPROGRESS';
export const REJECT_APPLICATION_SUCCESS = 'REJECT_APPLICATION_SUCCESS';
export const REJECT_APPLICATION_FAILURE = 'REJECT_APPLICATION_FAILURE';

export const DOWNLOAD_DOCUMENT_INPROGRESS = 'DOWNLOAD_DOCUMENT_INPROGRESS';
export const DOWNLOAD_DOCUMENT_SUCCESS = 'DOWNLOAD_DOCUMENT_SUCCESS';
export const DOWNLOAD_DOCUMENT_FAILURE = 'DOWNLOAD_DOCUMENT_FAILURE';

export const ADD_APPLICATIONS_ACTIVITY_INPROGRESS =
  'ADD_APPLICATIONS_ACTIVITY_INPROGRESS';
export const ADD_APPLICATIONS_ACTIVITY_SUCCESS =
  'ADD_APPLICATIONS_ACTIVITY_SUCCESS';
export const ADD_APPLICATIONS_ACTIVITY_FAILED =
  'ADD_APPLICATIONS_ACTIVITY_FAILED';

export const EXPORT_APPLICATION_CSV_INPROGRESS =
  'EXPORT_APPLICATION_CSV_INPROGRESS';
export const EXPORT_APPLICATION_CSV_SUCCESS = 'EXPORT_APPLICATION_CSV_SUCCESS';
export const EXPORT_APPLICATION_CSV_FAILED = 'EXPORT_APPLICATION_CSV_FAILED';

export const fetchApplications = (page, sort, q, limit) => async dispatch => {
  dispatch({
    type: FETCH_APPLICATIONS_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await fetchApplicationsList(page, sort, q, limit);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    const data = get(resp, 'data', []);
    if (status) {
      dispatch({
        type: FETCH_APPLICATIONS_SUCCESS,
        data: data || [],
      });
    } else {
      dispatch({
        type: FETCH_APPLICATIONS_FAILURE,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_APPLICATIONS_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const fetchApplicationDetail = id => async dispatch => {
  dispatch({
    type: FETCH_APPLICATIONS_DETAIL_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await fetchApplicationData(id);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: FETCH_APPLICATIONS_DETAIL_SUCCESS,
        data: resp || {},
      });
    } else {
      dispatch({
        type: FETCH_APPLICATIONS_DETAIL_FAILURE,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_APPLICATIONS_DETAIL_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const rejectApplicationById = id => async dispatch => {
  dispatch({
    type: REJECT_APPLICATION_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await rejectApplication(id);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: REJECT_APPLICATION_SUCCESS,
        data: resp || {},
      });
    } else {
      dispatch({
        type: REJECT_APPLICATION_FAILURE,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: REJECT_APPLICATION_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const downloadDocumentForApplication = (
  applicationId,
  documentId
) => async dispatch => {
  dispatch({
    type: DOWNLOAD_DOCUMENT_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await downloadApplicationDocs(applicationId, documentId);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: DOWNLOAD_DOCUMENT_SUCCESS,
        data: resp || {},
      });
    } else {
      dispatch({
        type: DOWNLOAD_DOCUMENT_FAILURE,
        error: message,
      });
    }
    return resp;
  } catch (error) {
    dispatch({
      type: DOWNLOAD_DOCUMENT_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};
export const exportApplicationToCSV = () => async dispatch => {
  dispatch({
    type: EXPORT_APPLICATION_CSV_INPROGRESS,
  });
  try {
    const resp = await fetchAllApplicationData();
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: EXPORT_APPLICATION_CSV_SUCCESS,
      });
    } else {
      dispatch({
        type: EXPORT_APPLICATION_CSV_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
    return resp;
  } catch (error) {
    dispatch({
      type: EXPORT_APPLICATION_CSV_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const addApplicationActivityById = (id, activity) => async dispatch => {
  dispatch({
    type: ADD_APPLICATIONS_ACTIVITY_INPROGRESS,
  });
  try {
    const resp = await addApplicationActivity(id, activity);
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: ADD_APPLICATIONS_ACTIVITY_SUCCESS,
        data: resp,
      });

      showSnackBar(message, 'success');
    } else {
      dispatch({
        type: ADD_APPLICATIONS_ACTIVITY_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: ADD_APPLICATIONS_ACTIVITY_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};
