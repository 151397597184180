import React, { useState } from 'react';
import cs from 'classnames';
import Input from 'components/Input';
import Button from 'components/Button';
import Modal from 'components/Modal';
import {
  createNewRole,
  fetchRoleDetail,
  updateRoleById,
} from 'store/actions/roles';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Checkbox from 'components/Checkbox';
import * as Yup from 'yup';
import { get } from 'lodash';
import LoadSpinner from 'components/LoadSpinner';

const RolesForm = ({ onModalClose, data, viewMode }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const isFetchingPermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.isInProgress', true)
  );

  const {
    formState: { errors },
    register,
    setValue,
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      label: data?.label || '',
      value: data?.value || '',
      permissions: data.permissions || [],
    },
    resolver: yupResolver(
      Yup.object({
        label: Yup.string().required('Role is Required'),
      })
    ),
  });

  const permissions = watch('permissions');
  const createRole = async values => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (!viewMode) {
      await dispatch(createNewRole(values));
    } else {
      await dispatch(updateRoleById(data?._id.toString(), values));
      await dispatch(fetchRoleDetail(data?._id.toString()));
    }
    setLoading(false);
    onModalClose(true);
  };
  return (
    <Modal
      isOpen
      hasCloseIcon
      handleCloseClick={onModalClose}
      containerClassname="max-w-screen-sm"
      onClick={onModalClose}
      title={!viewMode ? 'Add a Role' : 'Edit Role'}
    >
      <div
        className={cs('flex w-full flex-col mt-4', {
          'items-center justify-center': loading,
          'items-start': !loading,
        })}
      >
        <form className="w-full">
          <Input
            id="label"
            name="label"
            wrapperClassName="w-full mt-2"
            placeholder="Role Name"
            error={errors.label?.message}
            register={register}
            onChange={e => {
              setValue('label', e.target.value);
              setValue(
                'value',
                e.target.value.replace(/ /g, '-').toLowerCase()
              );
            }}
          />
          {isFetchingPermissions ? (
            <div className="flex flex-col items-center justify-center w-full h-full mt-4">
              <LoadSpinner color="#FEA400" className="mx-auto mb-5" />
              <h2 className="pb-14 text-black font-semibold">
                Please wait while we fetch data..
              </h2>
            </div>
          ) : (
            availablePermissions.map((permission, index) => {
              const allPermissionSelected = permission.permissions.every(
                p => permissions.indexOf(p) > -1
              );
              return (
                <div key={index} className="flex flex-col w-full mt-4">
                  <div className="flex justify-between mb-2">
                    <span className="font-bold">{permission.page_name}</span>
                    <Checkbox
                      className="flex-row-reverse"
                      label="Select All"
                      checked={allPermissionSelected}
                      onChange={e => {
                        if (e.target.checked) {
                          const permissionsToAdd = permission.permissions.filter(
                            f => permissions.indexOf(f) === -1
                          );
                          setValue('permissions', [
                            ...permissions,
                            ...permissionsToAdd,
                          ]);
                        } else {
                          const permissionsToRemove = permission.permissions.filter(
                            f => permissions.indexOf(f) > -1
                          );
                          setValue('permissions', [
                            ...permissions.filter(
                              f => permissionsToRemove.indexOf(f) === -1
                            ),
                          ]);
                        }
                      }}
                    />
                  </div>
                  <div className="grid mt-2 grid-cols-3 gap-4">
                    {permission.permissions.map((p, index) => (
                      <Checkbox
                        className="capitalize"
                        key={index}
                        label={p}
                        value={p}
                        checked={permissions.indexOf(p) > -1}
                        id={p}
                        onChange={e => {
                          setValue(
                            'permissions',
                            e.target.checked
                              ? [...permissions, e.target.value]
                              : [
                                  ...permissions.filter(
                                    g => g !== e.target.value
                                  ),
                                ]
                          );
                        }}
                      />
                    ))}
                  </div>
                </div>
              );
            })
          )}
          <div className="w-full py-4 bg-white sticky bottom-0 flex justify-between">
            <Button
              onClick={onModalClose}
              variant="transparent"
              className="mr-3"
              label="Cancel"
            />
            <Button
              isLoading={loading}
              onClick={handleSubmit(createRole)}
              label={!viewMode ? 'Create' : 'Save'}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default RolesForm;
