import React from 'react';

export default function Logo() {
  return (
    <svg
      width="147"
      height="20"
      viewBox="0 0 117 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M10.9569 8.63303C11.6934 7.71976 12.1379 6.53693 12.1379 5.24488C12.1379 2.35172 9.90187 0 7.14607 0C7.12837 0 7.11068 0 7.09299 0H0V17.9911H7.82064C7.83391 17.9911 7.84497 17.9911 7.85824 17.9911C10.614 17.9911 12.8523 15.6394 12.8523 12.7462C12.8523 11.08 12.1114 9.59509 10.9569 8.63303ZM2.8487 3.00239H7.11732H7.12174C8.30722 3.00239 9.26932 4.01093 9.26932 5.25186C9.26932 6.49278 8.30722 7.50132 7.12174 7.50132H7.11732H2.8487V3.00239ZM7.85382 15.005H7.8494L2.85754 15.0026V10.506L7.8494 10.5084H7.85382C9.0393 10.5084 10.0014 11.5169 10.0014 12.7578C10.0014 13.9964 9.03709 15.005 7.85382 15.005Z"
          fill="#184273"
        />
        <path
          d="M96.5885 8.64207C97.325 7.7288 97.7695 6.54597 97.7695 5.25392C97.7695 2.35843 95.5313 0.00671387 92.7755 0.00671387C92.7578 0.00671387 92.7401 0.00671387 92.7224 0.00671387H85.6294V17.9978H93.4501C93.4633 17.9978 93.4744 17.9978 93.4876 17.9978C96.2434 17.9978 98.4817 15.6461 98.4817 12.7529C98.4839 11.0891 97.743 9.60413 96.5885 8.64207ZM88.4803 3.01143H92.7489H92.7533C93.9388 3.01143 94.9009 4.01997 94.9009 5.26089C94.9009 6.50182 93.9388 7.51036 92.7533 7.51036H92.7489H88.4803V3.01143ZM93.4854 15.014H93.481L88.4891 15.0117V10.5127L93.481 10.5151H93.4854C94.6709 10.5151 95.633 11.5236 95.633 12.7645C95.633 14.0055 94.6687 15.014 93.4854 15.014Z"
          fill="#184273"
        />
        <path
          d="M27.1225 0.0186157C22.4027 0.0186157 18.5698 4.04581 18.5698 9.00487C18.5698 13.9639 22.4027 17.9911 27.1225 17.9911C31.8424 17.9911 35.6753 13.9639 35.6753 9.00487C35.6753 4.04349 31.8424 0.0186157 27.1225 0.0186157ZM27.1225 14.9934C23.9753 14.9934 21.4207 12.3094 21.4207 9.00254C21.4207 5.69573 23.9753 3.01171 27.1225 3.01171C30.2698 3.01171 32.8244 5.69573 32.8244 9.00254C32.8244 12.3094 30.2698 14.9934 27.1225 14.9934Z"
          fill="#184273"
        />
        <path
          d="M48.1603 6.31849L52.2122 0.00232383L55.6514 0.00464766L49.881 9.00019L55.6514 17.9934H52.2078L48.1603 11.6819L44.1106 17.9934H40.667L46.4374 9.00019L40.6692 0.00232383L44.1084 0L48.1603 6.31849Z"
          fill="#184273"
        />
        <path
          d="M61.3555 17.9957V9.00251L69.917 0.00231934L78.4786 9.00251V17.9957H75.6277V10.2388L69.917 4.24331L64.2086 10.2388V17.9957H61.3555Z"
          fill="#FFA400"
        />
        <path
          d="M71.3438 10.2388V13.2365H74.1969V10.2388H71.3438Z"
          fill="#FFA400"
        />
        <path
          d="M104.877 0.0186157V18.0027H117.004V14.9911H107.731V0.0186157H104.877Z"
          fill="#184273"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="117" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
