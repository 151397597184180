import React, { useMemo, useState, useEffect } from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'components/Table/Table';
import DatePicker from 'components/DatePicker/index';
import {
  fetchDocuments,
  exportDocumentToCSV,
  bulkReSendDocumentUpload,
} from 'store/actions/documents';
import {
  ChevronDown,
  ChevronRight,
  Info,
  UserX,
  UserCheck,
} from 'react-feather';
import moment from 'moment';
import Card from 'components/Card';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';
import cs from 'classnames';
import CSVDownload from 'components/CSVDownload';
import { useMedia } from 'react-use';
import ReactTooltip from 'react-tooltip';
import DocumentsSubRow from './DocumentsSubRow';
import Button from 'components/Button';
import DocumentsUploadEmailMessage from './DocumentsUploadEmailMessage.form';
import useQuery from 'helpers/useQuery';

const Documents = () => {
  const query = useQuery();
  const type_doc = query.get('type') === 'aml' ? 'aml' : undefined;

  const dispatch = useDispatch();
  const [type, setType] = useState(type_doc);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [modal, setModal] = useState(false);
  const isMaxWidth640px = useMedia('(max-width: 640px)');

  useEffect(() => {
    setType(type_doc);
  }, [type_doc]);

  const documentsData = useSelector(({ documents }) =>
    get(documents, 'documentState.data.data', [])
  );

  const documentsMetadata = useSelector(({ documents }) =>
    get(documents, 'documentState.data.meta', {})
  );

  const bulkDocumentsIsInProgress = useSelector(({ documents }) =>
    get(documents, 'bulkReSendDocumentUploadEmail.isInProgress', false)
  );

  const handleTableChange = async ({ pageIndex, sortBy, pageSize, query }) => {
    let sort = null;

    if (sortBy.length) {
      if (sortBy[0].id === 'users[0].createdAt') {
        sort = sortBy[0].desc
          ? { 'users.createdAt': -1 }
          : { 'users.createdAt': 1 };
      } else if (sortBy[0].id === 'users[0].updatedAt') {
        sort = sortBy[0].desc
          ? { 'users.updatedAt': -1 }
          : { 'users.updatedAt': 1 };
      } else if (sortBy[0].id === 'users[0].name') {
        sort = sortBy[0].desc ? { 'users.name': -1 } : { 'users.name': 1 };
      } else if (sortBy[0].id === 'users[0].email') {
        sort = sortBy[0].desc ? { 'users.email': -1 } : { 'users.email': 1 };
      }
    }

    await dispatch(
      fetchDocuments(
        pageIndex + 1,
        sort,
        query,
        pageSize,
        startDate,
        endDate,
        type
      )
    );
  };

  const userColumns = useMemo(
    () => [
      {
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <ChevronDown /> : <ChevronRight />}
          </span>
        ),
      },
      {
        Header: () => (
          <div className="flex justify-start w-full pl-4">Name</div>
        ),
        accessor: 'users[0].name',
        Cell: ({ value, row }) =>
          value ? (
            <div
              className="flex items-start"
              {...row.getToggleRowExpandedProps()}
            >
              {value}
            </div>
          ) : null,
      },
      {
        Header: 'Email',
        accessor: 'users[0].email',
      },
      {
        Header: 'Created',
        accessor: 'users[0].createdAt',
        Cell: ({ value }) =>
          value ? moment(value).format('MMM Do, YYYY') : null,
      },
      {
        Header: () => (
          <div className="flex items-center cursor-none">
            <ReactTooltip id="verify" effect="solid">
              If the user has all documents approved he is verified otherwise he
              is not.
            </ReactTooltip>
            <p className="cursor-pointer">User Verified</p>
            <Info
              className="ml-1 cursor-none"
              size={18}
              strokeWidth={2}
              data-tip
              data-for="verify"
            />
          </div>
        ),
        accessor: 'documents',
        Cell: ({ value }) => {
          const isTrue =
            value && value.length ? value.every(val => val.is_verified) : false;

          return (
            <p
              className={cs('flex justify-center font-medium', {
                'text-green-500': isTrue,
                'text-red-500': !isTrue,
              })}
            >
              {value && value.length && isTrue ? (
                <div className="flex items-center">
                  User Verified <UserCheck className="ml-2" />
                </div>
              ) : (
                <div className="flex items-center">
                  User Not Verified <UserX className="ml-2" />
                </div>
              )}
            </p>
          );
        },
      },
      {
        Header: () => (
          <div className="flex justify-center w-full">Last Updated</div>
        ),
        accessor: 'users[0].updatedAt',
        Cell: ({ value }) =>
          value ? moment(value).format('MMM Do, YYYY') : null,
      },
      {
        Header: 'Count',
        accessor: 'count',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onBulkReSendDocumentUploadClick = async message => {
    const response = await dispatch(
      bulkReSendDocumentUpload(selectedIds, message)
    );
    if (response.status) {
      setSelectedIds([]);
    }
  };

  const onModalClose = () => {
    setModal(false);
  };

  const handleChange = (event, row) => {
    let newSelectedIdsList = [...selectedIds];
    if (event.target.checked) {
      newSelectedIdsList.push({
        userId: row.original.user._id,
        offering: row.original.offering.slug,
        documentId: row.original._id,
        documentType: row.original.verification_for,
      });
    } else {
      const foundIndex = newSelectedIdsList.findIndex(
        e =>
          e.userId === row.original.user._id &&
          e.offering === row.original.offering.slug &&
          e.documentId === row.original._id
      );
      if (foundIndex > -1) {
        newSelectedIdsList.splice(foundIndex, 1);
      }
    }
    setSelectedIds(newSelectedIdsList);
  };

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <div className="mb-5">
        <DocumentsSubRow
          row={row}
          handleChange={handleChange}
          selectedIds={selectedIds}
          type={type}
        />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIds, type]
  );

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    type ? 'aml' : 'documents'
  );

  const generateCSVClick = async () => {
    const response = await dispatch(exportDocumentToCSV());
    if (response.status) {
      return response.data;
    }
    return [];
  };
  return (
    <CanAccessComponent
      allowedPermissions={permissionsForPage}
      redirectUrl="/permission-error"
    >
      <Card>
        <div
          className={cs('flex justify-between sm:items-center mb-2', {
            'flex-col': isMaxWidth640px,
          })}
        >
          {/* <Button
            onClick={() => {
              setType('aml');
            }}
            label={'toggle'}
          /> */}
          <Card.Header
            title={`${type ? 'AML' : 'Documents'} ${
              documentsMetadata.totalDocumentResults
                ? `(${documentsMetadata.totalDocumentResults})`
                : ''
            }`}
          />
          <div className="flex">
            {type ? null : (
              <CSVDownload
                filename="Documents.csv"
                onDataFetch={generateCSVClick}
                csvHeaders={[
                  { label: 'Document ID', key: '_id' },
                  { label: 'File Name', key: 'name' },
                  { label: 'File Size', key: 'size' },
                  { label: 'User ID', key: 'user._id' },
                  { label: 'User Name', key: 'user.name' },
                  { label: 'Email', key: 'user.email' },
                  { label: 'Document Verified?', key: 'is_verified' },
                  { label: 'Is Deleted?', key: 'is_deleted' },
                  { label: 'Uploaded On', key: 'createdAt' },
                ]}
              />
            )}
          </div>
        </div>
        <div className="flex justify-between sm:items-center mb-2">
          <div className="flex">
            <DatePicker
              label="Start Date"
              wrapperClassName="mr-2"
              selected={startDate}
              onChange={date => setStartDate(date)}
              isClearable
            />
            <DatePicker
              label="End Date"
              selected={endDate}
              onChange={date => setEndDate(date)}
              isClearable
            />
          </div>
          <Button
            loadingText="Please wait"
            isLoading={bulkDocumentsIsInProgress}
            className="mt-2 sm:mt-0 mr-2"
            label="Send Bulk Email"
            onClick={() => {
              setModal(!modal);
            }}
            isDisabled={!selectedIds.length}
          />
        </div>
        <Card.Content className="h-full flex">
          <Table
            columns={userColumns}
            data={documentsData.length ? documentsData : []}
            total={documentsMetadata?.totalPages}
            fetchData={handleTableChange}
            renderRowSubComponent={renderRowSubComponent}
            startDate={startDate}
            forceRefetch={type}
            endDate={endDate}
          />
        </Card.Content>
        {modal ? (
          <DocumentsUploadEmailMessage
            onModalClose={onModalClose}
            onDocumentsUploadEmailMessageClick={onBulkReSendDocumentUploadClick}
          />
        ) : null}
      </Card>
    </CanAccessComponent>
  );
};

export default Documents;
