import React from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import styles from './NewsEditor.module.scss';
import Button from 'components/Button';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useForm } from 'react-hook-form';
import Input from 'components/Input';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetchCreateOneNews } from 'store/actions/news';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import Card from 'components/Card';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';
import EditorFeaturedLinkButton from 'components/EditorFeaturedLinkButton/EditorFeaturedLinkButton';
import EditorImageButton from 'components/EditorImageButton/EditorImageButton';
const schema = Yup.object().shape({
  title: Yup.string().required(),
  featured_link: Yup.string(),
});

const NewsEditor = () => {
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  const dispatch = useDispatch();
  const isCreateLoading = useSelector(({ news }) =>
    get(news, 'isCreateLoading')
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ title, featured_link }) => {
    const HTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    dispatch(fetchCreateOneNews(title, HTML, featured_link));
  };

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'news'
  );

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card fullHeight={false}>
        <form className="flex w-full mb-3" onSubmit={handleSubmit(onSubmit)}>
          <Input
            wrapperClassName="w-full"
            placeholder="Title"
            name="title"
            register={register}
            error={errors?.title?.message}
          />
          <Button
            type="submit"
            className="ml-4"
            isLoading={isCreateLoading}
            label="Publish"
          />
        </form>

        <Editor
          toolbarCustomButtons={[
            <EditorFeaturedLinkButton key={'1'} register={register} />,
            <EditorImageButton key={'2'} setEditorState={setEditorState} />,
          ]}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'embedded',
              'emoji',
              //REMOVED IMAGE
              'remove',
              'history',
            ],
          }}
          wrapperClassName={styles.editor_container}
          editorClassName={styles.center}
          editorState={editorState}
          onEditorStateChange={setEditorState}
        />
      </Card>
    </CanAccessComponent>
  );
};

export default NewsEditor;
