import React from 'react';
// layout
import Input from 'components/Input';
import * as Yup from 'yup';
import Button from 'components/Button';
import { forgotPasswordUser } from 'store/actions/auth';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      Yup.object({
        email: Yup.string()
          .email()
          .required(),
      })
    ),
  });

  const onSubmit = async ({ email }) => {
    await forgotPasswordUser(email);
    setValue('email', '');
  };

  return (
    <div className="min-h-screen" style={{ background: '#E5E5E5' }}>
      <div className="container py-40 mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="px-4 lg:px-10 py-10"
              >
                <div className="w-full flex justify-center items-center mb-6">
                  <h1 className="font-medium text-3xl">Forgot Password</h1>
                </div>

                <Input
                  id="email"
                  type="email"
                  name="email"
                  label="Email"
                  error={errors.email?.message}
                  register={register}
                />

                <div className="text-center mt-6">
                  <Button className="full-width" type="submit" label="Submit" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
