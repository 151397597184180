import React from 'react';
import styles from './tags.module.scss';
import cs from 'classnames';

const Tags = ({ type, label }) => {
  return (
    <div
      className={cs(styles.tags, {
        [styles.pink]: type === 'pink',
        [styles.blue]: type === 'blue',
        [styles.black]: type === 'black',
        [styles.white]: type === 'white',
      })}
    >
      <span>{label}</span>
    </div>
  );
};

Tags.defaultProps = {
  label: '1 of 12',
};

export default Tags;
