import * as actions from '../actions/news';

const initialState = {
  // ALL NEWS
  newsState: {
    isInProgress: false,
    isError: false,
    message: '',
    data: {},
  },
  //ONE NEWS
  details: {
    isInProgress: true,
    isError: false,
    status: 0,
    error: '',
    data: {},
  },

  isAuthloading: false,
  auth: {},

  // CREATE
  isCreateLoading: false,
  createError: '',

  // DELETE
  isDeleteLoading: false,
  deleteError: '',

  //UPDATE
  isUpdateloading: false,
  updateError: '',
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_AUTH_NEWS_INPROGRESS:
      return {
        ...state,
        isAuthloading: true,
      };
    case actions.FETCH_AUTH_NEWS_SUCCESS:
      return {
        ...state,
        isAuthloading: false,
      };

    // ALL NEWS
    case actions.FETCH_NEWS_INPROGRESS:
      return {
        ...state,
        newsState: {
          ...state.newsState,
          isInProgress: true,
        },
      };
    case actions.FETCH_NEWS_SUCCESS:
      return {
        ...state,
        newsState: {
          ...state.newsState,
          isInProgress: false,
          data: action.payload,
        },
        headers: action.payload.headers,
      };
    case actions.FETCH_NEWS_FAILURE:
      return {
        ...state,
        newsState: {
          ...state.newsState,
          message: action.payload,
          isError: true,
        },
      };

    // ONE NEWS
    case actions.FETCH_ONE_NEWS_INPROGRESS:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: true,
        },
      };
    case actions.FETCH_ONE_NEWS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          data: action.payload.news,
          isInProgress: false,
        },
      };
    case actions.FETCH_ONE_NEWS_FAILURE:
      return {
        ...state,
      };

    // CREATE
    case actions.FETCH_CREATE_ONE_NEWS_INPROGRESS:
      return {
        ...state,
        isCreateLoading: true,
      };

    case actions.FETCH_CREATE_ONE_NEWS_SUCCESS:
      return {
        ...state,
        isCreateLoading: false,
      };

    case actions.FETCH_CREATE_ONE_NEWS_FAILURE:
      return {
        ...state,
        createError: action.payload,
      };

    // DELETE
    case actions.FETCH_DELETE_ONE_NEWS_INPROGRESS:
      return {
        ...state,
        isDeleteLoading: true,
      };

    case actions.FETCH_DELETE_ONE_NEWS_SUCCESS:
      return {
        ...state,
        isDeleteLoading: false,
      };

    case actions.FETCH_DELETE_ONE_NEWS_FAILURE:
      return {
        ...state,
        deleteError: action.payload,
      };

    //UPDATE
    case actions.FETCH_UPDATE_ONE_NEWS_INPROGRESS:
      return {
        ...state,
        isUpdateloading: true,
      };

    case actions.FETCH_UPDATE_ONE_NEWS_SUCCESS:
      return {
        ...state,
        isUpdateloading: false,
      };
    case actions.FETCH_UPDATE_ONE_NEWS_FAILURE:
      return {
        ...state,
        deleteError: action.payload,
      };

    default:
      return state;
  }
};

export default newsReducer;
