import Request from './request';
import axios from 'axios';
export const postIssueRefund = async data =>
  await Request.call({
    url: '/investments/issue-refund',
    method: 'POST',
    data: data,
  });

export const postInvestmentCsv = async data =>
  await Request.call({
    url: '/investments/investment-csv',
    method: 'POST',
    data: data,
  });

export const postMarkTradeAsSettled = async data =>
  await Request.call({
    url: '/investments/update-trade-as-settled',
    method: 'POST',
    data: data,
  });

export const addSignerIpAddressToTrade = async data =>
  await Request.call({
    url: '/investments/add-signer-ipaddress-to-trade',
    method: 'POST',
    data: data,
  });

export const getIpAddress = async () =>
  await axios.get('https://geolocation-db.com/json/');

export const postGetAccountsParties = async data =>
  await Request.call({
    url: '/investments/get-accounts-parties',
    method: 'POST',
    data: data,
  });

export const postGetAccounts = async accounts =>
  await Request.call({
    url: '/investments/accounts',
    method: 'POST',
    data: {
      accounts,
    },
  });

export const postGetAccountDocuments = async data =>
  await Request.call({
    url: '/investments/accountDocuments',
    method: 'POST',
    data: data,
  });

export const postUpdateAccountDocuments = async data =>
  await Request.call({
    url: '/investments/updateAccountDocuments',
    method: 'POST',
    data: data,
  });

export const fetchInvestmentsList = async (
  page,
  sort,
  query,
  limit,
  startDate,
  endDate,
  funded,
  isDisapproved,
  docStatus
) =>
  await Request.call({
    url: '/investments',
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
      limit,
      startDate,
      endDate,
      funded,
      isDisapproved,
      docStatus,
    },
  });

export const getLink = async data =>
  await Request.call({
    url: '/investments/get-link',
    method: 'POST',
    data,
  });

export const reSendPayment = async data =>
  await Request.call({
    url: '/investments/send-payment',
    method: 'POST',
    data,
  });

export const updateParty = async data =>
  await Request.call({
    url: '/investments/party-update',
    method: 'POST',
    data,
  });

export const accountUpdate = async data =>
  await Request.call({
    url: '/investments/account-update',
    method: 'POST',
    data,
  });

export const fetchInvestmentDetailList = async ({ accountMongoId, funded }) =>
  await Request.call({
    url: `/investments/investor`,
    method: 'GET',
    params: {
      accountMongoId,
      funded,
    },
  });

export const fetchAllInvestmentData = async () =>
  await Request.call({
    url: `/investments/all`,
    method: 'GET',
  });

export const resendDocumentEmailForInvestment = async (id, message, type) => {
  return await Request.call({
    url: `/investments/${id}/email`,
    method: 'POST',
    ...(message ? { data: { message, type } } : {}),
  });
};

export const bulkReSendDocumentsEmailsForInvestment = async (
  investmentId,
  message
) => {
  return await Request.call({
    url: `/investments/sendBulkEmail`,
    method: 'POST',
    data: { investmentId, ...(message ? { message } : {}) },
  });
};
