import React from 'react';
import Details from 'components/Details';
import moment from 'moment';

const EntityData = ({ entity }) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 px-6 pb-6 gap-6">
      {entity ? (
        <>
          <Details title="Link Type" data={entity?.linkType} />
          <Details title="type" data={entity?.type} />
          <Details title="Entity Name" data={entity?.entityName} />

          <Details
            title="Entity Description
"
            data={entity?.entityDesc}
          />

          <Details title="Entity Id" data={entity?.partyId} />

          <Details
            title="EIN"
            data={entity?.primCountry === 'US' ? entity?.ein : 'NOT US'}
          />

          <Details
            title="Aml Date"
            data={
              entity?.AMLdate && moment(entity?.AMLdate).format('MMM Do, YYYY')
            }
          />

          <Details title="Know Your Customer (KYC) " data={entity?.kycStatus} />

          <Details
            title="Anti Money Laundering (AML)"
            data={entity?.amlStatus}
          />

          <Details title="North Capital Notes" data={entity?.notes} />

          <Details
            title="Created Party At"
            data={
              entity?.createdDate &&
              moment(entity?.createdDate).format('MMM Do, YYYY')
            }
          />

          <Details title="Domicile" data={entity?.domicile} />
          <Details title="Email" data={entity?.emailAddress} />
          <Details title="Phone" data={entity?.phone} />
          <Details title="Primary Address" data={entity?.primAddress1} />
          <Details title="Secondary Address" data={entity?.primAddress2} />
          <Details title="Primary City" data={entity?.primCity} />

          <Details title="Primary Country" data={entity?.primCountry} />

          <Details title="Primary State" data={entity?.primState} />

          <Details title="Primarty Zip" data={entity?.primZip} />
        </>
      ) : null}
    </div>
  );
};

export default EntityData;
