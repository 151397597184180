import React, { useMemo } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'components/Table';
import NumberFormat from 'react-number-format';

import {
  fetchReservations,
  exportReservationToCSV,
} from 'store/actions/reservations';

import Card from 'components/Card';
import cs from 'classnames';
import CSVDownload from 'components/CSVDownload';
import { useMedia } from 'react-use';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';

const Reservations = () => {
  const dispatch = useDispatch();

  const isMaxWidth640px = useMedia('(max-width: 640px)');

  const reservationsData = useSelector(({ reservations }) =>
    get(reservations, 'reservations.data.data', [])
  );

  const reservationsMetadata = useSelector(({ reservations }) =>
    get(reservations, 'reservations.data.meta', {})
  );

  const handleTableChange = async ({ pageIndex, sortBy, pageSize, query }) => {
    let sort = '';
    if (sortBy.length) {
      sort = sortBy[0].desc ? `-${sortBy[0].id}` : sortBy[0].id;
    }
    await dispatch(fetchReservations(pageIndex + 1, sort, query, pageSize));
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Position',
        accessor: 'position',
      },
      {
        Header: () => 'Tracking Id',
        accessor: 'trackingId',
      },
      {
        Header: () => 'Payment Status',
        accessor: 'paymentStatus',
        Cell: ({ value }) => {
          return value === 'failed' ||
            value === 'succeeded' ||
            value === null ? (
            <div>succeeded</div>
          ) : (
            <div>processing</div>
          );
        },
      },

      {
        Header: 'Name',
        accessor: 'fullname',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone',
        accessor: 'loginPhoneNumber',
      },
      {
        Header: 'Units',
        accessor: 'casitaAmount',
      },
      {
        Header: 'Tier',
        accessor: 'tier',
        Cell: ({ value }) =>
          value ? (
            <div>
              $
              <NumberFormat
                value={value}
                displayType="text"
                thousandSeparator={true}
              />
            </div>
          ) : (
            '$0'
          ),
      },
      {
        Header: () => (
          <div className="flex justify-end w-full">Reserved On</div>
        ),
        accessor: 'createdAt',
        Cell: ({ value }) => (
          <div className="flex justify-end">
            <span className="flex items-center mr-4">
              {value ? moment(value).format('MMM Do, YYYY') : null}
            </span>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'reservations'
  );

  const generateCSVClick = async () => {
    const response = await dispatch(exportReservationToCSV());
    if (response.status) {
      return response?.data;
    }
    return [];
  };

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        <div
          className={cs('flex justify-between sm:items-center mb-2', {
            'flex-col': isMaxWidth640px,
          })}
        >
          <Card.Header
            title={`Reservations ${
              reservationsMetadata && reservationsMetadata.totalResults
                ? `(${reservationsMetadata.totalResults})`
                : ''
            }`}
          />
          <div className="flex">
            <CSVDownload
              filename="Reservation.csv"
              onDataFetch={generateCSVClick}
              csvHeaders={[
                { label: 'First Name', key: 'first_name' },
                { label: 'Last Name', key: 'last_name' },
                { label: 'Email', key: 'email' },
                { label: 'Phone', key: 'phone' },
                { label: 'Units', key: 'units' },
                { label: 'Amount', key: 'amount' },
                { label: 'Reserved On', key: 'createdAt' },
              ]}
            />
          </div>
        </div>
        <Card.Content className="h-full flex">
          <Table
            columns={columns}
            data={reservationsData}
            total={reservationsMetadata.totalPages}
            fetchData={handleTableChange}
          />
        </Card.Content>
      </Card>
    </CanAccessComponent>
  );
};

export default Reservations;
