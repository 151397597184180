import Request from './request';

export const fetchList = async (page, sort, query, limit) =>
  await Request.call({
    url: '/funding/investors',
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
      limit,
    },
  });

export const fetchDetail = async id =>
  await Request.call({
    url: `/funding/investors/${id}`,
    method: 'GET',
  });
