import * as actions from '../actions/applications';

const initialState = {
  applications: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
    data: {},
  },
  details: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
    data: {},
  },
  download: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
  },
  addActivity: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
  },
  exportToCsv: {
    isInProgress: true,
    isError: false,
    error: '',
    data: {},
  },
};

const applicationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_APPLICATIONS_INPROGRESS:
      return {
        ...state,
        applications: {
          ...state.applications,
          isInProgress: true,
        },
      };

    case actions.FETCH_APPLICATIONS_SUCCESS:
      return {
        ...state,
        applications: {
          ...state.applications,
          isInProgress: false,
          status: 1,
          data: action.data,
          isError: false,
          error: '',
        },
      };
    case actions.FETCH_APPLICATIONS_FAILURE:
      return {
        ...state,
        applications: {
          ...state.applications,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };

    case actions.FETCH_APPLICATIONS_DETAIL_INPROGRESS:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: true,
        },
      };

    case actions.FETCH_APPLICATIONS_DETAIL_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: false,
          status: 1,
          data: action.data,
          isError: false,
          error: '',
        },
      };

    case actions.FETCH_APPLICATIONS_DETAIL_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };

    case actions.DOWNLOAD_DOCUMENT_INPROGRESS:
      return {
        ...state,
        download: {
          ...state.download,
          isInProgress: true,
        },
      };

    case actions.DOWNLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        download: {
          ...state.download,
          isInProgress: false,
          status: 1,
          isError: false,
          error: '',
        },
      };

    case actions.DOWNLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        download: {
          ...state.download,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };
    case actions.ADD_APPLICATIONS_ACTIVITY_INPROGRESS:
      return {
        ...state,
        addActivity: {
          ...state.addActivity,
          isInProgress: true,
        },
      };
    case actions.ADD_APPLICATIONS_ACTIVITY_SUCCESS:
      return {
        ...state,
        addActivity: {
          ...state.addActivity,
          isInProgress: false,
        },
        details: {
          ...state.details,
          data: action.data,
          isError: false,
          error: '',
        },
      };
    case actions.ADD_APPLICATIONS_ACTIVITY_FAILED:
      return {
        ...state,
        addActivity: {
          ...state.addActivity,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };

    case actions.EXPORT_APPLICATION_CSV_INPROGRESS:
      return {
        ...state,
        exportToCsv: {
          ...state.exportToCsv,
          isInProgress: true,
        },
      };
    case actions.EXPORT_APPLICATION_CSV_SUCCESS:
      return {
        ...state,
        exportToCsv: {
          ...state.exportToCsv,
          isInProgress: false,
        },
      };
    case actions.EXPORT_APPLICATION_CSV_FAILED:
      return {
        ...state,
        exportToCsv: {
          ...state.exportToCsv,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };
    default:
      return state;
  }
};

export default applicationsReducer;
