import { get } from 'lodash';
import {
  fetchInvestmentDetailList,
  fetchInvestmentsList,
  fetchAllInvestmentData,
  resendDocumentEmailForInvestment,
  bulkReSendDocumentsEmailsForInvestment,
  postGetAccounts,
  postGetAccountDocuments,
  postUpdateAccountDocuments,
} from '../api/investments';
import { showSnackBar } from './snackbar';

export const FETCH_INVESTMENTS_INPROGRESS = 'FETCH_INVESTMENTS_INPROGRESS';
export const FETCH_INVESTMENTS_SUCCESS = 'FETCH_INVESTMENTS_SUCCESS';
export const FETCH_INVESTMENTS_FAILURE = 'FETCH_INVESTMENTS_FAILURE';

export const FETCH_INVESTMENTS_DETAIL_INPROGRESS =
  'FETCH_INVESTMENTS_DETAIL_INPROGRESS';
export const FETCH_INVESTMENTS_DETAIL_SUCCESS =
  'FETCH_INVESTMENTS_DETAIL_SUCCESS';
export const FETCH_INVESTMENTS_DETAIL_FAILURE =
  'FETCH_INVESTMENTS_DETAIL_FAILURE';

export const EXPORT_INVESTMENTS_CSV_INPROGRESS =
  'EXPORT_INVESTMENTS_CSV_INPROGRESS';
export const EXPORT_INVESTMENTS_CSV_SUCCESS = 'EXPORT_INVESTMENTS_CSV_SUCCESS';
export const EXPORT_INVESTMENTS_CSV_FAILED = 'EXPORT_INVESTMENTS_CSV_FAILED';

export const RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_INPROGRESS =
  'RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_INPROGRESS';
export const RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_SUCCESS =
  'RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_SUCCESS';
export const RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_FAILURE =
  'RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_FAILURE';

export const BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_INPROGRESS =
  'BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_INPROGRESS';
export const BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_SUCCESS =
  'BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_SUCCESS';
export const BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_FAILURE =
  'BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_FAILURE';

export const GET_ACCOUNTS_INPROGRESS = 'GET_ACCOUNTS_INPROGRESS';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_FAILURE = 'GET_ACCOUNTS_FAILURE';

export const GET_PARTIES_INPROGRESS = 'GET_PARTIES_INPROGRESS';
export const GET_PARTIES_SUCCESS = 'GET_PARTIES_SUCCESS';
export const GET_PARTIES_FAILURE = 'GET_PARTIES_FAILURE';

export const GET_ACCOUNT_DOCUMENT_INPROGRESS =
  'GET_ACCOUNT_DOCUMENT_INPROGRESS';
export const GET_ACCOUNT_DOCUMENT_SUCCESS = 'GET_ACCOUNT_DOCUMENT_SUCCESS';
export const GET_ACCOUNT_DOCUMENT_FAILURE = 'GET_ACCOUNT_DOCUMENT_FAILURE';

export const UPDATE_ACCOUNT_DOCUMENT_INPROGRESS =
  'UPDATE_ACCOUNT_DOCUMENT_INPROGRESS';
export const UPDATE_ACCOUNT_DOCUMENT_SUCCESS =
  'UPDATE_ACCOUNT_DOCUMENT_SUCCESS';
export const UPDATE_ACCOUNT_DOCUMENT_FAILURE =
  'UPDATE_ACCOUNT_DOCUMENT_FAILURE';

export const CHANGE_DOC_STATUS = 'CHANGE_DOC_STATUS';

export const setDocStatus = data => async dispatch => {
  dispatch({
    type: CHANGE_DOC_STATUS,
    data: data,
  });
};

export const fetchParties = accounts => async dispatch => {
  dispatch({
    type: GET_PARTIES_INPROGRESS,
  });
  try {
    const resp = await postGetAccounts(accounts);

    dispatch({
      type: FETCH_INVESTMENTS_SUCCESS,
      data: resp,
    });
  } catch (error) {
    dispatch({
      type: FETCH_INVESTMENTS_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const fetchAccounts = accounts => async dispatch => {
  dispatch({
    type: GET_ACCOUNTS_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await postGetAccounts(accounts);

    dispatch({
      type: FETCH_INVESTMENTS_SUCCESS,
      data: resp,
    });
  } catch (error) {
    dispatch({
      type: FETCH_INVESTMENTS_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const actionUpdateAccountDocuments = (
  document,
  documents
) => async dispatch => {
  dispatch({
    type: UPDATE_ACCOUNT_DOCUMENT_INPROGRESS,
    data: document,
  });

  try {
    const data = { document, documents };

    const resp = await postUpdateAccountDocuments(data);

    if (resp.err) {
      dispatch({
        type: UPDATE_ACCOUNT_DOCUMENT_FAILURE,
        error: 'Something went wrong! Please try again later',
      });
      showSnackBar(JSON.stringify({ ...resp }), 'error');
    }
    if (resp.data) {
      const { updatedDocument } = resp.data;
      const updatedDoc = { ...updatedDocument, isReviewLoading: false };

      dispatch({
        type: UPDATE_ACCOUNT_DOCUMENT_SUCCESS,
        data: updatedDoc,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_ACCOUNT_DOCUMENT_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const getAccountDocuments = data => async dispatch => {
  dispatch({
    type: GET_ACCOUNT_DOCUMENT_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await postGetAccountDocuments(data);

    if (resp.err) {
      dispatch({
        type: GET_ACCOUNT_DOCUMENT_FAILURE,
        error: 'Something went wrong! Please try again later',
      });
      showSnackBar(JSON.stringify({ ...resp }), 'error');
    }
    if (resp.data) {
      dispatch({
        type: GET_ACCOUNT_DOCUMENT_SUCCESS,
        data: resp.data.map(e => {
          return { ...e, isReviewLoading: false };
        }),
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ACCOUNT_DOCUMENT_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const fetchInvestments = (
  page,
  sort,
  q,
  limit,
  startDate,
  endDate,
  funded,
  isDisapproved,
  docStatus
) => async dispatch => {
  dispatch({
    type: FETCH_INVESTMENTS_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await fetchInvestmentsList(
      page,
      sort,
      q,
      limit,
      startDate,
      endDate,
      funded,
      isDisapproved,
      docStatus
    );

    if (resp.err) {
      dispatch({
        type: FETCH_INVESTMENTS_FAILURE,
        error: 'Something went wrong! Please try again later',
      });
      showSnackBar(JSON.stringify({ ...resp }), 'error');
    }
    if (resp.data) {
      dispatch({
        type: FETCH_INVESTMENTS_SUCCESS,
        data: resp.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_INVESTMENTS_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const fetchInvestmentDetail = ({
  accountMongoId,
  funded,
}) => async dispatch => {
  dispatch({
    type: FETCH_INVESTMENTS_DETAIL_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await fetchInvestmentDetailList({ accountMongoId, funded });

    if (resp?.err) {
      showSnackBar(JSON.stringify(resp), 'error');
      return dispatch({
        type: FETCH_INVESTMENTS_DETAIL_FAILURE,
        error: resp.message,
      });
    }

    return dispatch({
      type: FETCH_INVESTMENTS_DETAIL_SUCCESS,
      data: resp.data || {},
    });
  } catch (error) {
    dispatch({
      type: FETCH_INVESTMENTS_DETAIL_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const exportInvestmentToCSV = () => async dispatch => {
  dispatch({
    type: EXPORT_INVESTMENTS_CSV_INPROGRESS,
  });
  try {
    const resp = await fetchAllInvestmentData();
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: EXPORT_INVESTMENTS_CSV_SUCCESS,
      });
    } else {
      dispatch({
        type: EXPORT_INVESTMENTS_CSV_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
    return resp;
  } catch (error) {
    dispatch({
      type: EXPORT_INVESTMENTS_CSV_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const reSendDocumentUploadByInvestmentId = (
  investmentId,
  emailMessage,
  type
) => async dispatch => {
  dispatch({
    type: RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_INPROGRESS,
  });

  //eslint-disable-next-line no-undef
  try {
    const resp = await resendDocumentEmailForInvestment(
      investmentId,
      emailMessage,
      type
    );
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_SUCCESS,
      });
      showSnackBar(message, 'success');
    } else {
      dispatch({
        type: RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_FAILURE,
        error: message,
      });
      showSnackBar(message, 'error');
    }
    return resp;
  } catch (error) {
    dispatch({
      type: RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const bulkReSendDocumentsUploadForInvestment = investmentId => async dispatch => {
  dispatch({
    type: BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await bulkReSendDocumentsEmailsForInvestment(investmentId);
    dispatch({
      type: BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_SUCCESS,
    });
    showSnackBar('Sent Emails!', 'success');
    return resp;
  } catch (error) {
    dispatch({
      type: BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_FAILURE,
      error: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};
