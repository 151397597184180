import {
  addUser,
  fetchAllUsers,
  fetchUserDetail,
  getRolesDropdown,
  updateUser,
} from '../api/users';
import { get } from 'lodash';
import { showSnackBar } from './snackbar';

// Users action types
export const GET_ALL_USERS_INPROGRESS = 'GET_ALL_USERS_INPROGRESS';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_FAILED = 'GET_ALL_USERS_FAILED';

export const ADD_USER_INPROGRESS = 'ADD_USER_INPROGRESS';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';

export const GET_USER_DETAIL_INPROGRESS = 'GET_USER_DETAIL_INPROGRESS';
export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
export const GET_USER_DETAIL_FAILED = 'GET_USER_DETAIL_FAILED';

export const UPDATE_USER_INPROGRESS = 'UPDATE_USER_INPROGRESS';
export const UPDATED_USER_SUCCESS = 'UPDATED_USER_SUCCESS';
export const UPDATED_USER_FAILED = 'UPDATED_USER_FAILED';

export const GET_USER_ROLE_OPTIONS_INPROGRESS =
  'GET_USER_ROLE_OPTIONS_INPROGRESS';
export const GET_USER_ROLE_OPTIONS_SUCCESS = 'GET_USER_ROLE_OPTIONS_SUCCESS';
export const GET_USER_ROLE_OPTIONS_FAILED = 'GET_USER_ROLE_OPTIONS_FAILED';

export const getUsersData = (page, sort, query, limit) => async dispatch => {
  dispatch({
    type: GET_ALL_USERS_INPROGRESS,
  });

  try {
    const data = await fetchAllUsers(page, sort, query, limit);
    const status = get(data, 'status');
    const message = get(data, 'message');

    if (status) {
      dispatch({
        type: GET_ALL_USERS,
        data,
      });
    } else {
      dispatch({
        type: GET_ALL_USERS_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_USERS_FAILED,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const createNewUser = user => async dispatch => {
  dispatch({
    type: ADD_USER_INPROGRESS,
  });
  try {
    const data = await addUser(user);
    const status = get(data, 'status');
    const message = get(data, 'message');

    if (status) {
      dispatch({
        type: ADD_USER_SUCCESS,
        data,
      });
      dispatch(getUsersData());
      showSnackBar('You have successfully updated the user', 'success');

      return data;
    } else {
      dispatch({
        type: ADD_USER_FAILED,
        message,
      });
      showSnackBar(message, 'error');

      return data;
    }
  } catch (error) {
    dispatch({
      type: ADD_USER_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');

    return { status: 0 };
  }
};

export const getUserDetail = id => async dispatch => {
  dispatch({
    type: GET_USER_DETAIL_INPROGRESS,
  });

  try {
    const data = await fetchUserDetail(id);
    const status = get(data, 'status');
    const message = get(data, 'message');

    if (status) {
      dispatch({
        type: GET_USER_DETAIL_SUCCESS,
        data,
      });
    } else {
      dispatch({
        type: GET_USER_DETAIL_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: GET_USER_DETAIL_FAILED,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const updateUserDetail = (id, user) => async dispatch => {
  dispatch({
    type: UPDATE_USER_INPROGRESS,
  });

  try {
    const data = await updateUser(id, user);
    const status = get(data, 'status');
    const message = get(data, 'message');

    if (status) {
      dispatch({
        type: UPDATED_USER_SUCCESS,
        data,
      });

      showSnackBar('You have successfully updated the user', 'success');
    } else {
      dispatch({
        type: UPDATED_USER_FAILED,
        error: 'Something went wrong! Please try again later',
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: UPDATED_USER_FAILED,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const fetchRolesDropdown = () => async dispatch => {
  dispatch({
    type: GET_USER_ROLE_OPTIONS_INPROGRESS,
  });

  try {
    const data = await getRolesDropdown();
    const status = get(data, 'status');
    const message = get(data, 'message');

    if (status) {
      dispatch({
        type: GET_USER_ROLE_OPTIONS_SUCCESS,
        data,
      });
    } else {
      dispatch({
        type: GET_USER_ROLE_OPTIONS_FAILED,
        error: 'Something went wrong! Please try again later',
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: GET_USER_ROLE_OPTIONS_FAILED,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};
