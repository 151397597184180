import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { Redirect } from 'react-router-dom';
import React from 'react';

export const getCurrentPagePermissions = (allPermissions, pageName) => {
  const permissionsForPage = allPermissions.findIndex(
    permission => permission.page_name.toLowerCase() === pageName.toLowerCase()
  );
  if (permissionsForPage > -1) {
    return allPermissions[permissionsForPage].permissions;
  }
  return [];
};

const CanAccessComponent = ({
  allowedPermissions = [],
  children,
  redirectUrl = '/permission-error',
  shouldRedirect = true,
}) => {
  const user = useSelector(({ auth }) => get(auth, 'user.user'));
  const userPermissions = (user?.roles || []).reduce((acc, cur) => {
    return [...acc, ...(cur?.permissions || [])];
  }, []);

  let shouldAllow = false;
  if (allowedPermissions) {
    allowedPermissions.forEach(r => {
      if (userPermissions.findIndex(d => r === d) > -1) shouldAllow = true;
    });
  }

  if (shouldAllow) {
    return children;
  }
  if (shouldRedirect) {
    return (
      <Redirect
        exact
        to={{
          pathname: redirectUrl,
        }}
      />
    );
  }
  return null;
};

export default CanAccessComponent;
