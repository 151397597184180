import * as actions from '../actions/investors';

const initialState = {
  investors: {
    isInProgress: false,
    isError: false,
    error: '',
    data: {},
  },

  investorDetail: {
    isInProgress: false,
    isError: false,
    error: '',
    data: {},
  },
};

const investorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_INVESTORS_INPROGRESS:
      return {
        ...state,
        investors: {
          ...state.investors,
          isInProgress: true,
        },
      };

    case actions.FETCH_INVESTORS_SUCCESS:
      return {
        ...state,
        investors: {
          ...state.investors,
          isInProgress: false,
          data: action.data,
          isError: false,
          error: '',
        },
      };

    case actions.FETCH_INVESTORS_FAILURE:
      return {
        ...state,
        investors: {
          ...state.investors,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };

    case actions.FETCH_INVESTOR_INPROGRESS:
      return {
        ...state,
        investorDetail: {
          ...state.investorDetail,
          isInProgress: true,
        },
      };

    case actions.FETCH_INVESTOR_SUCCESS:
      return {
        ...state,
        investorDetail: {
          ...state.investorDetail,
          isInProgress: false,
          data: action.data,
          isError: false,
          error: '',
        },
      };

    case actions.FETCH_INVESTOR_FAILED:
      return {
        ...state,
        isInProgress: false,
        isError: true,
        error: action.error,
      };

    default:
      return state;
  }
};

export default investorsReducer;
