import React, { useMemo, useState } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'components/Table';
import styles from './investment.module.scss';
import cs from 'classnames';
import NumberFormat from 'react-number-format';

import { useMedia } from 'react-use';
import HeaderStatistic from 'components/HeaderStatistic';
import {
  fetchInvestments,
  setDocStatus,
  bulkReSendDocumentsUploadForInvestment,
} from 'store/actions/investments';

import Card from 'components/Card';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';
import CSVDownload from 'components/CSVDownload';
import Checkbox from 'components/Checkbox';
import DatePicker from 'components/DatePicker';
import Button from 'components/Button';
import InvestmentsUploadDocumentEmailMessage from './InvestmentsUploadDocumentEmailMessage.form';

import InvestmentSubRow from './InvestmentSubRow';
import DownArrow from 'components/DownArrow/DownArrow';
import { CheckCircle, Clock, XCircle } from 'react-feather';

import money from './icons/money.png';
import noMoney from './icons/no-money.png';
import noDoc from './icons/no-docs.png';
import docs from './icons/docs.png';
import ReactTooltip from 'react-tooltip';
import { showSnackBar } from 'store/actions/snackbar';
import { postInvestmentCsv } from 'store/api/investments';

const Investments = () => {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [selectedIds, setSelectedIds] = useState([]);
  //conditional fetch

  const docStatus = useSelector(({ investments }) =>
    get(investments, 'docStatus', false)
  );

  const [funded, setFunded] = useState(true);
  const [isDisapproved, setIsDisapproved] = useState(null);

  // should refetch
  const [refetch, setRefetch] = useState(false);

  const [modal, setModal] = useState(false);

  const isMaxWidth640px = useMedia('(max-width: 640px)');

  const investmentsData = useSelector(({ investments }) =>
    get(investments, 'investments.data.data', [])
  );

  const investmentsMetadata = useSelector(({ investments }) =>
    get(investments, 'investments.data.meta', {})
  );

  const bulkDocumentsIsInProgress = useSelector(({ documents }) =>
    get(documents, 'bulkReSendDocumentUploadEmail.isInProgress', false)
  );

  const isLoading = useSelector(
    ({ investments }) => investments.investments.isInProgress
  );

  React.useEffect(() => {
    setRefetch(!refetch);
    // if any of these dependency changes we should refetch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docStatus, funded, isDisapproved]);

  const handleTableChange = async ({ pageIndex, sortBy, pageSize, query }) => {
    let sort = '';

    if (sortBy.length) {
      sort = sortBy[0].desc ? `-${sortBy[0].id}` : sortBy[0].id;
    }
    await dispatch(
      fetchInvestments(
        pageIndex + 1,
        sort,
        query,
        pageSize,
        startDate,
        endDate,
        funded,
        isDisapproved === null
          ? 'Neither'
          : isDisapproved === true
          ? 'Disapproved'
          : 'Approved',
        docStatus
      )
    );
  };

  const isMaxWidth950px = useMedia('(max-width:950px)');

  const handleChange = (event, row) => {
    let newSelectedIdsList = [...selectedIds];
    if (event.target.checked) {
      newSelectedIdsList.push({
        _id: row?.original?._id,
      });
    } else {
      const foundIndex = newSelectedIdsList.findIndex(
        e => e._id === row?.original?._id
      );
      if (foundIndex > -1) {
        newSelectedIdsList.splice(foundIndex, 1);
      }
    }
    setSelectedIds(newSelectedIdsList);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(
    () => [
      {
        Header: () => 'Expand',
        accessor: 'selection',
        Cell: ({ row, rows, toggleRowExpanded }) => (
          <DownArrow
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const expandedRow = rows.find(row => row.isExpanded);

              if (expandedRow) {
                const isSubItemOfRow = Boolean(
                  expandedRow && row.id.split('.')[0] === expandedRow.id
                );

                if (isSubItemOfRow) {
                  const expandedSubItem = expandedRow.subRows.find(
                    subRow => subRow.isExpanded
                  );

                  if (expandedSubItem) {
                    const isClickedOnExpandedSubItem =
                      expandedSubItem.id === row.id;
                    if (!isClickedOnExpandedSubItem) {
                      toggleRowExpanded(expandedSubItem.id, false);
                    }
                  }
                } else {
                  toggleRowExpanded(expandedRow.id, false);
                }
              }
              row.toggleRowExpanded();
            }}
          />
        ),
      },
      {
        Header: () => 'Selection',
        accessor: 'select',
        Cell: ({ row }) => (
          <Checkbox
            className="flex-row-reverse"
            checked={selectedIds.some(e => e._id === row.original?._id)}
            onChange={event => handleChange(event, row)}
          />
        ),
      },
      {
        Header: () => (funded ? 'Total Invested' : 'Total Commited'),
        accessor: 'fundedInvestment',
        Cell: ({ value, row }) => {
          return (
            <div className="flex items-start justify-center">
              $
              <NumberFormat
                value={
                  funded
                    ? value
                      ? value
                      : 0
                    : row?.original?.totalCommited
                    ? row?.original?.totalCommited
                    : 0
                }
                displayType="text"
                thousandSeparator={true}
              />
            </div>
          );
        },
      },

      {
        Header: () => 'Accredited Status',
        accessor: 'Accredited Status',
        Cell: ({ row }) => {
          return (
            <div className="flex items-start justify-center">
              {' '}
              {row?.original?.accountAiPending ? (
                row?.original?.accountsAi ? (
                  row?.original?.accountsAi === 'Approved' ? (
                    <CheckCircle className="text-green-500	" />
                  ) : row?.original?.accountsAi === 'Disapproved' ? (
                    <XCircle color="#ff3300" />
                  ) : (
                    'Something went wrong'
                  )
                ) : (
                  <Clock color="orange" />
                )
              ) : row?.original?.accountsAi ? (
                row?.original?.accountsAi
              ) : (
                row?.original?.accountAccrediation
              )}
            </div>
          );
        },
      },

      {
        Header: 'KYC/AML',
        accessor: 'accountsKycAml',
        Cell: ({ row }) => {
          /*
         
          */

          return (
            <div className="flex items-start justify-center">
              {row?.original?.accountKycAmlPending ? (
                <Clock color="orange" />
              ) : row?.original?.accountsKycAml ? (
                row?.original?.accountsKycAml === 'Auto Approved' ? (
                  <CheckCircle className="text-green-500	" />
                ) : row?.original?.accountsKycAml === 'Disapproved' ? (
                  <XCircle color="#ff3300" />
                ) : (
                  'something went wrong'
                )
              ) : (
                <Clock color="orange" />
              )}
            </div>
          );
        },
      },
      {
        Header: () => 'Trades Settled',
        accessor: 'paidAccounts',
        Cell: ({ value }) => {
          /*
         
          */

          const isAllTradeSettled = value
            ? value.every(e => e?.trade?.tradeStatus === 'SETTLED')
            : false;

          const hasNoTrade = value.length === 0 ? true : false;

          return (
            <div className="flex items-start justify-center">
              {isAllTradeSettled && !hasNoTrade ? (
                <CheckCircle className="text-green-500	" />
              ) : (
                <XCircle color="#ff3300" />
              )}
            </div>
          );
        },
      },
      {
        Header: 'Acc Uploaded',
        accessor: 'isInvestorUploadedAllAi',
        Cell: ({ row }) => {
          /*
         
          */

          return (
            <div className="flex items-start justify-center">
              {' '}
              {row.original.isInvestorUploadedAllAi ? (
                <CheckCircle className="text-green-500	" />
              ) : (
                <XCircle color="#ff3300" />
              )}
            </div>
          );
        },
      },

      {
        Header: 'KYC/AML Uploaded',
        accessor: 'isInvestorUploadedAllKycAml',
        Cell: ({ row }) => {
          /*
         
          */

          return (
            <div className="flex items-start justify-center">
              {' '}
              {row.original.isInvestorUploadedAllKycAml ? (
                <CheckCircle className="text-green-500	" />
              ) : (
                <XCircle color="#ff3300" />
              )}
            </div>
          );
        },
      },

      {
        Header: 'Accounts',
        accessor: 'accountIds',
        Cell: ({ row }) => {
          /*
         
          */

          return (
            <div className="flex items-start justify-center">
              {' '}
              {row.original.accountIds ? row.original.accountIds.length : '0'}
            </div>
          );
        },
      },

      {
        Header: 'Investor',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },

      {
        Header: () => (
          <div className="flex justify-end w-full">Last Trade Update</div>
        ),
        accessor: 'newFundedTradeDate',
        Cell: ({ value }) => {
          return (
            <div className="flex justify-end">
              <span className="flex items-center mr-4">
                {value ? moment(value).format('MMM Do, YYYY') : null}
              </span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIds, funded]
  );

  const last7DaysAmount =
    investmentsMetadata && investmentsMetadata.last_7_days_amount
      ? Number(investmentsMetadata.last_7_days_amount)
      : null;

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'investments'
  );

  const generateCSVClick = async () => {
    const response = await postInvestmentCsv();

    return response.data.investors;
  };

  const { totalResults } = investmentsMetadata;

  const onBulkReSendDocumentsForInvestmentsUploadClick = async () => {
    const response = await dispatch(
      bulkReSendDocumentsUploadForInvestment(selectedIds)
    );
    if (response.status) {
      setSelectedIds([]);
    }
  };

  const onModalClose = () => {
    setModal(false);

    setSelectedIds([]);
  };

  const renderRowSubComponent = React.useCallback(
    ({ row }) => <InvestmentSubRow row={row} funded={funded} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [funded]
  );

  return (
    <CanAccessComponent
      allowedPermissions={permissionsForPage}
      redirectUrl="/permission-error"
    >
      <div className="flex flex-col">
        <HeaderStatistic
          paragraph="Total Investments"
          valueFormat={totalResults}
          classNames={styles.noMargin}
          isLoading={isLoading}
        />
        <div
          className={cs('grid', {
            'grid-cols-1': isMaxWidth950px,
            'grid-cols-2': !isMaxWidth950px,
          })}
        >
          <HeaderStatistic
            paragraph="Recent Investments ($)  (last 7 days)"
            valueFormat={last7DaysAmount ? last7DaysAmount.toFixed(2) : '-'}
            isLoading={isLoading}
          />

          <HeaderStatistic
            paragraph="Recent Investments Count (last 7 days)"
            valueFormat={investmentsMetadata?.last_7_days_count || '-'}
            classNames={styles.noMargin}
            isLoading={isLoading}
          />
        </div>
      </div>
      <Card className={styles.root}>
        <div
          className={cs('flex justify-between sm:items-center mb-2', {
            'flex-col': isMaxWidth640px,
          })}
        >
          <Card.Header
            title={`Investments ${
              investmentsMetadata && totalResults ? `(${totalResults})` : ''
            }`}
          />

          <div className="flex">
            <CSVDownload
              filename="Investments.csv"
              onDataFetch={generateCSVClick}
              csvHeaders={[
                { label: 'Name', key: 'name' },
                { label: 'Email', key: 'email' },
                { label: 'Phone', key: 'account.phone' },
                { label: 'Country', key: 'account.country' },
                { label: 'Street Address', key: 'account.streetAddress1' },
                { label: 'Zip', key: 'account.zip' },
                { label: 'City', key: 'account.city' },
                { label: 'Account Id', key: 'account.accountId' },
                { label: 'Invested As ', key: 'account.investedAs' },
                { label: 'Trade Id', key: 'trade.tradeId' },
                { label: 'Trade Amount', key: 'trade.transactionAmount' },
                { label: 'Trade Units', key: 'trade.transactionUnits' },
                { label: 'Trade Status', key: 'trade.tradeStatus' },
                { label: 'Trade Payment', key: 'trade.transactionType' },
                { label: 'Trade Creation', key: 'trade.createdAt' },
                { label: 'total Commited', key: 'totalCommited' },
              ]}
            />
          </div>
        </div>
        <div className="flex justify-between sm:items-center mb-2">
          <div className="flex content-center justify-center">
            <DatePicker
              label="Start Date"
              wrapperClassName="mr-2"
              selected={startDate}
              onChange={date => setStartDate(date)}
              isClearable
            />
            <DatePicker
              label="End Date"
              selected={endDate}
              onChange={date => setEndDate(date)}
              isClearable
            />

            <div className="flex items-end justify-center ml-2">
              <ReactTooltip id="funded" effect="solid">
                {funded
                  ? 'Showing Funded Accounts'
                  : 'Showing Funded | Non Funded Accounts'}
              </ReactTooltip>

              <button
                className={styles.icon}
                onClick={() => setFunded(!funded)}
                data-tip
                data-for="funded"
              >
                {funded ? (
                  <img src={money} style={{ height: '30px' }} />
                ) : (
                  <>
                    <img src={money} style={{ height: '30px' }} />|
                    <img src={noMoney} style={{ height: '30px' }} />
                  </>
                )}
              </button>
            </div>

            <div className="flex items-end justify-center ml-2">
              <ReactTooltip id="doc" effect="solid">
                {docStatus === false
                  ? 'Showing Docs | No Docs'
                  : 'Showing Docs'}
              </ReactTooltip>

              <button
                className={styles.icon}
                onClick={() => {
                  showSnackBar(
                    docStatus
                      ? 'Will Stay Showing Investors With Documents Or No Documents'
                      : 'Will Stay Showing Investors With Documents Only',
                    'info'
                  );
                  dispatch(setDocStatus(docStatus));
                }}
                data-tip
                data-for="doc"
              >
                {docStatus === false && (
                  <>
                    <img src={docs} style={{ height: '25px' }} />|
                    <img src={noDoc} style={{ height: '30px' }} />
                  </>
                )}
                {docStatus === true && (
                  <img src={docs} style={{ height: '25px' }} />
                )}
              </button>
            </div>

            <div className="flex items-end justify-center ml-2">
              <ReactTooltip id="kycaml" effect="solid">
                {isDisapproved === null
                  ? 'Showing Disapproved | Approved (KYC/AML)'
                  : isDisapproved === true
                  ? 'Showing Disapproved (KYC/AML)'
                  : 'Showing Approved (KYC/AML)'}
              </ReactTooltip>

              <button
                className={styles.icon}
                onClick={() =>
                  isDisapproved === null
                    ? setIsDisapproved(true)
                    : setIsDisapproved(!isDisapproved)
                }
                data-tip
                data-for="kycaml"
              >
                {isDisapproved === null ? (
                  <>
                    {' '}
                    <CheckCircle className="text-green-500  " /> |{' '}
                    <XCircle color="#ff3300" />
                  </>
                ) : isDisapproved === true ? (
                  <XCircle color="#ff3300" />
                ) : (
                  <CheckCircle className="text-green-500  " />
                )}
              </button>
            </div>
          </div>
          <Button
            loadingText="Please wait"
            isLoading={bulkDocumentsIsInProgress}
            className="mt-2 sm:mt-0 mr-2"
            label="Send Bulk Email"
            onClick={() => {
              setModal(!modal);
            }}
            isDisabled={!selectedIds.length}
          />
        </div>

        <Card.Content className="h-full flex">
          <Table
            columns={columns}
            data={investmentsData}
            total={investmentsMetadata.totalPages}
            renderRowSubComponent={renderRowSubComponent}
            fetchData={handleTableChange}
            startDate={startDate}
            endDate={endDate}
            forceRefetch={refetch}
          />
        </Card.Content>
        {modal ? (
          <InvestmentsUploadDocumentEmailMessage
            onModalClose={onModalClose}
            onDocumentsUploadEmailMessageClick={
              onBulkReSendDocumentsForInvestmentsUploadClick
            }
          />
        ) : null}
      </Card>
    </CanAccessComponent>
  );
};

export default Investments;
