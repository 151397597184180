import React, { useMemo } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'components/Table';
import { Link, useHistory } from 'react-router-dom';
import {
  fetchApplications,
  rejectApplicationById,
  exportApplicationToCSV,
} from 'store/actions/applications';
import DropdownMenu from 'components/DropdownMenu';
import Card from 'components/Card';
import { AlertPopupHandler } from 'components/AlertPopup';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';
import CSVDownload from 'components/CSVDownload';
import cs from 'classnames';
import { useMedia } from 'react-use';

const Applications = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMaxWidth640px = useMedia('(max-width: 640px)');
  const applicationsData = useSelector(({ applications }) =>
    get(applications, 'applications.data.data', [])
  );

  const applicationsMetadata = useSelector(({ applications }) =>
    get(applications, 'applications.data.meta', {})
  );

  const handleTableChange = async ({ pageIndex, sortBy, pageSize, query }) => {
    let sort = '';
    if (sortBy.length) {
      sort = sortBy[0].desc ? `-${sortBy[0].id}` : sortBy[0].id;
    }
    await dispatch(fetchApplications(pageIndex + 1, sort, query, pageSize));
  };

  const handleRejectApplication = (application, filters) => () => {
    AlertPopupHandler.open({
      type: 'error',
      text: `You are about to reject application from ${application.first_name} ${application.last_name}. Do you want to continue?`,
      onConfirm: rejectApplication,
      data: {
        application,
        filters,
      },
    });
  };

  const rejectApplication = async ({ application, filters }) => {
    await dispatch(rejectApplicationById(application._id.toString()));
    handleTableChange(filters);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'first_name',
        Cell: ({ value, row }) =>
          value ? (
            <div className="flex items-start">
              <Link to={`/applications/${row.original._id}`}>{value}</Link>
            </div>
          ) : null,
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'Rejected?',
        accessor: 'is_rejected',
        Cell: ({ value }) => <div>{value ? 'Yes' : 'No'}</div>,
      },
      {
        Header: () => <div className="flex justify-end w-full">Applied On</div>,
        accessor: 'createdAt',
        Cell: ({ value, row, filters }) => {
          return (
            <div className="flex justify-end">
              <span className="flex items-center mr-4">
                {value ? moment(value).format('MMM Do, YYYY') : null}
              </span>
              <DropdownMenu
                options={[
                  {
                    label: 'View Application',
                    onClick: () =>
                      history.push(`/applications/${row.original._id}`),
                  },
                  ...(!row.original.is_rejected
                    ? [
                        {
                          label: 'Reject Application',
                          onClick: handleRejectApplication(
                            row.original,
                            filters
                          ),
                        },
                      ]
                    : []),
                ]}
              />
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'job applications'
  );

  const generateCSVClick = async () => {
    const response = await dispatch(exportApplicationToCSV());
    if (response.status) {
      let newDataList = [];
      response?.data?.map(d => {
        if (d.files.length) {
          d.files.map((file, index) =>
            newDataList.push({
              ...(!index ? { ...d } : {}),
              fileName: file.name,
              fileUrl: file.url,
            })
          );
        } else {
          newDataList.push(d);
        }
      });
      return newDataList;
    }
    return [];
  };

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        <div
          className={cs('flex justify-between sm:items-center mb-2', {
            'flex-col': isMaxWidth640px,
          })}
        >
          <Card.Header
            title={`Applications ${
              applicationsMetadata && applicationsMetadata.totalResults
                ? `(${applicationsMetadata.totalResults})`
                : ''
            }`}
          />
          <div className="flex">
            <CSVDownload
              filename="Application.csv"
              onDataFetch={generateCSVClick}
              csvHeaders={[
                { label: 'First Name', key: 'first_name' },
                { label: 'Last Name', key: 'last_name' },
                { label: 'Description', key: 'description' },
                { label: 'Email', key: 'email' },
                { label: 'Phone', key: 'phone' },
                { label: 'Rejected?', key: 'is_rejected' },
                { label: 'Applied On', key: 'createdAt' },
                { label: 'File Name', key: 'fileName' },
                { label: 'File Url', key: 'fileUrl' },
              ]}
            />
          </div>
        </div>
        <Card.Content className="h-full flex">
          <Table
            columns={columns}
            data={applicationsData}
            total={applicationsMetadata.totalPages}
            fetchData={handleTableChange}
          />
        </Card.Content>
      </Card>
    </CanAccessComponent>
  );
};

export default Applications;
