import React from 'react';

import Button from 'components/Button';
import Modal from 'components/Modal';

import styles from './InvestmentsUploadDocumentEmailMessage.module.scss';
const InvestmentsUploadDocumentEmailMessage = ({
  onModalClose,
  onDocumentsUploadEmailMessageClick,
}) => {
  const sendEmail = async values => {
    onDocumentsUploadEmailMessageClick(values.message);
    onModalClose(true);
  };

  return (
    <Modal isOpen containerClassname="sm:max-w-sm" onClick={onModalClose}>
      <div className={styles.container}>
        <h2> Are You Sure? </h2>
        <div className={styles.buttons}>
          <Button onClick={onModalClose} variant="transparent" label="Cancel" />
          <Button type="submit" onClick={sendEmail} label="Send" />
        </div>
      </div>
    </Modal>
  );
};

export default InvestmentsUploadDocumentEmailMessage;
