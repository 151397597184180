import React from 'react';
import cs from 'classnames';
import styles from './input.module.scss';
import PropTypes from 'prop-types';
import LoadSpinner from '../LoadSpinner';

const Input = ({
  id = '',
  label = '',
  placeholder = '',
  required = false,
  type = 'text',
  wrapperClassName = '',
  labelClassName = '',
  className = '',
  isDisabled = false,
  error = null,
  leftIcon,
  isLoading,
  rightIcon,
  register,
  name,
  ...rest
}) => {
  const errorId = 'error-' + id;
  const labelTestId = id + '-label';

  return (
    <div
      className={cs(styles.root, wrapperClassName, {
        'flex flex-row-reverse items-center': type === 'checkbox',
      })}
    >
      {label ? (
        <label
          className={cs(styles.label, labelClassName)}
          htmlFor={id}
          data-testid={labelTestId}
        >
          {label}
        </label>
      ) : null}
      <div
        className={cs(styles.inputWrapper, {
          [styles.checkbox]: type === 'checkbox',
          [styles.withLabel]: label,
        })}
      >
        <input
          id={id}
          type={type}
          required={required}
          placeholder={placeholder}
          disabled={isDisabled}
          aria-describedby={errorId}
          {...(register ? { ...register(name) } : {})}
          className={cs(
            styles.input,
            className,
            `border focus:border-brandorange focus:outline-none rounded pt-2 w-full p-2`,
            {
              [styles.error]: error,
              [styles.withLeftIcon]: !!leftIcon,
              [styles.withRightIcon]: !!rightIcon,
            }
          )}
          {...rest}
        />
        {type === 'checkbox' ? <span className={styles.toggle} /> : null}
        {leftIcon ? (
          <span className={styles.leftIcon}>
            {isLoading ? (
              <LoadSpinner className={styles.inputLoading} />
            ) : (
              leftIcon
            )}
          </span>
        ) : null}
        {rightIcon ? (
          <span className={styles.rightIcon}>
            {isLoading ? (
              <LoadSpinner className={styles.inputLoading} />
            ) : (
              rightIcon
            )}
          </span>
        ) : null}
      </div>
      {/* If there is an error and we have an error message to display */}
      {error && (
        <span role="alert" id={errorId} className={styles.errorText}>
          {error}
        </span>
      )}
    </div>
  );
};

Input.propTypes = {
  /** Pass the unique ID */
  id: PropTypes.string.isRequired,
  /** Input tag label display above the input tag. */
  label: PropTypes.string,
  /** Input Component Wrapper Class Names  */
  wrapperClassName: PropTypes.string,
  /** Input Component Label Class Name  */
  labelClassName: PropTypes.string,
  /** Input Component Class Name  */
  className: PropTypes.string,
  /** Input tag type default is text.  */
  type: PropTypes.string,
  /** Placeholder of the Input tag */
  placeholder: PropTypes.string,
  /** Input disabled or not */
  isDisabled: PropTypes.bool,
  /** Input use to set validation or not and it default is False*/
  required: PropTypes.bool,
  /** showing icon left side of Input Component */
  leftIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** showing icon right side of Input Component */
  rightIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Input.defaultProps = {
  id: '',
  label: '',
  wrapperClassName: '',
  labelClassName: '',
  className: '',
  type: 'text',
  error: '',
  leftIcon: null,
  rightIcon: null,
  isLoading: false,
};

export default Input;
