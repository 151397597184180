import { useParams, useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addReservationActivityById,
  fetchReservationDetail,
} from 'store/actions/reservations';
import get from 'lodash/get';
import Details from 'components/Details';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { ChevronLeft } from 'react-feather';
import LoadingIndicator from 'components/LoadingIndicator';
import Card from 'components/Card';
import Timeline from 'components/Timeline';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';
const ReservationDetails = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  let history = useHistory();

  const reservation = useSelector(({ reservations }) =>
    get(reservations, 'details.data.data', {})
  );
  const isLoading = useSelector(({ reservations }) =>
    get(reservations, 'details.isInProgress', true)
  );

  useEffect(() => {
    dispatch(fetchReservationDetail(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onActivityCreate = async value => {
    await dispatch(addReservationActivityById(id, value));
  };

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'reservations'
  );

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <div className="flex items-center justify-between">
              <Card.Header
                title={
                  <>
                    <ChevronLeft
                      onClick={() => history.push(`/reservations`)}
                      className="cursor-pointer mr-2"
                    />
                    Reservation Details
                  </>
                }
              />
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3  gap-6 mt-4">
              <Details
                title="First Name"
                data={reservation?.first_name || '-'}
              />
              <Details title="Last Name" data={reservation?.last_name || '-'} />
              <Details title="Email" data={reservation?.email || '-'} />
              <Details title="Phone" data={reservation?.phone || '-'} />
              <Details
                title="Amount Received"
                data={
                  reservation?.payment_intent?.amount_received ? (
                    <div>
                      $
                      <NumberFormat
                        value={reservation?.payment_intent?.amount_received}
                        displayType="text"
                        thousandSeparator={true}
                      />
                    </div>
                  ) : (
                    '-'
                  )
                }
              />
              <Details title="Scheme" data={reservation?.scheme || ''} />
              <Details
                title="Payment Status"
                data={reservation?.payment_intent?.status || ''}
              />
              <Details
                title="Reserved On"
                data={
                  reservation?.createdAt
                    ? moment(reservation.createdAt).format('MMM Do, YYYY')
                    : '-'
                }
              />
            </div>
            <Timeline
              allowToCreateActivity
              title="Reservation Activity"
              className="mt-6"
              activities={reservation.activity || []}
              onActivityCreate={onActivityCreate}
            />
          </>
        )}
      </Card>
    </CanAccessComponent>
  );
};
export default ReservationDetails;
