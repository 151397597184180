import Boxabl from 'components/Logo/Boxabl';
import React, { useState } from 'react';
import styles from './AdminNavbar.module.scss';
import { Menu, ChevronDown, User, LogOut } from 'react-feather';
import cs from 'classnames';
import Dropdown from 'components/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { toggleNavBar } from 'store/actions/nav';
import { useHistory } from 'react-router-dom';
import { doLogout } from 'store/actions/auth';
import { get } from 'lodash';

export default function Navbar() {
  const [dropdown, setDropdown] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const dropdownOptions = [
    {
      name: 'My Profile',
      icon: <User className={cs(styles.icon, styles.blue)} />,
      onClick: () => history.push('/profile'),
    },
    {
      name: 'Logout',
      icon: <LogOut className={cs(styles.icon, styles.red)} />,
      onClick: async () => {
        await dispatch(doLogout());
        history.push('/auth/login');
      },
    },
  ];

  const name = useSelector(({ auth }) => get(auth, 'user.user.name', null));

  return (
    <div className={styles.navBar}>
      <div className="flex items-center">
        <Menu
          className={cs(styles.icon, styles.menuIcon)}
          onClick={() => dispatch(toggleNavBar())}
        />

        <Boxabl
          onClick={() => history.push('/investments')}
          className={cs('cursor-pointer', styles.boxabl)}
        />
      </div>

      <div className="flex justify-end items-center w-full">
        <div className={cs('flex items-center', styles.profile)}>
          <p>{name || 'Boxabl User'}</p>
          <div
            style={
              {
                /* backgroundImage: `url("https://dq1eylutsoz4u.cloudfront.net/2017/12/18161328/online-dating-tips-for-men-picture.jpg")`, */
              }
            }
            className={styles.avatar}
          >
            {(name && `${name.slice(0, 2)}`) || 'BX'}
          </div>
          <ChevronDown
            className={styles.chevronDown}
            onClick={() => setDropdown(!dropdown)}
          />
          {dropdown && (
            <Dropdown
              className={styles.dropdown}
              overlay={dropdown}
              onClickOverlay={() => setDropdown(!dropdown)}
              content={
                <>
                  {dropdownOptions.map((option, index) => (
                    <div
                      className={styles.dropdownItem}
                      onClick={() => {
                        option.onClick();
                        setDropdown(!dropdown);
                      }}
                      key={index}
                    >
                      {option.icon} <p>{option.name}</p>
                    </div>
                  ))}
                </>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
