import * as actions from '../actions/users';

const initialState = {
  users: {
    isInProgress: true,
    isError: false,
    error: '',
    data: {},
  },
  createUser: {
    isInProgress: true,
    isError: false,
    error: '',
  },
  userDetail: {
    isInProgress: true,
    isError: false,
    error: '',
    data: {},
  },
  updateUser: {
    isInProgress: true,
    isError: false,
    error: '',
  },
  roleOptions: {
    isInProgress: false,
    isError: false,
    error: '',
    data: [],
  },
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ALL_USERS_INPROGRESS:
      return {
        ...state,
        users: {
          ...state.users,
          isInProgress: true,
        },
      };

    case actions.GET_ALL_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          data: action.data,
          isInProgress: false,
          error: '',
          isError: false,
        },
      };

    case actions.GET_ALL_USERS_FAILED:
      return {
        ...state,
        users: {
          ...state.users,
          data: {},
          isInProgress: false,
          error: action.message,
          isError: true,
        },
      };

    case actions.GET_USER_DETAIL_INPROGRESS:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          isInProgress: true,
        },
      };

    case actions.GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: action.data,
          isInProgress: false,
          error: '',
          isError: false,
        },
      };

    case actions.GET_USER_DETAIL_FAILED:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: {},
          isInProgress: false,
          error: action.message,
          isError: true,
        },
      };

    case actions.UPDATE_USER_INPROGRESS:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          isInProgress: true,
        },
      };
    case actions.UPDATED_USER_SUCCESS:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          isInProgress: false,
        },
        userDetail: {
          ...state.userDetail,
          data: {
            ...state.userDetail.data,
            data: {
              ...state.userDetail.data.data,
              name: action.data.data.name,
              role: action.data.data.role,
            },
          },
        },
      };

    case actions.UPDATED_USER_FAILED:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          isInProgress: false,
          error: action.message,
          isError: false,
        },
      };
    case actions.ADD_USER_INPROGRESS:
      return {
        ...state,
        createUser: {
          ...state.createUser,
          isInProgress: true,
        },
      };
    case actions.ADD_USER_SUCCESS:
      return {
        ...state,
        createUser: {
          ...state.createUser,
          isInProgress: false,
        },
      };
    case actions.ADD_USER_FAILED:
      return {
        ...state,
        createUser: {
          ...state.createUser,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };
    case actions.GET_USER_ROLE_OPTIONS_INPROGRESS:
      return {
        ...state,
        roleOptions: {
          ...state.roleOptions,
          isInProgress: true,
        },
      };
    case actions.GET_USER_ROLE_OPTIONS_SUCCESS:
      return {
        ...state,
        roleOptions: {
          ...state.roleOptions,
          isInProgress: false,
          data: action.data,
        },
      };
    case actions.GET_USER_ROLE_OPTIONS_FAILED:
      return {
        ...state,
        roleOptions: {
          ...state.roleOptions,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };

    default:
      return state;
  }
};

export default usersReducer;
