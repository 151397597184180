import React from 'react';
import styles from './toggle.module.scss';
import cs from 'classnames';

const Toggle = ({ isDisabled, label, onChange, ...rest }) => {
  return (
    <label
      className={cs(styles.toggleButtonView, {
        [styles.toggleButtonDisabled]: isDisabled,
      })}
    >
      <input type="checkbox" onChange={onChange} {...rest} />
      <span>
        <div className={styles.circleToggler} />
      </span>

      <p>{label}</p>
    </label>
  );
};

export default Toggle;
