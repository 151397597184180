// import DropdownMenu from 'components/DropdownMenu';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadSpinner from 'components/LoadSpinner';
import Table from 'components/Table';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { CheckCircle, Clock } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionUpdateAccountDocuments,
  getAccountDocuments,
} from 'store/actions/investments';

const InvestmentDocumentSubRow = ({ row }) => {
  const dispatch = useDispatch();

  const accountDocuments = useSelector(({ investments }) =>
    get(investments, 'accountDocuments.data', null)
  );

  const isLoadingAccountDocuments = useSelector(({ investments }) =>
    get(investments, 'accountDocuments.isInProgress', [])
  );

  React.useEffect(() => {
    dispatch(getAccountDocuments({ accountId: row?.original?.accountId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAccountDocument = async document => {
    if (accountDocuments) {
      await dispatch(actionUpdateAccountDocuments(document, accountDocuments));
    }
  };

  const useColumns = useMemo(
    () => [
      {
        Header: 'Document Verified?',
        accessor: 'reviewed',
        Cell: ({ row, value }) => {
          return (
            <div
              className="flex justify-center cursor-pointer"
              onClick={() => updateAccountDocument(row?.original)}
            >
              {row?.original?.isReviewLoading ? (
                <LoadSpinner color="orange" height={30} />
              ) : value ? (
                <>
                  {' '}
                  <CheckCircle className="mr-2 text-green-500	" /> Approved
                </>
              ) : (
                <>
                  <Clock className="mr-2 text-blue-500	" /> Pending
                </>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Document Title',
        accessor: 'documentTitle',
        Cell: ({ value, row }) => {
          return value ? (
            <div
              className="cursor-pointer"
              onClick={() => {
                window.open(row?.original?.documentUrl, '_blank');
              }}
            >
              {value}
            </div>
          ) : null;
        },
      },

      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ value }) => {
          return <p className="uppercase">{value}</p>;
        },
      },

      //   {
      //     Header: 'investedAs',
      //     accessor: 'investedAs',
      //     Cell: ({ value, row }) => {
      //       return (
      //         <div className="flex justify-end">
      //           <span className="flex items-center mr-4">
      //             {value ? value : null}
      //           </span>

      //           <DropdownMenu
      //             options={[
      //               {
      //                 label: 'Issue Refund',
      //                 onClick: () => {},
      //               },
      //               {
      //                 label: 'Mark Trade As Settled',
      //                 onClick: () => {},
      //               },
      //             ]}
      //           />
      //         </div>
      //       );
      //     },

      //   },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="px-6 py-3">
      {isLoadingAccountDocuments ? (
        <LoadingIndicator />
      ) : (
        <Table
          columns={useColumns}
          data={accountDocuments || []}
          total={10}
          showSearch={false}
          withPagination={true}
          withPerPage={false}
          subRowComponentDocument={true}
        />
      )}
    </div>
  );
};

export default InvestmentDocumentSubRow;
