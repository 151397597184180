import React, { useMemo } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'components/Table';
import { Link, useHistory } from 'react-router-dom';
import {
  fetchLeads,
  rejectLeadById,
  exportLeadToCSV,
} from 'store/actions/leads';
import DropdownMenu from 'components/DropdownMenu';
import Card from 'components/Card';
import cs from 'classnames';
import CSVDownload from 'components/CSVDownload';
import { useMedia } from 'react-use';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';

const Leads = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMaxWidth640px = useMedia('(max-width: 640px)');

  const leadsData = useSelector(({ leads }) =>
    get(leads, 'leads.data.data', [])
  );

  const leadsMetadata = useSelector(({ leads }) =>
    get(leads, 'leads.data.meta', {})
  );

  const handleTableChange = async ({ pageIndex, sortBy, pageSize, query }) => {
    let sort = '';
    if (sortBy.length) {
      sort = sortBy[0].desc ? `-${sortBy[0].id}` : sortBy[0].id;
    }
    await dispatch(fetchLeads(pageIndex + 1, sort, query, pageSize));
  };

  const rejectLead = leadId => async () => {
    await dispatch(rejectLeadById(leadId));
  };

  const generateCSVClick = async () => {
    const response = await dispatch(exportLeadToCSV());
    if (response.status) {
      return response?.data;
    }
    return [];
  };

  const columns = useMemo(
    () => [
      {
        Header: () => 'First Name',
        accessor: 'first_name',
        Cell: ({ value, row }) =>
          value ? (
            <div className="flex items-start">
              <Link to={`/leads/${row.original._id}`}>{value}</Link>
            </div>
          ) : null,
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'Rejected?',
        accessor: 'is_rejected',
        Cell: ({ value }) => <div>{value ? 'Yes' : 'No'}</div>,
      },
      {
        Header: () => (
          <div className="flex justify-end w-full">Received On</div>
        ),
        accessor: 'createdAt',
        Cell: ({ value, row }) => (
          <div className="flex justify-end">
            <span className="flex items-center mr-4">
              {value ? moment(value).format('MMM Do, YYYY') : null}
            </span>
            <DropdownMenu
              options={[
                {
                  label: 'View Lead',
                  onClick: () => history.push(`/leads/${row.original._id}`),
                },
                ...(!row.original.is_rejected
                  ? [
                      {
                        label: 'Reject Lead',
                        onClick: rejectLead(row.original._id),
                      },
                    ]
                  : []),
              ]}
            />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'leads'
  );

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        <div
          className={cs('flex justify-between sm:items-center mb-2', {
            'flex-col': isMaxWidth640px,
          })}
        >
          <Card.Header
            title={`Leads ${
              leadsMetadata && leadsMetadata.totalResults
                ? `(${leadsMetadata.totalResults})`
                : ''
            }`}
          />
          <div className="flex">
            <CSVDownload
              filename="Lead.csv"
              onDataFetch={generateCSVClick}
              csvHeaders={[
                { label: 'First Name', key: 'first_name' },
                { label: 'Last Name', key: 'last_name' },
                { label: 'Email', key: 'email' },
                { label: 'Phone', key: 'phone' },
                { label: 'Rejected?', key: 'is_rejected' },
                { label: 'Received On', key: 'createdAt' },
                { label: 'Company', key: 'company' },
                { label: 'Details', key: 'details' },
                { label: 'Message', key: 'message' },
                { label: 'Lead Type', key: 'type' },
                { label: 'Has Resources?', key: 'has_resources' },
              ]}
            />
          </div>
        </div>
        <Card.Content className="h-full flex">
          <Table
            columns={columns}
            data={leadsData}
            total={leadsMetadata.totalPages}
            fetchData={handleTableChange}
          />
        </Card.Content>
      </Card>
    </CanAccessComponent>
  );
};

export default Leads;
