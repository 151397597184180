import * as actions from '../actions/offerings';

const initialState = {
  offerings: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
    data: null,
  },

  details: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
    data: {},
  },
  updateOfferings: {
    isInProgress: true,
    isError: false,
    error: '',
  },
  deleteOfferings: {
    isInProgress: true,
    isError: false,
    error: '',
  },
};

const offeringsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_OFFERINGS_INPROGRESS:
      return {
        ...state,
        offerings: {
          ...state.offerings,
          isInProgress: true,
        },
      };

    case actions.FETCH_OFFERINGS_SUCCESS:
      return {
        ...state,
        offerings: {
          ...state.offerings,
          isInProgress: false,
          status: 1,
          data: action.data,
          isError: false,
          error: '',
        },
      };
    case actions.FETCH_OFFERINGS_FAILURE:
      return {
        ...state,
        offerings: {
          ...state.offerings,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };

    case actions.FETCH_OFFERINGS_DETAIL_INPROGRESS:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: true,
        },
      };

    case actions.FETCH_OFFERINGS_DETAIL_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: false,
          status: 1,
          data: action.data,
          isError: false,
          error: '',
        },
      };

    case actions.FETCH_OFFERINGS_DETAIL_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };

    case actions.ADD_OFFERINGS:
      return {
        ...state,
        offerings: {
          ...state.offerings,
          isError: false,
          error: '',
          data: {
            data: [...state.offerings.data.data, action.data.data],
            meta: { ...state.offerings.data.meta },
          },
        },
      };

    case actions.ADD_OFFERINGS_FAILED:
      return {
        ...state,
        offerings: {
          ...state.offerings,
          isError: true,
          error: action.message,
        },
      };

    case actions.UPDATE_OFFERINGS_INPROGRESS:
      return {
        ...state,
        updateOfferings: {
          ...state.updateOfferings,
          isInProgress: true,
        },
      };
    case actions.UPDATE_OFFERINGS_SUCCESS:
      return {
        ...state,
        updateOfferings: {
          ...state.updateOfferings,
          isInProgress: false,
        },
      };
    case actions.UPDATE_OFFERINGS_FAILED:
      return {
        ...state,
        updateOfferings: {
          ...state.updateOfferings,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };
    case actions.DELETE_OFFERINGS_INPROGRESS:
      return {
        ...state,
        deleteOfferings: {
          ...state.deleteOfferings,
          isInProgress: true,
        },
      };
    case actions.DELETE_OFFERINGS_SUCCESS:
      return {
        ...state,
        deleteOfferings: {
          ...state.deleteOfferings,
          isInProgress: false,
        },
      };
    case actions.DELETE_OFFERINGS_FAILED:
      return {
        ...state,
        deleteOfferings: {
          ...state.deleteOfferings,
          isInProgress: false,
          isError: true,
          error: action.message,
        },
      };

    default:
      return state;
  }
};

export default offeringsReducer;
