import React from 'react';
import RDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './datePicker.module.scss';
import cs from 'classnames';

const DatePicker = ({
  id = '',
  label = '',
  placeholder = '',
  required = false,
  wrapperClassName = '',
  labelClassName = '',
  className = '',
  isDisabled = false,
  error = null,
  ...rest
}) => {
  const errorId = 'error-' + id;
  const labelTestId = id + '-label';

  return (
    <div className={cs(styles.root, wrapperClassName)}>
      {label ? (
        <label
          className={cs(styles.label, labelClassName)}
          htmlFor={id}
          data-testid={labelTestId}
        >
          {label}
        </label>
      ) : null}
      <div
        className={cs(styles.inputWrapper, {
          [styles.withLabel]: label,
        })}
      >
        <RDatePicker
          id={id}
          required={required}
          placeholder={placeholder}
          disabled={isDisabled}
          aria-describedby={errorId}
          className={cs(
            styles.input,
            className,
            `border focus:border-brandorange focus:outline-none rounded pt-2 w-full p-2`,
            {
              [styles.error]: error,
            }
          )}
          {...rest}
        />
      </div>
    </div>
  );
};

export default DatePicker;
