export const renderEntityType = dataType => {
  switch (dataType) {
    case 'company':
      return 'Company';
    case 'ira':
      return 'IRA';
    case 'person':
      return 'Person';
    case 'trust':
      return 'Trust';
    default:
      return dataType;
  }
};

export default renderEntityType;
