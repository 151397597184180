import { get } from 'lodash';
import {
  addProspectActivity,
  fetchProspectData,
  fetchProspectsList,
  rejectProspect,
  fetchAllProspectsData,
  sendEmail,
} from '../api/prospects';
import { showSnackBar } from './snackbar';

export const FETCH_PROSPECTS_INPROGRESS = 'FETCH_PROSPECTS_INPROGRESS';
export const FETCH_PROSPECTS_SUCCESS = 'FETCH_PROSPECTS_SUCCESS';
export const FETCH_PROSPECTS_FAILURE = 'FETCH_PROSPECTS_FAILURE';

export const FETCH_PROSPECTS_DETAIL_INPROGRESS =
  'FETCH_PROSPECTS_DETAIL_INPROGRESS';
export const FETCH_PROSPECTS_DETAIL_SUCCESS = 'FETCH_PROSPECTS_DETAIL_SUCCESS';
export const FETCH_PROSPECTS_DETAIL_FAILURE = 'FETCH_PROSPECTS_DETAIL_FAILURE';

export const REJECT_PROSPECT_INPROGRESS = 'REJECT_PROSPECT_FAILURE';
export const REJECT_PROSPECT_SUCCESS = 'REJECT_PROSPECT_FAILURE';
export const REJECT_PROSPECT_FAILURE = 'REJECT_PROSPECT_FAILURE';

export const ADD_PROSPECT_ACTIVITY_INPROGRESS =
  'ADD_PROSPECT_ACTIVITY_INPROGRESS';
export const ADD_PROSPECT_ACTIVITY_SUCCESS = 'ADD_PROSPECT_ACTIVITY_SUCCESS';
export const ADD_PROSPECT_ACTIVITY_FAILED = 'ADD_PROSPECT_ACTIVITY_FAILED';

export const EXPORT_PROSPECT_CSV_INPROGRESS = 'EXPORT_PROSPECT_CSV_INPROGRESS';
export const EXPORT_PROSPECT_CSV_SUCCESS = 'EXPORT_PROSPECT_CSV_SUCCESS';
export const EXPORT_PROSPECT_CSV_FAILED = 'EXPORT_PROSPECT_CSV_FAILED';

export const SEND_PROSPECT_EMAIL_INPROGRESS = 'SEND_PROSPECT_EMAIL_INPROGRESS';
export const SEND_PROSPECT_EMAIL_SUCCESS = 'SEND_PROSPECT_EMAIL_SUCCESS';
export const SEND_PROSPECT_EMAIL_FAILED = 'SEND_PROSPECT_EMAIL_FAILED';

export const fetchProspects = (page, sort, q, limit) => async dispatch => {
  dispatch({
    type: FETCH_PROSPECTS_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await fetchProspectsList(page, sort, q, limit);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    const data = get(resp, 'data', []);

    if (status) {
      dispatch({
        type: FETCH_PROSPECTS_SUCCESS,
        data: data || [],
      });
    } else {
      dispatch({
        type: FETCH_PROSPECTS_FAILURE,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_PROSPECTS_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const fetchProspectDetail = id => async dispatch => {
  dispatch({
    type: FETCH_PROSPECTS_DETAIL_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await fetchProspectData(id);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: FETCH_PROSPECTS_DETAIL_SUCCESS,
        data: resp || {},
      });
    } else {
      dispatch({
        type: FETCH_PROSPECTS_DETAIL_FAILURE,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_PROSPECTS_DETAIL_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const rejectProspectById = id => async dispatch => {
  dispatch({
    type: REJECT_PROSPECT_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await rejectProspect(id);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: REJECT_PROSPECT_SUCCESS,
        data: resp || {},
      });
    } else {
      dispatch({
        type: REJECT_PROSPECT_FAILURE,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: REJECT_PROSPECT_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const addProspectActivityById = (id, activity) => async dispatch => {
  dispatch({
    type: ADD_PROSPECT_ACTIVITY_INPROGRESS,
  });
  try {
    const resp = await addProspectActivity(id, activity);
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: ADD_PROSPECT_ACTIVITY_SUCCESS,
        data: resp,
      });

      showSnackBar(message, 'success');
    } else {
      dispatch({
        type: ADD_PROSPECT_ACTIVITY_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: ADD_PROSPECT_ACTIVITY_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const exportProspectToCSV = () => async dispatch => {
  dispatch({
    type: EXPORT_PROSPECT_CSV_INPROGRESS,
  });
  try {
    const resp = await fetchAllProspectsData();
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: EXPORT_PROSPECT_CSV_SUCCESS,
      });
    } else {
      dispatch({
        type: EXPORT_PROSPECT_CSV_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
    return resp;
  } catch (error) {
    dispatch({
      type: EXPORT_PROSPECT_CSV_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const sendEmailToProspect = prospectId => async dispatch => {
  dispatch({
    type: SEND_PROSPECT_EMAIL_INPROGRESS,
  });
  try {
    const resp = await sendEmail(prospectId);
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: SEND_PROSPECT_EMAIL_SUCCESS,
      });
    } else {
      dispatch({
        type: SEND_PROSPECT_EMAIL_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
    return resp;
  } catch (error) {
    dispatch({
      type: SEND_PROSPECT_EMAIL_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};
