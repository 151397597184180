import React from 'react';

import Modal from 'components/Modal';
import {
  postGetAccountsParties,
  postMarkTradeAsSettled,
  getIpAddress,
  addSignerIpAddressToTrade,
} from 'store/api/investments';
import LoadingIndicator from 'components/LoadingIndicator';
import styles from './TradeStatusChangeModal.module.scss';
import Button from 'components/Button';
import cs from 'classnames';

import { showSnackBar } from 'store/actions/snackbar';
const TradeStatusChangeModal = ({ onModalClose, modal, chosenInvestor }) => {
  const [accountsParties, setAccountsParties] = React.useState(null);
  const [isAiVerified, setIsAiVerified] = React.useState(true);

  const [ai, aiStatus] = React.useState(null);
  const [isAllPartiesApproved, setIsAllPartiesApproved] = React.useState(true);
  const [
    isAccountsPartiesLoading,
    setIsAccountsPartiesLoading,
  ] = React.useState(false);

  const [
    loaderpostMarkTradeAsSettled,
    setLoaderpostMarkTradeAsSettled,
  ] = React.useState(false);

  const getAccountsParties = async () => {
    try {
      setIsAccountsPartiesLoading(true);
      const accountPartiesResponse = await postGetAccountsParties({
        accountId: chosenInvestor.accountId,
      });

      setAccountsParties(accountPartiesResponse?.data?.partiesResponse);

      if (chosenInvestor?.accredited === 'Self Accredited') {
        aiStatus(accountPartiesResponse?.data?.account?.accreditedStatus);
        if (
          accountPartiesResponse?.data?.account?.accreditedStatus ===
            'Pending' ||
          accountPartiesResponse?.data?.account?.accreditedStatus ===
            'Self Accredited' ||
          accountPartiesResponse?.data?.account?.accreditedStatus ===
            'Not Accredited'
        ) {
          setIsAiVerified(false);
        }
      }

      accountPartiesResponse?.data?.partiesResponse.forEach(e => {
        if (
          e?.kycStatus === 'Disapproved' ||
          e?.amlStatus === 'Disapproved' ||
          e?.kycStatus === 'Need More Info' ||
          e?.amlStatus === 'Need More Info' ||
          e?.kycStatus === 'Pending' ||
          e?.amlStatus === 'Pending'
        ) {
          setIsAllPartiesApproved(false);
        }
      });
      setIsAccountsPartiesLoading(false);
    } catch (err) {
      alert('Something went wrong');
      setIsAccountsPartiesLoading(false);
    }
  };
  React.useEffect(() => {
    getAccountsParties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIpaddress = async () => {
    try {
      const ipResponse = await getIpAddress();
      return { data: ipResponse.data.IPv4, message: 'success' };
    } catch (err) {
      return { err: 'getIpaddress', message: 'Could not get ipaddress' };
    }
  };
  const addSignerIpTrade = async data => {
    try {
      const tradeResponse = await addSignerIpAddressToTrade(data);
      if (tradeResponse.err) {
        return {
          err: 'addSignerIpTrade',
          message: 'Could not add ip address to Trade',
        };
      }

      return { data: tradeResponse.data };
    } catch (err) {
      return {
        err: 'addSignerIpTrade',
        message: 'Could not add ip address to Trade',
      };
    }
  };

  const markTradeAsSettled = async () => {
    try {
      setLoaderpostMarkTradeAsSettled(true);
      const usersIpAdress = await getIpaddress();

      if (usersIpAdress.err) {
        return showSnackBar('Could not get users ipaddress ', 'error');
      }
      const ipaddress = usersIpAdress.data;
      if (!ipaddress)
        return showSnackBar('Could not get users ipaddress ', 'error');
      if (ipaddress.length === 0)
        return showSnackBar('Could not get users ipaddress ', 'error');

      const ipTradeResponse = await addSignerIpTrade({
        ip: ipaddress,
        tradeId: chosenInvestor?.trade?.tradeId,
      });

      if (ipTradeResponse.err)
        return showSnackBar('Could not update Trade', 'error');

      const updatedTradeResponse = await postMarkTradeAsSettled({
        accountId: chosenInvestor?.accountId,
        tradeId: chosenInvestor?.trade?.tradeId,
        orderStatus: 'SETTLED',
      });

      if (updatedTradeResponse.err) {
        setLoaderpostMarkTradeAsSettled(false);
        return showSnackBar(JSON.stringify(updatedTradeResponse), 'error');
      }

      setLoaderpostMarkTradeAsSettled(false);
      showSnackBar('Trade Updated', 'success');
      onModalClose();
    } catch (err) {
      showSnackBar('Something went wrong with updating trade', 'error');
      setLoaderpostMarkTradeAsSettled(false);
    }
  };

  return (
    <Modal isOpen={modal} onClick={onModalClose} width="500px">
      <div className={styles.container}>
        {isAccountsPartiesLoading ? (
          <div className={styles.container__loader}>
            <h2> Getting Parties</h2>
            <LoadingIndicator />
          </div>
        ) : (
          <div className={cs('flex w-full flex-col ')}>
            <div className="w-full">
              <div>
                {accountsParties && (
                  <h3> TradeId: {chosenInvestor?.trade?.tradeId}</h3>
                )}
                <h3>
                  {chosenInvestor?.accredited === 'Self Accredited' ? (
                    <div>AccreditedStatus : {ai} </div>
                  ) : null}
                </h3>
                {accountsParties &&
                  accountsParties.map((e, i) => (
                    <div key={i} className={styles.container__partiesContainer}>
                      <div>
                        {e.type}: {e.partyId}
                      </div>
                      <div
                        className={styles.container__partiesContainer__kycAml}
                      >
                        <div>
                          <div style={{ textDecoration: 'underline' }}>Aml</div>
                          <div>{e.amlStatus}</div>
                        </div>
                        <div>
                          <div style={{ textDecoration: 'underline' }}>Kyc</div>
                          <div>{e.kycStatus}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                <div className="w-full my-4 flex justify-between">
                  <Button
                    onClick={onModalClose}
                    variant="transparent"
                    label="Cancel"
                  />
                  <Button
                    type="submit"
                    isDisabled={
                      isAllPartiesApproved && isAiVerified ? false : true
                    }
                    isLoading={loaderpostMarkTradeAsSettled}
                    onClick={markTradeAsSettled}
                    label="confirm"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TradeStatusChangeModal;
