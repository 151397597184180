import React from 'react';
import Details from 'components/Details';
import NumberFormat from 'react-number-format';
import moment from 'moment';

const InvestorData = ({ parties }) => {
  return parties && parties.length > 0
    ? parties.map(
        ({
          amlDate,
          amlStatus,
          kycStatus,
          linkType,
          currentAnnIncome,
          householdNetworth,
          // associatedPerson,
          // avgAnnIncome,
          // avgHouseholdIncome,
          createdDate,

          // currentHouseholdIncome,
          dob,
          // documentKey,
          domicile,
          emailAddress,
          // emailAddress2,
          // empAddress1,
          // empAddress2,
          // empCity,
          // empCountry,
          // empName,
          // empState,
          // empStatus,
          // empZip,
          // esignStatus,
          // field1,
          // field2,
          // field3,
          firstName,
          lastName,

          // invest_to,

          // middleInitial,
          notes,
          // occupation,
          partyId,
          phone,
          // phone2,
          primAddress1,
          primAddress2,
          primCity,
          primCountry,
          primState,
          primZip,
          socialSecurityNumber,
          // tags,
          // type,
          // updatedDate,
        }) => (
          <div key={partyId} className="mt-10">
            <div className="grid grid-cols-2 md:grid-cols-3 px-6 pb-6 gap-6 ">
              <Details title="Link Type" data={linkType} />

              <Details title="First Name" data={firstName} />

              <Details title="Last Name" data={lastName} />

              <Details title="Party Id" data={partyId} />

              <Details
                title="Social Security Number"
                data={
                  primCountry === 'US'
                    ? socialSecurityNumber
                    : 'Not A US Citizen'
                }
              />

              <Details
                title="Aml Date"
                data={amlDate && moment(amlDate).format('MMM Do, YYYY')}
              />

              <Details title="Know Your Customer (KYC) " data={kycStatus} />

              <Details title="Anti Money Laundering (AML)" data={amlStatus} />

              <Details title="North Capital Notes" data={notes} />

              <Details
                title="Created Party At"
                data={createdDate && moment(createdDate).format('MMM Do, YYYY')}
              />

              <Details
                title="Date Of Birth"
                data={createdDate && moment(dob).format('MMM Do, YYYY')}
              />

              <Details title="Domicile" data={domicile} />
              <Details title="Email" data={emailAddress} />
              <Details title="Phone" data={phone} />
              <Details title="Primary Address" data={primAddress1} />
              <Details title="Secondary Address" data={primAddress2} />
              <Details title="Primary City" data={primCity} />

              <Details title="Primary Country" data={primCountry} />

              <Details title="Primary State" data={primState} />

              <Details title="Primarty Zip" data={primZip} />

              <Details
                title="Current Annual Income"
                data={
                  <NumberFormat
                    thousandSeparator={true}
                    value={currentAnnIncome}
                    displayType={'text'}
                    prefix={`$`}
                  />
                }
              />

              <Details
                title="Net Worth"
                data={
                  <NumberFormat
                    thousandSeparator={true}
                    value={householdNetworth}
                    displayType={'text'}
                    prefix={`$`}
                  />
                }
              />
            </div>

            <hr />
          </div>
        )
      )
    : null;
};

export default InvestorData;
