import React from 'react';

import Modal from 'components/Modal';

import styles from './ReSendPaymentModal.module.scss';
import Button from 'components/Button';
import cs from 'classnames';

import { showSnackBar } from 'store/actions/snackbar';
import { reSendPayment } from 'store/api/investments';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Input from 'components/Input';
const ReSendPaymentModal = ({ onModalClose, modal, chosenInvestor }) => {
  const [loaderPayment, setLoaderPayment] = React.useState(false);

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(
      Yup.object({
        message: Yup.string(),
      })
    ),
  });

  const onSubmit = async value => {
    try {
      setLoaderPayment(true);
      const sendPaymentResponse = await reSendPayment({
        tradeId: chosenInvestor?.trade?.tradeId,
        accountId: chosenInvestor?.accountId,
        ...value,
      });

      if (sendPaymentResponse.err) {
        setLoaderPayment(false);
        return showSnackBar(JSON.stringify(sendPaymentResponse), 'error');
      }

      setLoaderPayment(false);
      showSnackBar('reSendPaymentFailure', 'success');
      onModalClose();
    } catch (err) {
      showSnackBar('Something went wrong with updating trade', 'error');
      setLoaderPayment(false);
    }
  };

  return (
    <Modal isOpen={modal} onClick={onModalClose} width="500px">
      <div className={styles.container}>
        <div className={cs('flex w-full flex-col ')}>
          <div className="w-full">
            <div className="text-center text-lg">
              <div
                className={styles.container__tradeId}
                style={{ fontSize: '24px' }}
              >
                Re-send Payment Email
              </div>

              <div className="mt-3 mb-2 text-xl">
                {' '}
                {chosenInvestor?.accountRegistration}
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  id="message"
                  type="text"
                  name="message"
                  error={errors.message?.message}
                  register={register}
                  label={'Message'}
                />

                <div className="w-full my-4 flex justify-between mr-3">
                  <Button
                    onClick={onModalClose}
                    variant="transparent"
                    label="Cancel"
                  />
                  <Button
                    type="submit"
                    isLoading={loaderPayment}
                    label="Confirm"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReSendPaymentModal;
