import { get } from 'lodash';
import {
  addLeadActivity,
  fetchLeadData,
  fetchLeadsList,
  rejectLead,
  fetchAllLeadsData,
} from '../api/leads';
import { showSnackBar } from './snackbar';

export const FETCH_LEADS_INPROGRESS = 'FETCH_LEADS_INPROGRESS';
export const FETCH_LEADS_SUCCESS = 'FETCH_LEADS_SUCCESS';
export const FETCH_LEADS_FAILURE = 'FETCH_LEADS_FAILURE';

export const FETCH_LEADS_DETAIL_INPROGRESS = 'FETCH_LEADS_DETAIL_INPROGRESS';
export const FETCH_LEADS_DETAIL_SUCCESS = 'FETCH_LEADS_DETAIL_SUCCESS';
export const FETCH_LEADS_DETAIL_FAILURE = 'FETCH_LEADS_DETAIL_FAILURE';

export const REJECT_LEAD_INPROGRESS = 'REJECT_LEAD_FAILURE';
export const REJECT_LEAD_SUCCESS = 'REJECT_LEAD_FAILURE';
export const REJECT_LEAD_FAILURE = 'REJECT_LEAD_FAILURE';

export const ADD_LEAD_ACTIVITY_INPROGRESS = 'ADD_LEAD_ACTIVITY_INPROGRESS';
export const ADD_LEAD_ACTIVITY_SUCCESS = 'ADD_LEAD_ACTIVITY_SUCCESS';
export const ADD_LEAD_ACTIVITY_FAILED = 'ADD_LEAD_ACTIVITY_FAILED';

export const EXPORT_LEAD_CSV_INPROGRESS = 'EXPORT_LEAD_CSV_INPROGRESS';
export const EXPORT_LEAD_CSV_SUCCESS = 'EXPORT_LEAD_CSV_SUCCESS';
export const EXPORT_LEAD_CSV_FAILED = 'EXPORT_LEAD_CSV_FAILED';

export const fetchLeads = (page, sort, q, limit) => async dispatch => {
  dispatch({
    type: FETCH_LEADS_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await fetchLeadsList(page, sort, q, limit);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    const data = get(resp, 'data', []);
    if (status) {
      dispatch({
        type: FETCH_LEADS_SUCCESS,
        data: data || [],
      });
    } else {
      dispatch({
        type: FETCH_LEADS_FAILURE,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_LEADS_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const fetchLeadDetail = id => async dispatch => {
  dispatch({
    type: FETCH_LEADS_DETAIL_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await fetchLeadData(id);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: FETCH_LEADS_DETAIL_SUCCESS,
        data: resp || {},
      });
    } else {
      dispatch({
        type: FETCH_LEADS_DETAIL_FAILURE,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_LEADS_DETAIL_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const rejectLeadById = id => async dispatch => {
  dispatch({
    type: REJECT_LEAD_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await rejectLead(id);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: REJECT_LEAD_SUCCESS,
        data: resp || {},
      });
    } else {
      dispatch({
        type: REJECT_LEAD_FAILURE,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: REJECT_LEAD_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const addLeadActivityById = (id, activity) => async dispatch => {
  dispatch({
    type: ADD_LEAD_ACTIVITY_INPROGRESS,
  });
  try {
    const resp = await addLeadActivity(id, activity);
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: ADD_LEAD_ACTIVITY_SUCCESS,
        data: resp,
      });

      showSnackBar(message, 'success');
    } else {
      dispatch({
        type: ADD_LEAD_ACTIVITY_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: ADD_LEAD_ACTIVITY_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const exportLeadToCSV = () => async dispatch => {
  dispatch({
    type: EXPORT_LEAD_CSV_INPROGRESS,
  });
  try {
    const resp = await fetchAllLeadsData();
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: EXPORT_LEAD_CSV_SUCCESS,
      });
    } else {
      dispatch({
        type: EXPORT_LEAD_CSV_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
    return resp;
  } catch (error) {
    dispatch({
      type: EXPORT_LEAD_CSV_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};
