import React from 'react';
import NumberFormat from 'react-number-format';
import styles from './headerStatistic.module.scss';
import cs from 'classnames';
import LoaderSpinner from 'components/LoadSpinner';

const HeaderStatistic = ({ paragraph, valueFormat, classNames, isLoading }) => {
  return (
    <div
      className={cs(styles.totalInvestments, classNames, {
        'flex items-center justify-center': isLoading,
      })}
    >
      {isLoading ? (
        <LoaderSpinner color="#fff" className="my-4" />
      ) : (
        <>
          <p>{paragraph}</p>
          <NumberFormat
            value={valueFormat}
            displayType="text"
            thousandSeparator={true}
          />
        </>
      )}
    </div>
  );
};

export default HeaderStatistic;
