import Request from './request';

export const fetchAllInvestmentsByUser = async (id, page, sort, q, limit) =>
  await Request.call({
    url: `/users/${id}/investments`,
    method: 'GET',
    params: {
      page,
      sort,
      q,
      limit,
    },
  });
