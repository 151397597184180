import { get } from 'lodash';
import { showSnackBar } from './snackbar';
import {
  downloadDeprecatedDocument,
  getDeprecatedDocuments,
  getDeprecatedDocumentsData,
  updateDeprecatedDocumentsData,
} from '../api/deprecated-documents';

export const FETCH_DEPRECATED_DOCUMENT_DETAILS_INPROGRESS =
  'FETCH_DEPRECATED_DOCUMENT_DETAILS_INPROGRESS';
export const FETCH_DEPRECATED_DOCUMENT_DETAILS_SUCCESS =
  'FETCH_DEPRECATED_DOCUMENT_DETAILS_SUCCESS';
export const FETCH_DEPRECATED_DOCUMENT_DETAILS_FAILURE =
  'FETCH_DEPRECATED_DOCUMENT_DETAILS_FAILURE';

export const FETCH_DEPRECATED_DOCUMENTS_INPROGRESS =
  'FETCH_DEPRECATED_DOCUMENTS_INPROGRESS';
export const FETCH_DEPRECATED_DOCUMENTS_SUCCESS =
  'FETCH_DEPRECATED_DOCUMENTS_SUCCESS';
export const FETCH_DEPRECATED_DOCUMENTS_FAILURE =
  'FETCH_DEPRECATED_DOCUMENTS_FAILURE';

export const DOWNLOAD_DEPRECATED_DOCUMENT_PREIVEW_INPROGRESS =
  'DOWNLOAD_DEPRECATED_DOCUMENT_PREIVEW_INPROGRESS';
export const DOWNLOAD_DEPRECATED_DOCUMENT_PREIVEW_SUCCESS =
  'DOWNLOAD_DEPRECATED_DOCUMENT_PREIVEW_SUCCESS';
export const DOWNLOAD_DEPRECATED_DOCUMENT_PREIVEW_FAILURE =
  'DOWNLOAD_DEPRECATED_DOCUMENT_PREIVEW_FAILURE';

export const UPDATE_DEPRECATED_DOCUMENT_DETAILS_INPROGRESS =
  'UPDATE_DEPRECATED_DOCUMENT_DETAILS_INPROGRESS';
export const UPDATE_DEPRECATED_DOCUMENT_DETAILS_SUCCESS =
  'UPDATE_DEPRECATED_DOCUMENT_DETAILS_SUCCESS';
export const UPDATE_DEPRECATED_DOCUMENT_DETAILS_FAILURE =
  'UPDATE_DEPRECATED_DOCUMENT_DETAILS_FAILURE';

export const fetchDeprecatedDocuments = (
  page,
  sort,
  q,
  limit
) => async dispatch => {
  dispatch({
    type: FETCH_DEPRECATED_DOCUMENTS_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await getDeprecatedDocuments(page, sort, q, limit);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    const data = get(resp, 'data', []);

    if (status) {
      dispatch({
        type: FETCH_DEPRECATED_DOCUMENTS_SUCCESS,
        data: data,
      });
    } else {
      dispatch({
        type: FETCH_DEPRECATED_DOCUMENTS_FAILURE,
        message: message,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_DEPRECATED_DOCUMENTS_FAILURE,
      message: error,
    });
  }
};

export const fetchDeprecatedDocumentsDetail = id => async dispatch => {
  dispatch({
    type: FETCH_DEPRECATED_DOCUMENT_DETAILS_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await getDeprecatedDocumentsData(id);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: FETCH_DEPRECATED_DOCUMENT_DETAILS_SUCCESS,
        data: resp || {},
      });
    } else {
      dispatch({
        type: FETCH_DEPRECATED_DOCUMENT_DETAILS_FAILURE,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_DEPRECATED_DOCUMENT_DETAILS_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const updateDeprecatedDocumentsDetail = (id, data) => async dispatch => {
  dispatch({
    type: UPDATE_DEPRECATED_DOCUMENT_DETAILS_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await updateDeprecatedDocumentsData(id, data);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: UPDATE_DEPRECATED_DOCUMENT_DETAILS_SUCCESS,
        data: resp || {},
      });
    } else {
      dispatch({
        type: UPDATE_DEPRECATED_DOCUMENT_DETAILS_FAILURE,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_DEPRECATED_DOCUMENT_DETAILS_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const downloadDeprecatedDocumentById = data => async dispatch => {
  dispatch({
    type: DOWNLOAD_DEPRECATED_DOCUMENT_PREIVEW_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await downloadDeprecatedDocument(data);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: DOWNLOAD_DEPRECATED_DOCUMENT_PREIVEW_SUCCESS,
        data: resp || {},
      });
    } else {
      dispatch({
        type: DOWNLOAD_DEPRECATED_DOCUMENT_PREIVEW_FAILURE,
        error: message,
      });
    }
    return resp;
  } catch (error) {
    dispatch({
      type: DOWNLOAD_DEPRECATED_DOCUMENT_PREIVEW_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};
