import React from 'react';
import cs from 'classnames';

const Header = ({ title, className = '', color = 'text-brandgrey' }) => (
  <h1
    className={cs('flex items-center text-center text-2xl', className, color)}
  >
    {title}
  </h1>
);

const Content = ({ children, className }) => (
  <div className={cs('mt-4', className)}>{children}</div>
);

const Card = ({
  children,
  className,
  fullHeight = true,
  background = 'bg-white',
}) => {
  return (
    <div
      className={cs(
        'flex p-6 flex-col rounded-lg',
        background,
        fullHeight ? { 'h-full': fullHeight } : null,
        className
      )}
    >
      {children}
    </div>
  );
};

Card.Header = Header;
Card.Content = Content;

export default Card;
