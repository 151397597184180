import React from 'react';
import {
  Users,
  Home,
  DollarSign,
  Settings,
  ShoppingCart,
  Briefcase,
  Printer,
  Shield,
  BarChart2,
  File,
  List,
  Lock,
  Package,
  Clipboard,
} from 'react-feather';
import news from './icons/news.png';
import aml from './icons/aml.png';

import styles from './sidebar.module.scss';
import cs from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { HasInvestments } from 'helpers/hasInvestments';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';
import AbandonCart from './icons/AbandonCart';

const Sidebar = () => {
  const history = useHistory();
  const location = useLocation();

  const sideBarButtons = [
    {
      name: 'Account Overview',
      icon: <Home className={styles.icon} />,
      path: '/my-account',
      visible: HasInvestments(),
    },
    {
      name: 'My Investments',
      icon: <DollarSign className={styles.icon} />,
      path: '/my-investments',
      visible: HasInvestments(),
    },
    {
      name: 'Investments',
      icon: <List className={styles.icon} />,
      path: '/investments',
      visible: true,
      permissionsRequired: true,
    },
    {
      name: 'Roles',
      icon: <Shield className={styles.icon} />,
      path: '/roles',
      visible: true,
      permissionsRequired: true,
    },
    {
      name: 'Job Applications',
      icon: <Briefcase className={styles.icon} />,
      path: '/applications',
      visible: true,
      permissionsRequired: true,
    },
    {
      name: 'Leads',
      icon: <Printer className={styles.icon} />,
      path: '/leads',
      visible: true,
      permissionsRequired: true,
    },
    {
      name: 'Offerings',
      icon: <Package className={styles.icon} />,
      path: '/offerings',
      visible: true,
      permissionsRequired: true,
    },
    {
      name: 'Investors',
      path: '/investors',
      icon: <BarChart2 className={styles.icon} />,
      visible: true,
      permissionsRequired: true,
    },
    {
      name: 'Documents',
      icon: <File className={styles.icon} />,
      path: '/documents',
      visible: true,
      permissionsRequired: true,
    },

    {
      name: 'AML',
      path: '/documents?type=aml',
      icon: <img src={aml} />,
      visible: true,
      permissionsRequired: true,
    },
    /*{
      name: 'Deprecated Documents',
      icon: <File className={styles.icon} />,
      path: '/deprecated-documents',
      visible: true,
      permissionsRequired: true,
    },*/
    {
      name: 'Reservations',
      path: '/reservations',
      icon: <ShoppingCart className={styles.icon} />,
      visible: true,
      permissionsRequired: true,
    },
    {
      name: 'Prospect',
      path: '/prospects',
      icon: <Clipboard className={styles.icon} />,
      visible: true,
      permissionsRequired: true,
    },
    {
      name: 'Users',
      path: '/users',
      icon: <Users className={styles.icon} />,
      visible: true,
      permissionsRequired: true,
    },
    {
      name: 'News',
      path: '/news',
      icon: <img src={news} />,
      visible: true,
      permissionsRequired: true,
    },
    {
      name: 'Permissions',
      path: '/permissions',
      icon: <Lock className={styles.icon} />,
      visible: true,
      permissionsRequired: true,
    },
    {
      name: 'Settings',
      icon: <Settings />,
      path: '/profile',
      visible: true,
      permissionsRequired: true,
    },
    {
      name: 'Abandoned Investments',
      icon: <AbandonCart />,
      path: '/noPaymentMethod',
      visible: true,
      permissionsRequired: true,
    },
  ];

  const onRouteClick = path => () => {
    history.push(path);
  };
  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const renderSidebarItem = ({
    name,
    icon,
    path,
    permissionsRequired,
    index,
  }) => {
    const permissionsForPage = getCurrentPagePermissions(
      availablePermissions,
      name
    );

    const ButtonElement = (
      <button
        key={index}
        className={cs(styles.sidebarItem, {
          [styles.active]: location.pathname.startsWith(path),
        })}
        onClick={onRouteClick(path)}
      >
        {icon}{' '}
        <p style={name === 'Abandoned Investments' ? { fontSize: '16px' } : {}}>
          {name}
        </p>
      </button>
    );

    if (permissionsRequired) {
      return (
        <CanAccessComponent
          key={index}
          allowedPermissions={permissionsForPage}
          shouldRedirect={false}
        >
          {ButtonElement}
        </CanAccessComponent>
      );
    }
    return ButtonElement;
  };
  return (
    <div className={styles.sideBar}>
      {sideBarButtons.map(
        ({ name, icon, path, visible, permissionsRequired = false }, index) => {
          return visible
            ? renderSidebarItem({
                name,
                icon,
                path,
                permissionsRequired,
                index,
              })
            : null;
        }
      )}
    </div>
  );
};

export default Sidebar;
