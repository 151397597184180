import BaseAxios from 'axios';
import get from 'lodash/get';
import { store } from '../index';
import { doLogout } from '../actions/auth';

class Request {
  axios;

  constructor() {
    this.axios = BaseAxios.create({ timeout: 60000 });
  }

  async call(config) {
    let headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
      'Access-Control-Allow-Headers':
        'Origin, X-Requested-With, Content-Type, Accept, Authorization',
      'Content-Type': 'application/json',
    };

    const storeData = store.getState();
    const token =
      get(storeData, 'auth.user.tokens.access.token', null) ||
      localStorage.getItem('token');
    const {
      headers: customHeaders,
      base_url = process.env.REACT_APP_API_URL,
      ...rest
    } = config;

    if (token)
      headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
        ...customHeaders,
      };

    try {
      const res = await this.axios.request(
        {
          baseURL: base_url,
          headers,
          ...rest,
        },
        {}
      );

      return { ...res.data, status: 1 };
    } catch (error) {
      const errorStatus = get(error, 'response.status', null);
      if (errorStatus === 401) {
        store.dispatch(doLogout());
      }

      if (get(error, 'response', null)) {
        let data = error.response.data;

        if (get(data, 'message') && typeof data.message === 'object') {
          // convert  error message object into string
          let message = '';
          if (!(data.message instanceof Array)) {
            for (const field in data.message) {
              message = get(data.message, `${field}.0`, '');
              if (message) {
                data.message = message;
                break;
              }
            }
          }
        }

        return { ...data, status: 0 };
      } else {
        return {
          status: 0,
          message: 'Please try again later',
        };
      }
    }
  }
}

export default new Request();
