import React, { useRef, useEffect, useState } from 'react';
import styles from './EditorImageButton.module.scss';
import Input from 'components/Input';
import Button from 'components/Button';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AtomicBlockUtils } from 'draft-js';
import image_button from './image_button.png';
const useOnOutsideTouch = (ref, setDropDown) => {
  useEffect(() => {
    /**
     * if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setDropDown(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

const schema = Yup.object().shape({
  title: Yup.string(),
  image_url: Yup.string().required('Image Url is required'),
});
export const EditorImageButton = props => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [showDropDown, setDropDown] = useState(false);

  const wrapperRef = useRef(null);
  useOnOutsideTouch(wrapperRef, setDropDown);
  const onSubmit = ({ image_url }) => {
    let text = image_url;
    let result = text.replace(/webp/g, 'png');

    const entityKey = props.editorState
      .getCurrentContent()
      .createEntity('IMAGE', 'MUTABLE', {
        src: result,
        height: '400px',
        width: '400px',
      })
      .getLastCreatedEntityKey();
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      props.editorState,
      entityKey,
      ' '
    );
    props.setEditorState(newEditorState);
  };
  return (
    <div ref={wrapperRef} className={styles.image_button}>
      <div
        className={styles.image_button__container}
        onClick={() => setDropDown(!showDropDown)}
      >
        <img src={image_button} />
      </div>
      {showDropDown ? (
        <div className={styles.image_button__dropdown}>
          <div className={styles.image_button__dropdown__wrapper}>
            <h2> URL</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                wrapperClassName={styles.input_wrapper}
                placeholder="insert a image url"
                name="image_url"
                error={errors.image_url?.message}
                register={register}
              />
              <Button
                type="submit"
                label={'Add block'}
                className={styles.button}
                onClick={() => {}}
              />
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default EditorImageButton;
