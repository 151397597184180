import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

const isNotAuthorized = WrappedComponent => props => {
  let history = useHistory();

  const accessToken = useSelector(({ auth }) =>
    get(auth, 'user.tokens.access.token', null) || typeof window !== 'undefined'
      ? localStorage.getItem('token')
      : null
  );
  if (!accessToken) {
    return <WrappedComponent {...props} />;
  }
  history.replace('/investments');
  return null;
};

export default isNotAuthorized;
