import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { verifyEmailUser } from 'store/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircle } from 'react-feather';
import Button from 'components/Button';

const VerifyEmail = () => {
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  const { token } = useParams();
  const user = useSelector(state => state.auth.user);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(verifyEmailUser(token));
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div
      className="flex items-center justify-center h-screen"
      style={{ background: '#E5E5E5' }}
    >
      <div
        className={`flex flex-col mx-4 items-center ${!loading &&
          'bg-white'} p-5 py-7 rounded w-full lg:w-6/12 `}
      >
        {loading ? (
          1
        ) : (
          <>
            <CheckCircle size={100} color="#ffa400" />
            {/*<i className="far fa-check-circle text-100 text-yellow"></i>*/}
            <h2 className="text-2xl text-blueGray-700 font-medium pb-1 pt-2">
              Thank You
            </h2>
            <p className="text-xl text-blueGray-700 pb-1">
              You have verified your email
            </p>
            <div className="text-center mt-6 w-full">
              <Button
                onClick={() =>
                  user?.user
                    ? history.push('/dashbaord')
                    : history.push('/auth/login')
                }
                className="full-width"
                label="Go Back"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
