import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { fetchInvestmentDetail } from 'store/actions/investments';
import styles from './investmentDetails.module.scss';
import EntityData from './EntityData';
import InvestorData from './InvestorData';
import InvestmentData from './InvestmentData';

import LoadingIndicator from 'components/LoadingIndicator';
import Card from 'components/Card';
import get from 'lodash/get';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';
import useQuery from 'helpers/useQuery';

const InvestmentDetails = () => {
  const query = useQuery();
  const accountMongoId = query.get('accountMongoId');
  const funded = query.get('funded');
  const dispatch = useDispatch();

  const isLoading = useSelector(
    ({ investments }) => investments.details.isInProgress
  );

  const trade = useSelector(
    ({ investments }) => investments.details.data.trade
  );

  const entity = useSelector(
    ({ investments }) => investments.details.data.entity
  );

  const parties = useSelector(
    ({ investments }) => investments.details.data.parties
  );

  useEffect(() => {
    dispatch(fetchInvestmentDetail({ accountMongoId, funded }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountMongoId, funded]);

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'investments'
  );

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card className={styles.root}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {/* <div className="flex px-6 pt-6 items-center justify-between">
              <Card.Header
                title={
                  <>
                    <ChevronLeft
                      onClick={() => history.push(`/investments`)}
                      className="cursor-pointer"
                    />
                    Investment Details
                  </>
                }
              />
              <Button
                onClick={() => history.push(`/users/${investor.user_id}`)}
                variant="primary"
                label="Go to User"
              />
            </div> */}
            <Card.Content>
              <h1 className="bg-brandblue text-xl p-3 text-white mb-3">
                Trade
              </h1>
              <InvestmentData trade={trade} />

              <h1 className="bg-brandblue text-xl p-3 text-white mb-3">
                Parties
              </h1>
              <InvestorData parties={parties} />

              <h1 className="bg-brandblue text-xl p-3 text-white mb-3">
                Entity
              </h1>
              <EntityData entity={entity} />
            </Card.Content>
          </>
        )}
      </Card>
    </CanAccessComponent>
  );
};

export default InvestmentDetails;
