import React, { useMemo } from 'react';
import Table from 'components/Table';
import { get } from 'lodash';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvestmentsByUserId } from 'store/actions/profile';
// import HeaderStats from 'components/Headers/HeaderStats';
import styles from './investmentsview.module.scss';
import { Link, useHistory } from 'react-router-dom';
import DropdownMenu from 'components/DropdownMenu';
import Card from 'components/Card';

const MyInvestments = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const investmentsData = useSelector(({ profile }) =>
    get(profile, 'investments.data.data', [])
  );

  const investmentMetadata = useSelector(({ profile }) =>
    get(profile, 'investments.data.meta', {})
  );

  const userId = useSelector(({ auth }) => get(auth, 'user.user._id', {}));

  const handleTableChange = async ({ pageIndex, sortBy, pageSize, query }) => {
    let sort = '';
    if (sortBy.length) {
      sort = sortBy[0].desc ? `-${sortBy[0].id}` : sortBy[0].id;
    }

    const totalPageSize = pageSize === 50 ? 50 : pageSize;

    await dispatch(
      fetchInvestmentsByUserId(
        userId,
        pageIndex + 1,
        sort,
        query,
        totalPageSize
      )
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => 'Amount',
        accessor: 'amount',
        Cell: ({ value, row }) =>
          value ? (
            <div className="flex items-start">
              <Link to={`/investments/${row.original._id}`}>
                $
                <NumberFormat
                  value={value}
                  displayType="text"
                  thousandSeparator={true}
                />
              </Link>
            </div>
          ) : null,
      },
      {
        Header: 'Reference #',
        accessor: 'bank_reference',
      },
      {
        Header: 'Investment Method',
        accessor: 'funds_transfer_method',
      },
      {
        Header: 'Invested On',
        accessor: 'createdAt',
        Cell: ({ value }) =>
          value ? moment(value).format('MMM Do, YYYY') : null,
      },
      {
        Header: () => (
          <div className="flex justify-end w-full">Last Updated</div>
        ),
        accessor: 'updatedAt',
        Cell: ({ value, row }) => (
          <div className="flex justify-end">
            <span className="flex items-center mr-4">
              {value ? moment(value).format('MMM Do, YYYY') : null}
            </span>
            <DropdownMenu
              options={[
                {
                  label: 'View Investment',
                  onClick: () =>
                    history.push(`/investments/${row.original._id}`),
                },
                {
                  label: 'Edit Investment',
                  onClick: () => {},
                },
                {
                  label: 'Delete Investment',
                  onClick: () => {},
                },
              ]}
            />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      {/* <HeaderStats /> */}
      <Card className={styles.root}>
        <Card.Header
          title={`My Investments ${
            investmentMetadata && investmentMetadata.totalResults
              ? `(${investmentMetadata.totalResults})`
              : ''
          }`}
        />
        <Card.Content className="h-full flex">
          <Table
            columns={columns}
            data={investmentsData}
            total={investmentMetadata.totalPages}
            fetchData={handleTableChange}
          />
        </Card.Content>
      </Card>
    </>
  );
};

export default MyInvestments;
