import { get } from 'lodash';
import {
  addPermission,
  deletePermission,
  fetchPermissionID,
  fetchPermissions,
  updatePermission,
} from '../api/permissions';
import { showSnackBar } from './snackbar';

export const FETCH_ALL_PERMISSIONS_DATA = 'FETCH_ALL_PERMISSIONS_DATA';
export const FETCH_PERMISSIONS_INPROGRESS = 'FETCH_PERMISSIONS_INPROGRESS';
export const FETCH_ALL_PERMISSIONS_DATA_FAILED =
  'FETCH_ALL_PERMISSIONS_DATA_FAILED';

export const FETCH_PERMISSION_DETAIL =
  'FETCH_AFETCH_PERMISSION_DETAILLL_PERMISSIONS_DATA';
export const FETCH_PERMISSION_DETAIL_INPROGRESS =
  'FETCH_PERMISSION_DETAIL_INPROGRESS';
export const FETCH_PERMISSION_DETAIL_FAILED = 'FETCH_PERMISSION_DETAIL_FAILED';

export const ADD_PERMISSION_INPROGRESS = 'ADD_PERMISSION_INPROGRESS';
export const ADD_PERMISSION_SUCCESS = 'ADD_PERMISSION_SUCCESS';
export const ADD_PERMISSION_FAILED = 'ADD_PERMISSION_FAILED';

export const UPDATE_PERMISSION_INPROGRESS = 'UPDATE_PERMISSION_INPROGRESS';
export const UPDATE_PERMISSION_SUCCESS = 'UPDATE_PERMISSION_SUCCESS';
export const UPDATE_PERMISSION_FAILED = 'UPDATE_PERMISSION_FAILED';

export const DELETE_PERMISSION_INPROGRESS = 'DELETE_PERMISSION_INPROGRESS';
export const DELETE_PERMISSION_SUCCESS = 'DELETE_PERMISSION_SUCCESS';
export const DELETE_PERMISSION_FAILED = 'DELETE_PERMISSION_FAILED';

export const fetchAllPermissions = (page, sort, q, limit) => async dispatch => {
  dispatch({
    type: FETCH_PERMISSIONS_INPROGRESS,
  });

  try {
    const resp = await fetchPermissions(page, sort, q, limit);
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: FETCH_ALL_PERMISSIONS_DATA,
        data: resp,
      });
    } else {
      dispatch({
        type: FETCH_ALL_PERMISSIONS_DATA_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: FETCH_ALL_PERMISSIONS_DATA_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const fetchPermissionDetail = id => async dispatch => {
  dispatch({
    type: FETCH_PERMISSION_DETAIL_INPROGRESS,
  });

  try {
    const data = await fetchPermissionID(id);
    const status = get(data, 'status');
    const message = get(data, 'message');

    if (status) {
      dispatch({
        type: FETCH_PERMISSION_DETAIL,
        data,
      });
    } else {
      dispatch({
        type: FETCH_PERMISSION_DETAIL_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: FETCH_PERMISSION_DETAIL_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const createNewPermission = permission => async dispatch => {
  dispatch({
    type: ADD_PERMISSION_INPROGRESS,
  });
  try {
    const data = await addPermission(permission);
    const status = get(data, 'status');
    const message = get(data, 'message');

    if (status) {
      dispatch({
        type: ADD_PERMISSION_SUCCESS,
        data,
      });
      dispatch(fetchAllPermissions());
      showSnackBar('You have successfully updated the permission', 'success');

      return data;
    } else {
      dispatch({
        type: ADD_PERMISSION_FAILED,
        message,
      });
      showSnackBar(message, 'error');

      return data;
    }
  } catch (error) {
    dispatch({
      type: ADD_PERMISSION_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');

    return { status: 0 };
  }
};

export const updatePermissionById = (id, permission) => async dispatch => {
  dispatch({
    type: UPDATE_PERMISSION_INPROGRESS,
  });
  try {
    const data = await updatePermission(id, permission);
    const status = get(data, 'status');
    const message = get(data, 'message');

    if (status) {
      dispatch({
        type: UPDATE_PERMISSION_SUCCESS,
        data,
      });

      showSnackBar('You have successfully updated the permission', 'success');
    } else {
      dispatch({
        type: UPDATE_PERMISSION_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: UPDATE_PERMISSION_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const deletePermissionById = (id, permission) => async dispatch => {
  dispatch({
    type: DELETE_PERMISSION_INPROGRESS,
  });
  try {
    const data = await deletePermission(id, permission);
    const status = get(data, 'status');
    const message = get(data, 'message');

    if (status) {
      dispatch({
        type: DELETE_PERMISSION_SUCCESS,
        data,
      });

      showSnackBar(message, 'success');
    } else {
      dispatch({
        type: DELETE_PERMISSION_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: DELETE_PERMISSION_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};
