import React, { useMemo } from 'react';
import styles from './investment.module.scss';
import Table from 'components/Table';
import DropdownMenu from 'components/DropdownMenu';
import TradeStatusChangeModal from './TradeStatusChangeModal';
import IssueRefundModal from './IssueRefundModal';
import { Link } from 'react-router-dom';
import {
  CheckCircle,
  Clock,
  Info,
  UserCheck,
  UserX,
  XCircle,
} from 'react-feather';
import DownArrow from 'components/DownArrow/DownArrow';
import InvestmentDocumentSubRow from './InvestmentDocumentSubRow';
import ReactTooltip from 'react-tooltip';
import cs from 'classnames';
import AccountDisapproveModal from './AccountDisapproveModal';
import PartyUpdateModal from './PartyUpdateModal';
import ReSendPaymentModal from './ReSendPaymentModal';
import InvestmentLinkModal from './InvestmentLinkModal';
import money from './icons/money.png';
import noMoney from './icons/no-money.png';

const InvestmentSubRow = ({ row, funded }) => {
  const [subRowButtonState, setSubRowButtonState] = React.useState(false);

  const [tradeStatusChangeModal, setTradeStatusModal] = React.useState(false);

  const [issueRefundModal, setIssueRefundModal] = React.useState(false);

  const [disapproveAccountModal, setDisapproveAccountModal] = React.useState(
    false
  );
  // RE-SEND PAYMENT FAILURE
  const [reSendPayment, setReSendPayment] = React.useState(false);
  // RE-SEND ACCREDITED EMAIL
  const [reSendAcc, setReSendAcc] = React.useState(false);
  //RE-SEND KYC/AML
  const [reSendKycAml, setReSendKycAml] = React.useState(false);

  //UPLOAD GET LINK
  const [linkModal, setLinkModal] = React.useState(false);

  const [getAiLink, setGetAiLink] = React.useState(false);

  const [getAmlLink, setAmlLink] = React.useState(false);

  const [getPaymentLink, setPaymentLink] = React.useState(false);

  // UPDATE PARTY
  const [updatePartyModal, setUpdatePartyModal] = React.useState(false);

  const [chosenParty, setChosenParty] = React.useState({});

  const [partyStatus, setPartyStatus] = React.useState(false);

  //UPDATE ACCOUNT
  const [approve, setApprove] = React.useState(false);

  const [reject, setReject] = React.useState(false);

  const [chosenInvestor, setChosenInvestor] = React.useState({});

  const useColumns = useMemo(
    () => [
      {
        Header: () => 'Expand',
        accessor: 'selection',

        Cell: ({ row, rows, toggleRowExpanded }) => {
          return (
            <DownArrow
              style={{ cursor: 'pointer' }}
              onClick={() => {
                const expandedRow = rows.find(row => row.isExpanded);
                if (expandedRow) {
                  const isSubItemOfRow = Boolean(
                    expandedRow && row.id.split('.')[0] === expandedRow.id
                  );
                  if (isSubItemOfRow) {
                    const expandedSubItem = expandedRow.subRows.find(
                      subRow => subRow.isExpanded
                    );
                    if (expandedSubItem) {
                      const isClickedOnExpandedSubItem =
                        expandedSubItem.id === row.id;
                      if (!isClickedOnExpandedSubItem) {
                        toggleRowExpanded(expandedSubItem.id, false);
                      }
                    }
                  } else {
                    toggleRowExpanded(expandedRow.id, false);
                  }
                }
                row.toggleRowExpanded();
              }}
            />
          );
        },
      },

      {
        Header: () => (
          <div className="flex items-center cursor-none">
            <ReactTooltip id="verify" effect="solid">
              If the user has all documents approved. The investor is verified.
              Otherwise he is not.
            </ReactTooltip>

            <p className="cursor-pointer">Docs Verified</p>
            <Info
              className="ml-1 cursor-none"
              size={18}
              strokeWidth={2}
              data-tip
              data-for="verify"
            />
          </div>
        ),
        accessor: 'isAllBoxablDocumentsReviewed',
        Cell: ({ value }) => {
          return (
            <p
              className={cs('flex justify-center font-medium', {
                'text-green-500': value,
                'text-red-500': !value,
              })}
            >
              {value ? (
                <div className="flex items-center  text-sm">
                  Documents Verified <UserCheck className="ml-2" />
                </div>
              ) : (
                <div className="flex items-center text-sm">
                  Documents Not Verified <UserX className="ml-2" />
                </div>
              )}
            </p>
          );
        },
      },

      {
        Header: 'KYC/AML Approved',
        accessor: 'kycaml',
        Cell: ({ row }) => {
          return row?.original?.kycAmlPending ? (
            <div className="flex content-center justify-center">
              <Clock color="orange" />
            </div>
          ) : (
            <div className="flex content-center justify-center">
              {row?.original?.kycaml ? (
                row?.original?.kycaml === 'Auto Approved' ? (
                  <CheckCircle className="text-green-500	" />
                ) : row?.original?.kycaml === 'Disapproved' ? (
                  <XCircle color="#ff3300" />
                ) : (
                  'something went wrong'
                )
              ) : (
                'None'
              )}
            </div>
          );
        },
      },

      {
        Header: 'Accredited',
        accessor: 'AccreditedStatus',
        Cell: ({ row }) => {
          return (
            <div
              style={{ cursor: 'pointer' }}
              className="flex justify-center items-center"
            >
              {row?.original?.AccreditedStatus === 'Self Accredited' ? (
                <div className="flex content-center justify-center">
                  {row?.original?.aiRequestStatus ? (
                    row?.original?.aiRequestStatus === 'Pending' ? (
                      <Clock color="orange" />
                    ) : (
                      row?.original?.aiRequestStatus
                    )
                  ) : (
                    row?.original?.AccreditedStatus
                  )}
                </div>
              ) : (
                <div className="flex content-center justify-center">
                  {row?.original?.aiRequestStatus ? (
                    row?.original?.aiRequestStatus === 'Approved' ? (
                      <CheckCircle className="text-green-500	" />
                    ) : (
                      row?.original?.aiRequestStatus
                    )
                  ) : (
                    row?.original?.AccreditedStatus
                  )}
                </div>
              )}
            </div>
          );
        },
      },

      {
        Header: 'Status',
        accessor: 'trade.tradeStatus',
        //row?.original?.trade
        Cell: ({ row, value }) => {
          return row?.original?.trade ? value : 'No Trade';
        },
      },

      {
        Header: 'Trade ID',
        accessor: 'trade.tradeId',
        Cell: ({ row, value }) => {
          return row?.original?.trade ? value : 'No Trade';
        },
      },

      {
        Header: 'KYC/AML Uploaded',
        accessor: 'missingAmlUpload',
        Cell: ({ row }) => {
          return (
            <div>
              {row?.original?.missingAmlUpload ? (
                <XCircle color="#ff3300" />
              ) : row?.original?.trade?.tradeStatus === 'CREATED' ? (
                <Clock className="mr-2 text-blue-500	" />
              ) : (
                <CheckCircle className="text-green-500	" />
              )}
            </div>
          );
        },
      },

      {
        Header: 'Acc Uploaded',
        accessor: 'missingAiUpload',
        Cell: ({ row }) => {
          return (
            <div>
              {row?.original?.missingAiUpload ? (
                <XCircle color="#ff3300" />
              ) : (
                <CheckCircle className="text-green-500	" />
              )}
            </div>
          );
        },
      },

      {
        Header: 'Amount',
        accessor: 'trade.transactionAmount',
        Cell: ({ row }) => {
          return row?.original?.trade ? (
            <div>${row?.original?.trade?.transactionAmount}</div>
          ) : (
            '$0'
          );
        },
      },
      {
        Header: 'Payment',
        accessor: 'trade.transactionType',
        Cell: ({ row, value }) => {
          return row?.original?.trade ? value : 'No Trade';
        },
      },
      {
        Header: 'Offering',
        accessor: 'trade.offeringName',
        Cell: ({ row, value }) => {
          return row?.original?.trade ? value : 'No Trade';
        },
      },

      {
        Header: 'Name',
        accessor: 'accountRegistration',
      },

      {
        Header: 'Account',
        accessor: 'accountId',

        Cell: ({ value, row }) => {
          return (
            <Link
              to={`/investments/investor?accountMongoId=${row.original._id}&funded=${funded}`}
            >
              {value}
            </Link>
          );
        },
      },
      {
        Header: 'Parties/Entity',
        accessor: 'PartiesAndEntity',
        Cell: ({ row }) => {
          let investors = [];

          row?.original?.parties.forEach(({ partyId, ...e }) => {
            const isOnePending =
              e?.AMLstatus === 'Pending' || e?.KYCstatus === 'Pending';
            const isDisapproved =
              e?.AMLstatus === 'Disapproved' ||
              e?.KYCstatus === 'Disapproved' ||
              e?.AMLstatus === 'Need More Info' ||
              e?.KYCstatus === 'Need More Info';
            const isAllApproved =
              (e?.KYCstatus === 'Auto Approved' &&
                e?.AMLstatus === 'Auto Approved') ||
              (e?.KYCstatus === 'Manually Approved' &&
                e?.AMLstatus === 'Manually Approved') ||
              (e?.KYCstatus === 'Approved' && e?.AMLstatus === 'Approved');
            investors.push({
              ...e,
              partyId,
              type: 'party',
              kycAmlStatus: isOnePending
                ? 'Pending'
                : isDisapproved
                ? 'Disapproved'
                : isAllApproved
                ? 'Approved'
                : 'Disapproved',
            });
          });
          if (row?.original?.entity) {
            const { entityId, ...entity } = row?.original?.entity;

            const isOnePending =
              entity?.AMLstatus === 'Pending' ||
              entity?.KYCstatus === 'Pending';
            const isDisapproved =
              entity?.AMLstatus === 'Disapproved' ||
              entity?.KYCstatus === 'Disapproved' ||
              entity?.AMLstatus === 'Need More Info' ||
              entity?.KYCstatus === 'Need More Info';
            const isAllApproved =
              (entity?.KYCstatus === 'Auto Approved' &&
                entity?.AMLstatus === 'Auto Approved') ||
              (entity?.KYCstatus === 'Manually Approved' &&
                entity?.AMLstatus === 'Manually Approved') ||
              (entity?.KYCstatus === 'Approved' &&
                entity?.AMLstatus === 'Approved');

            investors.push({
              ...entity,
              partyId: entityId,
              type: 'entity',

              kycAmlStatus: isOnePending
                ? 'Pending'
                : isDisapproved
                ? 'Disapproved'
                : isAllApproved
                ? 'Approved'
                : 'Disapproved',
            });
          }

          return row?.original?.trade
            ? investors.map((e, i) => (
                <div key={i}>
                  {e && (
                    <DropdownMenu
                      domElement={
                        <div
                          style={
                            e?.kycAmlStatus === 'Pending'
                              ? {
                                  textDecoration: 'underline',
                                  textDecorationColor: 'yellow',
                                  textDecorationThickness: '5px',
                                }
                              : e?.kycAmlStatus === 'Disapproved'
                              ? {
                                  textDecoration: 'underline',
                                  textDecorationColor: 'red',
                                  textDecorationThickness: '5px',
                                }
                              : e?.kycAmlStatus === 'Approved'
                              ? {
                                  textDecoration: 'underline',
                                  textDecorationColor: 'green',
                                  textDecorationThickness: '5px',
                                }
                              : {
                                  textDecoration: 'underline',
                                  textDecorationColor: 'purple',
                                  textDecorationThickness: '5px',
                                }
                          }
                        >
                          {e.partyId}
                        </div>
                      }
                      isOneRow={row?.id === '0'}
                      options={[
                        {
                          label: 'Manually Approved',
                          onClick: () => {
                            setChosenParty(e);
                            setUpdatePartyModal(true);
                            setPartyStatus(true);
                            //TO STOP RESENDKYCAML
                            setReSendKycAml(false);
                          },
                        },
                        {
                          label: 'Disapproved',
                          onClick: () => {
                            setChosenParty(e);
                            setUpdatePartyModal(true);
                            setPartyStatus(false);
                            //TO STOP RESENDKYCAML
                            setReSendKycAml(false);
                          },
                        },
                      ]}
                    />
                  )}
                </div>
              ))
            : 'No Trade';
        },
      },

      {
        Header: 'Email',
        accessor: 'email',
      },

      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'Type',
        accessor: 'investedAs',
        Cell: ({ value, row }) => {
          return (
            <div className="flex justify-end">
              <span className="flex items-center mr-4">
                {value ? value : null}
              </span>
              {row?.original?.isPaid ? (
                row?.original?.AccreditedStatus === 'Self Accredited' ||
                row?.original?.AccreditedStatus === 'Verified Accredited' ? (
                  <DropdownMenu
                    isOneRow={row?.id === '0'}
                    options={[
                      {
                        label: 'Disapprove Accreditation',
                        onClick: () => {
                          setChosenInvestor(row?.original);
                          setDisapproveAccountModal(true);
                          setReject(false);
                          setApprove(false);
                          setReSendAcc(false);
                        },
                      },

                      {
                        label: 'Approve Accreditation',
                        onClick: () => {
                          setChosenInvestor(row?.original);
                          setDisapproveAccountModal(true);
                          setApprove(true);
                          setReject(false);
                        },
                      },

                      {
                        label: 'Reject Accreditation',
                        onClick: () => {
                          setChosenInvestor(row?.original);
                          setDisapproveAccountModal(true);
                          setReject(true);
                          setApprove(false);
                        },
                      },

                      {
                        label: 'Re-send Accreditation Email',
                        onClick: () => {
                          setChosenInvestor(row?.original);
                          setDisapproveAccountModal(true);
                          setReject(false);
                          setApprove(false);
                          setReSendAcc(true);
                        },
                      },

                      {
                        label: 'Re-send KYC/AML Email',
                        onClick: () => {
                          let investors = [];

                          row?.original?.parties.forEach(
                            ({ partyId, ...e }) => {
                              investors.push({
                                ...e,
                                partyId,
                                type: 'party',
                              });
                            }
                          );
                          if (row?.original?.entity) {
                            const {
                              entityId,
                              ...entity
                            } = row?.original?.entity;

                            investors.push({
                              ...entity,
                              partyId: entityId,
                              type: 'entity',
                            });
                          }

                          setReSendKycAml(true);
                          setChosenParty({ investors });
                          setChosenInvestor(row?.original);
                          setUpdatePartyModal(true);
                          setPartyStatus(false);
                        },
                      },
                      {
                        label: 'Re-send Payment Email',
                        onClick: () => {
                          setChosenInvestor(row?.original);
                          setReSendPayment(true);
                        },
                      },

                      {
                        label: 'Accreditation Upload Link',
                        onClick: () => {
                          setChosenInvestor(row?.original);
                          setGetAiLink(true);

                          setLinkModal(true);
                        },
                      },

                      {
                        label: 'KYC/AML Upload Link',
                        onClick: () => {
                          setChosenInvestor(row?.original);
                          setAmlLink(true);
                          setLinkModal(true);
                        },
                      },

                      {
                        label: 'Payment Link',
                        onClick: () => {
                          setChosenInvestor(row?.original);
                          setPaymentLink(true);
                          setLinkModal(true);
                        },
                      },

                      {
                        label: 'Issue Refund',
                        onClick: () => {
                          setChosenInvestor(row?.original);
                          setIssueRefundModal(true);
                        },
                      },
                      {
                        label: 'Mark Trade As Settled',
                        onClick: () => {
                          setChosenInvestor(row?.original);
                          setTradeStatusModal(true);
                        },
                      },
                    ]}
                  />
                ) : (
                  <DropdownMenu
                    isOneRow={row?.id === '0'}
                    options={[
                      {
                        label: 'Re-send KYC/AML Email',
                        onClick: () => {
                          let investors = [];

                          row?.original?.parties.forEach(
                            ({ partyId, ...e }) => {
                              investors.push({
                                ...e,
                                partyId,
                                type: 'party',
                              });
                            }
                          );
                          if (row?.original?.entity) {
                            const {
                              entityId,
                              ...entity
                            } = row?.original?.entity;

                            investors.push({
                              ...entity,
                              partyId: entityId,
                              type: 'entity',
                            });
                          }

                          setReSendKycAml(true);
                          setChosenParty({ investors });
                          setUpdatePartyModal(true);
                          setPartyStatus(false);
                          setChosenInvestor(row?.original);
                        },
                      },

                      {
                        label: 'Issue Refund',
                        onClick: () => {
                          setChosenInvestor(row?.original);
                          setIssueRefundModal(true);
                        },
                      },
                      {
                        label: 'Mark Trade As Settled',
                        onClick: () => {
                          setChosenInvestor(row?.original);
                          setTradeStatusModal(true);
                        },
                      },
                    ]}
                  />
                )
              ) : (
                <DropdownMenu
                  isOneRow={row?.id === '0'}
                  options={[
                    {
                      label: 'Re-send Accreditation Email',
                      onClick: () => {
                        setChosenInvestor(row?.original);
                        setDisapproveAccountModal(true);
                        setReject(false);
                        setApprove(false);
                        setReSendAcc(true);
                      },
                    },
                    {
                      label: 'Re-send KYC/AML Email',
                      onClick: () => {
                        let investors = [];

                        row?.original?.parties.forEach(({ partyId, ...e }) => {
                          investors.push({
                            ...e,
                            partyId,
                            type: 'party',
                          });
                        });
                        if (row?.original?.entity) {
                          const { entityId, ...entity } = row?.original?.entity;

                          investors.push({
                            ...entity,
                            partyId: entityId,
                            type: 'entity',
                          });
                        }

                        setReSendKycAml(true);
                        setChosenParty({ investors });
                        setUpdatePartyModal(true);
                        setPartyStatus(false);
                        setChosenInvestor(row?.original);
                      },
                    },
                    {
                      label: 'Re-send Payment Email',
                      onClick: () => {
                        setChosenInvestor(row?.original);
                        setReSendPayment(true);
                      },
                    },

                    {
                      label: 'Accreditation Upload Link',
                      onClick: () => {
                        setChosenInvestor(row?.original);
                        setGetAiLink(true);

                        setLinkModal(true);
                      },
                    },

                    {
                      label: 'KYC/AML Upload Link',
                      onClick: () => {
                        setChosenInvestor(row?.original);
                        setAmlLink(true);
                        setLinkModal(true);
                      },
                    },

                    {
                      label: 'Payment Link',
                      onClick: () => {
                        setChosenInvestor(row?.original);
                        setPaymentLink(true);
                        setLinkModal(true);
                      },
                    },
                  ]}
                />
              )}
            </div>
          );
        },

        /*
       
        */
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onModalClose = () => {
    setTradeStatusModal(false);
    setChosenInvestor({});
  };

  const renderRowSubComponent = React.useCallback(
    ({ row }) => <InvestmentDocumentSubRow row={row} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="px-6 py-3">
      <div
        style={{ marginLeft: '10px', display: 'flex', justifyContent: 'left' }}
      >
        <div>
          <ReactTooltip id="subRowButtonState" effect="solid">
            {subRowButtonState || !funded
              ? 'Showing Non Funded Accounts'
              : 'Showing Funded Accounts'}
          </ReactTooltip>
          <button
            className={styles.icon}
            onClick={() => {
              setSubRowButtonState(!subRowButtonState);
            }}
            data-tip
            data-for="subRowButtonState"
          >
            {subRowButtonState || !funded ? (
              <img src={noMoney} style={{ height: '30px' }} />
            ) : (
              <>
                <img src={money} style={{ height: '30px' }} />
              </>
            )}
          </button>
        </div>
      </div>
      <Table
        columns={useColumns}
        data={row?.original?.paidAccounts.filter(e => {
          if (!funded) {
            return e;
          }

          if (subRowButtonState) {
            return e;
          } else {
            return e.isPaid === true;
          }
        })}
        total={10}
        showSearch={false}
        withPagination={true}
        withPerPage={false}
        subRowComponentDocument={true}
        renderRowSubComponent={renderRowSubComponent}
      />

      {tradeStatusChangeModal ? (
        <TradeStatusChangeModal
          onModalClose={onModalClose}
          modal={tradeStatusChangeModal}
          chosenInvestor={chosenInvestor}
        />
      ) : null}

      {issueRefundModal && (
        <IssueRefundModal
          modal={issueRefundModal}
          chosenInvestor={chosenInvestor}
          onModalClose={() => setIssueRefundModal(false)}
        />
      )}

      {disapproveAccountModal && (
        <AccountDisapproveModal
          approve={approve}
          reject={reject}
          modal={disapproveAccountModal}
          chosenInvestor={chosenInvestor}
          reSendAcc={reSendAcc}
          onModalClose={() => {
            setDisapproveAccountModal(false);
            setReSendAcc(false);
          }}
        />
      )}
      {updatePartyModal && (
        <PartyUpdateModal
          reSendKycAml={reSendKycAml}
          partyStatus={partyStatus}
          modal={updatePartyModal}
          chosenParty={chosenParty}
          chosenInvestor={chosenInvestor}
          onModalClose={() => {
            setChosenInvestor({});
            setUpdatePartyModal(false);
            setReSendKycAml(false);
          }}
        />
      )}
      {reSendPayment && (
        <ReSendPaymentModal
          onModalClose={() => setReSendPayment(false)}
          modal={reSendPayment}
          chosenInvestor={chosenInvestor}
        />
      )}

      {linkModal && (
        <InvestmentLinkModal
          onModalClose={() => {
            setLinkModal(false);
            setChosenInvestor({});
            setAmlLink(false);
            setGetAiLink(false);
            setPaymentLink(false);
          }}
          getAiLink={getAiLink}
          getAmlLink={getAmlLink}
          getPaymentLink={getPaymentLink}
          modal={linkModal}
          chosenInvestor={chosenInvestor}
        />
      )}
    </div>
  );
};

export default InvestmentSubRow;
