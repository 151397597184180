import {
  getNews,
  getOneNews,
  deleteOneNews,
  updateNews,
  postOneNews,
  authNews,
} from '../api/news';

import { dispatchError } from 'helpers/dispatchError';
import { showSnackBar } from './snackbar';

// AUTHENTICATION
export const FETCH_AUTH_NEWS_INPROGRESS = 'FETCH_AUTH_NEWS_INPROGRESS';
export const FETCH_AUTH_NEWS_SUCCESS = 'FETCH_AUTH_NEWS_SUCCESS';
export const FETCH_AUTH_NEWS_FAILURE = 'FETCH_AUTH_NEWS_FAILURE';

// ALL NEWS
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
export const FETCH_NEWS_FAILURE = 'FETCH_NEWS_FAILURE';
export const FETCH_NEWS_INPROGRESS = 'FETCH_NEWS_INPROGRESS';

// ONE NEWS
export const FETCH_ONE_NEWS_SUCCESS = 'FETCH_ONE_NEWS_SUCCESS';
export const FETCH_ONE_NEWS_FAILURE = 'FETCH_ONE_NEWS_FAILURE';
export const FETCH_ONE_NEWS_INPROGRESS = 'FETCH_ONE_NEWS_INPROGRESS';

// CREATE
export const FETCH_CREATE_ONE_NEWS_SUCCESS = 'FETCH_CREATE_ONE_NEWS_SUCCESS';
export const FETCH_CREATE_ONE_NEWS_FAILURE = 'FETCH_CREATE_ONE_NEWS_FAILURE';
export const FETCH_CREATE_ONE_NEWS_INPROGRESS =
  'FETCH_CREATE_ONE_NEWS_INPROGRESS';

// DELETE
export const FETCH_DELETE_ONE_NEWS_SUCCESS = 'FETCH_DELETE_ONE_NEWS_SUCCESS';
export const FETCH_DELETE_ONE_NEWS_FAILURE = 'FETCH_DELETE_ONE_NEWS_FAILURE';
export const FETCH_DELETE_ONE_NEWS_INPROGRESS =
  'FETCH_DELETE_ONE_NEWS_INPROGRESS';

// UPDATE
export const FETCH_UPDATE_ONE_NEWS_SUCCESS = 'FETCH_UPDATE_ONE_NEWS_SUCCESS';
export const FETCH_UPDATE_ONE_NEWS_FAILURE = 'FETCH_UPDATE_ONE_NEWS_FAILURE';
export const FETCH_UPDATE_ONE_NEWS_INPROGRESS =
  'FETCH_UPDATE_ONE_NEWS_INPROGRESS';

export const fetchAuthentication = () => async dispatch => {
  dispatch({
    type: FETCH_AUTH_NEWS_INPROGRESS,
  });
  try {
    await authNews();
    dispatch({ type: FETCH_AUTH_NEWS_SUCCESS });
  } catch (err) {
    dispatch({ type: FETCH_AUTH_NEWS_FAILURE, err: err.response });
  }
};

// ALL NEWS
export const fetchNews = (amount_of_pages, page, status) => async dispatch => {
  dispatch({
    type: FETCH_NEWS_INPROGRESS,
  });

  try {
    const { data: news, headers } = await getNews(
      amount_of_pages,
      page,
      status
    );

    dispatch({
      type: FETCH_NEWS_SUCCESS,
      payload: {
        data: news,
        meta: headers,
      },
    });
  } catch (error) {
    // dispatchError(dispatch, FETCH_NEWS_FAILURE, error);
  }
};

// ONE NEWS
export const fetchOneNews = id => async dispatch => {
  dispatch({
    type: FETCH_ONE_NEWS_INPROGRESS,
  });
  try {
    const { data: news } = await getOneNews(id);

    dispatch({
      type: FETCH_ONE_NEWS_SUCCESS,
      payload: {
        news: news,
      },
    });
  } catch (error) {
    dispatchError(dispatch, FETCH_ONE_NEWS_FAILURE, error);
  }
};

// CREATE
export const fetchCreateOneNews = (
  title,
  HTML,
  featured_link
) => async dispatch => {
  dispatch({
    type: FETCH_CREATE_ONE_NEWS_INPROGRESS,
  });
  try {
    const data = await postOneNews(title, HTML, featured_link);
    dispatch({ type: FETCH_CREATE_ONE_NEWS_SUCCESS, payload: { news: data } });
    showSnackBar('News Published', 'success');
  } catch (error) {
    dispatchError(dispatch, FETCH_CREATE_ONE_NEWS_FAILURE, error);
  }
};

// DELETE
export const fetchDeleteOneNews = id => async dispatch => {
  dispatch({
    type: FETCH_DELETE_ONE_NEWS_INPROGRESS,
  });

  const data = await deleteOneNews(id);

  // ERROR
  if (!data.status) {
    dispatchError(dispatch, FETCH_DELETE_ONE_NEWS_FAILURE, data);
  } else {
    dispatch({
      type: FETCH_DELETE_ONE_NEWS_SUCCESS,
      payload: {
        deleted_news: data.data,
        invalidation_details: data.invalidation_details,
      },
    });
    showSnackBar('News Deleted', 'success');
  }
};

// UPDATE
export const fetchUpdateOneNews = (
  id,
  title,
  HTML,
  status,
  featured_link
) => async dispatch => {
  dispatch({
    type: FETCH_UPDATE_ONE_NEWS_INPROGRESS,
  });

  try {
    const { data: updated_news } = await updateNews(
      id,
      title,
      HTML,
      status,
      featured_link
    );

    dispatch({
      type: FETCH_UPDATE_ONE_NEWS_SUCCESS,
      payload: {
        updated_news: updated_news,
      },
    });
    showSnackBar('News Updated', 'success');
  } catch (error) {
    dispatchError(dispatch, FETCH_UPDATE_ONE_NEWS_FAILURE, error);
  }
};
