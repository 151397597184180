import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import { ChevronRight } from 'react-feather';
import styles from './myaccount.module.scss';
import Investments from './Investments';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvestmentsByUserId } from 'store/actions/profile';
import LoaderSpinner from 'components/LoadSpinner';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import ErrorMessage from 'components/ErrorMessage';
import { get } from 'lodash';
import { Bar, Line } from 'react-chartjs-2';
import Select from 'components/Select';
import moment from 'moment';

const chartOptions = [
  { label: 'Bar Chart', value: 1 },
  { label: 'Line Chart', value: 0 },
];

const MyAccountView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [chartNumber, setChartNumber] = useState(chartOptions[0]);

  const userId = useSelector(({ auth }) => get(auth, 'user.user._id', null));

  const isLoading = useSelector(({ profile }) => profile.investments.isLoading);

  const investmentsData = useSelector(
    ({ profile }) => profile.investments.data.data
  );

  const isError = useSelector(({ profile }) => profile.investments.isError);

  const error = useSelector(({ profile }) => profile.investments.error);

  const user = useSelector(({ auth }) => auth.user);

  useEffect(() => {
    dispatch(fetchInvestmentsByUserId(userId, 0, [], '', 8));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const totalValue =
    investmentsData &&
    investmentsData.reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.amount),
      0
    );

  const labelData =
    investmentsData &&
    investmentsData.map(data => moment(data.date).format('LL'));

  const investmentsChartData =
    investmentsData &&
    investmentsData.map(data => ({
      price: parseInt(data.amount),
      date: data.createdAt,
      payment: data.payment_method,
    }));

  const isMaxWidth1300px = useMedia('(max-width:1300px)');

  const dataChart = {
    labels: labelData,
    datasets: [
      {
        label: 'Payment Methods',
        backgroundColor: 'rgb(254,164,0)',
        borderColor: 'rgb(254,164,0)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgb(254,164,0, 0.5)',
        data: investmentsChartData,
      },
    ],
  };

  return (
    <div
      className={cs(styles.myAccount, {
        'flex items-center justify-center': isLoading && !isError,
        [styles.loading]: isLoading && !isError,
      })}
    >
      {isError ? (
        <ErrorMessage paragraph={error && error} />
      ) : isLoading ? (
        <LoaderSpinner
          color="#FEA400"
          width={200}
          height={200}
          strokeWidth={5}
        />
      ) : (
        <>
          <div className="w-full">
            <h2>
              Hi&nbsp;
              <span className="capitalize">
                {user && user.user && user.user.name}
              </span>
              , Welcome to Boxabl
            </h2>
            <div className={styles.myAccountValue}>
              <div className="w-full flex justify-between items-center">
                <p>Account Value</p>
              </div>

              <h1>
                $
                <NumberFormat
                  thousandSeparator={true}
                  value={totalValue && totalValue}
                  displayType={'text'}
                />
              </h1>
            </div>
          </div>

          <div
            className={cs(
              'flex w-full',
              {
                'flex-col': isMaxWidth1300px,
              },
              styles.myAccountChartContainer
            )}
          >
            <div className={cs('mr-4 flex flex-col', styles.myAccountChart)}>
              <h2 className="flex items-center justify-between">Overview</h2>

              <div
                className={cs(
                  'flex items-center justify-between',
                  styles.myAccountValueChart
                )}
              >
                <h1>Account Value</h1>

                <div className="flex items-center justify-end">
                  <div className={cs('flex items-center', styles.first)}>
                    <span className={cs(styles.chartCircle)} />
                    <p>Price / Payment Method</p>
                  </div>
                </div>
              </div>

              <div className={styles.dropdown}>
                <Select
                  options={chartOptions}
                  onChange={value => setChartNumber(value)}
                  value={chartNumber}
                />
              </div>

              <div className="flex-1">
                {chartNumber.value === 0 ? (
                  <Line
                    data={dataChart}
                    width={100}
                    options={{
                      parsing: {
                        xAxisKey: 'price',
                        yAxisKey: 'price',
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: items => {
                              const number = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              });

                              return `${number.format(items.raw.price)} (${
                                items.raw.payment === 'credit_card'
                                  ? 'Credit Card'
                                  : items.raw.payment.replace(/\b\w/g, m =>
                                      m.toUpperCase()
                                    )
                              })`;
                            },
                          },
                        },
                      },
                    }}
                  />
                ) : (
                  <Bar
                    data={dataChart}
                    width={100}
                    options={{
                      parsing: {
                        xAxisKey: 'price',
                        yAxisKey: 'price',
                      },
                      maintainAspectRatio: false,
                      responsive: true,
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: items => {
                              const number = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              });

                              return number.format(items.raw.price);
                            },
                          },
                        },
                      },
                    }}
                  />
                )}
              </div>
            </div>
            <div
              className={cs(
                'ml-4 overflow-hidden',
                styles.myAccountInvestments
              )}
            >
              <h3 className="flex items-start justify-between">
                My Investments
              </h3>

              <div
                className={cs(
                  'flex items-center justify-between',
                  styles.myAccountSeeAll
                )}
              >
                <p>Amount</p>
                <span onClick={() => history.push('/my-investments')}>
                  See All <ChevronRight className={styles.icon} />
                </span>
              </div>

              <table className={styles.myAccountInvestmentsTable}>
                <tbody>
                  {investmentsData &&
                    investmentsData.map(data => (
                      <Investments key={data._id} data={data} />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MyAccountView;
