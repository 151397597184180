import React from 'react';

import Modal from 'components/Modal';

import styles from './IssueRefundModal.module.scss';
import Button from 'components/Button';
import NumberFormat from 'react-number-format';
import { postIssueRefund } from 'store/api/investments';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Input from 'components/Input';
import { showSnackBar } from 'store/actions/snackbar';
const IssueRefundModal = ({ onModalClose, modal, chosenInvestor }) => {
  const [loadingRefund, setLoadingRefund] = React.useState(false);

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    defaultValues: {
      requestedBy: 'Boxabl Team',
      reason: 'Investor Requested Full Refund',
    },
    resolver: yupResolver(
      Yup.object({
        requestedBy: Yup.string().required(),
        reason: Yup.string().required(),
      })
    ),
  });

  const onSubmit = async value => {
    try {
      setLoadingRefund(true);
      const data = await postIssueRefund({
        accountId: chosenInvestor.accountId,
        tradeId: chosenInvestor.trade.tradeId,
        ...value,
      });
      if (data.err) {
        showSnackBar(JSON.stringify(data), 'error');
      }

      setLoadingRefund(false);
      onModalClose();
    } catch (err) {
      setLoadingRefund(false);
    }
  };

  return (
    <Modal isOpen={modal} onClick={onModalClose} width="500px">
      <div className={styles.container}>
        <div className={styles.container__tradeId}>
          TradeId: {chosenInvestor?.trade?.tradeId}
        </div>
        <div className={styles.container__title}>
          You are about to Refund to {chosenInvestor?.accountRegistration}
        </div>
        <div className={styles.container__amountTitle}>Amount</div>
        <div className={styles.container__amount}>
          $
          <NumberFormat
            value={chosenInvestor?.trade?.transactionAmount}
            displayType="text"
            thousandSeparator={true}
          />
        </div>

        <div className={styles.container__amountTitle}>Payment Type</div>
        <div className={styles.container__amount}>
          {chosenInvestor?.trade?.transactionType}
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            id="requestedBy"
            type="text"
            name="requestedBy"
            error={errors.requestedBy?.message}
            register={register}
            label={'Requested By'}
          />

          <Input
            id="reason"
            type="text"
            name="reason"
            error={errors.reason?.message}
            register={register}
            label={'Reason'}
          />

          <div className="w-full my-4 flex justify-between">
            <Button
              onClick={onModalClose}
              variant="transparent"
              label="Cancel"
            />
            <Button
              type="submit"
              isDisabled={loadingRefund}
              isLoading={loadingRefund}
              // isDisabled={isAllPartiesApproved && isAiVerified ? false : true}
              // onClick={markTradeAsSettled}
              label="confirm"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default IssueRefundModal;
