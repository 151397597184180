import React, { useEffect, useState } from 'react';
import { ChevronLeft } from 'react-feather';
import { useParams, useHistory } from 'react-router-dom';
import { deleteRoleById, fetchRoleDetail } from 'store/actions/roles';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import RolesForm from 'views/Roles/Roles.form';
import { showSnackBar } from 'store/actions/snackbar';
import { AlertPopupHandler } from 'components/AlertPopup';
import Details from 'components/Details';
import DropdownMenu from 'components/DropdownMenu';
import LoadingIndicator from 'components/LoadingIndicator';
import Card from 'components/Card';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';
import { get } from 'lodash';

const RoleDetails = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  let history = useHistory();

  const [modal, setModal] = useState(false);

  const isLoading = useSelector(({ roles }) => roles.rolesDetail.isInProgress);

  const roleData = useSelector(({ roles }) => roles.rolesDetail.data);

  useEffect(() => {
    dispatch(fetchRoleDetail(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onModalClose = () => {
    setModal(false);
  };

  const confirmDelete = async data => {
    if (data.no_of_users === 0) {
      await dispatch(deleteRoleById(data?._id.toString()));
      history.push('/roles');
    } else {
      showSnackBar('This role has active users', 'error');
    }
  };

  const handleRoleDelete = () => {
    AlertPopupHandler.open({
      type: 'error',
      text: `You are about to delete ${roleData.label} role. Do you want to continue?`,
      onConfirm: confirmDelete,
      data: roleData,
    });
  };

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'roles'
  );

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <div className="flex items-center justify-between">
              <div className="flex justify-between w-full">
                <Card.Header
                  title={
                    <>
                      <ChevronLeft
                        onClick={() => history.push(`/roles`)}
                        className="cursor-pointer mr-2"
                      />
                      Role Details
                    </>
                  }
                />
                <DropdownMenu
                  options={[
                    {
                      label: 'Edit Role',
                      onClick: () => setModal(true),
                    },
                    {
                      label: 'Delete Role',
                      onClick: handleRoleDelete,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3  gap-6 mt-4">
              <Details title="Role" data={roleData?.label || ''} />
              <Details
                title="Created"
                data={
                  roleData?.createdAt
                    ? moment(roleData.createdAt).format('LL')
                    : '-'
                }
              />
              <Details
                title="Last Updated"
                data={
                  roleData?.updatedAt
                    ? moment(roleData.updatedAt).format('LL')
                    : ''
                }
              />
            </div>
          </>
        )}
        {modal ? (
          <RolesForm
            data={roleData}
            viewMode="edit"
            onModalClose={onModalClose}
          />
        ) : null}
      </Card>
    </CanAccessComponent>
  );
};

export default RoleDetails;
