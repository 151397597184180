import { get } from 'lodash';
import { fetchDetail, fetchList } from '../api/investors';
import { showSnackBar } from './snackbar';

export const FETCH_INVESTORS_INPROGRESS = 'FETCH_INVESTORS_INPROGRESS';
export const FETCH_INVESTORS_SUCCESS = 'FETCH_INVESTORS_SUCCESS';
export const FETCH_INVESTORS_FAILURE = 'FETCH_INVESTORS_FAILURE';

export const FETCH_INVESTOR_INPROGRESS = 'FETCH_INVESTOR_INPROGRESS';
export const FETCH_INVESTOR_SUCCESS = 'FETCH_INVESTOR_SUCCESS';
export const FETCH_INVESTOR_FAILED = 'FETCH_INVESTOR_FAILED';

export const fetchInvestors = (page, sort, q, limit) => async dispatch => {
  dispatch({
    type: FETCH_INVESTORS_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await fetchList(page, sort, q, limit);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: FETCH_INVESTORS_SUCCESS,
        data: resp || {},
      });
    } else {
      dispatch({
        type: FETCH_INVESTORS_FAILURE,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_INVESTORS_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const fetchInvestorDetail = id => async dispatch => {
  dispatch({
    type: FETCH_INVESTOR_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await fetchDetail(id);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: FETCH_INVESTOR_SUCCESS,
        data: resp || {},
      });
    } else {
      dispatch({
        type: FETCH_INVESTOR_FAILED,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_INVESTOR_FAILED,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};
