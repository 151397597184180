import React, { useState } from 'react';
import Input from 'components/Input';
import Button from 'components/Button';
import styles from './profile.module.scss';
import cs from 'classnames';
import { Eye, EyeOff, Mail, Calendar, Edit2 } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { resetPasswordById } from 'store/actions/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import { useMedia } from 'react-use';
import { get } from 'lodash';

const ProfileView = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const userId = useSelector(state => get(state, 'auth.user.user._id', null));
  const user = useSelector(state => get(state, 'auth.user.user', null));

  const isMaxWidht900px = useMedia('(max-width: 900px)');

  const schema = Yup.object({
    password: Yup.string().required('Password is required'),
    new_password: Yup.string().required('New Password is required'),
    confirm_new_password: Yup.string()
      .oneOf([Yup.ref('new_password')], "Passwords don't match")
      .required('Confirm new password is required'),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async data => {
    setLoading(true);

    const { password, new_password, confirm_new_password } = data;

    const resp = await dispatch(
      resetPasswordById(userId, password, new_password, confirm_new_password)
    );

    if (resp.status === 1) {
      setValue('password', '');
      setValue('new_password', '');
      setValue('confirm_new_password', '');
    }

    setLoading(false);
  };

  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const toggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  return (
    <form className={styles.profile} onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col flex-1">
        <div className={styles.editTitle}>
          <h1 className="flex items-center text-center text-2xl text-brandgrey">
            Edit Profile
          </h1>
        </div>

        <div
          className={cs('flex items-start flex-1', {
            'flex-col flex-col-reverse': isMaxWidht900px,
          })}
        >
          <div className={styles.profileSettings}>
            <div className={styles.editContent}>
              <h1>EDIT PASSWORD</h1>
              <Input
                id="password"
                name="password"
                wrapperClassName="mt-6"
                placeholder="Current Password"
                label="Current Password"
                type={showCurrentPassword ? 'text' : 'password'}
                error={errors.password?.message}
                rightIcon={
                  showCurrentPassword ? (
                    <Eye
                      className={cs('cursor-pointer', styles.inputColor)}
                      onClick={toggleCurrentPassword}
                    />
                  ) : (
                    <EyeOff
                      className={cs('cursor-pointer', styles.inputColor)}
                      onClick={toggleCurrentPassword}
                    />
                  )
                }
                register={register}
              />
              <Input
                id="new_password"
                name="new_password"
                wrapperClassName="mt-6"
                placeholder="New Password"
                label="New Password"
                type={showNewPassword ? 'text' : 'password'}
                error={errors.new_password?.message}
                rightIcon={
                  showNewPassword ? (
                    <Eye
                      className={cs('cursor-pointer', styles.inputColor)}
                      onClick={toggleNewPassword}
                    />
                  ) : (
                    <EyeOff
                      className={cs('cursor-pointer', styles.inputColor)}
                      onClick={toggleNewPassword}
                    />
                  )
                }
                register={register}
              />
              <Input
                id="confirm_new_password"
                name="confirm_new_password"
                wrapperClassName="mt-6"
                placeholder="Confirm New Password"
                label="Confirm New Password"
                type={showConfirmPassword ? 'text' : 'password'}
                error={errors.confirm_new_password?.message}
                rightIcon={
                  showConfirmPassword ? (
                    <Eye
                      className={cs('cursor-pointer', styles.inputColor)}
                      onClick={toggleConfirmPassword}
                    />
                  ) : (
                    <EyeOff
                      className={cs('cursor-pointer', styles.inputColor)}
                      onClick={toggleConfirmPassword}
                    />
                  )
                }
                register={register}
              />
            </div>
          </div>
          <div className={styles.myProfile}>
            <div className={styles.profileContainer}>
              <div className={styles.avatar}>
                {(user && user.name && (
                  <span className="capitalize">
                    {user.name.charAt(0)}
                    {user.name.charAt(1)}
                  </span>
                )) ||
                  'BX'}
              </div>
              <h1>{user ? user.name : 'Boxabl User'}</h1>
              <div className="flex flex-col items-center">
                <p className="flex items-center">
                  <Mail className={styles.svg} />
                  {(user && user.email && user.email) || 'N/A'}
                </p>
                <p className="flex items-center">
                  <Calendar className={styles.svg} /> Joined Since
                  {(user &&
                    user.createdAt &&
                    moment(user.createdAt).format('MMM Do, YYYY')) ||
                    'No Date'}
                </p>
                <p className="flex items-center">
                  <Edit2 className={styles.svg} /> Last Updated
                  {(user &&
                    user.updatedAt &&
                    moment(user.updatedAt).format('MMM Do, YYYY')) ||
                    'No Date'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.button}>
        <Button
          className={cs('ml-2', styles.btn)}
          type="submit"
          label={loading ? 'Loading...' : 'Save'}
        />
      </div>
    </form>
  );
};

export default ProfileView;
