import React from 'react';
import styles from './errormessage.module.scss';
import { AlertTriangle } from 'react-feather';

const ErrorMessage = ({ paragraph }) => {
  return (
    <div className={styles.error}>
      <AlertTriangle size={75} className={styles.icon} />
      <h1>Sorry!</h1>
      <p>{paragraph}</p>
    </div>
  );
};

export default ErrorMessage;
