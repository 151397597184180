import React from 'react';
import PropTypes from 'prop-types';
import styles from './modal.module.scss';
import { X } from 'react-feather';
import cs from 'classnames';

const Modal = ({
  isOpen,
  width,
  children,
  title,
  hasCloseIcon = true,
  handleCloseClick,
  notHidden,
  isModalOutsideClose,
  containerClassname,
  wrapperClassName,
  ...rest
}) => {
  const handleOutsideClick = () => {
    if (isModalOutsideClose) handleCloseClick();
  };

  const handleClickInside = event => {
    event.stopPropagation();
  };

  const handleModalCloseClick = () => {
    handleCloseClick();
  };

  return (
    <div onClick={handleOutsideClick}>
      {isOpen && (
        <div className={styles.overlay} {...rest}>
          <div
            className={cs(styles.modalContainer, containerClassname)}
            style={{ width: width }}
            onClick={handleClickInside}
          >
            <div
              className={cs(styles.modal, {
                [styles.notHidden]: notHidden,
              })}
            >
              {title || hasCloseIcon ? (
                <div className={styles.modalHeader}>
                  {title ? <p className={styles.paragraph}>{title}</p> : null}
                  {hasCloseIcon ? (
                    <X
                      size={19}
                      className={styles.icon}
                      onClick={handleModalCloseClick}
                    />
                  ) : null}
                </div>
              ) : null}
              <div className={cs(styles.modalContent, wrapperClassName)}>
                {children}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Modal.propTypes = {
  /** modal is open or not */
  isOpen: PropTypes.bool.isRequired,
  /** Width of the modal, specify number with it's type. */
  width: PropTypes.string,
  /** element rendered inside the modal.*/
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string,
  ]),
  /** element rendered inside the modal.*/
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  /** Display close button or not in top right side */
  hasCloseIcon: PropTypes.bool,
  /** Outside modal click close or not */
  isModalOutsideClose: PropTypes.bool,
  /** Handel outside modal click to close modal */
  handleCloseClick: PropTypes.func,
};

Modal.defaultProps = {
  width: '50%',
  hasCloseIcon: false,
  isModalOutsideClose: false,
};

export default Modal;
