import { get } from 'lodash';
import { fetchAllInvestmentsByUser } from 'store/api/profile';

export const FETCH_USERS_INVESTMENTS = 'FETCH_USERS_INVESTMENTS';
export const FETCHING_INVESTMENTS_INPROGRESS =
  'FETCHING_INVESTMENTS_INPROGRESS';
export const FETCH_USERS_INVESTMENTS_FAILED = 'FETCH_USERS_INVESTMENTS_FAILED';

export const fetchInvestmentsByUserId = (
  id,
  page,
  sort,
  q,
  limit
) => async dispatch => {
  dispatch({
    type: FETCHING_INVESTMENTS_INPROGRESS,
  });

  const resp = await fetchAllInvestmentsByUser(id, page, sort, q, limit);
  const status = get(resp, 'status');
  const message = get(resp, 'message');

  try {
    if (status === 1) {
      dispatch({
        type: FETCH_USERS_INVESTMENTS,
        data: resp.data,
      });
    } else {
      dispatch({
        type: FETCH_USERS_INVESTMENTS_FAILED,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_USERS_INVESTMENTS_FAILED,
      error: 'Something went wrong! Please try again later',
    });
  }
};
