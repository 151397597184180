import React from 'react';
import { AlertCircle, XCircle, CheckCircle } from 'react-feather';
import Button from 'components/Button';

class AlertPopup extends React.Component {
  state = {
    show: false,
    options: null,
    data: null,
    ChildTag: null,
  };

  isShowing = () => {
    return this.state.show;
  };

  // using data here so that when calling delete api we can pass it from here as argument
  // To open regular confirm modal which we have on delete
  show = ({ data, ...restOptions }) => {
    this.setState({ show: true, options: restOptions, data });
  };

  handleConfirmClick = () => {
    this.state.options.onConfirm(this.state.data);
    this.resetState();
  };

  handelCancelClick = () => {
    this.state.options.onCancel && this.state.options.onCancel(this.state.data);
    this.resetState();
  };

  resetState = () => {
    this.setState({
      show: false,
      showCustom: false,
      options: null,
      data: null,
    });
  };

  renderIcon = () => {
    const { type } = this.state.options;
    let icon = null;
    switch (type) {
      case 'success': {
        icon = <CheckCircle size={72} color="#00ff11" />;
        break;
      }
      case 'warning': {
        icon = <AlertCircle size={72} color="#ffbb00" />;
        break;
      }
      case 'error': {
        icon = <XCircle size={72} color="#ff3300" />;
        break;
      }
      default:
        icon = <AlertCircle size={72} color="#ffbb00" />;
    }

    return (
      <div className="my-4 flex items-center justify-center w-full">{icon}</div>
    );
  };

  render() {
    if (!this.state.show) {
      return null;
    }
    const { text } = this.state.options;
    if (this.state.show) {
      return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex-col items-center justify-center">
                  {this.renderIcon()}
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg text-center leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Are you sure?
                    </h3>
                    <div className="mt-2">
                      <p className="text-lg text-center text-gray-500">
                        {text}
                      </p>
                    </div>
                  </div>
                  <div className="mt-3 px-4 py-3 sm:px-6 sm:flex justify-center">
                    <Button
                      onClick={this.handleConfirmClick}
                      className="mr-3"
                      label="Confirm"
                    />
                    <Button
                      variant="transparent"
                      onClick={this.handelCancelClick}
                      label="Cancel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default AlertPopup;
