import { get } from 'lodash';
import {
  getDocuments,
  fetchDocumentsData,
  downloadDocument,
  verifyDocument,
  addDocumentActivity,
  fetchAllDocumentData,
  getDocumentsByUserId,
  reSendEmailDocumentsUpload,
  bulkReSendEmailDocumentsUpload,
} from '../api/documents';
import { showSnackBar } from './snackbar';
export const FETCH_DOCUMENTS_INPROGRESS = 'FETCH_DOCUMENTS_INPROGRESS';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE';

export const FETCH_DOCUMENTS_BY_USER_INPROGRESS =
  'FETCH_DOCUMENTS_BY_USER_INPROGRESS';
export const FETCH_DOCUMENTS_BY_USER_SUCCESS =
  'FETCH_DOCUMENTS_BY_USER_SUCCESS';
export const FETCH_DOCUMENTS_BY_USER_FAILURE =
  'FETCH_DOCUMENTS_BY_USER_FAILURE';

export const VERIFY_DOCUMENT_INPROGRESS = 'VERIFY_DOCUMENT_INPROGRESS';
export const VERIFY_DOCUMENT_SUCCESS = 'VERIFY_DOCUMENT_SUCCESS';
export const VERIFY_DOCUMENT_FAILURE = 'VERIFY_DOCUMENT_FAILURE';

export const FETCH_DOCUMENT_DETAILS_INPROGRESS =
  'FETCH_DOCUMENT_DETAILS_INPROGRESS';
export const FETCH_DOCUMENT_DETAILS_SUCCESS = 'FETCH_DOCUMENT_DETAILS_SUCCESS';
export const FETCH_DOCUMENT_DETAILS_FAILURE = 'FETCH_DOCUMENT_DETAILS_FAILURE';

export const DOWNLOAD_DOCUMENT_PREIVEW_INPROGRESS =
  'DOWNLOAD_DOCUMENT_PREIVEW_INPROGRESS';
export const DOWNLOAD_DOCUMENT_PREIVEW_SUCCESS =
  'DOWNLOAD_DOCUMENT_PREIVEW_SUCCESS';
export const DOWNLOAD_DOCUMENT_PREIVEW_FAILURE =
  'DOWNLOAD_DOCUMENT_PREIVEW_FAILURE';

export const ADD_DOCUMENT_ACTIVITY_INPROGRESS =
  'ADD_DOCUMENT_ACTIVITY_INPROGRESS';
export const ADD_DOCUMENT_ACTIVITY_SUCCESS = 'ADD_DOCUMENT_ACTIVITY_SUCCESS';
export const ADD_DOCUMENT_ACTIVITY_FAILED = 'ADD_DOCUMENT_ACTIVITY_FAILED';

export const EXPORT_DOCUMENT_CSV_INPROGRESS = 'EXPORT_DOCUMENT_CSV_INPROGRESS';
export const EXPORT_DOCUMENT_CSV_SUCCESS = 'EXPORT_DOCUMENT_CSV_SUCCESS';
export const EXPORT_DOCUMENT_CSV_FAILED = 'EXPORT_DOCUMENT_CSV_FAILED';

export const RESEND_DOCUMENTS_UPLOAD_EMAIL_INPROGRESS =
  'RESEND_DOCUMENTS_UPLOAD_EMAIL_INPROGRESS';
export const RESEND_DOCUMENTS_UPLOAD_EMAIL_SUCCESS =
  'RESEND_DOCUMENTS_UPLOAD_EMAIL_SUCCESS';
export const RESEND_DOCUMENTS_UPLOAD_EMAIL_FAILURE =
  'RESEND_DOCUMENTS_UPLOAD_EMAIL_FAILURE';

export const BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_INPROGRESS =
  'BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_INPROGRESS';
export const BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_SUCCESS =
  'BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_SUCCESS';
export const BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_FAILURE =
  'BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_FAILURE';

export const fetchDocuments = (
  page,
  sort,
  q,
  limit,
  startDate,
  endDate,
  type
) => async dispatch => {
  dispatch({
    type: FETCH_DOCUMENTS_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await getDocuments(
      page,
      sort,
      q,
      limit,
      startDate,
      endDate,
      type
    );
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    const data = get(resp, 'data', []);

    if (status) {
      dispatch({
        type: FETCH_DOCUMENTS_SUCCESS,
        data: data,
      });
    } else {
      dispatch({
        type: FETCH_DOCUMENTS_FAILURE,
        message: message,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_DOCUMENTS_FAILURE,
      message: error,
    });
  }
};

export const fetchDocumentsByUserId = (userId, aml) => async dispatch => {
  dispatch({
    type: FETCH_DOCUMENTS_BY_USER_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await getDocumentsByUserId(userId, aml);
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: FETCH_DOCUMENTS_BY_USER_SUCCESS,
      });
    } else {
      dispatch({
        type: FETCH_DOCUMENTS_BY_USER_FAILURE,
        message: message,
      });
    }
    return resp;
  } catch (error) {
    dispatch({
      type: FETCH_DOCUMENTS_BY_USER_FAILURE,
      message: error,
    });
  }
};

export const fetchDocumentsDetail = (id, type) => async dispatch => {
  dispatch({
    type: FETCH_DOCUMENT_DETAILS_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await fetchDocumentsData(id, type);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: FETCH_DOCUMENT_DETAILS_SUCCESS,
        data: resp || {},
      });
    } else {
      dispatch({
        type: FETCH_DOCUMENT_DETAILS_FAILURE,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_DOCUMENT_DETAILS_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const downloadDocumentById = (documentId, type) => async dispatch => {
  dispatch({
    type: DOWNLOAD_DOCUMENT_PREIVEW_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await downloadDocument(documentId, type);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: DOWNLOAD_DOCUMENT_PREIVEW_SUCCESS,
        data: resp || {},
      });
    } else {
      dispatch({
        type: DOWNLOAD_DOCUMENT_PREIVEW_FAILURE,
        error: message,
      });
    }
    return resp;
  } catch (error) {
    dispatch({
      type: DOWNLOAD_DOCUMENT_PREIVEW_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const verifyDocumentById = (documentId, data) => async dispatch => {
  dispatch({
    type: VERIFY_DOCUMENT_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await verifyDocument(documentId, data);
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: VERIFY_DOCUMENT_SUCCESS,
        data: resp || {},
      });
    } else {
      dispatch({
        type: VERIFY_DOCUMENT_FAILURE,
        error: message,
      });

      showSnackBar(message, 'error');
    }
    return resp;
  } catch (error) {
    dispatch({
      type: VERIFY_DOCUMENT_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const addDocumentActivityById = (
  id,
  activity,
  type
) => async dispatch => {
  dispatch({
    type: ADD_DOCUMENT_ACTIVITY_INPROGRESS,
  });
  try {
    const resp = await addDocumentActivity(id, activity, type);
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: ADD_DOCUMENT_ACTIVITY_SUCCESS,
        data: resp,
      });

      showSnackBar(message, 'success');
    } else {
      dispatch({
        type: ADD_DOCUMENT_ACTIVITY_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: ADD_DOCUMENT_ACTIVITY_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const exportDocumentToCSV = () => async dispatch => {
  dispatch({
    type: EXPORT_DOCUMENT_CSV_INPROGRESS,
  });
  try {
    const resp = await fetchAllDocumentData();
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: EXPORT_DOCUMENT_CSV_SUCCESS,
      });
    } else {
      dispatch({
        type: EXPORT_DOCUMENT_CSV_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
    return resp;
  } catch (error) {
    dispatch({
      type: EXPORT_DOCUMENT_CSV_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const reSendDocumentUpload = (
  documentId,
  emailMessage,
  type
) => async dispatch => {
  dispatch({
    type: RESEND_DOCUMENTS_UPLOAD_EMAIL_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await reSendEmailDocumentsUpload(
      documentId,
      emailMessage,
      type
    );
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: RESEND_DOCUMENTS_UPLOAD_EMAIL_SUCCESS,
      });
      showSnackBar(message, 'success');
    } else {
      dispatch({
        type: RESEND_DOCUMENTS_UPLOAD_EMAIL_FAILURE,
        error: message,
      });
      showSnackBar(message, 'error');
    }
    return resp;
  } catch (error) {
    dispatch({
      type: RESEND_DOCUMENTS_UPLOAD_EMAIL_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const bulkReSendDocumentUpload = (
  documentIds,
  emailMessage
) => async dispatch => {
  dispatch({
    type: BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await bulkReSendEmailDocumentsUpload(
      documentIds,
      emailMessage
    );
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_SUCCESS,
      });
      showSnackBar(message, 'success');
    } else {
      dispatch({
        type: BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_FAILURE,
        error: message,
      });
      showSnackBar(message, 'error');
    }
    return resp;
  } catch (error) {
    dispatch({
      type: BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_FAILURE,
      error: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};
