import Request from './request';
export const getNoPaymentMethod = async (page, per_page, sort, query) =>
  await Request.call({
    url: '/noPaymentMethod',
    method: 'GET',
    params: {
      page,
      per_page,
      sort,
      query,
    },
  });
