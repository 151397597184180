import React, { useMemo, useState } from 'react';
import Button from 'components/Button';
import Table from 'components/Table';
import { get } from 'lodash';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletePermissionById,
  fetchAllPermissions,
} from 'store/actions/permissions';
import PermissionsForm from 'views/Permissions/Permission.form';
import DropdownMenu from 'components/DropdownMenu';
import { AlertPopupHandler } from 'components/AlertPopup';
import Card from 'components/Card';
import cs from 'classnames';
import { useMedia } from 'react-use';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';
import { fetchAvailablePermissions } from 'store/actions/roles';

const Permissions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMaxWidth640px = useMedia('(max-width: 640px)');
  const [modal, setModal] = useState(false);
  const [data, setData] = useState({
    label: '',
    value: '',
  });
  const [viewMode, setViewMode] = useState(null);

  const permissions = useSelector(({ permissions }) =>
    get(permissions, 'permissions.data.data', [])
  );

  const permissionsMetadata = useSelector(({ permissions }) =>
    get(permissions, 'permissions.data.meta', {})
  );

  const handleTableChange = async ({ pageIndex, sortBy, pageSize, query }) => {
    let sort = '';
    if (sortBy.length) {
      sort = sortBy[0].desc ? `-${sortBy[0].id}` : sortBy[0].id;
    }
    await dispatch(fetchAllPermissions(pageIndex + 1, sort, query, pageSize));
  };

  const confirmDelete = async data => {
    await dispatch(deletePermissionById(data?._id.toString()));
    dispatch(fetchAllPermissions());
    dispatch(fetchAvailablePermissions());
  };

  const handlePermissionDelete = data => () => {
    AlertPopupHandler.open({
      type: 'error',
      text: `You are about to delete ${data.label} permission. Do you want to continue?`,
      onConfirm: confirmDelete,
      data,
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: () => 'Name',
        accessor: 'page_name',
        Cell: ({ value, row }) =>
          value ? (
            <div className="flex items-start">
              <Link to={`/permissions/${row.original._id}`}>{value}</Link>
            </div>
          ) : null,
      },
      {
        Header: 'Total Permissions',
        accessor: 'permissions',
        Cell: ({ value }) =>
          value ? (
            <div className="flex">{(value && value.length) || 0}</div>
          ) : null,
        maxWidth: 50,
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        Cell: ({ value }) =>
          value ? moment(value).format('MMM Do, YYYY') : null,
      },
      {
        Header: () => (
          <div className="flex justify-end w-full">Last Updated</div>
        ),
        accessor: 'updatedAt',
        Cell: ({ value, row }) => (
          <div className="flex justify-end">
            <span className="flex items-center mr-4">
              {value ? moment(value).format('MMM Do, YYYY') : null}
            </span>
            <DropdownMenu
              options={[
                {
                  label: 'View Permission',
                  onClick: () =>
                    history.push(`/permissions/${row.original._id}`),
                },
                {
                  label: 'Edit Permission',
                  onClick: () => {
                    setViewMode('edit');
                    setData({ ...row.original });
                    setModal(true);
                  },
                },
                {
                  label: 'Delete Permission',
                  onClick: handlePermissionDelete(row.original),
                },
              ]}
            />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onModalClose = () => {
    setModal(false);
    setViewMode(null);
    setData({
      label: '',
      value: '',
    });
  };

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'permissions'
  );

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        <div
          className={cs('flex justify-between sm:items-center mb-2', {
            'flex-col': isMaxWidth640px,
          })}
        >
          <Card.Header
            title={`Permissions ${
              permissionsMetadata && permissionsMetadata.totalResults
                ? `(${permissionsMetadata.totalResults})`
                : ''
            }`}
          />
          <Button
            className="mt-2 sm:mt-0"
            onClick={() => setModal(!modal)}
            label="Add Permission"
          />
        </div>
        <Card.Content className="h-full flex">
          <Table
            columns={columns}
            data={permissions || []}
            total={permissionsMetadata.totalPages}
            fetchData={handleTableChange}
          />
          {modal ? (
            <PermissionsForm
              data={data}
              viewMode={viewMode}
              onModalClose={onModalClose}
            />
          ) : null}
        </Card.Content>
      </Card>
    </CanAccessComponent>
  );
};

export default Permissions;
