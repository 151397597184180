import Request from './request';

export const fetchLeadsList = async (page, sort, query, limit) =>
  await Request.call({
    url: '/leads',
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
      limit,
    },
  });

export const fetchLeadData = async id =>
  await Request.call({
    url: `/leads/${id}`,
    method: 'GET',
  });

export const rejectLead = async id =>
  await Request.call({
    url: `/leads/${id}`,
    method: 'DELETE',
  });

export const fetchAllLeadsData = async () =>
  await Request.call({
    url: `/leads/all`,
    method: 'GET',
  });

export const addLeadActivity = async (id, data) =>
  await Request.call({
    url: `/leads/${id}/activity`,
    method: 'POST',
    data,
  });
