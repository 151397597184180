import * as actions from '../actions/NoPaymentMethod';

const initialState = {
  noPaymentMethodInvestors: {
    isInProgress: false,
    isError: false,
    message: '',
    data: {},
  },
};

const noPaymentMethodReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_NO_PAYMENTS_INVESTORS_INPROGRESS:
      return {
        ...state,
        noPaymentMethodInvestors: {
          ...state.noPaymentMethodInvestors,
          isInProgress: true,
        },
      };

    case actions.GET_NO_PAYMENTS_INVESTORS_SUCCESS:
      return {
        ...state,
        noPaymentMethodInvestors: {
          ...state.noPaymentMethodInvestors,
          isInProgress: false,
          isError: false,
          data: action.data,
        },
      };

    case actions.GET_NO_PAYMENTS_INVESTORS_FAILURE:
      return {
        ...state,
        noPaymentMethodInvestors: {
          ...state.noPaymentMethodInvestors,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    default:
      return state;
  }
};

export default noPaymentMethodReducer;
