import { useParams, useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOfferingDetail } from 'store/actions/offerings';
import get from 'lodash/get';
import Details from 'components/Details';
import moment from 'moment';
import { ChevronLeft } from 'react-feather';
import LoadingIndicator from 'components/LoadingIndicator';
import Card from 'components/Card';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';

const OfferingDetails = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  let history = useHistory();

  const offering = useSelector(({ offerings }) =>
    get(offerings, 'details.data.data', {})
  );
  const isLoading = useSelector(({ offerings }) =>
    get(offerings, 'details.isInProgress', true)
  );

  useEffect(() => {
    dispatch(fetchOfferingDetail(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'offerings'
  );

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <div className="flex items-center justify-between">
              <Card.Header
                title={
                  <>
                    <ChevronLeft
                      onClick={() => history.push(`/offerings`)}
                      className="cursor-pointer mr-2"
                    />
                    Offering Details
                  </>
                }
              />
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3  gap-6 mt-4">
              <Details title="Name" data={offering?.name || '-'} />
              <Details title="Description" data={offering?.description || ''} />
              <Details title="Type" data={offering?.type || '-'} />
              <Details title="Slug" data={offering?.slug || '-'} />
              <Details
                title="No. Of Offerings"
                data={
                  offering?.offerings ? offering?.offerings.length : '0' || '-'
                }
              />
              <Details
                title="Created On"
                data={
                  offering?.createdAt
                    ? moment(offering.createdAt).format('MMM Do, YYYY')
                    : '-'
                }
              />
              <Details
                title="Review By"
                data={
                  offering?.reviewBy
                    ? offering.reviewBy === 'NorthCapital'
                      ? 'North Capital'
                      : 'Boxabl'
                    : '-'
                }
              />
            </div>
          </>
        )}
      </Card>
    </CanAccessComponent>
  );
};
export default OfferingDetails;
