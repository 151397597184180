import React, { useState } from 'react';
import cs from 'classnames';
import Input from 'components/Input';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Toggle from 'components/Toggle';
import { Plus, Minus } from 'react-feather';
import { addOfferings, updateOfferingById } from 'store/actions/offerings';
import { useDispatch } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Select from 'components/Select';

const OfferingForm = ({ data, viewMode, onModalClose }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const {
    formState: { errors },
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    setError,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: data?.name || '',
      terms: data?.terms || '',
      description: data?.description || '',
      issueName: data?.issueName || '',
      type: data?.type || 'reg_d',
      is_deleted: data?.is_deleted || false,
      has_equity_shares: data?.has_equity_shares || false,
      show_questionnaire: data?.show_questionnaire || false,
      payment_enabled: data?.payment_enabled || false,
      offerings:
        data?.offerings && data?.offerings.length
          ? data?.offerings
          : [
              {
                offeringId: '',
                offeringStatus: true,
                offeringName: '',
                offeringText: data?.type || 'reg_d',
                is_deleted: false,
                min_value: '',
                unitPrice: null,
                is_default: false,
              },
            ],
      reviewBy: data?.reviewBy || 'Boxabl',
    },
    resolver: yupResolver(
      Yup.object({
        name: Yup.string().required('Name is Required'),
        terms: Yup.string().required('Terms is required'),
        description: Yup.string().required('description is Required'),
        issueName: Yup.string().required('issueName is Required'),
        type: Yup.string().required('Type is Required'),
        has_equity_shares: Yup.boolean().required(
          'Has equity share is Required'
        ),
        show_questionnaire: Yup.boolean().required(
          'Show Questionnaire is Required'
        ),
        payment_enabled: Yup.boolean().required('Payment enabled is Required'),
        offerings: Yup.array().of(
          Yup.object().shape({
            offeringName: Yup.string().required('Issue Name is required'),
            offeringText: Yup.string().required('Offering Text is required'),
            offeringId: Yup.string().required('Offering ID is required'),
            offeringStatus: Yup.boolean(),
            is_deleted: Yup.boolean(),
            min_value: Yup.string().required('Amount is required'),
            unitPrice: Yup.string().nullable(),
            is_default: Yup.boolean(),
          })
        ),
        reviewBy: Yup.string().required('Review By is Required'),
      })
    ),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'offerings',
  });

  const watchFieldArray = watch('offerings');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const createOffering = async values => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (!viewMode) {
      await dispatch(addOfferings(values));
    } else {
      const offeringsData = values.offerings.map(
        // eslint-disable-next-line no-unused-vars
        ({ _id, _v, ...rest }) => rest
      );
      await dispatch(
        updateOfferingById(data?._id.toString(), {
          ...values,
          offerings: offeringsData,
        })
      );
    }
    // dispatch(fetchOfferings());
    setLoading(false);
    onModalClose(true);
  };

  return (
    <Modal
      isOpen
      containerClassname="max-w-screen-lg"
      onClick={onModalClose}
      title={viewMode ? 'Edit Offering' : 'Add Offering'}
    >
      <div
        className={cs('flex w-full flex-col ', {
          'items-center justify-center': loading,
          'items-start': !loading,
        })}
      >
        <form className="w-full">
          <Input
            id="name"
            name="name"
            wrapperClassName="w-full my-4"
            placeholder="Name"
            label="Name"
            error={errors.name?.message}
            register={register}
            onChange={e => {
              setValue('name', e.target.value);
            }}
          />
          <Input
            id="description"
            name="description"
            label="Description"
            wrapperClassName="w-full my-4"
            placeholder="Description"
            error={errors.description?.message}
            register={register}
            onChange={e => {
              setValue('description', e.target.value);
            }}
          />

          <Input
            id="terms"
            name="terms"
            label="Terms"
            wrapperClassName="w-full my-4"
            placeholder="Terms"
            error={errors.terms?.message}
            register={register}
            onChange={e => {
              setValue('terms', e.target.value);
            }}
          />

          <Input
            id="issueName"
            name="issueName"
            label="Issue Name"
            wrapperClassName="w-full my-4"
            placeholder="Issue Name"
            error={errors.issueName?.message}
            register={register}
            onChange={e => {
              setValue('issueName', e.target.value);
            }}
          />
          <Select
            wrapperClassName="w-full my-4"
            label="Type"
            id="type"
            name="type"
            control={control}
            error={errors?.type ? errors.type.message : null}
            options={[
              { label: 'Reg A', value: 'reg_a' },
              { label: 'Reg D', value: 'reg_d' },
            ]}
            setError={setError}
            onChange={value => {
              setValue('type', value.value);
            }}
            value={
              watch('type') === 'reg_a'
                ? { label: 'Reg A', value: 'reg_a' }
                : { label: 'Reg D', value: 'reg_d' }
            }
          />
          <Select
            wrapperClassName="w-full my-4"
            label="Review By"
            id="type"
            name="reviewBy"
            control={control}
            error={errors?.reviewBy ? errors.reviewBy.message : null}
            options={[
              { label: 'North Capital', value: 'NorthCapital' },
              { label: 'Boxabl', value: 'Boxabl' },
            ]}
            setError={setError}
            onChange={value => {
              setValue('reviewBy', value.value);
            }}
            value={
              watch('reviewBy') === 'NorthCapital'
                ? { label: 'North Capital', value: 'NorthCapital' }
                : { label: 'Boxabl', value: 'Boxabl' }
            }
          />
          <div className="flex justify-between my-4">
            <Toggle
              id="has_equity_shares"
              name="has_equity_shares"
              className="flex-col-reverse w-1/2"
              wrapperClassName="mb-3"
              label="Equity Shares?"
              labelClassName="mb-2"
              register={register}
              checked={watch('has_equity_shares')}
              onChange={e => {
                setValue(`has_equity_shares`, e.target.checked);
              }}
            />
            <Toggle
              id="show_questionnaire"
              name="show_questionnaire"
              className="flex-col-reverse w-1/2"
              wrapperClassName="mb-3"
              label="Should show additional questionnaire page?"
              labelClassName="mb-2"
              register={register}
              checked={watch('show_questionnaire')}
              onChange={e => {
                setValue(`show_questionnaire`, e.target.checked);
              }}
            />
          </div>
          <div className="flex my-4 w-full">
            <Toggle
              id="payment_enabled"
              name="payment_enabled"
              className="flex-col-reverse w-1/2"
              wrapperClassName="mb-3"
              label="Is Payment Enabled?"
              labelClassName="mb-2"
              register={register}
              checked={watch('payment_enabled')}
              onChange={e => {
                setValue(`payment_enabled`, e.target.checked);
              }}
            />
          </div>
          {/*Multiples Offer*/}
          {controlledFields.map((field, index) => {
            return (
              <div
                className="border border-solid rounded-md p-3 mb-3"
                key={index}
              >
                <div className="grid grid-cols-2 gap-4 my-4">
                  <Input
                    id="offeringName"
                    name={`offerings.${index}.offeringName`}
                    label="Issue Name"
                    type="text"
                    wrapperClassName="mr-4 mb-3"
                    placeholder="Issue Name"
                    error={
                      errors.offerings &&
                      errors.offerings[index] &&
                      errors.offerings[index].offeringName?.message
                        ? errors.offerings[index].offeringName?.message
                        : ''
                    }
                    register={register}
                    onChange={e => {
                      setValue(
                        `offerings.${index}.offeringName`,
                        e.target.value
                      );
                    }}
                  />

                  <Input
                    id="offeringText"
                    name={`offerings.${index}.offeringText`}
                    label="Offering Text"
                    type="text"
                    wrapperClassName="mr-4 mb-3"
                    placeholder="Offering Text"
                    error={
                      errors.offerings &&
                      errors.offerings[index] &&
                      errors.offerings[index].offeringText?.message
                        ? errors.offerings[index].offeringText?.message
                        : ''
                    }
                    register={register}
                    onChange={e => {
                      setValue(
                        `offerings.${index}.offeringText`,
                        e.target.value
                      );
                    }}
                  />

                  <Input
                    id="min_value"
                    name={`offerings.${index}.min_value`}
                    label="Amount"
                    type="number"
                    wrapperClassName="mr-4 mb-3"
                    placeholder="Amount"
                    error={
                      errors.offerings &&
                      errors.offerings[index] &&
                      errors.offerings[index].min_value?.message
                        ? errors.offerings[index].min_value?.message
                        : ''
                    }
                    register={register}
                    onChange={e => {
                      if (Number(e.target.value) < 0) return;
                      setValue(
                        `offerings.${index}.min_value`,
                        Number(e.target.value)
                      );
                    }}
                    min={0}
                  />
                  <Input
                    id="offeringId"
                    name={`offerings.${index}.offeringId`}
                    wrapperClassName="mb-3"
                    placeholder="ID"
                    label="ID"
                    error={
                      errors.offerings &&
                      errors.offerings[index] &&
                      errors.offerings[index].offeringId?.message
                        ? errors.offerings[index].offeringId?.message
                        : ''
                    }
                    register={register}
                  />
                  <Input
                    id="unitPrice"
                    name={`offerings.${index}.unitPrice`}
                    wrapperClassName="mb-3"
                    placeholder="Equity share unit price"
                    type="number"
                    label="Equity share unit price"
                    register={register}
                    onChange={e => {
                      if (Number(e.target.value) < 0) return;
                      setValue(
                        `offerings.${index}.unitPrice`,
                        Number(e.target.value)
                      );
                    }}
                    min={0}
                    isDisabled={!watch('has_equity_shares')}
                  />
                  <Toggle
                    id="is_default"
                    name={`offerings.${index}.is_default`}
                    className="flex-col-reverse"
                    label="Default?"
                    labelClassName="mb-2"
                    error={
                      errors.offerings &&
                      errors.offerings[index] &&
                      errors.offerings[index].is_default?.message
                        ? errors.offerings[index].is_default?.message
                        : ''
                    }
                    register={register}
                    checked={field.is_default}
                    onChange={e => {
                      let newOfferArray = [
                        ...watchFieldArray.map(o => ({
                          ...o,
                          is_default: false,
                        })),
                      ];
                      newOfferArray[index].is_default = e.target.checked;
                      setValue('offerings', newOfferArray);
                    }}
                  />
                  <div className="flex text-center justify-between">
                    <Toggle
                      id="offeringStatus"
                      name={`offerings.${index}.offeringStatus`}
                      className="flex-col-reverse"
                      label="Is active?"
                      labelClassName="mb-2"
                      register={register}
                      checked={field.offeringStatus}
                      onChange={e => {
                        setValue(
                          `offerings.${index}.offeringStatus`,
                          e.target.checked
                        );
                      }}
                    />
                    {index > 0 ? (
                      <Button
                        onClick={e => {
                          e.stopPropagation();
                          e.preventDefault();
                          remove(index);
                        }}
                        label={<Minus />}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
          <Button
            type="button"
            onClick={() => {
              append({
                offeringId: '',
                offeringStatus: true,
                is_deleted: false,
                min_value: '',
                unitPrice: null,
                is_default: false,
              });
            }}
            className="mt-3"
            label={
              <>
                <Plus /> Add Entry
              </>
            }
          />
          <div className="w-full my-4 flex justify-between">
            <Button
              onClick={onModalClose}
              variant="transparent"
              label="Cancel"
            />
            <Button
              type="submit"
              isLoading={loading}
              onClick={handleSubmit(createOffering)}
              label={viewMode ? 'Save' : 'Create'}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default OfferingForm;
