const initialState = {
  loading: false,
  isError: false,
  message: '',
  isLoggedIn: false,
  isActiveSession: null,
  user: null,
  verifyEmail: {
    message: '',
    data: '',
  },
  successEmailVerified: '',
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_INPROGRESS':
      return {
        ...state,
        loading: true,
        isError: false,
        message: '',
      };

    case 'VERIFY_EMAIL':
      return {
        ...state,
        successEmailVerified: action.message,
        isError: false,
      };

    case 'VERIFY_EMAIL_FAILED':
      return {
        ...state,
        isError: true,
        message: action.message,
      };

    case 'RESEND_VERIFICATION_EMAIL':
      return {
        ...state,
        verifyEmail: {
          ...state.verifyEmail,
          message: action.data.message,
          status: action.data.status,
        },
      };

    case 'LOGIN_SUCCESS': {
      localStorage.setItem('token', action.data.tokens.access.token);
      localStorage.setItem('expiresAt', action.data.tokens.access.expires);
      return {
        ...state,
        loading: false,
        isError: false,
        isActiveSession: true,
        isLoggedIn: true,
        user: action.data,
      };
    }
    case 'LOGIN_FAILURE':
      return {
        ...state,
        loading: false,
        isError: true,
        message: action.message,
      };
    case 'REGISTER_INPROGRESS':
      return {
        ...state,
        loading: true,
        isError: false,
        message: '',
      };
    case 'REGISTER_SUCCESS': {
      localStorage.setItem('token', action.data.tokens.access.token);
      localStorage.setItem('expiresAt', action.data.tokens.access.expires);
      return {
        ...state,
        loading: false,
        isError: false,
        isActiveSession: true,
        isLoggedIn: true,
        user: action.data,
      };
    }
    case 'REGISTER_FAILURE':
      return {
        ...state,
        loading: false,
        isError: true,
        message: action.message,
      };
    case 'GET_PROFILE_INPROGRESS':
      return {
        ...state,
        loading: true,
        isError: false,
      };

    case 'GET_PROFILE_SUCCESS': {
      return {
        ...state,
        loading: false,
        isError: false,
        isActiveSession: true,
        isLoggedIn: true,
        user: {
          ...state.user,
          user: action.data,
        },
      };
    }
    case 'GET_PROFILE_FAILURE':
      return {
        ...state,
        loading: false,
        isError: false,
        isActiveSession: false,
        isLoggedIn: false,
        user: null,
      };

    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        ...initialState,
      };

    case 'RESET_PASSWORD':
      localStorage.setItem('token', action.data.data.tokens.access.token);
      localStorage.setItem('expiresAt', action.data.data.tokens.access.expires);
      return {
        ...state,
        user: {
          ...state.user,
          tokens: action.data.data.tokens,
        },
      };

    default:
      return state;
  }
};

export default authReducer;
