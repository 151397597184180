import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOneNews, fetchUpdateOneNews } from 'store/actions/news';
import styles from './NewsDetails.module.scss';
import htmlToDraft from 'html-to-draftjs';
import Button from 'components/Button';
import Input from 'components/Input';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import LoadingIndicator from 'components/LoadingIndicator';
import get from 'lodash/get';
import Card from 'components/Card';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';
import EditorFeaturedLinkButton from 'components/EditorFeaturedLinkButton/EditorFeaturedLinkButton';
import EditorImageButton from 'components/EditorImageButton/EditorImageButton';
const schema = Yup.object().shape({
  title: Yup.string(),
  featured_link: Yup.string(),
});

const NewsDetails = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();

  const selected_news = useSelector(({ news }) => get(news, 'details.data'));

  const isLoading = useSelector(({ news }) =>
    get(news, 'details.isInProgress', true)
  );
  const isUpdateloading = useSelector(({ news }) =>
    get(news, 'isUpdateloading', false)
  );
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  let { id } = useParams();

  useEffect(() => {
    dispatch(fetchOneNews(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]); //fix this

  useEffect(() => {
    if (!isLoading && selected_news.content) {
      const blocksFromHtml = htmlToDraft(
        selected_news.content.rendered + '<p></p><p></p><p></p>'
      );

      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );

      const newEditorState = EditorState.createWithContent(contentState);

      setEditorState(newEditorState);
    }
  }, [selected_news, isLoading]);

  const editedTitleOrDefault = (title, featured_link) => {
    const { id } = selected_news;
    const HTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    dispatch(fetchUpdateOneNews(id, title, HTML, 'publish', featured_link));
  };

  const onSubmit = async ({ title, featured_link }) => {
    if (title) {
      editedTitleOrDefault(title, featured_link);
    } else {
      editedTitleOrDefault(selected_news.title.rendered, featured_link);
    }
  };

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'news'
  );

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      {isLoading && selected_news ? (
        <LoadingIndicator />
      ) : (
        <>
          <Card fullHeight={false}>
            <form
              className="flex w-full mb-3"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Input
                wrapperClassName="w-full"
                placeholder={
                  selected_news ? selected_news.title.rendered : null
                }
                name="title"
                register={register}
                error={errors?.title?.message}
              />
              <Button
                className=" ml-4"
                label="Update"
                type="submit"
                isLoading={isUpdateloading}
              />
            </form>

            <Editor
              toolbarCustomButtons={[
                <EditorFeaturedLinkButton key={'1'} register={register} />,
                <EditorImageButton key={'2'} setEditorState={setEditorState} />,
              ]}
              toolbar={{
                options: [
                  'inline',
                  'blockType',
                  'fontSize',
                  'fontFamily',
                  'list',
                  'textAlign',
                  'colorPicker',
                  'link',
                  'embedded',
                  'emoji',
                  //REMOVED IMAGE
                  'remove',
                  'history',
                ],
              }}
              wrapperClassName={styles.editor_container}
              editorClassName={styles.center}
              editorState={editorState}
              onEditorStateChange={setEditorState}
            />
          </Card>
        </>
      )}
    </CanAccessComponent>
  );
};

export default NewsDetails;
