import React from 'react';

const AbandonCart = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="40px"
      height="40px"
      viewBox="0 0 508.000000 424.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g
        transform="translate(0.000000,424.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M834 3436 c-30 -30 -34 -40 -34 -86 0 -46 4 -56 34 -86 29 -29 41
  -34 81 -34 32 0 57 7 76 21 19 13 36 18 47 14 15 -6 97 -29 201 -55 18 -5 37
  -12 42 -17 9 -9 22 -57 49 -178 5 -22 18 -80 30 -130 12 -49 25 -108 30 -130
  5 -22 18 -80 30 -130 12 -49 25 -108 30 -130 5 -22 18 -78 29 -125 25 -106 62
  -265 92 -400 6 -25 14 -61 19 -80 5 -19 13 -55 19 -80 5 -25 19 -83 30 -130
  11 -47 22 -98 26 -114 5 -25 0 -35 -45 -78 -72 -68 -100 -135 -100 -238 0 -94
  26 -164 84 -222 24 -25 36 -46 36 -65 1 -68 53 -160 113 -201 64 -42 93 -52
  158 -52 71 0 137 28 189 80 33 34 44 51 69 113 l11 27 415 0 415 0 26 -55 c51
  -107 138 -165 246 -165 142 0 253 101 265 240 7 79 -18 155 -67 208 -141 149
  -371 100 -449 -95 l-13 -33 -419 0 -418 0 -26 52 c-44 87 -99 132 -197 158
  -85 22 -194 -20 -263 -101 -29 -35 -29 -35 -60 23 -22 40 -19 140 5 187 24 48
  79 95 129 110 27 8 281 11 890 11 l851 0 17 38 c25 58 31 74 47 122 8 25 29
  83 46 130 17 47 40 110 50 140 10 30 33 93 50 140 17 47 40 110 50 140 10 30
  33 93 50 140 17 47 40 110 50 140 10 30 33 93 51 140 39 103 43 156 13 177
  -20 13 -24 12 -47 -9 -14 -13 -35 -52 -47 -88 -12 -36 -25 -74 -30 -85 -5 -11
  -16 -40 -24 -65 -8 -25 -29 -83 -46 -130 -17 -47 -40 -110 -50 -140 -10 -30
  -37 -107 -60 -170 -23 -63 -46 -126 -50 -140 -5 -14 -22 -63 -40 -110 -17 -47
  -38 -103 -45 -125 -8 -22 -23 -62 -33 -90 l-18 -50 -837 -3 c-665 -2 -838 0
  -841 10 -2 7 -9 40 -16 73 -7 33 -16 71 -20 85 -4 14 -13 50 -19 80 -7 30 -21
  93 -32 140 -11 47 -24 103 -29 125 -5 22 -18 81 -30 130 -12 50 -26 110 -31
  135 -6 25 -14 61 -19 80 -5 19 -13 55 -19 80 -17 78 -29 132 -50 223 -12 48
  -30 126 -42 175 -11 48 -24 105 -29 127 -30 137 -54 222 -66 236 -8 8 -37 21
  -66 28 -29 7 -66 17 -83 22 -16 5 -62 18 -101 29 -62 17 -73 24 -84 50 -19 45
  -60 70 -115 70 -40 0 -52 -5 -81 -34z"
        />
        <path
          d="M2510 3030 c-115 -21 -211 -71 -298 -153 -130 -124 -192 -265 -192
  -438 0 -176 55 -313 175 -433 75 -76 150 -121 254 -155 91 -30 261 -30 352 0
  196 64 327 191 400 389 21 57 24 82 24 195 -1 141 -8 172 -66 288 -47 92 -165
  206 -260 250 -41 20 -90 40 -109 47 -46 15 -222 21 -280 10z m15 -440 l100
  -100 100 100 c78 79 106 100 127 100 24 0 28 -4 28 -28 0 -21 -21 -48 -95
  -122 -52 -52 -95 -99 -95 -105 0 -6 43 -53 95 -105 73 -73 95 -101 95 -122 0
  -18 -6 -29 -21 -33 -18 -6 -40 11 -123 94 -56 56 -106 101 -111 101 -6 0 -55
  -45 -110 -100 -55 -55 -107 -100 -117 -100 -24 0 -41 35 -29 58 5 9 50 57 100
  107 50 49 91 94 91 100 0 6 -45 55 -100 110 -55 55 -100 104 -100 110 0 18 22
  35 44 35 13 0 60 -39 121 -100z"
        />
      </g>
    </svg>
  );
};

export default AbandonCart;
