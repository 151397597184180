import React from 'react';
import PropTypes from 'prop-types';
import { Check } from 'react-feather';
import cs from 'classnames';
import styles from './checkbox.module.scss';

const Checkbox = ({
  id,
  isDisabled,
  label,
  className,
  name,
  register,
  ...rest
}) => {
  return (
    <label
      className={cs(styles.root, className, {
        [styles.checkboxDisabled]: isDisabled,
      })}
    >
      <input
        type="checkbox"
        id={id}
        name={name}
        {...(register ? { ...register(name) } : {})}
        {...rest}
      />
      <span className={styles.checkIconWrapper}>
        <Check size={17} className={styles.checkIcon} />
      </span>
      <p className={styles.label}>{label}</p>
    </label>
  );
};

Checkbox.propTypes = {
  /** Pass the unique ID */
  id: PropTypes.string.isRequired,
  /** Label for the component */
  label: PropTypes.string,
  /** Checkbox disabled or not */
  isDisabled: PropTypes.bool,
  /** Checkbox Component Class Name  */
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  id: '',
  className: '',
};

export default Checkbox;
