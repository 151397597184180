import React, { useState } from 'react';
import cs from 'classnames';
import Input from 'components/Input';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Select from 'components/Select';
import {
  CheckCircle,
  XCircle,
  UploadCloud,
  Clock,
  Trash2,
} from 'react-feather';

const ActivityForm = ({ onModalClose, onActivityCreate }) => {
  const [loading, setLoading] = useState(false);
  const {
    formState: { errors },
    register,
    setValue,
    handleSubmit,
    control,
    watch,
  } = useForm({
    defaultValues: {
      title: '',
      description: '',
      icon: '',
    },
    resolver: yupResolver(
      Yup.object({
        title: Yup.string().required('Title is Required'),
        description: Yup.string().required('Description is Required'),
        icon: Yup.object().required('Icon is Required'),
      })
    ),
  });

  const addActivity = async values => {
    setLoading(true);
    await onActivityCreate({
      title: values.title,
      description: values.description,
      icon: values.icon.value,
    });
    setLoading(false);
    onModalClose(true);
  };

  return (
    <Modal
      isOpen
      hasCloseIcon
      handleCloseClick={onModalClose}
      containerClassname="max-w-screen-sm"
      onClick={onModalClose}
      title={'Add a Activity'}
    >
      <div className={cs('flex w-full flex-col mt-4 items-start')}>
        <form className="w-full">
          <Input
            id="title"
            name="title"
            label="Title"
            wrapperClassName="w-full my-4"
            placeholder="Title"
            error={errors.title?.message}
            register={register}
          />
          <Input
            id="description"
            name="description"
            label="Description"
            wrapperClassName="w-full my-4"
            placeholder="Description"
            error={errors.description?.message}
            register={register}
          />
          <Select
            wrapperClassName="w-full my-4"
            label="Icon"
            id="icon"
            name="icon"
            control={control}
            error={errors?.icon ? errors.icon.message : null}
            options={[
              {
                label: 'Upload',
                value: 'upload',
                icon: <UploadCloud className="text-green-500" />,
              },
              {
                label: 'Cross',
                value: 'cross',
                icon: <XCircle className="text-red-500" />,
              },
              {
                label: 'Check',
                value: 'check',
                icon: <CheckCircle className="text-green-500" />,
              },
              {
                label: 'Update',
                value: 'update',
                icon: <Clock className="text-blue-500" />,
              },
              {
                label: 'Delete',
                value: 'delete',
                icon: <Trash2 className="text-red-500" />,
              },
            ]}
            value={watch('icon')}
            onChange={value => {
              setValue('icon', value);
            }}
          />
          <div className="w-full my-4 flex justify-between">
            <Button
              onClick={onModalClose}
              variant="transparent"
              className="mr-3"
              label="Cancel"
            />
            <Button
              isLoading={loading}
              onClick={handleSubmit(addActivity)}
              label="Add Activity"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ActivityForm;
