import { get } from 'lodash';
import { resetUserPassword } from 'store/api/users';
import {
  getProfile,
  login,
  register,
  resendEmail,
  forgotPassword,
  resetPassword,
  verifyEmail,
} from '../api/auth';
import { showSnackBar } from './snackbar';

export const LOGIN_INPROGRESS = 'LOGIN_INPROGRESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REGISTER_INPROGRESS = 'REGISTER_INPROGRESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const GET_PROFILE_INPROGRESS = 'GET_PROFILE_INPROGRESS';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const RESEND_VERIFICATION_EMAIL = 'RESEND_VERIFICATION_EMAIL';

export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_FAILED = 'VERIFY_EMAIL_FAILED';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const doLogin = data => async dispatch => {
  try {
    dispatch({
      type: LOGIN_INPROGRESS,
    });
    const resp = await login(data);
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: LOGIN_SUCCESS,
        data: resp.data,
      });
      showSnackBar('You have successfully logged in!', 'success');
    } else {
      dispatch({
        type: LOGIN_FAILURE,
        message: resp.message,
      });

      showSnackBar(message, 'error');
    }
    return resp.status;
  } catch (e) {
    dispatch({
      type: LOGIN_FAILURE,
      message: 'Something went wrong!',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const registerNewUser = data => async dispatch => {
  dispatch({
    type: REGISTER_INPROGRESS,
  });

  try {
    const resp = await register(data);
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: REGISTER_SUCCESS,
        data: resp.data,
      });

      showSnackBar('You have successfully registered an account!', 'success');
    } else {
      dispatch({
        type: REGISTER_FAILURE,
        message: message,
      });

      showSnackBar(message, 'error');
    }
  } catch (e) {
    dispatch({
      type: REGISTER_FAILURE,
      message: 'Something went wrong!',
    });

    showSnackBar('Sorry something went wrong', 'error');
  }
};

export const getUserProfile = () => async dispatch => {
  dispatch({
    type: GET_PROFILE_INPROGRESS,
  });

  try {
    const data = await getProfile();
    const status = get(data, 'status');
    const message = get(data, 'message');

    if (status) {
      dispatch({
        type: GET_PROFILE_SUCCESS,
        data: {
          ...data.data,
          token: localStorage.getItem('token'),
        },
      });
    } else {
      dispatch({
        type: GET_PROFILE_FAILURE,
        message: message,
      });
    }
  } catch (e) {
    dispatch({
      type: GET_PROFILE_FAILURE,
      message: 'Something went wrong!',
    });
  }
};

export const doLogout = () => async dispatch => {
  localStorage.removeItem('token');
  localStorage.removeItem('expiresAt');
  dispatch({
    type: LOGOUT_SUCCESS,
  });
};

export const resendVerificationEmail = email => async dispatch => {
  const data = await resendEmail(email);

  dispatch({
    type: RESEND_VERIFICATION_EMAIL,
    data: { message: data.message, status: data.status },
  });
};

export const verifyEmailUser = token => async dispatch => {
  const resp = await verifyEmail(token);
  const status = get(resp, 'status');
  const message = get(resp, 'message');

  try {
    if (status) {
      dispatch({
        type: VERIFY_EMAIL,
        message: message,
      });

      showSnackBar(message, 'success');
    } else {
      dispatch({
        type: VERIFY_EMAIL_FAILED,
        message: message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: VERIFY_EMAIL_FAILED,
      message: error,
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const resetPasswordUser = async (
  token,
  new_password,
  confirm_password
) => {
  try {
    const resp = await resetPassword(token, new_password, confirm_password);
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      showSnackBar(message, 'success');
    } else {
      showSnackBar(message, 'error');
    }
  } catch (error) {
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const forgotPasswordUser = async email => {
  try {
    const resp = await forgotPassword(email);
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      showSnackBar(message, 'success');
    } else {
      showSnackBar(message, 'error');
    }
  } catch (error) {
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const resetPasswordById = (
  id,
  password,
  new_password,
  confirmPassword
) => async dispatch => {
  try {
    const data = await resetUserPassword(
      id,
      password,
      new_password,
      confirmPassword
    );
    const status = get(data, 'status');
    const message = get(data, 'message');

    if (status) {
      dispatch({
        type: RESET_PASSWORD,
        data: data.data,
      });

      showSnackBar('Your password was successfully reseted', 'success');
      return data;
    } else {
      dispatch({
        type: RESET_PASSWORD_FAILED,
        message: message,
      });
      showSnackBar(message, 'error');
    }
    return data;
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAILED,
    });
    showSnackBar('Something went wrong! Please try again later', 'error');

    return { status: 0 };
  }
};
