import Request from './request';

export const fetchApplicationsList = async (page, sort, query, limit) =>
  await Request.call({
    url: '/applications',
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
      limit,
    },
  });

export const fetchApplicationData = async id =>
  await Request.call({
    url: `/applications/${id}`,
    method: 'GET',
  });

export const rejectApplication = async id =>
  await Request.call({
    url: `/applications/${id}`,
    method: 'DELETE',
  });

export const downloadApplicationDocs = async (applicationId, documentId) =>
  await Request.call({
    url: `/applications/${applicationId}/${documentId}/preview`,
    method: 'GET',
  });

export const fetchAllApplicationData = async () =>
  await Request.call({
    url: `/applications/all`,
    method: 'GET',
  });

export const addApplicationActivity = async (id, data) =>
  await Request.call({
    url: `/applications/${id}/activity`,
    method: 'POST',
    data,
  });
