import React, { useMemo, useEffect, useState } from 'react';

import DropdownMenu from 'components/DropdownMenu';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircle, Clock } from 'react-feather';
import Button from 'components/Button';

import {
  fetchAuthentication,
  fetchNews,
  fetchDeleteOneNews,
  fetchUpdateOneNews,
} from 'store/actions/news';
import { Link, useHistory } from 'react-router-dom';
import Table from 'components/Table/Table';
import Card from 'components/Card';
import { AlertPopupHandler } from 'components/AlertPopup';

import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';
import get from 'lodash/get';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
const News = () => {
  let history = useHistory();
  const [draft_mode, setDraftMode] = useState(false);
  const dispatch = useDispatch();

  const newsData = useSelector(({ news }) =>
    get(news, 'newsState.data.data', [])
  );
  const newsMetadata = useSelector(({ news }) =>
    get(news, 'newsState.data.meta', {})
  );

  const isAuthloading = useSelector(({ news }) =>
    get(news, 'isAuthloading', true)
  );
  const isDeleteLoading = useSelector(({ news }) =>
    get(news, 'isDeleteLoading', false)
  );

  // FETCH PUBLISH NEWS
  const onClickNewsPagination = async ({
    pageIndex: page,
    pageSize: per_page,
  }) => {
    draft_mode
      ? await dispatch(fetchNews(per_page, page + 1, 'draft'))
      : await dispatch(fetchNews(per_page, page + 1));
  };

  //authenticate to create post
  useEffect(() => {
    if (!localStorage.getItem('wordpress_jwt')) {
      dispatch(fetchAuthentication());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmDelete = async data => {
    await dispatch(fetchDeleteOneNews(data.id));
  };

  const deleteNews = data => () => {
    AlertPopupHandler.open({
      type: 'error',
      text: `You are about to delete ${data.title.rendered} post. Do you want to continue?`,
      onConfirm: confirmDelete,
      data,
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: () => 'Post',
        accessor: 'title',
        Cell: ({ value, row }) =>
          value ? (
            <Link to={`/news/${row.original.id}`} className="flex items-start">
              {value.rendered}
            </Link>
          ) : null,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
          <div className="flex justify-center">
            {value === 'publish' ? (
              <>
                <CheckCircle className="mr-2 text-green-500" /> Published
              </>
            ) : (
              <>
                <Clock className="mr-2 text-blue-500" /> Draft
              </>
            )}
          </div>
        ),
      },
      {
        Header: () => <div className="flex justify-end w-full">Created At</div>,
        accessor: 'date',
        Cell: ({ value, row }) => {
          return (
            <div className="flex justify-end">
              <span className="flex items-center mr-4">
                {value ? moment(value).format('MMM Do, YYYY') : null}
              </span>
              <DropdownMenu
                options={[
                  {
                    label:
                      row.original.status === 'publish' ? 'Draft' : 'Publish',
                    onClick: () => {
                      const {
                        id,
                        content: { rendered: HTML },
                        title: { rendered: title },
                      } = row.original;

                      dispatch(
                        fetchUpdateOneNews(
                          id,
                          title,
                          HTML,
                          row.original.status === 'publish'
                            ? 'draft'
                            : 'publish'
                        )
                      );
                    },
                  },
                  {
                    label: 'Edit Post',
                    onClick: () => {
                      history.push(`/news/${row.original.id}`);
                    },
                  },
                  {
                    label: 'Delete Post',
                    onClick: deleteNews(row.original),
                  },
                ]}
              />
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, dispatch]
  );

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'news'
  );

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        <div className="flex justify-between w-full">
          <Card.Header
            title={`${draft_mode ? 'Draft News' : 'News'} ${
              parseInt(newsMetadata['x-wp-total'])
                ? `(${parseInt(newsMetadata['x-wp-total'])})`
                : ''
            }`}
          />
          <div className="flex ">
            <Button
              label={draft_mode ? 'Publish' : 'Draft'}
              variant={draft_mode ? 'secondary' : 'primary'}
              className="mr-3"
              onClick={() => setDraftMode(!draft_mode)}
            />
            <Button
              label="Create"
              type="submit"
              onClick={() => history.push('/news/editor')}
            />
          </div>
        </div>
        <Card.Content>
          {isAuthloading ? null : (
            <Table
              showSearch={false}
              loader={isDeleteLoading}
              columns={columns}
              data={newsData}
              total={parseInt(newsMetadata['x-wp-totalpages'])}
              fetchData={onClickNewsPagination}
              forceRefetch={draft_mode}
            />
          )}
        </Card.Content>
      </Card>
    </CanAccessComponent>
  );
};

export default News;
