import React, { Fragment, useEffect, useState } from 'react';
import { useTable, useExpanded, usePagination, useSortBy } from 'react-table';
import { useMedia } from 'react-use';
import cs from 'classnames';
import { debounce } from 'lodash';
import {
  ChevronLeft,
  ChevronRight,
  ChevronsRight,
  Search,
  ChevronsLeft,
  X,
  Triangle,
} from 'react-feather';
import NoDataIllustration from 'assets/no_data.svg';
import Input from 'components/Input';
import Tags from 'components/Tags';
import Select from 'components/Select';
import LoadingIndicator from 'components/LoadingIndicator';
import styles from './table.module.scss';

const pageSizeOptions = [
  { label: '50', value: 50 },
  { label: '100', value: 100 },
  { label: '500', value: 500 },
  { label: '1000', value: 1000 },
];

const Table = ({
  loader = false,
  columns,
  data,
  total,
  fetchData,
  onRowClick,
  withPagination = true,
  withPerPage = true,
  withShowPerPage = true,
  showSearch = true,
  forceRefetch = false,
  subRowComponentDocument = false,
  renderRowSubComponent,
  startDate,
  endDate,
  ...restProps
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    placeholderInput,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state: { pageIndex, pageSize, sortBy },
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      ...(withPagination && withPerPage
        ? {
            initialState: { pageIndex: 0 },
            manualPagination: true,
            manualSortBy: true,
            autoResetPage: false,
            pageCount: total,
            disableMultiSort: true,
            disableSortRemove: true,
          }
        : subRowComponentDocument
        ? { autoResetPage: false }
        : {}),
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');

  useEffect(() => {
    const fetchTableData = async () => {
      setLoading(true);
      if (withPagination && withPerPage) {
        await fetchData({ pageIndex, pageSize, sortBy, query });
      }
      setLoading(false);
    };
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize, sortBy, forceRefetch, startDate, endDate]);

  useEffect(() => {
    gotoPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRefetch]);

  const isMaxWidth500px = useMedia('(max-width:500px)');

  const delayedHandleChange = debounce(value => {
    setQuery(value);
    setLoading(true);
    gotoPage(0);

    fetchData({ pageIndex, pageSize, sortBy, query: value });
    setLoading(false);
  }, 500);

  const handleSearch = e => {
    e.persist();
    delayedSearch(e.target.value);
  };

  const handleSearchClear = () => {
    delayedHandleChange('');
  };

  // debouncing search function so that we don't make a call on each key down event
  const delayedSearch = debounce(delayedHandleChange, 500);

  return (
    <div className="flex flex-col overflow-x-hidden w-full flex-1">
      {showSearch ? (
        <Input
          type="text"
          placeholder={placeholderInput || 'Search...'}
          onChange={handleSearch}
          leftIcon={<Search size={20} />}
          rightIcon={
            query ? (
              <X className="cursor-pointer" onClick={handleSearchClear} />
            ) : null
          }
        />
      ) : null}
      <div
        className={cs(
          styles.tableContainer,
          'overflow-x-hidden w-full h-full',
          {
            [styles.tableContainerCenter]: !rows.length,
          }
        )}
      >
        {loading || loader ? (
          <LoadingIndicator />
        ) : (
          <Fragment>
            {!rows.length ? (
              <div className="flex flex-col items-center justify-center w-full h-full">
                <img src={NoDataIllustration} alt="No Data" width={500} />
                <h2 className="pb-14 text-black font-semibold text-xl">
                  {query ? 'No Matches Found' : 'Nothing Found'}
                </h2>
              </div>
            ) : (
              <Fragment>
                <div className="overflow-x-auto">
                  <table
                    className={styles.myAccountInvestmentsTable}
                    {...restProps}
                    {...getTableProps()}
                  >
                    <thead>
                      {headerGroups.map((headerGroup, headerGroupIndex) => (
                        <tr
                          {...headerGroup.getHeaderGroupProps()}
                          key={`table-header-group-${headerGroupIndex}`}
                        >
                          {headerGroup.headers.map((column, index) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              key={`table-header-${index}`}
                            >
                              <div className="flex items-center justify-center">
                                {column.render('Header')}
                                <span>
                                  {column.isSorted ? (
                                    <Triangle
                                      className={cs('ml-2', {
                                        'rotate-180': column.isSortedDesc,
                                      })}
                                      size={12}
                                      fill="#000"
                                    />
                                  ) : null}
                                </span>
                              </div>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                          <Fragment key={`table-tr-group-${rowIndex}`}>
                            <tr
                              {...row.getRowProps()}
                              className={styles.investment}
                              onClick={() => {
                                return onRowClick
                                  ? onRowClick(
                                      row.original._id || row.original.id
                                    )
                                  : null;
                              }}
                            >
                              {row.cells.map((cell, cellIndex) => (
                                <td
                                  {...cell.getCellProps()}
                                  key={`table-cell-group-${cellIndex}`}
                                >
                                  {cell.render('Cell', {
                                    filters: {
                                      pageIndex,
                                      pageSize,
                                      sortBy,
                                      query,
                                    },
                                  })}
                                </td>
                              ))}
                            </tr>
                            {row.isExpanded ? (
                              <tr>
                                <td colSpan={visibleColumns.length}>
                                  {renderRowSubComponent({ row })}
                                </td>
                              </tr>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {withPagination || withPerPage ? (
                  <div
                    className={cs(
                      'px-4 pt-2 flex items-center justify-between',
                      {
                        'flex-col': isMaxWidth500px,
                      }
                    )}
                  >
                    {withPagination ? (
                      <div
                        className={cs('flex items-center', {
                          'mb-3': isMaxWidth500px,
                        })}
                      >
                        <button
                          type="button"
                          className={cs(styles.button)}
                          onClick={() => gotoPage(0)}
                          disabled={!canPreviousPage}
                        >
                          <ChevronsLeft />
                        </button>
                        <button
                          type="button"
                          className={cs('mr-1', styles.button)}
                          onClick={() => previousPage()}
                          disabled={!canPreviousPage}
                        >
                          <ChevronLeft />
                        </button>
                        <Tags
                          type="black"
                          label={`${pageIndex + 1} of ${pageOptions.length}`}
                        />
                        <button
                          type="button"
                          className={cs('ml-1', styles.button)}
                          onClick={() => nextPage()}
                          disabled={!canNextPage}
                        >
                          <ChevronRight />
                        </button>
                        <button
                          type="button"
                          className={cs(styles.button)}
                          onClick={() => gotoPage(total - 1)}
                          disabled={!canNextPage}
                        >
                          <ChevronsRight />
                        </button>
                      </div>
                    ) : null}

                    {withPerPage && withShowPerPage ? (
                      <Select
                        menuPlacement="top"
                        wrapperClassName="flex flex-row items-center w-1/4"
                        className="m-0"
                        label="Rows per page:"
                        id="row_per_page"
                        labelClassName="w-1/2"
                        options={pageSizeOptions}
                        onChange={v => setPageSize(v.value)}
                        value={
                          pageSize
                            ? pageSizeOptions.find(p => p.value === pageSize)
                            : null
                        }
                      />
                    ) : (
                      <div style={{ visibility: 'hidden' }}>
                        <Select
                          menuPlacement="top"
                          wrapperClassName="flex flex-row items-center w-1/4"
                          className="m-0"
                          label="Rows per page:"
                          id="row_per_page"
                          labelClassName="w-1/2"
                          options={pageSizeOptions}
                          onChange={v => setPageSize(v.value)}
                          value={
                            pageSize
                              ? pageSizeOptions.find(p => p.value === pageSize)
                              : null
                          }
                        />
                        )
                      </div>
                    )}
                  </div>
                ) : null}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Table;
