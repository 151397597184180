import { get } from 'lodash';
import {
  addReservationActivity,
  fetchReservationDetailList,
  fetchReservationsList,
  fetchAllReservationData,
} from '../api/reservations';
import { showSnackBar } from './snackbar';

export const FETCH_RESERVATIONS_INPROGRESS = 'FETCH_RESERVATIONS_INPROGRESS';
export const FETCH_RESERVATIONS_SUCCESS = 'FETCH_RESERVATIONS_SUCCESS';
export const FETCH_RESERVATIONS_FAILURE = 'FETCH_RESERVATIONS_FAILURE';

export const ADD_RESERVATION_ACTIVITY_INPROGRESS =
  'ADD_RESERVATION_ACTIVITY_INPROGRESS';
export const ADD_RESERVATION_ACTIVITY_SUCCESS =
  'ADD_RESERVATION_ACTIVITY_SUCCESS';
export const ADD_RESERVATION_ACTIVITY_FAILED =
  'ADD_RESERVATION_ACTIVITY_FAILED';

export const FETCH_RESERVATIONS_DETAIL_INPROGRESS =
  'FETCH_RESERVATIONS_DETAIL_INPROGRESS';
export const FETCH_RESERVATIONS_DETAIL_SUCCESS =
  'FETCH_RESERVATIONS_DETAIL_SUCCESS';
export const FETCH_RESERVATIONS_DETAIL_FAILURE =
  'FETCH_RESERVATIONS_DETAIL_FAILURE';

export const EXPORT_RESERVATIONS_CSV_INPROGRESS =
  'EXPORT_RESERVATIONS_CSV_INPROGRESS';
export const EXPORT_RESERVATIONS_CSV_SUCCESS =
  'EXPORT_RESERVATIONS_CSV_SUCCESS';
export const EXPORT_RESERVATIONS_CSV_FAILED = 'EXPORT_RESERVATIONS_CSV_FAILED';

export const fetchReservations = (page, sort, q, limit) => async dispatch => {
  dispatch({
    type: FETCH_RESERVATIONS_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await fetchReservationsList(page, sort, q, limit);
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: FETCH_RESERVATIONS_SUCCESS,
        data: resp || {},
      });
    } else {
      dispatch({
        type: FETCH_RESERVATIONS_FAILURE,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_RESERVATIONS_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const fetchReservationDetail = id => async dispatch => {
  dispatch({
    type: FETCH_RESERVATIONS_DETAIL_INPROGRESS,
  });
  // eslint-disable-next-line no-undef
  try {
    const resp = await fetchReservationDetailList(id);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      dispatch({
        type: FETCH_RESERVATIONS_DETAIL_SUCCESS,
        data: resp || {},
      });
    } else {
      dispatch({
        type: FETCH_RESERVATIONS_DETAIL_FAILURE,
        error: message,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_RESERVATIONS_DETAIL_FAILURE,
      error: 'Something went wrong! Please try again later',
    });

    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const exportReservationToCSV = () => async dispatch => {
  dispatch({
    type: EXPORT_RESERVATIONS_CSV_INPROGRESS,
  });
  try {
    const resp = await fetchAllReservationData();
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: EXPORT_RESERVATIONS_CSV_SUCCESS,
      });
    } else {
      dispatch({
        type: EXPORT_RESERVATIONS_CSV_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
    return resp;
  } catch (error) {
    dispatch({
      type: EXPORT_RESERVATIONS_CSV_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};

export const addReservationActivityById = (id, activity) => async dispatch => {
  dispatch({
    type: ADD_RESERVATION_ACTIVITY_INPROGRESS,
  });
  try {
    const resp = await addReservationActivity(id, activity);
    const status = get(resp, 'status');
    const message = get(resp, 'message');

    if (status) {
      dispatch({
        type: ADD_RESERVATION_ACTIVITY_SUCCESS,
        data: resp,
      });

      showSnackBar(message, 'success');
    } else {
      dispatch({
        type: ADD_RESERVATION_ACTIVITY_FAILED,
        message,
      });

      showSnackBar(message, 'error');
    }
  } catch (error) {
    dispatch({
      type: ADD_RESERVATION_ACTIVITY_FAILED,
      message: 'Something went wrong! Please try again later',
    });
    showSnackBar('Something went wrong! Please try again later', 'error');
  }
};
