import { get } from 'lodash';
import { useSelector } from 'react-redux';

export const HasInvestments = () => {
  const individual = useSelector(({ auth }) =>
    get(auth, 'user.user.individual', [])
  );

  const joint = useSelector(({ auth }) => get(auth, 'user.user.joint', []));
  const company = useSelector(({ auth }) => get(auth, 'user.user.company', []));
  const ira = useSelector(({ auth }) => get(auth, 'user.user.ira', []));
  const trust = useSelector(({ auth }) => get(auth, 'user.user.trust', []));

  if (
    individual.length ||
    joint.length ||
    company.length | ira.length ||
    trust.length
  )
    return true;

  return false;
};
