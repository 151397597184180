import * as actions from '../actions/investments';

const initialState = {
  parties: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
    data: null,
  },
  accountDocuments: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
    data: null,
  },
  investments: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
    data: {},
  },

  details: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
    data: {},
  },
  resendEmail: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
  },
  bulkReSendDocumentUploadEmail: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
  },

  accounts: {
    isInProgress: false,
    isError: false,
    status: 0,
    error: '',
    data: {},
  },

  docStatus: false,
};

const investmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CHANGE_DOC_STATUS:
      return {
        ...state,
        docStatus: !action.data,
      };

    case actions.UPDATE_ACCOUNT_DOCUMENT_INPROGRESS:
      return {
        ...state,
        accountDocuments: {
          ...state.accountDocuments,
          //isReviewLoading
          data: state.accountDocuments.data.map(e =>
            e.id === action.data.id ? { ...e, isReviewLoading: true } : { ...e }
          ),
        },
      };

    case actions.UPDATE_ACCOUNT_DOCUMENT_SUCCESS:
      return {
        ...state,
        accountDocuments: {
          ...state.accountDocuments,
          isInProgress: false,
          status: 1,
          data: state.accountDocuments.data.map(e =>
            e._id === action.data._id
              ? { ...action.data, documentUrl: e.documentUrl }
              : e
          ),
          isError: false,
          error: '',
        },
      };
    case actions.UPDATE_ACCOUNT_DOCUMENT_FAILURE:
      return {
        ...state,
        accountDocuments: {
          ...state.accountDocuments,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };

    case actions.GET_ACCOUNT_DOCUMENT_INPROGRESS:
      return {
        ...state,
        accountDocuments: {
          ...state.accountDocuments,
          isInProgress: true,
        },
      };

    case actions.GET_ACCOUNT_DOCUMENT_SUCCESS:
      return {
        ...state,
        accountDocuments: {
          ...state.accountDocuments,
          isInProgress: false,
          status: 1,
          data: action.data,
          isError: false,
          error: '',
        },
      };
    case actions.GET_ACCOUNT_DOCUMENT_FAILURE:
      return {
        ...state,
        accountDocuments: {
          ...state.accountDocuments,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };

    case actions.GET_PARTIES_INPROGRESS:
      return {
        ...state,
        parties: {
          ...state.parties,
          isInProgress: true,
        },
      };

    case actions.GET_PARTIES_SUCCESS:
      return {
        ...state,
        parties: {
          ...state.parties,
          isInProgress: false,
          status: 1,
          data: action.data,
          isError: false,
          error: '',
        },
      };
    case actions.GET_PARTIES_FAILURE:
      return {
        ...state,
        parties: {
          ...state.parties,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };

    case actions.FETCH_INVESTMENTS_INPROGRESS:
      return {
        ...state,
        investments: {
          ...state.investments,
          isInProgress: true,
        },
      };

    case actions.FETCH_INVESTMENTS_SUCCESS:
      return {
        ...state,
        investments: {
          ...state.investments,
          isInProgress: false,
          status: 1,
          data: action.data,
          isError: false,
          error: '',
        },
      };
    case actions.FETCH_INVESTMENTS_FAILURE:
      return {
        ...state,
        investments: {
          ...state.investments,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };

    case actions.FETCH_INVESTMENTS_DETAIL_INPROGRESS:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: true,
        },
      };

    case actions.FETCH_INVESTMENTS_DETAIL_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: false,
          status: 1,
          data: action.data,
          isError: false,
          error: '',
        },
      };

    case actions.FETCH_INVESTMENTS_DETAIL_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };
    case actions.RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_INPROGRESS:
      return {
        ...state,
        resendEmail: {
          ...state.details,
          isInProgress: true,
        },
      };

    case actions.RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_SUCCESS:
      return {
        ...state,
        resendEmail: {
          ...state.resendEmail,
          isInProgress: false,
          status: 1,
          isError: false,
          error: '',
        },
      };
    case actions.RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_FAILURE:
      return {
        ...state,
        resendEmail: {
          ...state.resendEmail,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };
    case actions.BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_INPROGRESS:
      return {
        ...state,
        bulkReSendDocumentUploadEmail: {
          ...state.update,
          isInProgress: true,
        },
      };
    case actions.BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_SUCCESS:
      return {
        ...state,
        bulkReSendDocumentUploadEmail: {
          ...state.update,
          isInProgress: false,
          status: 1,
          isError: false,
          error: '',
        },
      };
    case actions.BULK_RESEND_DOCUMENTS_UPLOAD_EMAIL_FOR_INVESTMENT_FAILURE:
      return {
        ...state,
        bulkReSendDocumentUploadEmail: {
          ...state.update,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };

    case actions.GET_ACCOUNTS_INPROGRESS:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          isInProgress: true,
        },
      };
    case actions.GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          isInProgress: false,
          status: 1,
          isError: false,
          error: '',
        },
      };
    case actions.GET_ACCOUNTS_FAILURE:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          isInProgress: false,
          isError: true,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default investmentsReducer;
