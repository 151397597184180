import React from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from 'components/Button/Button';

const ErrorFourZeroFour = () => {
  const accessToken = useSelector(({ auth }) =>
    get(auth, 'user.tokens.access.token', null)
  );

  let history = useHistory();

  return (
    <div className="w-full min-h-screen flex items-center justify-center">
      <div className="flex flex-col items-center w-full xl:w-3/12 lg:w-4/12 md:w-6/12 px-8">
        <h1 className="font-bold text-12xl text-blueGray-700">404</h1>
        <p className="py-2 font-medium text-blueGray-700 text-xl">
          Page Not Found
        </p>
        <Button
          className="bg-yellow-500 mt-1 py-2 text-white text-blueGray-700 w-full"
          type="submit"
          onClick={() =>
            accessToken
              ? history.push('/investments')
              : history.push('/auth/login')
          }
          label="Go Home"
        />
      </div>
    </div>
  );
};

export default ErrorFourZeroFour;
