import React from 'react';
import Details from 'components/Details';
import NumberFormat from 'react-number-format';
import moment from 'moment';

export const renderPaymentMethod = trade => {
  switch (trade.payment_method) {
    case 'ach':
      return 'ACH';

    case 'credit_card':
      return 'Credit Card';

    case 'ethereum':
      return 'Ethereum';

    case 'check':
      return 'Check';

    case 'bitcoin':
      return 'Bitcoin';

    case 'wire':
      return 'Wire';

    default:
      trade.payment_method;
  }
};

const InvestmentData = ({ trade }) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 px-6 pb-6 gap-6">
      <Details
        title="Amount"
        data={
          <NumberFormat
            thousandSeparator={true}
            value={trade && parseInt(trade?.totalAmount)}
            displayType={'text'}
            prefix={`$`}
          />
        }
      />
      <Details title="Trade Status" data={trade && trade?.orderStatus} />

      <Details
        title="Total Shares"
        data={
          <NumberFormat
            thousandSeparator={true}
            value={trade && parseInt(trade?.totalShares)}
            displayType={'text'}
            prefix={`$`}
          />
        }
      />

      <Details
        title="offeringId"
        data={<>{trade && trade?.offeringId && <p>{trade?.offeringId}</p>}</>}
      />

      <Details
        title="Payment Type"
        data={
          <>
            <p>{trade?.transactionType}</p>
          </>
        }
      />

      <Details title="E Sign Status" data={trade?.esignStatus} />

      <Details
        title="Created At"
        data={trade && moment(trade.createdDate).format('MMM Do, YYYY')}
      />
    </div>
  );
};

export default InvestmentData;
