import React, { useMemo, useState } from 'react';
import cs from 'classnames';
import moment from 'moment';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'components/Table';
import { Link, useHistory } from 'react-router-dom';
import { fetchOfferings, deleteOfferingById } from 'store/actions/offerings';
import Button from 'components/Button';
import OfferingForm from './Offering.form';
import DropdownMenu from 'components/DropdownMenu';
import { AlertPopupHandler } from 'components/AlertPopup';
import Card from 'components/Card';
import { useMedia } from 'react-use';
import CanAccessComponent, {
  getCurrentPagePermissions,
} from 'routes/CanAccessComponent';

const Offerings = () => {
  const [modal, setModal] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const isMaxWidth640px = useMedia('(max-width: 640px)');

  const offeringsData = useSelector(({ offerings }) => {
    return get(offerings, 'offerings.data.data', []);
  });

  const offeringsMetadata = useSelector(({ offerings }) =>
    get(offerings, 'offerings.data.meta', {})
  );

  const handleTableChange = async ({ pageIndex, sortBy, pageSize, query }) => {
    let sort = '';
    if (sortBy.length) {
      sort = sortBy[0].desc ? `-${sortBy[0].id}` : sortBy[0].id;
    }
    await dispatch(fetchOfferings(pageIndex + 1, sort, query, pageSize));
  };

  const confirmDelete = async data => {
    await dispatch(deleteOfferingById(data?._id.toString()));
    dispatch(fetchOfferings());
  };

  const handleOfferDelete = data => () => {
    AlertPopupHandler.open({
      type: 'error',
      text: `You are about to delete ${data.name} Offer. Do you want to continue?`,
      onConfirm: confirmDelete,
      data,
    });
  };

  const onModalClose = () => {
    setModal(false);
    setViewMode(null);
    setData({});
  };

  const columns = useMemo(
    () => [
      {
        Header: () => 'Name',
        accessor: 'name',
        Cell: ({ value, row }) =>
          value ? (
            <div className="flex items-start">
              <Link to={`/offerings/${row.original._id}`}>{value}</Link>
            </div>
          ) : null,
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'IssueName',
        accessor: 'issueName',
      },
      {
        Header: () => <div className="flex justify-end w-full">Created On</div>,
        accessor: 'createdAt',
        Cell: ({ value, row }) => (
          <div className="flex justify-end">
            <span className="flex items-center mr-4">
              {value ? moment(value).format('MMM Do, YYYY') : null}
            </span>
            <DropdownMenu
              options={[
                {
                  label: 'View Offering',
                  onClick: () => history.push(`/offerings/${row.original._id}`),
                },
                {
                  label: 'Edit Offering',
                  onClick: () => {
                    setViewMode('edit');
                    setData({ ...row.original });
                    setModal(true);
                  },
                },
                {
                  label: 'Delete Offering',
                  onClick: handleOfferDelete(row.original),
                  isDisabled: row.original?.offerings?.some(o => o.is_active),
                },
              ]}
            />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const availablePermissions = useSelector(({ roles }) =>
    get(roles, 'availablePermissions.data', [])
  );

  const permissionsForPage = getCurrentPagePermissions(
    availablePermissions,
    'offerings'
  );

  return (
    <CanAccessComponent allowedPermissions={permissionsForPage}>
      <Card>
        <div
          className={cs('flex justify-between sm:items-center mb-2', {
            'flex-col': isMaxWidth640px,
          })}
        >
          <Card.Header
            title={`Offerings ${
              offeringsMetadata && offeringsMetadata.totalResults
                ? `(${offeringsMetadata.totalResults})`
                : ''
            }`}
          />
          <Button
            className="mt-2 sm:mt-0"
            onClick={() => setModal(!modal)}
            label="Add Offering"
          />
        </div>
        <Card.Content className="h-full flex">
          <Table
            columns={columns}
            data={offeringsData || []}
            total={1}
            fetchData={handleTableChange}
          />
          {modal ? (
            <OfferingForm
              data={data}
              viewMode={viewMode}
              onModalClose={onModalClose}
            />
          ) : null}
        </Card.Content>
      </Card>
    </CanAccessComponent>
  );
};

export default Offerings;
