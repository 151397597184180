import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Eye, EyeOff } from 'react-feather';
import Auth from 'layouts/Auth.js';
import { useDispatch } from 'react-redux';
import { doLogin } from 'store/actions/auth';
import Input from 'components/Input';
import Button from 'components/Button';
import { yupResolver } from '@hookform/resolvers/yup';

const Login = () => {
  // Show eye password to text
  const [passwordShow, setPasswordShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(
      Yup.object({
        email: Yup.string()
          .email('Please enter valid Email')
          .required('Email is Required'),
        password: Yup.string().required('Password is Required'),
      })
    ),
  });

  const onSubmit = async values => {
    setLoading(true);
    await dispatch(doLogin(values));
    setLoading(false);
  };

  const togglePassword = () => setPasswordShow(!passwordShow);

  return (
    <Auth>
      <div className="container mx-auto h-full login overflow-hidden ">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-white border-0">
              <div className="flex-auto px-4 lg:px-10 py-10">
                <div className="w-full flex items-center justify-center mb-6">
                  <h1 className="font-medium text-3xl text-defaultColor">
                    Log In
                  </h1>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <Input
                    wrapperClassName="mt-2"
                    id="email"
                    type="email"
                    name="email"
                    label="Email"
                    placeholder="Email Address"
                    error={errors.email?.message}
                    register={register}
                  />
                  <Input
                    wrapperClassName="mt-4"
                    id="password"
                    name="password"
                    label="Password"
                    type={`${passwordShow ? 'text' : 'password'}`}
                    placeholder="Password"
                    error={errors.password?.message}
                    rightIcon={
                      passwordShow ? (
                        <EyeOff onClick={togglePassword} />
                      ) : (
                        <Eye onClick={togglePassword} />
                      )
                    }
                    register={register}
                  />

                  <p className="mt-2 text-sm cursor-pointer flex justify-end">
                    <Link to="/forgot-password">Forgot your password ?</Link>
                  </p>

                  <div className="w-full text-center mt-6">
                    <Button
                      className="full-width"
                      type="submit"
                      label="Log In"
                      isLoading={loading}
                    />
                  </div>

                  <div className="w-full flex items-center justify-center mt-4">
                    <p className="text-defaultColor">
                      Need an account account?&nbsp;
                    </p>
                    <Link to="/auth/register">
                      <p className="text-defaultColor font-bold cursor-pointer">
                        Register
                      </p>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Auth>
  );
};

export default Login;
