import Request from './request';

export const fetchOfferingsList = async (page, sort, query, limit) =>
  await Request.call({
    url: '/offerings/allOfferings',
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
      limit,
    },
  });

export const fetchOfferingData = async id =>
  await Request.call({
    url: `/offerings/id/${id}`,
    method: 'GET',
  });

export const addOffering = async data =>
  await Request.call({
    url: '/offerings/createOffering',
    method: 'POST',
    data,
  });

export const updateOffering = async (id, data) =>
  await Request.call({
    url: `/offerings/${id}`,
    method: 'PUT',
    data,
  });

export const deleteOffering = async id =>
  await Request.call({
    url: `/offerings/${id}`,
    method: 'DELETE',
  });
