import React from 'react';
import 'tailwindcss/tailwind.css';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PublicRoute, ProtectedRoute } from './routes';
import Login from 'views/Login';
import Register from 'views/Register';
import ForgotPassword from 'views/ForgotPassword';
import ResetPassword from 'views/ResetPassword';
import MyAccountView from 'views/MyAccountView';
import MyInvestments from 'views/MyInvestments';
import ProfileView from 'views/Profile';
import VerifyEmail from 'views/VerifyEmail';
import Documents from 'views/Documents';
import Investments from 'views/Investments';
import InvestmentDetails from 'views/InvestmentDetails';
import Investors from 'views/Investors';
import InvestorDetails from 'views/InvestorDetails';
import Reservations from 'views/Reservations';
import ReservationDetails from 'views/ReservationDetails';
import Roles from 'views/Roles';
import RolesDetailsView from 'views/RoleDetails';
import Users from 'views/Users';
import UserDetails from 'views/UserDetails';
import PageNotFound from 'views/PageNotFound/PageNotFound';
import Applications from 'views/Applications';
import ApplicationDetails from 'views/ApplicationDetails';
import Leads from 'views/Leads';
import LeadDetails from 'views/LeadDetails';
import Offerings from 'views/Offerings';
import OfferingDetails from 'views/OfferingsDetailView';
//import DeprecatedDocuments from 'views/DeprecatedDocuments';
import AlertPopup, { AlertPopupHandler } from 'components/AlertPopup';
import DocumentDetails from 'views/DocumentDetails';
//import DeprecatedDocumentDetails from 'views/DeprecatedDocumentDetails';
import News from 'views/News';
import NewsDetails from 'views/NewsDetails';
import NewsEditor from 'views/NewsEditor';
import Permissions from 'views/Permissions';
import PermissionDetails from 'views/PermissionDetails';
import Prospects from 'views/Prospects';
import ProspectDetails from 'views/ProspectDetails';
import PermissionError from 'views/PermissionError/PermissionError';
import NoPaymentMethod from 'views/NoPaymentMethod/NoPaymentMethod';

function App() {
  return (
    <>
      <AlertPopup ref={AlertPopupHandler.setRef} />
      <ToastContainer autoClose={5000} hideProgressBar={false} closeOnClick />
      <Router>
        <Switch>
          <PublicRoute exact path="/auth/login" component={Login} />
          <PublicRoute exact path="/auth/register" component={Register} />
          <PublicRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <PublicRoute exact path="/verify-email" component={VerifyEmail} />
          <PublicRoute exact path="/reset-password" component={ResetPassword} />
          <ProtectedRoute exact path="/my-account" component={MyAccountView} />
          <ProtectedRoute
            exact
            path="/my-investments"
            component={MyInvestments}
          />
          <ProtectedRoute exact path="/profile" component={ProfileView} />

          <ProtectedRoute
            exact
            path="/permission-error"
            component={PermissionError}
          />

          <ProtectedRoute exact path="/documents" component={Documents} />

          <ProtectedRoute
            exact
            path="/documents/:id"
            component={DocumentDetails}
          />
          {/*<ProtectedRoute
            exact
            path="/deprecated-documents"
            component={DeprecatedDocuments}
          />
          <ProtectedRoute
            exact
            path="/deprecated-documents/:id"
            component={DeprecatedDocumentDetails}
          />*/}

          <ProtectedRoute exact path="/investments" component={Investments} />

          <ProtectedRoute
            exact
            path="/investments/:id"
            component={InvestmentDetails}
          />
          <ProtectedRoute exact path="/investors" component={Investors} />

          <ProtectedRoute
            exact
            path="/investors/:id"
            component={InvestorDetails}
          />
          <ProtectedRoute exact path="/offerings" component={Offerings} />
          <ProtectedRoute
            exact
            path="/offerings/:id"
            component={OfferingDetails}
          />
          <ProtectedRoute exact path="/applications" component={Applications} />
          <ProtectedRoute
            exact
            path="/applications/:id"
            component={ApplicationDetails}
          />
          <ProtectedRoute exact path="/leads" component={Leads} />
          <ProtectedRoute exact path="/leads/:id" component={LeadDetails} />
          <ProtectedRoute exact path="/reservations" component={Reservations} />
          <ProtectedRoute
            exact
            path="/reservations/:id"
            component={ReservationDetails}
          />
          <ProtectedRoute exact path="/roles" component={Roles} />
          <ProtectedRoute
            exact
            path="/roles/:id"
            component={RolesDetailsView}
          />
          <ProtectedRoute exact path="/users" component={Users} />

          <ProtectedRoute exact path="/users/:id" component={UserDetails} />
          <ProtectedRoute exact path="/prospects" component={Prospects} />

          <ProtectedRoute
            exact
            path="/prospects/:id"
            component={ProspectDetails}
          />

          <ProtectedRoute exact path="/news" component={News} />

          <ProtectedRoute exact path="/news/editor" component={NewsEditor} />
          <ProtectedRoute exact path="/news/:id" component={NewsDetails} />
          <ProtectedRoute exact path="/permissions" component={Permissions} />

          <ProtectedRoute
            exact
            path="/noPaymentMethod"
            component={NoPaymentMethod}
          />

          <ProtectedRoute
            exact
            path="/permissions/:id"
            component={PermissionDetails}
          />
          <Route
            exact
            path="/"
            render={() => <Redirect to={`/investments`} />}
          />
          <ProtectedRoute component={PageNotFound} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
